import React, { memo, useMemo, useState } from "react";
// eslint-disable-next-line import/no-unresolved
import { react, useChanged } from "uikit";
import "leaflet-polylinedecorator";
import MarkerController from "react-leaflet-enhanced-marker";
import PropsOf from "uikit/src/types/PropsOf";
import { useMap } from "react-leaflet";
import Color from "color";

import ExecutorLocations from "../../services/ExecutorLocations";
import { ExecutorStatus } from "../../services/Executor";
import OrderSource from "../../types/OrderSource";

import LocalController from "./Controller";
import CarMarker from "./components/CarMarker";

const executorStatusToColor = {
	[ExecutorStatus.AVAILABLE]: "#4baf4f",
	[ExecutorStatus.BUSY]: "#ff9d48",
	[ExecutorStatus.DINNER]: "#40a4f4",
	[ExecutorStatus.HOME]: "#3d50b2",
	[ExecutorStatus.CLOSED]: "#7b3636",
};

const MapExecutorLocationsBase = react.withController<
	MapExecutorLocations.PropsBase,
	LocalController
>(({ controller, executorLocations }) => {
	const map = useMap();

	const [focusedExecutorId, setFocusedExecutorId] = useState<
		number | undefined
	>(undefined);

	const markers = useMemo(
		() =>
			executorLocations?.map((executorLocation) => {
				const onOrder = executorLocation.executor.hasActiveOrder;
				let onOrderStatus: "ownOrder" | "otherOrder" | null = null;

				if (onOrder) {
					onOrderStatus = executorLocation.executor.orders?.some(
						(order) =>
							order.source === OrderSource.EXECUTOR &&
							order.status !== "closed",
					)
						? "ownOrder"
						: "otherOrder";
				}

				let mainColor: string;
				let secondaryColor: string;

				if (!executorLocation.executor.isWorking || onOrder) {
					secondaryColor =
						executorStatusToColor[
							executorLocation.executor
								.status as keyof typeof executorStatusToColor
						] ?? "#fff509";

					if (!executorLocation.executor.isWorking) {
						mainColor = "#a0a0a0";
						secondaryColor = Color(secondaryColor)
							.lighten(0.25)
							.hex();
					} else {
						mainColor =
							onOrderStatus === "ownOrder"
								? "#654cad"
								: "#e5503b";
					}
				} else {
					mainColor =
						executorStatusToColor[executorLocation.executor.status];
					secondaryColor = "#fff509";
				}

				return (
					<MarkerController
						key={executorLocation.id}
						icon={
							<CarMarker
								focused={
									executorLocation.executor.id ===
									focusedExecutorId
								}
								mainColor={mainColor}
								secondaryColor={secondaryColor}
								alias={executorLocation.executor.alias}
							/>
						}
						draggable={false}
						position={executorLocation.point}
					/>
				);
			}),
		[executorLocations, focusedExecutorId],
	);

	controller.setContext({
		setFocusedExecutorId,

		map,
		executorLocations,
		focusedExecutorId,
	});

	useChanged(() => {
		controller.focus();
	}, focusedExecutorId);

	return <>{markers}</>;
}, LocalController);

const MapExecutorLocations = memo(MapExecutorLocationsBase);

declare namespace MapExecutorLocations {
	type Controller = LocalController;

	interface PropsBase {
		executorLocations: ExecutorLocations.Model[];
	}

	type Props = PropsOf<typeof MapExecutorLocations>;
}

export default MapExecutorLocations;
