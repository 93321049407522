import { LatLngLiteral } from "leaflet";

import { defaultLanguage } from "../../assets/languages/langs";
import { store } from "../../redux/store";
import SearchType from "../../types/SearchType";
import Base from "../Base";
import Language from "../Language";

class Map extends Base {
	/**
	 * @deprecated Questionable code, use search2
	 */
	public static async deprecatedSearch(
		query: Map.Search.Query,
		params: Map.Search.Options,
	) {
		const res = await this.request((prpc) =>
			prpc.theirsModel.map.search(query, params),
		);

		if (!Array.isArray(res?.objects)) return res;

		const currentLang =
			store.getState().settings.map.mapLanguage || defaultLanguage;

		const processedData: Map.Search.DeprecatedResponse[] = res.objects.map(
			(item) => ({
				type: item.type,
				...item.properties.language[currentLang],
				coordinates: item.properties.geoCentroid,
				bbox: item.properties.bbox,
				geometry: item.properties.geometry,
			}),
		);
		// console.log("processedData:", processedData);

		return processedData;
	}

	public static async search(
		query: Map.Search.Query,
		options: Map.Search.Options,
	): Promise<Map.Search.Response> {
		const response = await this.request((prpc) =>
			prpc.theirsModel.map.search(query, this.toSearchOptions(options)),
		);

		return {
			resolution: response.resolution,
			objects: response.objects.map((object) => {
				const { id, type } = object;
				const language = Object.keys(
					object.properties.language,
				)[0] as Language;
				const { properties } = object;
				const address = object.properties.language[language];

				const item: Map.Search.Object = {
					id,
					type,
					language,
					bbox: properties.bbox,
					geometry: properties.geometry,
					coordinates: properties.geoCentroid,

					country: address.country,
					countryCode: address.countryCode,

					settlement: address.settlement,
					settlementClass: address.settlementClass,
					settlementType: address.settlementType,
					district: address.district,
					region: address.region,

					sector: address.sector,

					street: address.street,
					streetType: address.streetType,
					houses: address.streetHouses,

					name: address.name,

					number: address.number || "",

					house: address.house || "",
					entrance: address.entrance || "",

					customHouse: address.customHouse || "",
					customStreet: address.customStreet || "",
					title: address.title || "",
				};

				return item;
			}),
		};
	}

	private static toSearchOptions(options: Map.Search.Options) {
		return {
			customerId: options.customerId,
			searchType: options.searchType,
			lang: options.language,
			limit: options.limit,
			country: options.country,
			near: options.near,
			responseOrder: options.responseOrder,
			responseOrderBySettlement: options.responseOrderBySettlement,
			taxiServiceId: options.taxiServiceId,
		};
	}
}

declare namespace Map {
	namespace Search {
		type Query = string;

		/**
		 * @deprecated Used in deprecated search
		 */
		interface DeprecatedResponse {
			type: Type;
			language: Language;
			bbox: LatLngLiteral[];
			geometry?: {
				type: string;
				coordinates:
					| LatLngLiteral[]
					| LatLngLiteral[][]
					| LatLngLiteral;
			};
			coordinates: string;
			country: string;
			countryCode: string;

			settlement?: string;
			settlementType?: string;
			region?: string;
			district?: string;

			street?: string;
			streetType?: string;
			streetHouses?: any[];

			number?: string;
			house?: string;
			entrance?: string;
			customHouse?: string;

			name?: string;
			customStreet?: string;
			title?: string;
		}

		interface PolygonGeometry {
			type: "Polygon";
			coordinates: LatLngLiteral[][];
		}

		interface MultiPolygonGeometry {
			type: "MultiPolygon";
			coordinates: LatLngLiteral[][][];
		}

		interface LineGeometry {
			type: "LineString";
			coordinates: LatLngLiteral[];
		}

		interface MultilineGeometry {
			type: "MultiLineString";
			coordinates: LatLngLiteral[][];
		}

		interface PointGeometry {
			type: "Point";
			coordinates: LatLngLiteral;
		}

		type Geometry =
			| PointGeometry
			| LineGeometry
			| MultilineGeometry
			| PolygonGeometry
			| MultiPolygonGeometry;

		interface House {
			id: string;
			number: string;
		}

		interface Object {
			streetHouses?: any;
			id: string;
			type: Type;
			language: Language;
			bbox: LatLngLiteral[];
			geometry: Geometry;
			coordinates: LatLngLiteral;

			country: string;
			countryCode: string;

			settlement?: string;
			settlementClass?: string;
			settlementType?: string;

			region?: string;

			district?: string;

			sector?: { id: number; name: Record<Language, string> } | null;
			street?: string;
			streetType?: string;

			houses?: House[];

			name?: string;
			number?: string;
			entrance?: string;
			house?: string;
			customHouse?: string;
			customStreet?: string;
			title?: string;
		}

		interface Response {
			resolution: "visicom";
			// eslint-disable-next-line @typescript-eslint/ban-types
			objects: Object[];
		}

		interface Options {
			customerId?: number;
			searchType: Type | Type[];
			language?: Language;
			country?: string;
			near?: {
				point: LatLngLiteral;
				radius?: number;
				zoom?: number;
			};
			/**
			 * Limit of objects in response.
			 */
			limit?: number;
			/**
			 * Return only objects of the specified taxi service.
			 * Used for the 'localObject' search type
			 */
			taxiServiceId?: number;
			/**
			 * Response order of objects
			 */
			responseOrder?: ResponseOrderType | ResponseOrderType[];
			/**
			 * Sorts by settlements given the order of 'responseOrder'
			 */
			responseOrderBySettlement?: boolean;
		}

		type ResponseOrderType =
			| "address"
			| "street"
			| "country"
			| "settlement"
			| "settlementDistrict"
			| "region"
			| "district"
			| "road"
			| "adminLevel3"
			| "adminPlace"
			| "localObject"
			| "object";

		type Type = "price_zone" | "parking" | SearchType;
	}
}

export default Map;
