import React, { useMemo } from "react";
// import { isNumber } from "lodash";

import ExecutorReport from "../../../../../../../../../../services/ExecutorReport";
import formatNumber from "../../../../../../../../../../utils/formatNumber";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";

const PenaltyAmountCellContent: React.FC<
	PenaltyAmountCellContent.Props
> = () => {
	// const content = useMemo(() => {
	// 	if (!isNumber(item?.ordersCounters?.bonus?.amount))
	// 		return formatNumber(0);
	// 	return formatNumber(0);
	// }, [item?.ordersCounters?.bonus?.amount]);
	const content = useMemo(() => formatNumber(0), []);
	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace PenaltyAmountCellContent {
	interface Props {
		item: ExecutorReport.Model;
	}
}

export default PenaltyAmountCellContent;
