import React, { Dispatch, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { MultilineTextBox, Select } from "uikit";

import PhoneBlacklist from "../../../../../../../../services/PhoneBlacklist";
import useObjectEditor from "../../../../../../../../hooks/useObjectEditor";

import Root from "./components/Root";

export enum ClientCancelReason {
	TooExpensive = "too_expensive",
	TariffError = "tariff_calculation",
	CarIsNotSelected = "car-is-not-selected",
}

const Content: React.FC<Content.Props> = ({ value, onChange }) => {
	const { t } = useTranslation();
	const valueEditor = useObjectEditor(value, onChange);

	const levelValue = valueEditor.useGetter("level");
	const levelOnChange = valueEditor.useSetter("level");

	const commentValue = valueEditor.useGetter("comment");
	const commentOnChange = valueEditor.useSetter("comment");

	const clientBlockOptions = useMemo(
		() => [
			{
				key: PhoneBlacklist.Level.WARNING,
				label:
					t(
						"pages.mainPage.pages.orders.addClientToBlacklistModal.content.str200",
					) ?? "",
				value: PhoneBlacklist.Level.WARNING,
			},
			{
				key: PhoneBlacklist.Level.BLOCKED,
				label:
					t(
						"pages.mainPage.pages.orders.addClientToBlacklistModal.content.str201",
					) ?? "",
				value: PhoneBlacklist.Level.BLOCKED,
			},
			{
				key: PhoneBlacklist.Level.BAN_CALLS,
				label:
					t(
						"pages.mainPage.pages.orders.addClientToBlacklistModal.content.str202",
					) ?? "",
				value: PhoneBlacklist.Level.BAN_CALLS,
			},
		],
		[t],
	);

	return (
		<Root gaps="16px*">
			<Select
				value={levelValue}
				options={clientBlockOptions}
				onSelect={(option) => levelOnChange(option.value)}
			/>
			<MultilineTextBox
				value={commentValue}
				style={{ height: 80 }}
				placeholder={
					t(
						"pages.mainPage.pages.orders.addClientToBlacklistModal.content.str0",
					) ?? ""
				}
				onChange={commentOnChange}
			/>
		</Root>
	);
};

declare namespace Content {
	interface Value {
		level: PhoneBlacklist.Level;
		comment?: string;
	}

	interface Props {
		value: Value;

		onChange: Dispatch<Value>;
	}
}

export default Content;
