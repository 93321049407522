/* eslint-disable no-shadow */

import React, { useMemo, useState } from "react";
import { Column } from "uikit";
import { isString } from "lodash";
import TabRoot from "../../TabRoot";
import AddressTable from "./components/Table";
import Client from "../../../../../../../../../../../../../services/Client";
import useObjectEditor from "../../../../../../../../../../../../../hooks/useObjectEditor";
import FavoriteAddress from "../../../../../../../../../../../../../services/FavouriteAddress";
import MountableSubscription from "../../../../../../../../../../../../../components/MountableSubscription";

declare namespace AddressesTab {
	type Value = Pick<Client.Model, "additionalFields"> &
		Partial<Pick<Client.Model, "id">>;

	interface Props {
		visible: boolean;

		value: Value;
		onChange: (value: Value) => void;
	}
}

const AddressesTab: React.FC<AddressesTab.Props> = ({
	value,
	visible,
	onChange,
}) => {
	const subscribeOptions = useMemo<FavoriteAddress.SubscribeOptions>(
		() => ({ customerId: value.id }),
		[value.id],
	);
	const [addresses, setAddresses] = useState<FavoriteAddress.Model[]>([]);

	const [sort, setSort] = useState<AddressTable.Sort>({
		column: "rideNumber",
		type: "asc",
	});

	const tableData = useMemo<AddressTable.Row[]>(() => {
		const order = sort.type === "asc" ? 1 : -1;

		const processedData = addresses.map((item) => ({
			key: item.key,
			rideNumber: item.rideNumber,

			street: [item.address.streetType, item.address.street].join(" "),
			settlement: [
				item.address.settlementType,
				item.address.settlement,
			].join(" "),
			house: item.address.house || "",
		}));

		return isString(sort.column)
			? processedData.sort((a, b) => {
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					const column1 = a[sort.column!];
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					const column2 = b[sort.column!];

					const comparison =
						typeof column1 === "number" &&
						typeof column2 === "number"
							? column1 - column2
							: String(column1).localeCompare(String(column2));

					return comparison * order;
			  })
			: processedData;
	}, [addresses, sort.column, sort.type]);

	const valueEditor = useObjectEditor(value, onChange);
	const setDefaultAddress = valueEditor
		.usePropertyEditor("additionalFields")
		.useSetter("defaultAddress");

	return (
		<TabRoot hasPaddings={false} visible={visible}>
			{subscribeOptions.customerId != null && (
				<MountableSubscription
					subscribeOptions={subscribeOptions}
					Service={FavoriteAddress}
					onModelsChange={setAddresses}
				/>
			)}

			<Column sizes="1fr" maxedWidth maxedHeight>
				<AddressTable
					data={tableData}
					sort={sort}
					onSortChange={setSort}
					defaultAddress={value.additionalFields.defaultAddress}
					onChangeDefaultAddress={setDefaultAddress}
				/>
			</Column>
		</TabRoot>
	);
};

export default AddressesTab;
