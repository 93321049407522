import React, { Dispatch, RefAttributes, memo, useMemo } from "react";
import { react, useRefWithSetter } from "uikit";

import useObjectEditor from "../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import { ModelId } from "../../../../../../../../../../../../../../../../types/ModelId";
import { AccountsTabs } from "../../constants/tabs";

import Root from "./components/Root";
import InternalController from "./Controller";
import Main from "./tabs/Main";
import Employees from "./tabs/Employees";
import Orders from "./tabs/Orders";
import Transactions from "./tabs/Transactions";
import Files from "./tabs/Files";
import History from "./tabs/History";

const ContentBase = react.withController<Content.PropsBase, Content.Controller>(
	({
		controller,
		id,
		companyId,
		tab,
		generalPaymentAccounts,
		generalPaymentTransactions,
		ignoreGeneralThresholdBalance,
		allowGeneralBonusBalance,
		value,
		onChange,
		checkCards,
		setCheckCards,
	}) => {
		const [mainTabRef, setMainTabRef] = useRefWithSetter<Main.Ref | null>(
			null,
		);
		controller.setContext({ mainTabRef });

		const valueEditor = useObjectEditor(value, onChange);

		const mainTabValue = valueEditor.usePicker([
			"paymentAccounts",
			"paymentTransactions",
			"activationDate",
			"completionDate",
			"additionalFields",
			"accessAllEmployeeToPersonalApp",
			"isAccessToCorporateAccount",
			"corporateLogin",
			"corporatePassword",
			"useGeneralMainBalance",
			"useGeneralBonusBalance",
			"useGeneralThresholdBalance",
			"ignoreThresholdBalance",
			"allowBonusBalance",
		]);

		const filesTabValue = valueEditor.usePicker([
			"otherFiles",
			"personalFiles",
		]);

		const assigner = valueEditor.useAssigner();

		const options = useMemo(
			() => ({ checkIds: id ? [id] : undefined }),
			[id],
		);

		const actualTab = useMemo(
			() => (
				<>
					<Main
						visible={tab === AccountsTabs.MAIN}
						ref={setMainTabRef}
						generalPaymentAccounts={generalPaymentAccounts}
						generalPaymentTransactions={generalPaymentTransactions}
						ignoreGeneralThresholdBalance={
							ignoreGeneralThresholdBalance
						}
						allowGeneralBonusBalance={allowGeneralBonusBalance}
						companyId={companyId}
						checkId={id}
						value={mainTabValue}
						onChange={assigner}
					/>
					<Employees
						visible={tab === AccountsTabs.EMPLOYEES}
						checkCards={checkCards}
						setCheckCards={setCheckCards}
					/>
					<Orders
						visible={tab === AccountsTabs.ORDERS}
						entityId={id}
						options={options}
					/>
					<Transactions
						visible={tab === AccountsTabs.TRANSACTIONS}
						paymentAccounts={mainTabValue.paymentAccounts}
					/>
					<Files
						visible={tab === AccountsTabs.FILES}
						value={filesTabValue}
						onChange={assigner}
					/>

					<History
						visible={tab === AccountsTabs.HISTORY}
						value={[]}
					/>
				</>
			),
			[
				assigner,
				checkCards,
				companyId,
				filesTabValue,
				id,
				mainTabValue,
				options,
				setCheckCards,
				setMainTabRef,
				generalPaymentAccounts,
				generalPaymentTransactions,
				ignoreGeneralThresholdBalance,
				allowGeneralBonusBalance,
				tab,
			],
		);

		return <Root>{actualTab}</Root>;
	},
	InternalController,
);
const Content = memo(ContentBase);

declare namespace Content {
	type Ref = InternalController | null;
	type Controller = InternalController;

	interface PropsBase {
		id?: ModelId;
		companyId: number | undefined;
		tab: AccountsTabs;
		generalPaymentAccounts: Main.Props["generalPaymentAccounts"];
		generalPaymentTransactions: Main.Props["generalPaymentTransactions"];
		allowGeneralBonusBalance: boolean;
		ignoreGeneralThresholdBalance: boolean;
		value: Value;
		onChange: Dispatch<Value>;
		checkCards: Employees.Value;
		setCheckCards: (value: Employees.Value) => void;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	interface Value extends Main.Value, Files.Value {}
}

export default Content;
