import React, { Dispatch, memo } from "react";
import { useTranslation } from "react-i18next";

import Counterparty from "../../../../../../../../../../../../../../services/Counterparty";
import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";
import LabeledField from "../../../../../../../../../../../../../../components/LabeledField";
import Transaction from "../Transaction";

const Transactions: React.FC<Transactions.Props> = memo(
	({
		value,
		onChange,
		path,
		ignoreGeneralThresholdBalance,
		allowGeneralBonusBalance,
	}) => {
		const { t } = useTranslation();

		const editor = useObjectEditor(value, onChange);

		const main = editor.useGetter("main");
		const setMain = editor.useSetter("main");

		const bonus = editor.useGetter("bonus");
		const setBonus = editor.useSetter("bonus");

		const threshold = editor.useGetter("threshold");
		const setThreshold = editor.useSetter("threshold");

		return (
			<>
				<LabeledField
					label={
						t([`${path}.main`, "balances.main", "Balance"]) || ""
					}
				>
					<Transaction value={main} type="main" onChange={setMain} />
				</LabeledField>
				<LabeledField
					label={
						t([
							`${path}.bonus`,
							"balances.bonus",
							"Bonus Balances",
						]) || ""
					}
				>
					<Transaction
						disabled={
							ignoreGeneralThresholdBalance ||
							!allowGeneralBonusBalance
						}
						value={bonus}
						type="bonus"
						onChange={setBonus}
					/>
				</LabeledField>
				<LabeledField
					label={
						t([
							`${path}.threshold`,
							"balances.threshold",
							"Shutdown threshold",
						]) || ""
					}
				>
					<Transaction
						disabled={ignoreGeneralThresholdBalance}
						value={threshold}
						type="threshold"
						onChange={setThreshold}
					/>
				</LabeledField>
			</>
		);
	},
);

declare namespace Transactions {
	interface Props {
		path?: string;
		value: Value;
		onChange: Dispatch<Value>;
		allowGeneralBonusBalance: boolean;
		ignoreGeneralThresholdBalance: boolean;
	}

	type Value = Counterparty.New["paymentTransactions"];
}

export default Transactions;
