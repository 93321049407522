import React, { useCallback, useEffect, useMemo, useState } from "react";
import { cloneDeep, compact, isEqual, uniq } from "lodash";
import { useTranslation } from "react-i18next";

import ServiceExecutor from "../../../../../../services/Executor";
import Role from "../../../../../../services/Role";
import WorkShift from "../../../../../../services/WorkShift";
import { useTypedSelector } from "../../../../../../redux/store";
import useModelSubscribe from "../../../../../../hooks/useModelSubscribe";
import useModelSubscribe2 from "../../../../../../hooks/useModelSubscribe2";
import { useSort } from "../../../../../../hooks/useTableSettings";
import mapByKey from "../../../../../../utils/mapByKey";
import fileToIds from "../../../../../../utils/fileToIds";
import DeleteModal from "../../../../../../components/DeleteModal";
import {
	StyledColumn,
	StyledP,
	StyledRow,
} from "../../../../../../components/common";

import { ErrorResponse, getErrorByMessage } from "./constants/errors";
import Content from "./components/Content";
import Header from "./components/Header";
import EditModal from "./components/EditModal";
import Root from "./components/Root";

const Executors: React.FC = () => {
	const { t } = useTranslation();
	const settingsLanguage = useTypedSelector(
		(state) => state.settings.map.mapLanguage,
	);

	const { sortMethod: sort, setSortMethod: setSort } = useSort(
		"accounts.executors",
		"executor",
	);

	const currentUser = useTypedSelector((state) => state.account.user);

	const roleData = useModelSubscribe2({ assignableTo: "executor" }, Role);

	const roles = useMemo(() => roleData.models, [roleData.models]);

	const defaultRole = useMemo(
		() => roles.find((role) => role.default),
		[roles],
	);

	const workShiftData = useModelSubscribe({ default: true }, WorkShift);

	const workShifts = useMemo(
		() => workShiftData?.cache ?? [],
		[workShiftData?.cache],
	);

	const defaultWorkShift = useMemo(
		() => workShifts.find((workShift) => workShift.default),
		[workShifts],
	);

	const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
	const [requestError, setRequestError] = useState<ErrorResponse | null>(
		null,
	);
	const [isSave, setIsSave] = useState<boolean>(false);
	const [preparedData, setPreparedData] =
		useState<EditModal.Value.Validated | null>(null);

	const [headerFilters, setHeaderFilters] = useState<Header.Filters.Value>({
		search: "",
	});

	const modelSubscriptionOptions =
		useMemo<ServiceExecutor.SubscribeOptions>(() => {
			const result: ServiceExecutor.SubscribeOptions = {
				query: headerFilters.search,
			};

			if (sort.column && sort.type)
				result.order = {
					[sort.column as keyof ServiceExecutor.SubscribeOptions["order"]]:
						sort.type,
				};

			return result;
		}, [headerFilters.search, sort.column, sort.type]);

	const modelData = useModelSubscribe2(
		modelSubscriptionOptions,
		ServiceExecutor,
	);

	const modelItems = useMemo(
		() => modelData.models ?? [],
		[modelData.models],
	);

	const modelItemById = useMemo(
		() => mapByKey(modelItems, "id"),
		[modelItems],
	);

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [editingItem, setEditingItem] = useState<EditModal.Value | null>(
		null,
	);
	const [selected, setSelected] = useState<number[]>([]);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const edit = useCallback(
		(id: number) => {
			const item = modelItemById[id];

			setEditingItem(
				cloneDeep({
					id: item.id,

					name: [
						item.person?.lastName ?? "",
						item.person?.firstName ?? "",
						item.person?.fatherName ?? "",
					]
						.join(" ")
						.trim(),

					lastName: item.person?.lastName ?? "",
					firstName: item.person?.firstName ?? "",
					fatherName: item.person?.fatherName ?? "",
					phones:
						item.person?.phones?.map((phone) => phone.number) ?? [],
					login: item.login,
					alias: item.alias,
					taxiServiceId: item.taxiService?.id,
					roleIds: item.roles?.map((role) => role.id) ?? [],
					responsibleDispatcherId: item.responsibleDispatcher?.id,
					workShiftId: item.workShift?.id,
					executorTeamId: item.group?.id,
					executorRateId: item.rate?.id,
					notes: item.notes,
					paymentAccounts: item.paymentAccounts,

					serviceIds:
						item.services?.map((service) => service.id) ?? [],

					passportSeries: item.passportSeries,
					passportNumber: item.passportNumber,
					passportIssuedAt: item.passportIssuedAt
						? new Date(item.passportIssuedAt)
						: undefined,
					passportIssuedBy: item.passportIssuedBy,
					taxId: item.taxId,
					birthAt: item.person?.birthday
						? new Date(item.person.birthday)
						: undefined,
					residenceAddress: item.residenceAddress,
					livingAddress: item.livingAddress,
					passportFiles: item.passportFiles ?? [],
					drivingLicenseSeries: item.drivingLicenseSeries,
					drivingLicenseNumber: item.drivingLicenseNumber,
					drivingLicenseCategory: item.drivingLicenseCategory,
					drivingLicenseIssuedAt: item.drivingLicenseIssuedAt,
					drivingExperience: item.drivingExperience,
					drivingLicenseFiles: item.drivingLicenseFiles ?? [],
					bankCardNumber: String(item.bankCardNumber ?? ""),
					bankCardExpiresAt: item.bankCardExpiresAt,
					bankCardNote: item.bankCardNotes,
					IESeries: item.IESeries,
					IECertificateNumber: String(item.IECertificateNumber ?? ""),
					IERegisteredAt: item.IERegisteredAt,
					IEUSREOU: item.IEUSREOU,
					IEIBAN: item.IEIBAN,
					IEFiles: item.IEFiles ?? [],

					carIds: item.cars?.map((car) => car.id) ?? [],

					otherFiles: item.otherFiles ?? [],
					taxIdFiles: [],
					avatarFiles: item.avatarFiles ?? [],
					licenseFiles: item.licenseFiles ?? [],

					active: item.active,

					createdAt: item.createdAt ?? undefined,
					priority: item.priority,
					permits: item.permits,
				}),
			);
		},
		[modelItemById],
	);

	const headerCanEdit = useMemo(
		() => selected.length === 1,
		[selected.length],
	);

	const headerCanDelete = useMemo(
		() => selected.length !== 0,
		[selected.length],
	);

	const headerOnAdd = useCallback(() => {
		setEditingItem({
			paymentAccounts: [],
			firstName: "",
			phones: [],
			login: "",
			password: "",
			alias: "",
			roleIds:
				typeof defaultRole?.id === "undefined" ? [] : [defaultRole.id],
			notes: "",
			responsibleDispatcherId: currentUser?.id,
			workShiftId: defaultWorkShift?.id,

			serviceIds: [],

			passportSeries: "",
			passportNumber: "",
			passportIssuedBy: "",
			taxId: "",
			residenceAddress: "",
			livingAddress: "",
			passportFiles: [],
			drivingLicenseSeries: "",
			drivingLicenseNumber: "",
			drivingLicenseCategory: "",
			drivingLicenseFiles: [],
			bankCardNumber: "",
			bankCardNote: "",
			IESeries: "",
			IECertificateNumber: "",
			IEUSREOU: "",
			IEIBAN: "",
			IEFiles: [],
			paymentTransactions: {
				main: [],
				bonus: [],
			},

			carIds: [],

			otherFiles: [],
			taxIdFiles: [],
			avatarFiles: [],
			licenseFiles: [],

			active: true,

			priority: 0,
			permits: {
				isPaymentCommissionsThroughPaymentSystem: false,
				isAcceptanceOfCustomerPaymentsToPersonalAccount: false,
				isAllowToSelectCarClassesInApp: false,
			},
		});
	}, [currentUser?.id, defaultRole?.id, defaultWorkShift?.id]);

	const headerOnEdit = useCallback(() => {
		edit(selected[0]);
	}, [edit, selected]);

	const headerOnDelete = useCallback(() => {
		setShowDeleteModal(true);
	}, []);

	const contentOnEdit = useCallback((item) => edit(item.id), [edit]);

	const contentOnLoadMore = useCallback(() => {}, []);

	const checkedExecutors = useMemo(
		() => modelItems.filter((c) => selected.includes(c.id)),
		[modelItems, selected],
	);

	const content = useMemo(
		() => (
			<>
				<Header
					checkedExecutors={checkedExecutors}
					filters={headerFilters}
					canEdit={headerCanEdit}
					canDelete={headerCanDelete}
					onChangeFilters={setHeaderFilters}
					onAdd={headerOnAdd}
					onEdit={headerOnEdit}
					onDelete={headerOnDelete}
				/>
				<Content
					selected={selected}
					sort={sort}
					loading={false}
					data={modelItems}
					onChangeSelected={setSelected}
					onChangeSort={setSort}
					onEdit={contentOnEdit}
					onLoadMore={contentOnLoadMore}
				/>
			</>
		),
		[
			checkedExecutors,
			contentOnEdit,
			contentOnLoadMore,
			headerCanDelete,
			headerCanEdit,
			headerFilters,
			headerOnAdd,
			headerOnDelete,
			headerOnEdit,
			modelItems,
			selected,
			setSort,
			sort,
		],
	);

	const editModalOnCancel = useCallback(() => {
		setEditingItem(null);
	}, []);

	const editModalOnSave = useCallback(
		async (newItem: EditModal.Value.Validated) => {
			if (isEqual(editingItem, newItem)) {
				setEditingItem(null);

				return;
			}
			let isAllOk = true;
			if (isSave) return;

			const otherFileIds = await fileToIds(newItem.otherFiles);
			const passportFileIds = await fileToIds(newItem.passportFiles);
			const drivingLicenseFileIds = await fileToIds(
				newItem.drivingLicenseFiles,
			);
			const IEFileIds = await fileToIds(newItem.IEFiles);
			const avatarFileIds = await fileToIds(newItem.avatarFiles);
			const licenseFileIds = await fileToIds(newItem.licenseFiles);

			const bankCardNumberString = newItem.bankCardNumber.replace(
				/\D/g,
				"",
			);
			let bankCardNumber: number | undefined;

			if (bankCardNumberString)
				bankCardNumber = Number(bankCardNumberString);

			const newProperties: ServiceExecutor.Model.New = {
				lastName: newItem.lastName,
				firstName: newItem.firstName,
				fatherName: newItem.fatherName,
				phones: newItem.phones,
				priority: newItem.priority,

				login: newItem.login,
				password: newItem.password,

				alias: newItem.alias,
				taxiServiceId: newItem.taxiServiceId,
				roleIds: newItem.roleIds,
				responsibleDispatcherId: newItem.responsibleDispatcherId,
				workShiftId: newItem.workShiftId,
				rateId: newItem.executorRateId,
				groupId: newItem.executorTeamId,

				serviceIds: newItem.serviceIds,

				passportSeries: newItem.passportSeries,
				passportNumber: newItem.passportNumber,
				passportIssuedAt: newItem.passportIssuedAt,
				passportIssuedBy: newItem.passportIssuedBy,
				taxId: newItem.taxId,
				birthAt: newItem.birthAt,
				residenceAddress: newItem.residenceAddress,
				livingAddress: newItem.livingAddress,

				drivingLicenseSeries: newItem.drivingLicenseSeries,
				drivingLicenseNumber: newItem.drivingLicenseNumber,
				drivingLicenseCategory: newItem.drivingLicenseCategory,
				drivingLicenseIssuedAt: newItem.drivingLicenseIssuedAt,
				drivingExperience: newItem.drivingExperience,

				bankCardNumber,
				bankCardExpiresAt: newItem.bankCardExpiresAt,
				bankCardNotes: newItem.bankCardNote,

				IESeries: newItem.IESeries,
				IECertificateNumber: newItem.IECertificateNumber,
				IERegisteredAt: newItem.IERegisteredAt,
				IEUSREOU: newItem.IEUSREOU,
				IEIBAN: newItem.IEIBAN,

				carIds: newItem.carIds,

				otherFileIds,
				passportFileIds,
				drivingLicenseFileIds,
				IEFileIds,
				avatarFileIds,
				licenseFileIds,
				permits: newItem.permits,

				paymentTransactions:
					newItem.paymentTransactions as unknown as ServiceExecutor.Model.New["paymentTransactions"],
			};

			if (typeof newItem.id === "number") {
				const res = await ServiceExecutor.update({
					id: newItem.id,

					...newProperties,
				});
				isAllOk = !(res?.error || res === null);
				if (res.error) {
					const { message, details } = res.data;
					setRequestError(getErrorByMessage(message, true, details));
				}
			} else {
				const res = await ServiceExecutor.store(newProperties);
				isAllOk = !(res?.error || res === null);
				if (res.error) {
					const { message, details } = res.data;
					setRequestError(getErrorByMessage(message, false, details));
				}
			}
			setIsSave(false);

			if (isAllOk) {
				setEditingItem(null);
			} else {
				setPreparedData(newItem);
				setShowErrorModal(true);
			}
		},
		[editingItem, isSave],
	);

	const deleteModalOnCancel = useCallback(() => {
		setShowDeleteModal(false);
	}, []);

	const deleteModalOnConfirm = useCallback(async () => {
		setShowDeleteModal(false);

		await ServiceExecutor.destroy(selected);

		setSelected([]);
	}, [selected]);

	useEffect(() => {
		if (editingItem?.id) {
			const executor = modelItemById?.[editingItem.id];

			if (executor) {
				setEditingItem({
					...editingItem,
					paymentAccounts: executor?.paymentAccounts ?? [],
					executorRateId: executor?.rate?.id,
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modelItems]);

	// --- Error ---
	const [errorPhone, setErrorPhone] = useState<any>(null);

	const errorPhoneFormat = useCallback(
		async ({ translation, baseText, details }: ErrorResponse) => {
			const text = t([translation, baseText]);

			const getPhone = details
				?.map?.((item) => item?.metadata?.phones)
				?.flat();

			const phones: string[] = compact(uniq(getPhone || []));

			const getExecutorsByPhone = phones.map((phone) =>
				ServiceExecutor.getByPhone(phone),
			);
			const existExecutor = await Promise.all(getExecutorsByPhone);

			const data = existExecutor?.map((item) => {
				if (!item) return null;

				return `${item?.person?.surname ?? ""} ${
					item?.person?.name ?? ""
				} ${item?.person?.fatherName ?? ""} ${
					item?.callSign ? `(${item?.callSign})` : ""
				}`.trim();
			});
			setErrorPhone({
				status: true,
				text,
				data: compact(data),
				phones,
			});
		},
		[t],
	);

	const errorModalLabel = useMemo(() => {
		if (!requestError) return "";

		if (requestError.additional && requestError.isPhone) {
			errorPhoneFormat(requestError);
			setShowErrorModal(false);
			return (
				t("pages.mainPage.pages.accounts.tabs.executors.str200") ?? ""
			);
		}

		return t([requestError.translation, requestError.baseText]);
	}, [requestError, t, errorPhoneFormat]);

	const errorModalLabelElem = useMemo(() => {
		if (!errorPhone) return "";

		return (
			<StyledColumn gap="10px">
				<StyledP
					m="0 0 15px 0"
					justify="center"
					alignItems="center"
					textAlign="center"
					font={{
						size: "16px",
						line: "21px",
						fw: 700,
						fs: "normal",
					}}
					text={{ ws: "normal" }}
				>
					{errorPhone.text}
				</StyledP>
				<StyledRow gap="10px">
					{errorPhone.phones.length ? (
						errorPhone.phones.map((item, key) => (
							<StyledP
								key={key}
								font={{
									size: "16px",
									line: "21px",
									fw: 700,
									fs: "normal",
								}}
								text={{ ws: "normal" }}
							>
								{item}
							</StyledP>
						))
					) : (
						<></>
					)}
				</StyledRow>
				{errorPhone.data.length ? (
					errorPhone.data.map((item, key) => (
						<StyledP
							key={key}
							font={{
								size: "16px",
								line: "21px",
								fw: 700,
								fs: "normal",
							}}
							text={{ ws: "normal" }}
						>
							{item}
						</StyledP>
					))
				) : (
					<></>
				)}
			</StyledColumn>
		);
	}, [errorPhone]);

	return (
		<Root sizes="auto! 1fr" gaps="16px" maxedWidth maxedHeight>
			{content}
			{editingItem && (
				<EditModal
					value={editingItem}
					language={settingsLanguage}
					onCancel={editModalOnCancel}
					onSave={editModalOnSave}
				/>
			)}
			{showDeleteModal && (
				<DeleteModal
					onCancel={deleteModalOnCancel}
					onConfirm={deleteModalOnConfirm}
				/>
			)}
			{showErrorModal && (
				<DeleteModal
					label={errorModalLabel}
					onCancel={() => {
						setShowErrorModal(false);
						setPreparedData(null);
						setRequestError(null);
						setErrorPhone(null);
					}}
					isConfirm
					confirmButtonLabel={
						requestError?.canRetry
							? t(
									"pages.mainPage.pages.accounts.tabs.executors.str201",
							  ) ?? ""
							: t(
									"pages.mainPage.pages.accounts.tabs.executors.str202",
							  ) ?? ""
					}
					onConfirm={() => {
						if (requestError?.canRetry && preparedData) {
							editModalOnSave(preparedData);
						}
						setShowErrorModal(false);
						setRequestError(null);
						setErrorPhone(null);
						setIsSave(false);
					}}
				/>
			)}
			{errorModalLabelElem && (
				<DeleteModal
					label={errorModalLabelElem}
					w={"500px"}
					onCancel={() => {
						setShowErrorModal(false);
						setPreparedData(null);
						setRequestError(null);
						setErrorPhone(null);
					}}
					isConfirm
					confirmButtonLabel={
						requestError?.canRetry
							? t(
									"pages.mainPage.pages.accounts.tabs.executors.str201",
							  ) ?? ""
							: t(
									"pages.mainPage.pages.accounts.tabs.executors.str202",
							  ) ?? ""
					}
					onConfirm={() => {
						if (requestError?.canRetry && preparedData) {
							editModalOnSave(preparedData);
						}
						setShowErrorModal(false);
						setRequestError(null);
						setErrorPhone(null);
						setIsSave(false);
					}}
				/>
			)}
		</Root>
	);
};

export default Executors;
