/* eslint-disable no-param-reassign */

import { CaseReducer, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { responseDriverShiftsModel } from "../../../services/Archives/ArchivalShifts/responseData.types";
import { RootState } from "../../../store";
import { responseModelToRowData } from "../../../services/Archives/ArchivalShifts/driverShiftsHelper";

interface State {
	loading: boolean;
	rowsData: RowShiftData[] | any;
	data: responseDriverShiftsModel[] | any;
}

type Reducer<P> = CaseReducer<State, PayloadAction<P>>;

export interface RowShiftData {
	name: string;
	surname: string;
	callSign: string;
	car: string;
	registrationNumber: string;
	garageNumber: string;
	color: string;
	carParks: string;
	openedAt: string;
	userStartedShift: string;
	status: string;
	stateSetDate: string;
	currentParking: string;
	parkingNumber: string;
	registrationDateOnParking: string;
	positionLatitude: string;
	positionLongitude: string;
	lastCompletedOrderDate: string;
	rating: string;
}

const initialState: State = {
	loading: false,
	rowsData: [],
	data: [],
};

const setDriverShifts: Reducer<State["data"]> = (state, { payload }) => {
	state.data = payload;
};

const setRowData: Reducer<State["rowsData"]> = (state, { payload }) => {
	state.rowsData = payload;
};

const archiveDriverShifts = createSlice({
	name: "archiveDriverShifts",
	initialState,
	reducers: {
		setDriverShifts,
		setRowData,
	},
});

export const convertDataToArchiveRows =
	() => (dispatch, getState: () => RootState) => {
		const { data } = getState().archiveDriverShifts;
		const rowData = responseModelToRowData(data);
		dispatch(archiveDriverShifts.actions.setRowData(rowData));
	};
export default archiveDriverShifts;
