import sha256 from "crypto-js/sha256";
import EventEmitter from "events";
import { getPRPC } from "../../hooks/usePRPC";
import createRPCQuery from "../../utils/createRPCQuery.util";
import blobToString from "../../utils/blobToString";
import UInt8ArrayToString from "../../utils/UInt8ArrayToString";

class File {
	static fromResponse(data: any): File.Model {
		return new File.Model({
			id: data.id,

			name: data.originalName,
			size: data.size,
			hash: data.hash,
			type: data.mimetype,
			isPublicImage: data.isPublicImage,

			createdAt: data.createdAt,
			updatedAt: data.updatedAt,
			deletedAt: data.deletedAt,
		});
	}

	static async upload(file: File.Model, params: File.UploadParams = {}) {
		const prpc = getPRPC();

		if (!prpc) return null;

		const blob = await file.load();

		if (!blob) return null;

		const { size, isPublicImage } = file;
		const mimetype = file.type;
		const originalName = file.name ?? "";

		const hash = sha256(await blobToString(blob)).toString();

		const uploadStream = (await createRPCQuery(async () =>
			prpc.theirsModel.file.upload({
				size,
				mimetype,
				originalName,
				hash,
				isPublicImage,

				...params,
			}),
		)) as File.WebStream;

		const reader = blob.stream().getReader();

		// eslint-disable-next-line no-constant-condition
		while (true) {
			const { done, value } = await reader.read();

			if (done) break;

			// eslint-disable-next-line no-continue
			if (!value) continue;

			await uploadStream.write(UInt8ArrayToString(value));
		}

		const endResult = await uploadStream.end();

		if (!endResult.success) throw endResult.error;

		return File.fromResponse(endResult.fileInfo);
	}

	static async download(id: number) {
		const prpc = getPRPC();

		if (!prpc) return null;

		const downloadStream = await createRPCQuery(() =>
			prpc.theirsModel.file.download({ fileId: id }),
		);
		const data: Buffer[] = [];

		// eslint-disable-next-line no-constant-condition
		while (true) {
			const { done, value } = await downloadStream.read();

			if (done) break;

			data.push(Buffer.from(value, "ascii"));
		}

		return new Blob(data, {
			type: downloadStream.fileInfo.fileInfo.mimetype,
		});
	}

	static async delete(id: number) {
		const prpc = getPRPC();

		if (!prpc) return;

		await createRPCQuery(() => prpc.theirsModel.file.delete({ id }));
	}
}

namespace File {
	export interface WebStream {
		write: (data: string | Buffer) => Promise<WebStream.WriteResult>;
		end: () => Promise<WebStream.EndResult>;
	}

	export namespace WebStream {
		export interface SuccessWriteResult {
			success: true;
		}

		export interface FailureWriteResult {
			success: false;
			error: Error;
		}

		export type WriteResult = SuccessWriteResult | FailureWriteResult;

		export interface SuccessEndResult {
			success: true;
			fileInfo: any;
		}

		export interface FailureEndResult {
			success: false;
			error: Error;
		}

		export type EndResult = SuccessEndResult | FailureEndResult;
	}

	export class Model extends EventEmitter implements Model.Base {
		public blob?: Blob;

		private _uploadPromise?: Promise<Model | null>;

		private set uploadPromise(value: Promise<Model | null> | undefined) {
			this._uploadPromise = value;
			this._uploadPromise?.finally(() => this.emit("uploaded"));
		}

		private get uploadPromise(): Promise<Model | null> | undefined {
			return this._uploadPromise;
		}

		public id?: number | undefined;

		public name = "";

		public size = 0;

		public hash = "";

		public type = "";

		public isPublicImage = false;

		public createdAt: string | null = null;

		public updatedAt: string | null = null;

		public deletedAt: string | null = null;

		public get isUploading() {
			return !!this.uploadPromise || !!(this.uploadPromise as any)?.done;
		}

		constructor(data: Model);

		constructor(data: Model.Base);

		constructor(data: globalThis.File);

		constructor(data: Blob & Partial<Model.Base>);

		constructor(
			data: Model.Base | globalThis.File | (Blob & Partial<Model.Base>),
		) {
			super();

			if (data instanceof Model) {
				this.blob = data.blob;
				this.uploadPromise = data.uploadPromise;

				this.id = data.id;

				this.name = data.name ?? "";
				this.size = data.size;
				this.type = data.type;
				this.isPublicImage = data.isPublicImage;

				if (data.hash) this.hash = data.hash;
				else this.calculateHash();

				this.createdAt = data.createdAt ?? null;
				this.updatedAt = data.updatedAt ?? null;
				this.deletedAt = data.deletedAt ?? null;
			} else if (data instanceof globalThis.File) {
				this.blob = data;

				this.name = data.name;
				this.size = data.size;
				this.type = data.type;

				this.calculateHash();

				this.createdAt = null;
				this.updatedAt = null;
				this.deletedAt = null;
			} else if (data instanceof Blob) {
				this.blob = data;

				this.id = data.id;

				this.name = data.name ?? "";
				this.size = data.size;
				this.type = data.type;

				if (data.hash) this.hash = data.hash;
				else this.calculateHash();

				this.createdAt = data.createdAt ?? null;
				this.updatedAt = data.updatedAt ?? null;
				this.deletedAt = data.deletedAt ?? null;
			} else {
				this.id = data.id;

				this.name = data.name;
				this.size = data.size;
				this.type = data.type;
				this.isPublicImage = data.isPublicImage;

				this.createdAt = data.createdAt;
				this.updatedAt = data.updatedAt;
				this.deletedAt = data.deletedAt;
			}
		}

		private async calculateHash() {
			if (this.blob)
				this.hash = sha256(await this.blob.text()).toString();
		}

		public async load() {
			if (this.blob) return this.blob;
			if (this.id) {
				this.blob = (await File.download(this.id)) ?? undefined;

				return this.blob;
			}

			return null;
		}

		public async upload() {
			if (this.uploadPromise) return this.uploadPromise;
			if (this.id) return this;

			this._uploadPromise?.finally(() => this.emit("upload"));

			this.uploadPromise = File.upload(this);

			this.uploadPromise.finally(
				() => ((this.uploadPromise as any).done = true),
			);

			const result = await this.uploadPromise;

			this.uploadPromise = undefined;

			if (!result) return undefined;

			this.id = result.id;

			this.createdAt = result.createdAt;
			this.updatedAt = result.updatedAt;
			this.deletedAt = result.deletedAt;

			return result;
		}

		public forHash() {
			return {
				id: this.id,

				name: this.name,
				size: this.size,
				type: this.type,

				hash: this.hash,

				createdAt: this.createdAt,
				updatedAt: this.updatedAt,
				deletedAt: this.deletedAt,
			};
		}
	}

	export namespace Model {
		export interface Base {
			id?: number;

			name: string;
			size: number;
			hash: string;
			type: string;
			isPublicImage: boolean;

			createdAt: string | null;
			updatedAt: string | null;
			deletedAt: string | null;
		}
	}

	export interface UploadParams {
		executorId?: number;
		dispatcherId?: number;
		orderId?: number;

		additionalFields?: any;
	}
}

export default File;
