/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-shadow */

import React, { Dispatch, RefAttributes, memo, useMemo } from "react";
import { react } from "uikit";

import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";
import Role from "../../../../../../../../../../../../services/Role";
import { TabRoot } from "..";

import InternalController from "./Controller";
import Select from "./components/Select";

const PermissionsTab = memo(
	react.withController<PermissionsTab.PropsBase, PermissionsTab.Controller>(
		({ value, disabled, visible, assignableTo, onChange }) => {
			const valueEditor = useObjectEditor(value, onChange);

			const selectValue = valueEditor.useGetter("permissions");
			const selectOnChange = valueEditor.useSetter("permissions");

			const selectAllowed = useMemo(() => {
				if (assignableTo === "dispatcher") return ["sudo.*"];
				if (assignableTo === "executor") return ["executor.*"];

				return [];
			}, [assignableTo]);

			return (
				<TabRoot hasPaddings={false} visible={visible}>
					<Select
						value={selectValue}
						disabled={disabled}
						allowed={selectAllowed}
						onChange={selectOnChange}
					/>
				</TabRoot>
			);
		},
		InternalController,
	),
);

declare namespace PermissionsTab {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value {
		permissions: string[];
	}

	interface PropsBase {
		value: Value;

		disabled: boolean;
		visible: boolean;
		assignableTo?: Role.AssignableTo;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default PermissionsTab;
