import React, {
	Dispatch,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { Column, theme, useSize } from "uikit";
import { cloneDeep } from "lodash";
import { useTranslation } from "react-i18next";
import { useDebounce } from "use-debounce/lib";

import useCurrentTaxiServices from "../../../../../../../../../../../../hooks/useCurrentTaxiService";
import DeleteModal from "../../../../../../../../../../../../components/DeleteModal";
import DefaultPageHeader from "../../../../../../../../../../../../components/DefaultPageHeader";
import { StyledColumn } from "../../../../../../../../../../../../components/common";
import { useTableOptions } from "../../../../../../../../../../../../components/LightTable";
import Block from "../../../Block";
import Main from "../../tabs/Main";

import tPath from "./constants/tPath";
import Cards from "./Cards";
import Modal from "./components/Modal";
import Filters from "./components/Filters";
import defaultValue from "./constants/defaultValue";
import Content from "./components/Content";
import Root from "./components/Root";

const Accounts: React.FC<Accounts.Props> = ({
	visible,
	checks,
	setChecks,
	additional,
	generalPaymentAccounts,
	generalPaymentTransactions,
	ignoreGeneralThresholdBalance,
	allowGeneralBonusBalance,
	companyId,
	taxiServiceId,
	counterpartyId,
}) => {
	const { t } = useTranslation();
	const {
		editor,
		onChange,
		query,
		setQuery,
		active,
		isNotActive,
		isActive,
		onActive,
	} = useTableOptions();

	const {
		active: activeCards,
		editor: editorCards,
		onChange: onChangeCards,
	} = useTableOptions();

	const [showModal, setShowModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const [actualDefaultValue, setActualDefaultValue] = useState(defaultValue);

	const settings = useCurrentTaxiServices()?.settings;
	const check = settings?.counterparty.check;

	useEffect(() => {
		if (check) {
			setActualDefaultValue({
				...defaultValue,
				active: check?.active,
				accessAllEmployeeToPersonalApp:
					check?.accessAllEmployeeToPersonalApp,
				isAccessToCorporateAccount: check?.isAccessToCorporateAccount,

				useGeneralMainBalance: check?.useGeneralMainBalance,
				useGeneralBonusBalance: check?.useGeneralBonusBalance,
				useGeneralThresholdBalance: check?.useGeneralThresholdBalance,
				ignoreThresholdBalance: check?.ignoreThresholdBalance,

				additionalFields: {
					...defaultValue.additionalFields,
					executorNotes: additional.executorNotes ?? "",
					notes: additional.notes ?? "",
					orderNotes: additional.orderNotes ?? "",
					tripLimit: {
						...defaultValue.additionalFields.tripLimit,
						active: check?.tripLimit.active,
						availableChangeOrder:
							check?.tripLimit.availableChangeOrder,
					},
				},
			});
		}
	}, [
		additional.executorNotes,
		additional.notes,
		additional.orderNotes,
		check,
	]);

	const [editingItem, setEditingItem] =
		useState<Accounts.Check>(actualDefaultValue);

	const [selected, setSelected] = useState<number[]>([]);

	const edit = useCallback(
		(index: number) => {
			const item = checks.find((item) => item.checkId === index);
			if (!item) return;
			setEditingItem(cloneDeep(item));
			setShowModal(true);
			setSelected([]);
		},
		[checks],
	);

	const editHeaderHandler = useCallback(() => {
		edit(selected[0]);
	}, [edit, selected]);

	const editContentHandler = useCallback(
		(index: number) => {
			edit(index);
		},
		[edit],
	);

	const addHandler = useCallback(() => {
		setEditingItem(actualDefaultValue);
		setShowModal(true);
	}, [actualDefaultValue]);

	const preDeleteHandler = useCallback(() => {
		setShowDeleteModal(true);
	}, []);

	const cancelDelete = useCallback(() => {
		setShowDeleteModal(false);
		setSelected([]);
	}, []);

	const deleteHandler = useCallback(() => {
		const newChecks = checks.filter(
			(check) => !selected.includes(check.checkId ?? -1),
		);
		setChecks(newChecks);
		setSelected([]);
		setShowDeleteModal(false);
	}, [checks, selected, setChecks]);

	const cancelHandler = useCallback(() => {
		setShowModal(false);
		setEditingItem(actualDefaultValue);
	}, [actualDefaultValue]);

	const saveHandler = useCallback(
		(check: Accounts.Check) => {
			if (check.checkId) {
				const newChecks = [...checks].map((item) => {
					if (item.checkId === check.checkId) {
						const payload = { ...item, ...check };

						const checkCards = payload.checkCards.map((card) => {
							if (card.cardId && card.checkId) return card;
							const payloadCard = {
								...card,
								cardId: card?.cardId
									? card.cardId
									: new Date().getTime(),
								checkId: check.checkId,
							};

							return payloadCard;
						});

						return { ...payload, checkCards };
					}
					return item;
				});
				setChecks(newChecks);
				setSelected([check.checkId]);
			} else {
				if (!check.checkId) {
					check.checkId = new Date().getTime();
				}

				setChecks([...checks, check]);
				setSelected([check.checkId]);
			}

			setShowModal(false);
			setEditingItem(actualDefaultValue);
		},
		[actualDefaultValue, checks, setChecks],
	);

	const selectCheck = useMemo(
		() => checks.find((item) => item.checkId === selected[0]),
		[checks, selected],
	);

	const checkValue = useMemo(() => {
		const payload =
			selected.length === 1 && selectCheck
				? selectCheck?.additionalFields?.checkValue
				: null;

		return payload;
	}, [selectCheck, selected]);

	const notesValue = useMemo(() => {
		const payload =
			selected.length === 1 && selectCheck
				? selectCheck?.additionalFields
				: null;

		return {
			notes: payload?.notes ?? "",
			orderNotes: payload?.orderNotes ?? "",
			executorNotes: payload?.executorNotes ?? "",
		};
	}, [selectCheck, selected.length]);

	const checkPaymentAccounts = useMemo(() => {
		const payload =
			selected.length === 1 && selectCheck
				? selectCheck?.paymentAccounts || []
				: [];

		return payload;
	}, [selectCheck, selected.length]);

	const saveCardsHandler = useCallback(
		(newCards: any) => {
			try {
				const newChecks = [...checks];
				/** apologies are triggered if only one account is selected */
				if (selected.length === 1) {
					const check = newChecks.find(
						(item) => item.checkId === selected[0],
					);

					if (!check) return;

					const prevCheckCards = [...check.checkCards].filter(
						(item) => {
							const exist = newCards.find(
								(newItem) => item.cardId === newItem.cardId,
							);
							if (exist) return false;
							return true;
						},
					);

					const checkCards = [...prevCheckCards, ...newCards]
						.filter((item) => !item?.isDelete)
						.map((card) => {
							if (card.cardId && card.checkId) return card;
							return {
								...card,
								cardId: card?.cardId
									? card.cardId
									: new Date().getTime(),
								checkId: check.checkId,
							};
						});

					const dataChecks = newChecks.map((item) => {
						if (item.checkId === selected[0]) {
							return { ...item, checkCards };
						}
						return item;
					});

					setChecks(dataChecks);
					return;
				}

				const dataChecks = newChecks.map((item) => {
					if (!item?.checkId) return item;

					if (selected.includes(item.checkId)) {
						const prevCheckCards = [...item.checkCards].filter(
							(item) => {
								const exist = newCards.find(
									(newItem) => item.cardId === newItem.cardId,
								);
								if (exist) return false;
								return true;
							},
						);

						const checkCards = [...prevCheckCards, ...newCards]
							?.filter((card) => item.checkId === card.checkId)
							?.map((card) => ({
								...card,
								cardId: card?.cardId
									? card.cardId
									: new Date().getTime(),
								checkId: item.checkId,
							}))
							/** remove card */
							.filter((item) => !item?.isDelete);
						return { ...item, checkCards };
					}

					return item;
				});

				setChecks(dataChecks);
			} catch (error) {
				console.error(error);
			}
		},
		[checks, selected, setChecks],
	);

	const [queryDebounce] = useDebounce(query, 300);

	const checksData = useMemo(() => {
		try {
			if (active === null) return [];

			const payload = [...checks];

			if (active === false) {
				return payload.filter((item) => {
					if (queryDebounce) {
						return (
							item?.active === active &&
							item?.additionalFields?.checkValue?.includes(
								queryDebounce,
							)
						);
					}

					return item?.active === active;
				});
			}

			if (active === true) {
				return payload.filter((item) => {
					if (queryDebounce) {
						return (
							item.active === active &&
							item?.additionalFields?.checkValue?.includes(
								queryDebounce.trim(),
							)
						);
					}

					return item.active === active;
				});
			}

			if (queryDebounce.trim()) {
				return payload.filter((item) =>
					item?.additionalFields?.checkValue?.includes(
						queryDebounce.trim(),
					),
				);
			}

			return payload;
		} catch (error) {
			console.error(error);
			return [];
		}
	}, [active, checks, queryDebounce]);

	const cardsData = useMemo(() => {
		try {
			if (activeCards === null) return [];
			if (!checksData?.length) return [];
			if (!selected?.length) return [];

			const cards = [...checksData]
				?.filter((item) => selected.includes(item.checkId || -1))
				?.map((item) => item?.checkCards)
				?.flat();

			if (!cards || !cards.length) return [];
			const payload = [...cards];

			if (activeCards === false) {
				return payload.filter((item) => item?.active === activeCards);
			}

			if (activeCards === true) {
				return payload.filter((item) => item?.active === activeCards);
			}

			return payload;
		} catch (error) {
			console.error(error);
			return [];
		}
	}, [checksData, selected, activeCards]);

	const allCardsData = useMemo(() => {
		try {
			if (!checks?.length) return [];

			const payload = [...checks].map((item) => item.checkCards).flat();

			return payload;
		} catch (error) {
			console.error(error);
			return [];
		}
	}, [checks]);

	// resize 2 table
	const ref = useRef<HTMLDivElement | null>(null);
	const refAccount = useRef<HTMLDivElement | null>(null);
	const refCard = useRef<HTMLDivElement | null>(null);

	const size = useSize(ref.current);
	const sizeAccount = useSize(refAccount.current);
	const sizeCard = useSize(refCard.current);

	const sizes = useMemo(() => {
		const heightCard = size.height - sizeAccount.height;
		const heightAccount = size.height - sizeCard.height;

		return {
			main: size.height || 770,
			card: heightCard || 400,
			account: heightAccount || 400,
		};
	}, [size, sizeAccount, sizeCard]);

	const labelDeleteModal = useMemo(() => {
		if (selected.length > 1) {
			return (
				t([
					`${tPath}.deleteModal.title2`,
					"Are you sure you want to delete accounts?",
				]) || ""
			);
		}
		return (
			t([
				`${tPath}.deleteModal.title`,
				"Are you sure you want to delete your account?",
			]) || ""
		);
	}, [selected.length, t]);

	return (
		<Root visible={visible}>
			<StyledColumn
				ref={ref}
				gap="7px"
				h="100%"
				flex={{ flex: "1 1 800px" }}
			>
				<Block
					ref={refAccount}
					style={{
						maxHeight: "70%",
						height: `${sizes.account}px`,
						minHeight: "210px",
						resize: "vertical",
					}}
				>
					<StyledColumn
						b="1px solid #dee0e2"
						br="5px"
						h="100%"
						overY="hidden"
					>
						<div
							style={{
								backgroundColor: theme.colors.background_color,
								borderTopRightRadius: theme.borderRadius.m,
								fontFamily: "Lato",
								fontWeight: 600,
								fontSize: "18px",
								padding: "5px 15px",
								color: theme.colors.primary,
								marginBottom: "10px",
							}}
						>
							{t([`${tPath}.title`, "Accounts"]) || ""}
						</div>

						<Column
							style={{ height: "100%", flex: "1 1 auto" }}
							sizes="auto 1fr"
							gaps="8px*"
						>
							<DefaultPageHeader
								canEdit={selected.length === 1}
								canDelete={!!selected.length}
								onAdd={addHandler}
								onEdit={editHeaderHandler}
								onDelete={preDeleteHandler}
								filters={
									<Filters
										value={query}
										onChange={setQuery}
									/>
								}
								afterAdditionalButtons={[
									{
										disabled: false,
										icon: { id: "counterpart", size: 20 },
										onClick: () => {
											onActive("yes");
										},
										variation: isActive
											? "primary"
											: "secondary",

										title:
											t([
												`${tPath}.table.showNoActive`,
												"Show active accounts",
											]) || "",
									},
									{
										disabled: false,
										icon: { id: "counterpart", size: 20 },
										onClick: () => {
											onActive("no");
										},
										variation: isNotActive
											? "primary"
											: "secondary",
										title:
											t([
												`${tPath}.table.showNoActive`,
												"Show inactive accounts",
											]) || "",
									},
								]}
							/>
							<Content
								height={sizes.account}
								value={checksData}
								additional={additional}
								selected={selected}
								setSelected={setSelected}
								onEdit={editContentHandler}
								saveHandler={saveHandler}
								editorTable={editor}
								onChangeTable={onChange}
							/>
						</Column>
					</StyledColumn>
				</Block>

				<Block
					ref={refCard}
					style={{
						maxHeight: "70%",
						height: `${sizes.card}px`,
						minHeight: "210px",
						resize: "vertical",
					}}
				>
					<Cards
						cards={cardsData}
						setCards={saveCardsHandler}
						checkValue={checkValue}
						paymentAccounts={checkPaymentAccounts}
						editorTable={editorCards}
						onChangeTable={onChangeCards}
						height={sizes.card}
						notesValue={notesValue}
						companyId={companyId}
						taxiServiceId={taxiServiceId}
						counterpartyId={counterpartyId}
						allCards={allCardsData}
						allChecks={checks}
					/>
				</Block>
			</StyledColumn>
			{showModal && (
				<Modal
					title={
						editingItem?.additionalFields?.checkValue
							? `${t(`${tPath}.modal.editTitle`)} "${
									editingItem.additionalFields.checkValue
							  }"`
							: t(`${tPath}.modal.title`)
					}
					companyId={companyId}
					generalPaymentAccounts={generalPaymentAccounts}
					generalPaymentTransactions={generalPaymentTransactions}
					ignoreGeneralThresholdBalance={
						ignoreGeneralThresholdBalance
					}
					allowGeneralBonusBalance={allowGeneralBonusBalance}
					value={editingItem}
					onCancel={cancelHandler}
					onSave={saveHandler}
				/>
			)}
			{showDeleteModal && (
				<DeleteModal
					label={labelDeleteModal}
					onCancel={cancelDelete}
					onConfirm={deleteHandler}
				/>
			)}
		</Root>
	);
};

declare namespace Accounts {
	interface Props {
		visible: boolean;
		companyId: number | undefined;
		taxiServiceId?: number;
		counterpartyId?: number;
		generalPaymentAccounts: Modal.Props["generalPaymentAccounts"];
		generalPaymentTransactions: Modal.Props["generalPaymentTransactions"];
		allowGeneralBonusBalance: boolean;
		ignoreGeneralThresholdBalance: boolean;
		checks: Check[];
		setChecks: Dispatch<Check[]>;
		additional: Main.AdditionalFields;
	}

	type Check = Modal.Value & {
		checkId?: number;
		checkCards: Cards.Value;
		rideCounter?: {
			total: number;
			failed?: number;
			success?: number;
			cancelled?: number;
		};
		useGeneralThresholdBalance?: boolean;
		ignoreThresholdBalance?: boolean;
		allowBonusBalance: boolean;
	};
}

export default Accounts;
