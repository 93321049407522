import { getPRPC } from "../../../hooks/usePRPC";
import createRPCQuery from "../../../utils/createRPCQuery.util";

interface ISendParams {
	action: number;
}

export default function sendSMSByOrder(id, params: ISendParams) {
	return () => {
		const prpcow = getPRPC();

		if (!prpcow) return null;

		return createRPCQuery(
			() => prpcow?.theirsModel.orders.sms.send(id, params),
			{ verbose: false, silent: true, name: "orders.sms.send" },
		);
	};
}
