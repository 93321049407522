import * as ModelEvent from "@node-elion/syncron";

import SubscriptionPool from "../../redux/services/SubscriptionPool";
import ServiceSubscribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";
import Subscription from "../../types/Subscription";
import { NonEditableProperties } from "../../types/NonEditableProperties";
import Base from "../Base";
import TaxiService from "../TaxiService";
import File from "../File";
import Client from "../Client";
import Dispatcher from "../Dispatcher";
import Executor from "../Executor";
import Language from "../Language";
import PushNotice from "../PushNotice";

class PushMessage extends Base {
	public static fromResponse(data: any): PushMessage.Model {
		return {
			id: data.id,
			additionalFields: data.additionalFields,
			bigPicture: data.bigPicture
				? File.fromResponse(data.bigPicture)
				: null,
			customer: data.customer ? Client.fromResponse(data.customer) : null,
			dispatcher: data.dispatcher
				? Dispatcher.fromResponse(data.dispatcher)
				: null,
			executor: data.executor
				? Executor.fromResponse(data.executor)
				: null,
			isAdvertising: data.isAdvertising,
			largeIcon: data.largeIcon
				? File.fromResponse(data.largeIcon)
				: null,
			notificationTemplate: data.notificationTemplate
				? PushNotice.fromResponse(data.notificationTemplate)
				: null,
			sender: data.sender,
			simpleStatus: data.simpleStatus,
			status: data.status,
			taxiService: data.taxiService
				? TaxiService.fromResponse(data.taxiService)
				: null,
			text: data.text,
			title: data.title,
			type: data.type,
			createdAt: data.createdAt,
			updatedAt: data.updatedAt,
			expiredAt: data.expiredAt,
		};
	}

	public static async subscribe(
		options: PushMessage.SubscribeOptions,
		onUpdate: Subscription.OnUpdate<PushMessage.Model>,
	): Promise<Subscription<PushMessage.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				onUpdate({
					...state,

					models: state.models.map(this.fromResponse),
				});
			},
		});

		const subscription = await SubscriptionPool.add(
			(prpc) =>
				prpc.theirsModel.notification.message.subscribe({
					params: this.optionsToRequest(options),
					ping: () => true,
					onEvent: (events) => {
						modelEventConstructor.onEvent(events);
					},
					onError: (error) => {
						console.error(error);
					},
				}),
			{ name: "PushMessage.subscribe" },
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (options: PushMessage.SubscribeOptions) =>
				subscription.update(this.optionsToRequest(options)),
		} as Subscription<PushMessage.SubscribeOptions>;
	}

	private static optionsToRequest(options: PushMessage.SubscribeOptions) {
		return {
			limit: options.limit,
			offset: options.offset,
			isActive: options.isActive,
			isAdvertising: options.isAdvertising,
			statuses: options.statuses,
			simpleStatuses: options.simpleStatuses,
			types: options.types,
			dateRange: options.dateRange,
			taxiServiceIds: options.taxiServiceIds,
			lang: options.language,
			order: options.order,
		};
	}
}

declare namespace PushMessage {
	interface Model extends Omit<NonEditableProperties, "deletedAt"> {
		additionalFields: {
			largeIconUrl?: string;
			bigPictureUrl?: string;
		};
		bigPicture: File.Model | null;
		customer: Client.Model | null;
		dispatcher: Dispatcher.Model | null;
		executor: Executor.Model | null;
		isAdvertising: boolean;
		largeIcon: File.Model | null;
		notificationTemplate: PushNotice.Model | null;
		sender: string;
		simpleStatus: NotificationMessageSimpleStatus;
		status: NotificationMessageStatus;
		taxiService: TaxiService.Model | null;
		text: string | null;
		title: string | null;
		type: NotificationMessageType;
		expiredAt: string | null;
	}

	interface SubscribeOptions
		extends Omit<ServiceSubscribeOptionsBase<PushMessage.Model>, "query"> {
		isActive?: boolean;
		isAdvertising?: boolean;
		statuses?: NotificationMessageStatus[];
		simpleStatuses?: NotificationMessageSimpleStatus[];
		types?: NotificationMessageType[];
		dateRange?: {
			from: Date;
			to: Date;
		};
		taxiServiceIds?: number[];
		language?: Language;
	}
	type NotificationMessageSimpleStatus =
		| "DELIVERED"
		| "UNDELIVERABLE"
		| "READ";

	type NotificationMessageStatus =
		| "PENDING"
		| "PENDING_CONNECTION_TIMEOUT"
		| "ENROUTE"
		| "ENROUTE_COMPLETE"
		| "EXPIRED"
		| "SCHEDULED"
		| "DELETED"
		| "DELIVERED"
		| "UNDELIVERABLE"
		| "REJECTED"
		| "REJECTED_CONNECTION_TIMEOUT"
		| "READ";

	type NotificationMessageType = "info" | "order";
}

export default PushMessage;
