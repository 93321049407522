import { CaseReducer, PayloadAction, createSlice } from "@reduxjs/toolkit";
import AdditionalTariff from "../../services/AdditionalTariff";

interface InitialState {
	options: AdditionalTariff.Model | null;
}

const initialState: InitialState = {
	options: null,
};

type Reducer<P> = CaseReducer<typeof initialState, PayloadAction<P>>;

const setSettings: Reducer<AdditionalTariff.Model> = (state, { payload }) => {
	state.options = payload;
};

const additionalTariff = createSlice({
	name: "additionalTariff",
	initialState,
	reducers: {
		setSettings,
	},
});

export default additionalTariff;
