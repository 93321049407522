import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "uikit";

import { useTypedSelector } from "../../../../redux/store";
import { NavigationLayout } from "../../../../components/common";
import { ROUTES } from "../../../../constants/routes";
import { hasAccess, generateAccessName, AccessKey } from "../../../../access";

const Reports: React.FC = () => {
	const ACCESS_SECTION = AccessKey.REPORTS;
	const { t } = useTranslation();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);

	const links: NavigationLayout.ILink[] = useMemo(
		() =>
			[
				{
					text: t(`mainPage.reports.accounting.title`),
					Image: () => <Icon id="reports" size={90} />,
					link: ROUTES.REPORTS_ACCOUNTING,
					accessName: generateAccessName(
						ACCESS_SECTION,
						AccessKey.REPORTS_ACCOUNTING,
					),
				},
				{
					text: t(`mainPage.reports.charts.title`),
					Image: () => <Icon id="reports" size={90} />,
					link: ROUTES.REPORTS_CHARTS,
					accessName: generateAccessName(
						ACCESS_SECTION,
						AccessKey.REPORTS_CHARTS,
					),
				},

				// TODO DM GROUP_START change mocked links
				{
					disabled: true,
					text: t(`mainPage.reports.analytics.title`),
					Image: () => <Icon id="reports" size={90} />,
					link: ROUTES.REPORTS_CHARTS,
					accessName: generateAccessName(
						ACCESS_SECTION,
						AccessKey.REPORTS_CHARTS,
					),
				},
				{
					disabled: true,
					text: t(`mainPage.reports.drivers.title`),
					Image: () => <Icon id="reports" size={90} />,
					link: ROUTES.REPORTS_CHARTS,
					accessName: generateAccessName(
						ACCESS_SECTION,
						AccessKey.REPORTS_CHARTS,
					),
				},
				{
					disabled: true,
					text: t(`mainPage.reports.details.title`),
					Image: () => <Icon id="reports" size={90} />,
					link: ROUTES.REPORTS_CHARTS,
					accessName: generateAccessName(
						ACCESS_SECTION,
						AccessKey.REPORTS_CHARTS,
					),
				},
				{
					disabled: true,
					text: t(`mainPage.reports.salary.title`),
					Image: () => <Icon id="reports" size={90} />,
					link: ROUTES.REPORTS_CHARTS,
					accessName: generateAccessName(
						ACCESS_SECTION,
						AccessKey.REPORTS_CHARTS,
					),
				},
				{
					disabled: true,
					text: t(`mainPage.reports.clients.title`),
					Image: () => <Icon id="reports" size={90} />,
					link: ROUTES.REPORTS_CHARTS,
					accessName: generateAccessName(
						ACCESS_SECTION,
						AccessKey.REPORTS_CHARTS,
					),
				},
				{
					disabled: true,
					text: t(`mainPage.reports.exchange.title`),
					Image: () => <Icon id="reports" size={90} />,
					link: ROUTES.REPORTS_CHARTS,
					accessName: generateAccessName(
						ACCESS_SECTION,
						AccessKey.REPORTS_CHARTS,
					),
				},
				{
					disabled: true,
					text: t(`mainPage.reports.service.title`),
					Image: () => <Icon id="reports" size={90} />,
					link: ROUTES.REPORTS_CHARTS,
					accessName: generateAccessName(
						ACCESS_SECTION,
						AccessKey.REPORTS_CHARTS,
					),
				},
				{
					disabled: true,
					text: t(`mainPage.reports.serviceStatus.title`),
					Image: () => <Icon id="reports" size={90} />,
					link: ROUTES.REPORTS_CHARTS,
					accessName: generateAccessName(
						ACCESS_SECTION,
						AccessKey.REPORTS_CHARTS,
					),
				},
				{
					disabled: true,
					text: t(`mainPage.reports.directories.title`),
					Image: () => <Icon id="reports" size={90} />,
					link: ROUTES.REPORTS_CHARTS,
					accessName: generateAccessName(
						ACCESS_SECTION,
						AccessKey.REPORTS_CHARTS,
					),
				},
			].filter((btn) => hasAccess(btn.accessName, personalRole)),
		[ACCESS_SECTION, personalRole, t],
	);

	return <NavigationLayout links={links} />;
};

export default Reports;
