import styled from "styled-components";
import { theme } from "uikit";

const Root = styled.div`
	padding: 10px;

	font-family: "Lato";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;

	color: ${theme.colors.primary};

	background: ${theme.colors.background_color};

	user-select: none;
`;

export default Root;
