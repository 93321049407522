import React from "react";
import { ColumnProps, RowDataType } from "rsuite-table";

import ExecutorReport from "../../../../../../../../../services/ExecutorReport";
import LightTable from "../../../../../../../../../components/LightTable";
import { TranslationText } from "../../../../../../../../../components/common";

import columns, { ColumnId } from ".";

function base(
	columnId: ColumnId,
	render: base.Render,
	column: Partial<ColumnProps<RowDataType>>,
) {
	return (
		<LightTable.Column key={columnId} resizable sortable {...column}>
			<LightTable.HeaderCell>
				<TranslationText text={columns[columnId].label} />
			</LightTable.HeaderCell>
			<LightTable.Cell dataKey={columnId} fullText>
				{(rowData) => render(rowData as ExecutorReport.Model)}
			</LightTable.Cell>
		</LightTable.Column>
	);
}

declare namespace base {
	type Render = (item: ExecutorReport.Model) => React.ReactNode;
}

export default base;
