/* eslint-disable no-param-reassign */
// import sessionConstants from "../constants/session.constants";

import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import AdditionalTariff from "../../services/AdditionalTariff";

interface State {
	models: AdditionalTariff.Model[];
}

type Reducer<Payload> = CaseReducer<State, PayloadAction<Payload>>;

const setModels: Reducer<State["models"]> = (state, { payload }) => {
	state.models = payload;
};

const initialState: State = {
	models: [],
};

const additionalTariffs = createSlice({
	name: "additionalTariffs",
	initialState,
	reducers: {
		setModels,
	},
});

export default additionalTariffs;
