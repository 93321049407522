import { useCallback, useMemo, useState } from "react";

import Client from "../../../../../../../services/Client";
import { Schema } from "../../../../../../../redux/constants/OrdersPage/order";
import { useTypedSelector } from "../../../../../../../redux/store";

export interface UseCreateClient {
	response: Client.Model | null;
	create: (params: Schema, isSwap?: boolean) => Promise<Client.Model | null>;
	reset: () => void;
}

export const defaultValue: Client.Model.New = {
	companyId: NaN,
	defaultTaxiServiceId: undefined,
	carClassId: undefined,
	person: {
		firstName: "",
		lastName: "",
		fatherName: "",
		emails: [],
		phones: [],
		birthday: null,
	},
	status: {
		level: Client.Status.ACTIVE,
		description: "",
	},
	notes: "",
	orderNotes: "",
	executorNotes: "",
	paymentTransactions: {
		main: [],
		bonus: [],
	},

	gender: "unknown",
	personalFileIds: [],
	otherFileIds: [],
	customerGroupId: undefined,
	isAllowedApp: true,
	isAllowedCabinet: true,
	smsNotifications: true,
	login: undefined,
	password: undefined,
	additionalFields: { defaultAddress: undefined },
};

export type UseCreateClientProps = () => UseCreateClient;

/**
 *  `useCreateClient`
 */
export const useCreateClient: UseCreateClientProps = () => {
	const [response, setResponse] = useState<UseCreateClient["response"]>(null);
	const { classes } = useTypedSelector(
		(state) => state.preferencesReducer.classesReducer,
	);

	const getDefaultCarClass = useCallback(
		(params: Schema) =>
			classes?.find((item) => {
				const {
					taxiServiceToCarClasses,
					active,
					default: isDefault,
				} = item;

				const taxiServiceId = params?.taxiServiceId;
				const companyID = params?.companyID;

				const isClass = taxiServiceToCarClasses?.some(
					(item) =>
						item.taxiService?.id === taxiServiceId &&
						item.taxiService?.company?.id === companyID,
				);

				return isClass && active && isDefault;
			}),
		[classes],
	);

	const create = useCallback<UseCreateClient["create"]>(
		async (params, personInfo = false) => {
			if (!params.companyID) return null;
			const person: Client.Model.New["person"] = {
				firstName: params.customerName || "",
				lastName: params.customerSurname || "",
				fatherName: params.customerFatherName || "",
				phones: [params.phone || ""],
				birthday: params.customerBirthday || null,
				emails: [],
			};

			const carClass = getDefaultCarClass(params);

			const payload: Client.Model.New = {
				companyId: params.companyID,
				defaultTaxiServiceId: params.taxiServiceId,
				carClassId:
					carClass?.id ||
					params.customerCarClassId ||
					params.taxiClass?.[0]?.value ||
					undefined,
				notes: "",

				person: {
					firstName: "",
					lastName: "",
					fatherName: "",
					phones: [params.phone || ""],
					birthday: null,
					emails: [],
				},
				status: {
					level: Client.Status.ACTIVE,
					description: "",
				},
				paymentTransactions: {
					main: [],
					bonus: [],
				},

				gender: "unknown",
				personalFileIds: [],
				otherFileIds: [],
				customerGroupId: undefined,
				isAllowedApp: true,
				isAllowedCabinet: true,
				smsNotifications: true,
				login: undefined,
				password: undefined,
				orderNotes: "",
				executorNotes: "",
				additionalFields: { defaultAddress: undefined },
			};

			if (personInfo) {
				payload.person = person;
				payload.notes = params.customerNotes || "";
			}

			try {
				const res = await Client.store(payload);
				console.log("[ OrderModal ] res ", res);
				setResponse(res);
				return res;
			} catch (error) {
				console.log("[ OrderModal ] res error", error);
				return null;
			}
		},
		[getDefaultCarClass],
	);

	const reset = useCallback(() => {
		setResponse(null);
	}, [setResponse]);

	return useMemo<UseCreateClient>(
		() => ({
			response,
			reset,
			create,
		}),
		[response, reset, create],
	);
};
