import { AutoRateRulePolygonType } from "../../../../../../../../../../../../../../../services/AutoTariff/enums";

import Modal from "../components/Modal";
import { AutoRateRulePaymentType } from "../components/Modal/components/Content/components/Orders/components/Payment/constants";
import { AutoRateRuleOrderType } from "../components/Modal/components/Content/components/Orders/constants";
import { AutoRateSectorsIgnoreOrderType } from "../components/Modal/components/Content/components/Sectors/components/OrdersCounter/components/IgnoreOrders/constants";
import {
	AutoRateRuleSectorItemCounterType,
	AutoRateRuleSectorsDistributionType,
} from "../components/Modal/components/Content/components/Sectors/constants";
import { AutoRateRuleCalculationType } from "../components/Modal/components/Content/components/TariffCalculation/constants";

const zeroHoursDate = new Date();
zeroHoursDate.setHours(0, 0, 0, 0);

const endOfDay = new Date();
endOfDay.setHours(23, 59, 59, 999);

const defaultValue: Modal.Value = {
	active: true,
	name: "",
	priceZoneIds: [],
	carClassIds: [],
	sectorIds: [],
	additionalFields: {
		considerSuburbanInTaximeter: false,
		validityDate: {
			active: false,
			from: zeroHoursDate.getTime(),
			to: endOfDay.getTime(),
		},
		validityTime: {
			active: false,
			from: zeroHoursDate,
			to: endOfDay,
		},
		outsidePriceZones: false,
		polygonType: AutoRateRulePolygonType.PRICE_ZONE,
		sectors: {
			distributionType: AutoRateRuleSectorsDistributionType.ALL,
			executorsCounter: {
				active: false,
				considerExecutorPositions: false,
				ignoreExecutors: {
					active: false,
					statuses: [],
				},
				range: {
					from: {
						active: false,
						value: 0,
					},
					to: {
						active: false,
						value: 0,
					},
				},
				type: AutoRateRuleSectorItemCounterType.EACH,
			},
			ordersCounter: {
				active: false,
				ignoreOrders: {
					active: false,
					types: [
						AutoRateSectorsIgnoreOrderType.PRELIMINARY,
						AutoRateSectorsIgnoreOrderType.LOCAL_ORDERS,
						AutoRateSectorsIgnoreOrderType.EXECUTING,
						AutoRateSectorsIgnoreOrderType.GLOBAL_ORDERS,
					],
				},
				range: {
					from: {
						active: false,
						value: 0,
					},
					to: {
						active: false,
						value: 0,
					},
				},
				type: AutoRateRuleSectorItemCounterType.EACH,
			},
		},
		weekdays: {
			active: false,
			days: [],
		},
		applyToOrderPoints: {
			active: false,
			points: [],
		},
		orderSource: { active: false, sources: [] },
		orders: {
			types: [AutoRateRuleOrderType.EXECUTING],
			active: false,
			payment: {
				active: false,
				types: [AutoRateRulePaymentType.BONUS],
			},
		},
		priceZoneChangeDistanceThreshold: {
			active: false,
			value: 0,
		},
		calculationType: AutoRateRuleCalculationType.ENTIRE_ROUTE,
		minOrderCost: {
			active: false,
			value: 0,
		},
		carClasses: {
			active: false,
		},
	},
};

export default defaultValue;
