import React from "react";

import Content from "../components/CellContent";

import base from "./base";
import { Column, ColumnId } from ".";

function cashCommissionGross({ width, onResize }: Column.Options) {
	return base(
		ColumnId.CashCommissionGross,
		(item) => <Content item={item} />,
		{
			width,

			onResize: onResize as (
				columnWidth?: number | undefined,
				dataKey?: string | undefined,
			) => void,
		},
	);
}

export default cashCommissionGross;
