/* eslint-disable no-shadow */

import React, { Dispatch, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Block from "./components/Block";
import Root from "./components/Root";
import useObjectEditor from "../../../../../../../../../../../../../../../../../../hooks/useObjectEditor";

const List: React.FC<List.Props> = ({ value, disabled, onChange }) => {
	const [t] = useTranslation();

	const valueEditor = useObjectEditor(value, onChange);

	const blocks = useMemo(
		() =>
			value.reduce((accumulator, permission) => {
				const lastDotIndex = permission.lastIndexOf(".");
				const path = permission.substring(0, lastDotIndex);

				accumulator[path] = accumulator[path] ?? [];

				accumulator[path].push(permission);

				return accumulator;
			}, {} as Record<string, string[]>),
		[value],
	);

	const content = useMemo(
		() =>
			Object.entries(blocks).map(([name, permissions]) => (
				<Block
					key={name}
					value={permissions}
					disabled={disabled}
					label={t(`permissions.${name}.label`)}
					onChange={(value) => {
						const removedPermissions = permissions.filter(
							(permission) => !value.includes(permission),
						);

						valueEditor.removeByValues(removedPermissions);
					}}
				/>
			)),
		[blocks, disabled, t, valueEditor],
	);

	return <Root gaps="10px*">{content}</Root>;
};

declare namespace List {
	type Value = string[];

	interface Props {
		value: Value;

		disabled: boolean;

		onChange: Dispatch<Value>;
	}
}

export default List;
