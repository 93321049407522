import React, { useMemo } from "react";

import ExecutorReport from "../../../../../../../../../../services/ExecutorReport";
import Language from "../../../../../../../../../../services/Language";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";

const FleetCellContent: React.FC<FleetCellContent.Props> = ({ item }) => {
	const content = useMemo(() => {
		if (!item || !item.fleet) return "";

		return item.fleet?.name || "";
	}, [item]);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace FleetCellContent {
	interface Props {
		item: ExecutorReport.Model;
		language: Language;
	}
}

export default FleetCellContent;
