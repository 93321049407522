import React, { useMemo } from "react";

import ExecutorReport from "../../../../../../../../../../services/ExecutorReport";

import { CellContentRoot } from "../../../../../../../../../../components/LightTable";

const FirstNameCellContent: React.FC<FirstNameCellContent.Props> = ({
	item,
}) => {
	const content = useMemo(
		() => item?.person?.firstName || "",
		[item?.person?.firstName],
	);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace FirstNameCellContent {
	interface Props {
		item: ExecutorReport.Model;
	}
}

export default FirstNameCellContent;
