import React, { Dispatch } from "react";
import { Column } from "uikit";
import { useTranslation } from "react-i18next";
import Header from "./components/Header";
import Item from "./components/Item";
import useObjectEditor from "../../../../../../../../../../../../../../../../../../../../hooks/useObjectEditor";

const Block: React.FC<Block.Props> = ({ value, disabled, label, onChange }) => {
	const [t] = useTranslation();

	const valueEditor = useObjectEditor(value, onChange);

	return (
		<Column gaps="5px">
			<Header label={label} />
			{value.map((permission) => (
				<Item
					key={permission}
					disabled={disabled}
					label={t(`permissions.${permission}.label`)}
					onDelete={() => {
						valueEditor.removeByValue(permission);
					}}
				/>
			))}
		</Column>
	);
};

declare namespace Block {
	type Value = string[];

	interface Props {
		value: Value;

		disabled: boolean;
		label: string;

		onChange: Dispatch<Value>;
	}
}

export default Block;
