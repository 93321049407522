import React, { useCallback, useMemo, useRef } from "react";
import { uniqueId } from "lodash";

import { useTypedDispatch, useTypedSelector } from "../../../redux/store";
import orderPage from "../../../redux/reducers/OrdersPage";
import openOrderCard from "../../../redux/services/Order/card/openOrderCard";
import {
	useColumns,
	useSort,
	useVisibleColumns,
	useWidths,
} from "../../../hooks/useTableSettings";

import { ColumnId } from "./components/ModelTable/columns";
import ModelTable from "./components/ModelTable";

const orderTableByType = {
	live: "orderLive",
	closed: "orderClosed",
	preliminary: "orderPreliminary",
	executable: "orderExecutable",
	all: "orderAll",
} as const;

const OrderTable: React.FC = () => {
	const dispatch = useTypedDispatch();

	const settingsLanguage = useTypedSelector(
		(state) => state.settings.map.mapLanguage,
	);

	const {
		ordersType,
		orders: ordersByType,
		activeOrder,
	} = useTypedSelector((state) => state.ordersPageReducer);

	const filters = useTypedSelector(
		(state) => state.ordersPageReducer.filters[ordersType],
	);

	const orders = useMemo(
		() => ordersByType[ordersType],
		[ordersByType, ordersType],
	);

	const tableId = useMemo(
		() => `main-order-page-${ordersType}`,
		[ordersType],
	);
	const tableType = useMemo(() => orderTableByType[ordersType], [ordersType]);

	const columns = useColumns(tableType);
	const { columnIds, setColumnIds } = useVisibleColumns(tableId, tableType);
	const { sortMethod, setSortMethod } = useSort(tableId, tableType);
	const { widths, setWidths } = useWidths(tableId, tableType);

	const modelTableColumns = useMemo<ModelTable.Column[]>(
		() =>
			columns
				.map((column) => ({
					id: column.id as ColumnId,
					width: widths[column.id],
					visible: columnIds.includes(column.id as ColumnId),
				}))
				.sort((leftColumn, rightColumn) => {
					const leftIndex = columnIds.indexOf(leftColumn.id);
					const rightIndex = columnIds.indexOf(rightColumn.id);

					return leftIndex - rightIndex;
				}),
		[columnIds, columns, widths],
	);

	const context = useRef<any>({});

	context.current.activeOrder = activeOrder;

	const selectOrder = useCallback(
		(orderId: number) => {
			if (context.current.activeOrder?.id === orderId) return;

			const original = orders.items.find((item) => item.id === orderId);

			if (original) {
				dispatch(orderPage.actions.setFocusableOrder(null));

				dispatch(
					orderPage.actions.setActiveOrder({
						...original,
						hash: uniqueId("order"),
					}),
				);

				if (original.points.length === 1) {
					dispatch(orderPage.actions.setActivePoint(0));
				}
			}
		},
		[orders.items, dispatch],
	);

	const editOrder = useCallback(
		(orderId: number) => {
			dispatch(openOrderCard(orderId));
		},
		[dispatch],
	);

	const modelTableOnChangeColumns = useCallback(
		(columns: ModelTable.Column[]) => {
			setColumnIds(
				columns
					.filter((column) => column.visible)
					.map((column) => column.id),
			);

			setWidths(
				columns.reduce((accumulator, column) => {
					accumulator[column.id] = column.width;

					return accumulator;
				}, {} as Record<ColumnId, number>),
			);
		},
		[setColumnIds, setWidths],
	);

	const modelTableOnLoadMore = useCallback(() => {
		dispatch(
			orderPage.actions.setFilters({
				type: ordersType,
				data: {
					...filters,
					limit: orders.items.length + 10,
				},
			}),
		);
	}, [dispatch, filters, orders.items.length, ordersType]);

	return (
		<ModelTable
			selected={activeOrder.id}
			sort={sortMethod}
			columns={modelTableColumns}
			data={orders.items}
			language={settingsLanguage}
			onChangeSelected={selectOrder}
			onChangeSort={setSortMethod}
			onChangeColumns={modelTableOnChangeColumns}
			onEdit={editOrder}
			onLoadMore={modelTableOnLoadMore}
		/>
	);
};

export default OrderTable;
