import React from "react";

import ExecutorReport from "../../../../../../../../../../services/ExecutorReport";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";

const CallSignCellContent: React.FC<CallSignCellContent.Props> = ({ item }) => (
	<CellContentRoot alignItems="center" w="100%" h="100%">
		{item.callSign}
	</CellContentRoot>
);

declare namespace CallSignCellContent {
	interface Props {
		item: ExecutorReport.Model;
	}
}

export default CallSignCellContent;
