import Cards from "../Cards";
import Modal from "../components/Modal";

const defaultValue: Modal.Value & {
	checkCards: Cards.Value;
	isLoginOnlyByEmployee: boolean;
	riderCount: {
		total: number;
	};
} = {
	active: true,
	personalFiles: [],
	otherFiles: [],
	activationDate: new Date(),
	isAccessToCorporateAccount: false,
	isLoginOnlyByEmployee: false,
	accessAllEmployeeToPersonalApp: false,
	paymentAccounts: [],
	useGeneralMainBalance: false,
	useGeneralBonusBalance: false,
	useGeneralThresholdBalance: false,
	ignoreThresholdBalance: false,
	allowBonusBalance: false,
	paymentTransactions: {
		main: [],
		threshold: [
			{
				amount: 0,
				description: "",
			},
		],
		bonus: [],
	},
	riderCount: {
		total: 0,
	},
	additionalFields: {
		checkValue: "",
		phones: [],
		deactivationThreshold: {
			value: 0,
			description: "",
		},
		tripLimit: {
			active: false,
			amountByPeriod: {
				active: false,
				amount: 0,
				customPeriod: 0,
			},
			amountByTrip: {
				active: false,
				maxAmountOfTrip: 0,
			},
			countByPeriod: {
				active: false,
				numberOfTrips: 0,
				customPeriod: 0,
			},
			distanceByPeriod: {
				active: false,
				distance: 0,
				customPeriod: 0,
			},
			availableChangeOrder: {
				active: false,
			},
		},
	},
	checkCards: [],
};

export default defaultValue;
