import React, { useMemo } from "react";

import ExecutorReport from "../../../../../../../../../../services/ExecutorReport";

import { CellContentRoot } from "../../../../../../../../../../components/LightTable";

const LastNameCellContent: React.FC<LastNameCellContent.Props> = ({ item }) => {
	const content = useMemo(
		() => item?.person?.lastName || "",
		[item?.person?.lastName],
	);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace LastNameCellContent {
	interface Props {
		item: ExecutorReport.Model;
	}
}

export default LastNameCellContent;
