import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "uikit";
import Root from "./components/Root";
import ColumnCountText from "./components/ColumnCountText";
import ColumnCount from "./components/ColumnCount";

const Header = memo<Header.Props>(({ disabled, count, onClear }) => {
	const { t } = useTranslation();
	return (
		<Root align="center" sizes="auto! 1fr auto!" gaps="5px*">
			<ColumnCountText>
				{t(
					"pages.mainPage.pages.accounts.tabs.roles.editModal.content.permissionsTab.select.viewer.header.str200",
				) ?? ""}
				:{" "}
			</ColumnCountText>
			<ColumnCount>{count}</ColumnCount>
			<Button.Button
				text={
					t(
						"pages.mainPage.pages.accounts.tabs.roles.editModal.content.permissionsTab.select.viewer.header.str0",
					) ?? ""
				}
				disabled={disabled}
				variant="negative"
				transparent
				hasPaddings={false}
				onClick={onClear}
			/>
		</Root>
	);
});

declare namespace Header {
	interface Props {
		disabled: boolean;
		count: number;

		onClear: () => void;
	}
}

export default Header;
