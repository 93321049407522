import React, {
	Dispatch,
	useCallback,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { RowDataType } from "rsuite-table";
import { pick } from "lodash";

import ExecutorReport from "../../../../../../../../services/ExecutorReport";
import ExecutorRate from "../../../../../../../../services/ExecutorRate";
import {
	useColumns,
	useVisibleColumns,
	useWidths,
} from "../../../../../../../../hooks/useTableSettings";
import useModelSubscribe from "../../../../../../../../hooks/useModelSubscribe2";
import makeLookupTable from "../../../../../../../../utils/makeLookupTable";
import { PaymentAccountType } from "../../../../../../../../types/PaymentAccount";
import TableSettings from "../../../../../../../../components/TableSettings";
import LightTable, {
	useTableOptions,
	UseTableOptions,
} from "../../../../../../../../components/LightTable";
import {
	StyledGrid,
	StyledGridItem,
	Divider,
} from "../../../../../../../../components/common";
import { useAccountingExecutors } from "../../../../hooks";
import sortByNumberOrString from "../../../../../../../../utils/sortByNumberOrString";

import columnBase, {
	ColumnId as LocalColumnId,
	defaultColumnIds,
	disabledColumnsIds,
} from "./columns";

const Content: React.FC<Content.Props> = ({
	value: models,
	counters,
	selected,
	setSelected,
	onChangeTable,
	editorTable,
}) => {
	const subscribeExecutorRate = useModelSubscribe({}, ExecutorRate);
	const { filters: filtersRedux, setIsSortMap } = useAccountingExecutors();

	const {
		sort,
		onSort,
		onScroll,
		tableRef,
		onKeyUp,
		onKeyDown,
		ctrlPressed,
		lang,
		widthScroll,
	} = useTableOptions({
		heightRow: 32,
		itemLimit: 2,
		value: editorTable,
		setValue: onChangeTable,
	});
	const countersTableRef = useRef<LightTable.Ref | null>(null);

	const [valueLength, setValueLength] = useState<number>(0);
	const [valueMap, setValueMap] = useState<
		Map<string, ExecutorReport.Model[]>
	>(new Map());

	useLayoutEffect(() => {
		if (models.length !== valueLength || filtersRedux.isSortMap) {
			setValueLength(models.length);
			setValueMap(new Map());
			setIsSortMap(false);
		}
	}, [filtersRedux.isSortMap, setIsSortMap, models.length, valueLength]);

	const columns = useColumns("accountingExecutors");
	const { columnIds, setColumnIds } = useVisibleColumns(
		"accounting.executors",
		"accountingExecutors",
	);
	const { widths, setWidths } = useWidths(
		`accounting.executors`,
		"accountingExecutors",
	);

	// const tableExecutorRateColumns = useMemo(() => {
	// 	if (!executorRateColumns) return [];
	// 	return executorRateColumns.map((item) => ({
	// 		id: item.name?.[lang],
	// 		label: item.name?.[lang],
	// 		sortable: true,
	// 		resizable: true,
	// 		width: 150,
	// 		itemId: item.id,
	// 		visible: true,
	// 		render: (column: Partial<ColumnProps<RowDataType>>) => (
	// 			<LightTable.Column
	// 				key={item.name?.[lang]}
	// 				resizable
	// 				sortable
	// 				{...column}
	// 			>
	// 				<LightTable.HeaderCell>
	// 					<HeaderLabel label={item.name?.[lang]} />
	// 				</LightTable.HeaderCell>
	// 				<LightTable.Cell dataKey={item.name?.[lang]} fullText>
	// 					{(rowData) => (
	// 						<GrossNetCellContent
	// 							id={item.id}
	// 							item={rowData as ExecutorReport.Model}
	// 						/>
	// 					)}
	// 				</LightTable.Cell>
	// 			</LightTable.Column>
	// 		),
	// 	}));
	// }, [executorRateColumns, lang]);

	// const executorRateColumnIds = useMemo(() => {
	// 	const payload: Record<string, string> = {} as const;
	// 	tableExecutorRateColumns.forEach((item) => {
	// 		payload[item.label] = item.label;
	// 	});

	// 	return payload;
	// }, [tableExecutorRateColumns]);

	// const allColumns = useMemo(
	// 	() => [...columns, ...tableExecutorRateColumns],
	// 	[columns, tableExecutorRateColumns],
	// );

	// type ExecutorRateColumnIds =
	// 	(typeof executorRateColumnIds)[keyof typeof executorRateColumnIds];

	const executorRates = useMemo<ExecutorRate.Model[]>(
		() => subscribeExecutorRate.models,
		[subscribeExecutorRate.models],
	);

	const value = useMemo(() => {
		if (!models || !models.length) return [];

		return models.map((item) => {
			const rate = executorRates.find((rate) =>
				rate?.executorIds?.includes(item.id),
			);

			return {
				...item,
				rate: rate
					? pick(rate, "amountOfMoneyToBlock", "active")
					: undefined,
			};
		});
	}, [executorRates, models]);

	const modelTableColumns = useMemo<Content.Column[]>(
		() =>
			columns
				.map((column) => ({
					id: column.id as LocalColumnId,
					width: widths[column.id],
					visible: columnIds.includes(column.id as LocalColumnId),
				}))
				.sort((leftColumn, rightColumn) => {
					const leftIndex = columnIds.indexOf(leftColumn.id);
					const rightIndex = columnIds.indexOf(rightColumn.id);
					return leftIndex - rightIndex;
				}),
		[columns, widths, columnIds],
	);

	const modelTableOnChangeColumns = useCallback(
		(columns: Content.Column[]) => {
			setColumnIds(
				columns
					.filter((column) => column.visible)
					.map((column) => column.id),
			);
			setWidths(
				columns.reduce((accumulator, column) => {
					accumulator[column.id] = column.width;
					return accumulator;
				}, {} as Record<LocalColumnId, number>),
			);
		},
		[setColumnIds, setWidths],
	);

	const columnLookup = useMemo(
		() => makeLookupTable(modelTableColumns, (column) => column.id, true),
		[modelTableColumns],
	);

	const tableSettingsValue = useMemo(
		() =>
			modelTableColumns
				.filter((column) => column.visible)
				.map((column) => column.id),
		[modelTableColumns],
	);

	const tableSettingsOnChange = useCallback(
		(columnIds: string[]) => {
			const otherColumns = modelTableColumns
				.filter((column) => !columnIds.includes(column.id))
				.map((column) => ({ ...column, visible: false }));

			const newColumns = columnIds.map((columnId) => ({
				id: columnId as LocalColumnId,
				width: columnLookup[columnId]?.value.width,
				visible: true,
			}));

			const arr = [...newColumns, ...otherColumns];
			modelTableOnChangeColumns(arr);
		},
		[columnLookup, modelTableColumns, modelTableOnChangeColumns],
	);

	const renderedColumns = useMemo(
		() =>
			modelTableColumns
				.filter((column) => column.visible)
				.map((column) => {
					const node = columnBase[column.id]?.render({
						width: column.width,
						language: lang,

						onResize: (width, columnId) => {
							const newColumns = modelTableColumns.map(
								(columns) =>
									columns.id === columnId
										? { ...columns, width: width ?? 0 }
										: columns,
							);

							modelTableOnChangeColumns(newColumns);
						},
					});

					// if (!node) {
					// 	const elem = tableExecutorRateColumns.find(
					// 		(item) => item.id === column.id,
					// 	);
					// 	const onResize = (width, columnId) => {
					// 		const newColumns = [
					// 			...modelTableColumns,
					// 			...tableExecutorRateColumns,
					// 		].map((item) =>
					// 			item.id === columnId
					// 				? { ...item, width: width ?? 0 }
					// 				: item,
					// 		);

					// 		modelTableOnChangeColumns(newColumns);
					// 	};

					// 	if (elem) {
					// 		return elem?.render({
					// 			width: column.width,
					// 			onResize,
					// 		});
					// 	}
					// }

					return node;
				}),
		[modelTableColumns, lang, modelTableOnChangeColumns],
	);

	const updateSelected = useCallback(
		(item: RowDataType<ExecutorReport.Model>, force = false) => {
			const model = item as ExecutorReport.Model;
			if (ctrlPressed || force) {
				if (selected.includes(model.id)) {
					setSelected(selected.filter((id) => model.id !== id));
				} else {
					setSelected([...selected, model.id]);
				}
			} else {
				selected.includes(model.id)
					? setSelected([])
					: setSelected([model.id]);
			}
		},
		[ctrlPressed, selected, setSelected],
	);

	const getClassName = useCallback(
		(rowData: RowDataType<ExecutorReport.Model>) => {
			if (rowData && selected.includes(rowData.id)) {
				if (!rowData.active) return "selected not-active";
				return "selected";
			}

			if (rowData && !rowData.active) return "not-active";
			return "";
		},
		[selected],
	);

	useLayoutEffect(() => {
		if (!countersTableRef || !countersTableRef.current) return;
		const { scrollLeft } = countersTableRef.current;
		scrollLeft(widthScroll);
	}, [widthScroll, value.length]);

	const countersData = useMemo(() => {
		if (!value.length) return [];
		return counters;
	}, [counters, value.length]);

	const valueSort = useMemo<ExecutorReport.Model[]>(() => {
		if (!valueLength) return [];
		const key = `${sort.dataKey}_${sort.sortType}`;
		const exist = valueMap.get(key);

		if (exist) return exist;

		if (sort.sortType === "asc") {
			if (sort.dataKey === LocalColumnId.Id) {
				const sort = [...value].sort((prev, next) => {
					const prevName = prev.id;
					const nextName = next.id;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.CallSign) {
				const sort = [...value].sort((prev, next) => {
					const prevName = prev.callSign;
					const nextName = next.callSign;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.FatherName) {
				const sort = [...value].sort((prev, next) => {
					const prevName = prev.person?.fatherName || "";
					const nextName = next.person?.fatherName || "";
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.LastName) {
				const sort = [...value].sort((prev, next) => {
					const prevName = prev.person?.lastName || "";
					const nextName = next.person?.lastName || "";
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.FirstName) {
				const sort = [...value].sort((prev, next) => {
					const prevName = prev.person?.firstName || "";
					const nextName = next.person?.firstName || "";
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.AdditionalFee) {
				const sort = [...value].sort((prev, next) => {
					const en = "Additional subscription fee";
					const prevName =
						prev.executorRateColumns.find(
							(data) => data.name?.en === en,
						)?.amount || 0;
					const nextName =
						next.executorRateColumns.find(
							(data) => data.name?.en === en,
						)?.amount || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}

			if (sort.dataKey === LocalColumnId.Balance) {
				const sort = [...value].sort((prev, next) => {
					const prevName =
						prev?.paymentAccounts?.find(
							(a) => a.type === PaymentAccountType.MAIN,
						)?.amount || 0;
					const nextName =
						next?.paymentAccounts?.find(
							(a) => a.type === PaymentAccountType.MAIN,
						)?.amount || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}

			if (sort.dataKey === LocalColumnId.BonusOrdersAmount) {
				const sort = [...value].sort((prev, next) => {
					const prevName = prev?.ordersCounters?.bonus?.amount || 0;
					const nextName = next?.ordersCounters?.bonus?.amount || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}

			if (sort.dataKey === LocalColumnId.BonusOrdersCount) {
				const sort = [...value].sort((prev, next) => {
					const prevName = prev?.ordersCounters?.bonus?.count || 0;
					const nextName = next?.ordersCounters?.bonus?.count || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}

			if (sort.dataKey === LocalColumnId.OrdersAmountToCardService) {
				const sort = [...value].sort((prev, next) => {
					const prevName =
						prev?.ordersCounters?.cardToTaxiService?.amount || 0;
					const nextName =
						next?.ordersCounters?.cardToTaxiService?.amount || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.OrdersCountToCardService) {
				const sort = [...value].sort((prev, next) => {
					const prevName =
						prev?.ordersCounters?.cardToTaxiService?.count || 0;
					const nextName =
						next?.ordersCounters?.cardToTaxiService?.count || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.OrdersAmountToCardExecutor) {
				const sort = [...value].sort((prev, next) => {
					const prevName =
						prev?.ordersCounters?.cardToExecutor?.amount || 0;
					const nextName =
						next?.ordersCounters?.cardToExecutor?.amount || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.OrdersCountToCardExecutor) {
				const sort = [...value].sort((prev, next) => {
					const prevName =
						prev?.ordersCounters?.cardToExecutor?.count || 0;
					const nextName =
						next?.ordersCounters?.cardToExecutor?.count || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.OrdersAmountToTerminalService) {
				const sort = [...value].sort((prev, next) => {
					const prevName =
						prev?.ordersCounters?.terminal?.amount || 0;
					const nextName =
						next?.ordersCounters?.terminal?.amount || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.OrdersCountToTerminalService) {
				const sort = [...value].sort((prev, next) => {
					const prevName = prev?.ordersCounters?.terminal?.count || 0;
					const nextName = next?.ordersCounters?.terminal?.count || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.CashAmount) {
				const sort = [...value].sort((prev, next) => {
					const prevName = prev?.ordersCounters?.cash?.amount || 0;
					const nextName = next?.ordersCounters?.cash?.amount || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.CashCount) {
				const sort = [...value].sort((prev, next) => {
					const prevName = prev?.ordersCounters?.cash?.count || 0;
					const nextName = next?.ordersCounters?.cash?.count || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.CashlessAmount) {
				const sort = [...value].sort((prev, next) => {
					const prevName =
						prev?.ordersCounters?.cashless?.amount || 0;
					const nextName =
						next?.ordersCounters?.cashless?.amount || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.CashlessCount) {
				const sort = [...value].sort((prev, next) => {
					const prevName = prev?.ordersCounters?.cashless?.count || 0;
					const nextName = next?.ordersCounters?.cashless?.count || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.OverallAmount) {
				const sort = [...value].sort((prev, next) => {
					const prevName = prev?.ordersCounters?.overall?.amount || 0;
					const nextName = next?.ordersCounters?.overall?.amount || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.OverallCount) {
				const sort = [...value].sort((prev, next) => {
					const prevName = prev?.ordersCounters?.overall?.count || 0;
					const nextName = next?.ordersCounters?.overall?.count || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.Cash) {
				const sort = [...value].sort((prev, next) => {
					const en = "Cash";
					const prevName =
						prev.executorRateColumns.find(
							(data) => data.name?.en === en,
						)?.amount || 0;
					const nextName =
						next.executorRateColumns.find(
							(data) => data.name?.en === en,
						)?.amount || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.Commission) {
				const sort = [...value].sort((prev, next) => {
					const en = "Commission";
					const prevName =
						prev.executorRateColumns.find(
							(data) => data.name?.en === en,
						)?.amount || 0;
					const nextName =
						next.executorRateColumns.find(
							(data) => data.name?.en === en,
						)?.amount || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.Fee) {
				const sort = [...value].sort((prev, next) => {
					const en = "Subscription fee";
					const prevName =
						prev.executorRateColumns.find(
							(data) => data.name?.en === en,
						)?.amount || 0;
					const nextName =
						next.executorRateColumns.find(
							(data) => data.name?.en === en,
						)?.amount || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.Rent) {
				const sort = [...value].sort((prev, next) => {
					const en = "Rent";
					const prevName =
						prev.executorRateColumns.find(
							(data) => data.name?.en === en,
						)?.amount || 0;
					const nextName =
						next.executorRateColumns.find(
							(data) => data.name?.en === en,
						)?.amount || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.CompensationByBonusOrders) {
				const sort = [...value].sort((prev, next) => {
					const prevName = prev?.discountCompensation || 0;
					const nextName = next?.discountCompensation || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.DueAmount) {
				const sort = [...value].sort((prev, next) => {
					const prevName = prev?.dueAmount || 0;
					const nextName = next?.dueAmount || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.DataChangeBalance) {
				const sort = [...value].sort((prev, next) => {
					const prevName =
						prev?.paymentAccounts?.find(
							(a) => a.type === PaymentAccountType.MAIN,
						)?.amountUpdatedAt || 0;
					const nextName =
						next?.paymentAccounts?.find(
							(a) => a.type === PaymentAccountType.MAIN,
						)?.amountUpdatedAt || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.Fleet) {
				const sort = [...value].sort((prev, next) => {
					const prevName = prev?.fleet?.name || "";
					const nextName = next?.fleet?.name || "";
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.Group) {
				const sort = [...value].sort((prev, next) => {
					const prevName = prev?.group?.name?.[lang] || "";
					const nextName = next?.group?.name?.[lang] || "";
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.InvoiceAmount) {
				const sort = [...value].sort((prev, next) => {
					const prevName = prev?.ordersCounters?.invoice?.amount || 0;
					const nextName = next?.ordersCounters?.invoice?.amount || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.InvoiceCount) {
				const sort = [...value].sort((prev, next) => {
					const prevName = prev?.ordersCounters?.invoice?.count || 0;
					const nextName = next?.ordersCounters?.invoice?.count || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.PaymentFromExecutorCash) {
				const sort = [...value].sort((prev, next) => {
					const prevName = prev?.manualReplenishmentAmount || 0;
					const nextName = next?.manualReplenishmentAmount || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.PaymentFromExecutorCashPeriod) {
				const sort = [...value].sort((prev, next) => {
					const prevName = prev?.manualReplenishmentPeriod || 0;
					const nextName = next?.manualReplenishmentPeriod || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.PaymentFromExecutorToAccount) {
				const sort = [...value].sort((prev, next) => {
					const prevName = prev?.cardReplenishmentAmount || 0;
					const nextName = next?.cardReplenishmentAmount || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (
				sort.dataKey ===
				LocalColumnId.PaymentFromExecutorToAccountPeriod
			) {
				const sort = [...value].sort((prev, next) => {
					const prevName =
						prev?.paymentAccounts?.find(
							(a) => a.type === PaymentAccountType.MAIN,
						)?.amountAtEndOfPeriod || 0;
					const nextName =
						next?.paymentAccounts?.find(
							(a) => a.type === PaymentAccountType.MAIN,
						)?.amountAtEndOfPeriod || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
		}
		// desc
		if (sort.sortType === "desc") {
			if (sort.dataKey === LocalColumnId.Id) {
				const sort = [...value].sort((prev, next) => {
					const prevName = next.id;
					const nextName = prev.id;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.CallSign) {
				const sort = [...value].sort((prev, next) => {
					const prevName = next.callSign;
					const nextName = prev.callSign;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.FatherName) {
				const sort = [...value].sort((prev, next) => {
					const prevName = next.person?.fatherName || "";
					const nextName = prev.person?.fatherName || "";
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.LastName) {
				const sort = [...value].sort((prev, next) => {
					const prevName = next.person?.lastName || "";
					const nextName = prev.person?.lastName || "";
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.FirstName) {
				const sort = [...value].sort((prev, next) => {
					const prevName = next.person?.firstName || "";
					const nextName = prev.person?.firstName || "";
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.AdditionalFee) {
				const sort = [...value].sort((prev, next) => {
					const en = "Additional subscription fee";
					const prevName =
						next.executorRateColumns.find(
							(data) => data.name?.en === en,
						)?.amount || 0;
					const nextName =
						prev.executorRateColumns.find(
							(data) => data.name?.en === en,
						)?.amount || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.Balance) {
				const sort = [...value].sort((prev, next) => {
					const prevName =
						next?.paymentAccounts?.find(
							(a) => a.type === PaymentAccountType.MAIN,
						)?.amount || 0;
					const nextName =
						prev?.paymentAccounts?.find(
							(a) => a.type === PaymentAccountType.MAIN,
						)?.amount || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.BonusOrdersAmount) {
				const sort = [...value].sort((prev, next) => {
					const prevName = next?.ordersCounters?.bonus?.amount || 0;
					const nextName = prev?.ordersCounters?.bonus?.amount || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.BonusOrdersCount) {
				const sort = [...value].sort((prev, next) => {
					const prevName = next?.ordersCounters?.bonus?.count || 0;
					const nextName = prev?.ordersCounters?.bonus?.count || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.OrdersAmountToCardService) {
				const sort = [...value].sort((prev, next) => {
					const prevName =
						next?.ordersCounters?.cardToTaxiService?.amount || 0;
					const nextName =
						prev?.ordersCounters?.cardToTaxiService?.amount || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.OrdersCountToCardService) {
				const sort = [...value].sort((prev, next) => {
					const prevName =
						next?.ordersCounters?.cardToTaxiService?.count || 0;
					const nextName =
						prev?.ordersCounters?.cardToTaxiService?.count || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.OrdersAmountToCardExecutor) {
				const sort = [...value].sort((prev, next) => {
					const prevName =
						next?.ordersCounters?.cardToExecutor?.amount || 0;
					const nextName =
						prev?.ordersCounters?.cardToExecutor?.amount || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.OrdersCountToCardExecutor) {
				const sort = [...value].sort((prev, next) => {
					const prevName =
						next?.ordersCounters?.cardToExecutor?.count || 0;
					const nextName =
						prev?.ordersCounters?.cardToExecutor?.count || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.OrdersAmountToTerminalService) {
				const sort = [...value].sort((prev, next) => {
					const prevName =
						next?.ordersCounters?.terminal?.amount || 0;
					const nextName =
						prev?.ordersCounters?.terminal?.amount || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.OrdersCountToTerminalService) {
				const sort = [...value].sort((prev, next) => {
					const prevName = next?.ordersCounters?.terminal?.count || 0;
					const nextName = prev?.ordersCounters?.terminal?.count || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.CashAmount) {
				const sort = [...value].sort((prev, next) => {
					const prevName = next?.ordersCounters?.cash?.amount || 0;
					const nextName = prev?.ordersCounters?.cash?.amount || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.CashCount) {
				const sort = [...value].sort((prev, next) => {
					const prevName = next?.ordersCounters?.cash?.count || 0;
					const nextName = prev?.ordersCounters?.cash?.count || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.CashlessAmount) {
				const sort = [...value].sort((prev, next) => {
					const prevName =
						next?.ordersCounters?.cashless?.amount || 0;
					const nextName =
						prev?.ordersCounters?.cashless?.amount || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.CashlessCount) {
				const sort = [...value].sort((prev, next) => {
					const prevName = next?.ordersCounters?.cashless?.count || 0;
					const nextName = prev?.ordersCounters?.cashless?.count || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.OverallAmount) {
				const sort = [...value].sort((prev, next) => {
					const prevName = next?.ordersCounters?.overall?.amount || 0;
					const nextName = prev?.ordersCounters?.overall?.amount || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.OverallCount) {
				const sort = [...value].sort((prev, next) => {
					const prevName = next?.ordersCounters?.overall?.count || 0;
					const nextName = prev?.ordersCounters?.overall?.count || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.Cash) {
				const sort = [...value].sort((prev, next) => {
					const en = "Cash";
					const prevName =
						next.executorRateColumns.find(
							(data) => data.name?.en === en,
						)?.amount || 0;
					const nextName =
						prev.executorRateColumns.find(
							(data) => data.name?.en === en,
						)?.amount || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.Commission) {
				const sort = [...value].sort((prev, next) => {
					const en = "Commission";
					const prevName =
						next.executorRateColumns.find(
							(data) => data.name?.en === en,
						)?.amount || 0;
					const nextName =
						prev.executorRateColumns.find(
							(data) => data.name?.en === en,
						)?.amount || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.Fee) {
				const sort = [...value].sort((prev, next) => {
					const en = "Subscription fee";
					const prevName =
						next.executorRateColumns.find(
							(data) => data.name?.en === en,
						)?.amount || 0;
					const nextName =
						prev.executorRateColumns.find(
							(data) => data.name?.en === en,
						)?.amount || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.Rent) {
				const sort = [...value].sort((prev, next) => {
					const en = "Rent";
					const prevName =
						next.executorRateColumns.find(
							(data) => data.name?.en === en,
						)?.amount || 0;
					const nextName =
						prev.executorRateColumns.find(
							(data) => data.name?.en === en,
						)?.amount || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.CompensationByBonusOrders) {
				const sort = [...value].sort((prev, next) => {
					const prevName = next?.discountCompensation || 0;
					const nextName = prev?.discountCompensation || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.DueAmount) {
				const sort = [...value].sort((prev, next) => {
					const prevName = next?.dueAmount || 0;
					const nextName = prev?.dueAmount || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.DataChangeBalance) {
				const sort = [...value].sort((prev, next) => {
					const prevName =
						next?.paymentAccounts?.find(
							(a) => a.type === PaymentAccountType.MAIN,
						)?.amountUpdatedAt || 0;
					const nextName =
						prev?.paymentAccounts?.find(
							(a) => a.type === PaymentAccountType.MAIN,
						)?.amountUpdatedAt || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.Fleet) {
				const sort = [...value].sort((prev, next) => {
					const prevName = next?.fleet?.name || "";
					const nextName = prev?.fleet?.name || "";
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.Group) {
				const sort = [...value].sort((prev, next) => {
					const prevName = next?.group?.name?.[lang] || "";
					const nextName = prev?.group?.name?.[lang] || "";
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.InvoiceAmount) {
				const sort = [...value].sort((prev, next) => {
					const prevName = next?.ordersCounters?.invoice?.amount || 0;
					const nextName = prev?.ordersCounters?.invoice?.amount || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.InvoiceCount) {
				const sort = [...value].sort((prev, next) => {
					const prevName = next?.ordersCounters?.invoice?.count || 0;
					const nextName = prev?.ordersCounters?.invoice?.count || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.PaymentFromExecutorCash) {
				const sort = [...value].sort((prev, next) => {
					const prevName = next?.manualReplenishmentAmount || 0;
					const nextName = prev?.manualReplenishmentAmount || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.PaymentFromExecutorCashPeriod) {
				const sort = [...value].sort((prev, next) => {
					const prevName = next?.manualReplenishmentPeriod || 0;
					const nextName = prev?.manualReplenishmentPeriod || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === LocalColumnId.PaymentFromExecutorToAccount) {
				const sort = [...value].sort((prev, next) => {
					const prevName = next?.cardReplenishmentAmount || 0;
					const nextName = prev?.cardReplenishmentAmount || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
			if (
				sort.dataKey ===
				LocalColumnId.PaymentFromExecutorToAccountPeriod
			) {
				const sort = [...value].sort((prev, next) => {
					const prevName =
						next?.paymentAccounts?.find(
							(a) => a.type === PaymentAccountType.MAIN,
						)?.amountAtEndOfPeriod || 0;
					const nextName =
						prev?.paymentAccounts?.find(
							(a) => a.type === PaymentAccountType.MAIN,
						)?.amountAtEndOfPeriod || 0;
					return sortByNumberOrString(prevName, nextName);
				});
				valueMap.set(key, sort);
				return sort;
			}
		}
		return value;
	}, [valueLength, sort.dataKey, sort.sortType, valueMap, value, lang]);

	const areas = useMemo(() => `'table' 'counters' 'settings' `, []);
	return (
		<StyledGrid
			areas={areas}
			rows="1fr 32px 32px"
			w="100%"
			h="100%"
			br="5px"
			over="hidden"
		>
			<StyledGridItem
				area="table"
				w="100%"
				h="100%"
				tabIndex={-1}
				onKeyDown={onKeyDown}
				onKeyUp={onKeyUp}
			>
				<LightTable
					ref={tableRef}
					data={valueSort}
					virtualized
					fillHeight
					rowHeight={32}
					shouldUpdateScroll={false}
					rowClassName={getClassName}
					onRowClick={updateSelected}
					sortColumn={sort?.dataKey}
					sortType={sort?.sortType}
					onScroll={onScroll}
					onSortColumn={onSort}
				>
					{renderedColumns}
				</LightTable>
			</StyledGridItem>
			<StyledGridItem area="counters" w="100%" h="32px">
				<Divider side="top" />
				<LightTable
					ref={countersTableRef}
					data={countersData}
					virtualized
					rowHeight={32}
					headerHeight={0}
					height={32}
					minHeight={32}
					showHeader={!!counters.length}
					shouldUpdateScroll={false}
					autoHeight
				>
					{renderedColumns}
				</LightTable>
			</StyledGridItem>
			<StyledGridItem area="settings" w="100%" h="100%" zi="1">
				<TableSettings
					value={tableSettingsValue}
					defaultValue={defaultColumnIds}
					columns={columns}
					disabledColumnsIds={disabledColumnsIds}
					onChange={tableSettingsOnChange}
				/>
			</StyledGridItem>
		</StyledGrid>
	);
};

declare namespace Content {
	interface Column {
		id: LocalColumnId;
		width: number;
		visible: boolean;
	}
	interface Value {
		id: number;
		active: boolean;
		name: string;
	}
	interface Props {
		value: ExecutorReport.Model[];
		counters: Pick<
			ExecutorReport.Model,
			| "ordersCounters"
			| "executorRateColumns"
			| "dueAmount"
			| "discountCompensation"
			| "callSign"
		>[];
		executorRateColumns: ExecutorReport.ExecutorRateColumn[] | null;
		selected: number[];
		setSelected: Dispatch<number[]>;
		editorTable: UseTableOptions["editor"];
		onChangeTable: UseTableOptions["onChange"];
	}
}

export default Content;
