/* eslint-disable no-shadow */
import React, { Dispatch, RefAttributes, useMemo } from "react";
import { react, useRefWithSetter } from "uikit";

import Language from "../../../../../../../../../../services/Language";
import {
	useObjectEditor,
	useTaxiServiceIdsDecoder,
} from "../../../../../../../../../../hooks";
import {
	ExecutorSelectTab,
	DispatcherSelectTab,
} from "../../../../../../../../../../components";

import { Root, MainTab, HistoryTab, PermissionsTab } from "./components";
import InternalController from "./Controller";

const Content = react.withController<Content.PropsBase, Content.Controller>(
	({ controller, value, type, disabled, language, history, onChange }) => {
		const [mainTabRef, setMainTabRef] =
			useRefWithSetter<MainTab.Ref | null>(null);

		controller.setContext({ mainTabRef });

		const decodeTaxiServiceIds = useTaxiServiceIdsDecoder();

		const valueEditor = useObjectEditor(value, onChange);

		const companyIds = valueEditor.useGetter("companyIds");
		const taxiServiceIds = valueEditor.useGetter("taxiServiceIds");

		const decodedTaxiServiceIds = useMemo(
			() => decodeTaxiServiceIds(companyIds, taxiServiceIds),
			[companyIds, decodeTaxiServiceIds, taxiServiceIds],
		);

		const assignableToValue = valueEditor.useGetter("assignableTo");

		const mainTabValue = valueEditor.usePicker([
			"name",
			"assignableTo",
			"default",
			"companyIds",
			"taxiServiceIds",
			"description",
			"executorIds",
			"dispatcherIds",
			"serviceIds",
		]);

		const userSelectTabValue = valueEditor.usePicker(["dispatcherIds"]);

		const executorSelectTabValue = valueEditor.usePicker(["executorIds"]);

		const permissionsTabValue = valueEditor.usePicker(["permissions"]);

		const anyTabOnChange = valueEditor.useAssigner();

		const content = useMemo(
			() => (
				<>
					<MainTab
						ref={setMainTabRef}
						value={mainTabValue}
						disabled={disabled}
						visible={type === "main"}
						language={language}
						onChange={anyTabOnChange}
					/>
					<DispatcherSelectTab
						value={userSelectTabValue}
						disabled={disabled}
						visible={type === "user-select"}
						language={language}
						allowedTaxiServiceIds={decodedTaxiServiceIds}
						onChange={anyTabOnChange}
					/>
					<ExecutorSelectTab
						value={executorSelectTabValue}
						disabled={disabled}
						visible={type === "executor-select"}
						language={language}
						allowedTaxiServiceIds={decodedTaxiServiceIds}
						onChange={anyTabOnChange}
					/>
					<PermissionsTab
						value={permissionsTabValue}
						disabled={disabled}
						visible={type === "permission-select"}
						assignableTo={assignableToValue}
						onChange={anyTabOnChange}
					/>
					<HistoryTab
						value={history}
						visible={type === "history"}
						language={language}
					/>
				</>
			),
			[
				setMainTabRef,
				mainTabValue,
				type,
				language,
				anyTabOnChange,
				userSelectTabValue,
				disabled,
				decodedTaxiServiceIds,
				executorSelectTabValue,
				permissionsTabValue,
				assignableToValue,
				history,
			],
		);

		return <Root>{content}</Root>;
	},
	InternalController,
);

declare namespace Content {
	type Ref = InternalController | null;

	type Controller = InternalController;

	type Type =
		| "main"
		| "user-select"
		| "executor-select"
		| "permission-select"
		| "history";

	type Value = MainTab.Value &
		DispatcherSelectTab.Value &
		PermissionsTab.Value &
		ExecutorSelectTab.Value;

	interface PropsBase {
		value: Value;

		type: Type;
		disabled: boolean;
		language: Language;
		history: HistoryTab.Value;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default Content;
