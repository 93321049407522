import React, { memo, useMemo, useCallback } from "react";
import styled from "styled-components";
import { LatLngLiteral } from "leaflet";

import { IOrderPoint } from "../../../../redux/constants/OrdersPage/order";
import CompactTable from "../../../CompactTable";
import PointsTable from "../../PointsTable";

import { PointOrderModalTableProvider } from "./context";
import { Header, WrapItem } from "./components";

const Wrap = styled(CompactTable)`
	overflow-y: auto;
	overflow-x: auto;

	background-color: #ffffff;

	.rs-table-scrollbar-vertical {
		width: 5px;
		background-color: transparent;
		.rs-table-scrollbar-handle {
			width: 4px;
		}
	}

	div .rs-table-cell-full-text:hover {
		box-shadow: none;
	}
	div .rs-table-cell {
		overflow: visible;
	}
`;

/**
 * Points Table component preforms order points rendering
 */
const PointOrderModalTableBase: React.FC<PointOrderModalTableBase.Props> = ({
	tableLoading,
	headerHeight,
	rowHeight,
	tableWidth,
	data,
	setData,
	defaultColumns = [],
	disabled,
	customerId,
	activeRow,
	handleColumnResize,
	handleRowClick,
	containerId,
	onAddRow,
	onDelete,
	searchTypes,
	taxiServiceId,
}) => {
	const columns = useMemo(() => defaultColumns, [defaultColumns]);

	const updatedData = useCallback(
		(params: PointOrderModalTableBase.OrderModal) => {
			if (!params) return;
			if (!params.raw.save) return;

			const newData = data.map((row) => {
				if (params.id === row.id) return params;
				return row;
			});

			setData(newData);
			if (handleRowClick) handleRowClick(params);
		},

		[data, handleRowClick, setData],
	);

	const tableRowClassName = useCallback(
		(rowData) => {
			if (activeRow === rowData?.id) return "founded";
			return "not-active";
		},
		[activeRow],
	);

	const countRow = useMemo(() => data.length, [data.length]);
	return (
		<PointOrderModalTableProvider>
			<Wrap
				loading={tableLoading}
				headerHeight={headerHeight}
				rowHeight={rowHeight}
				width={tableWidth}
				data={data}
				rowClassName={tableRowClassName}
				onRowClick={handleRowClick}
				bordered
				cellBordered
				hover
				showHeader
				fillHeight
			>
				{columns.map((column, i) => {
					const { key, label, width, ...rest } = column;

					const resizable = [
						key === "setting",
						key === "id",
					].includes(true);
					const id = key === "id" ? "left" : "right";
					return (
						<CompactTable.Column
							{...rest}
							width={width}
							resizable={!resizable}
							key={key}
							fullText
							onResize={handleColumnResize}
							fixed={resizable ? id : false}
						>
							<Header fixed width={width || 0}>
								{label}
							</Header>
							<CompactTable.Cell fullText dataKey={key}>
								{(rowData, rowIndex) => (
									<WrapItem
										width={width}
										dataKey={key}
										colIndex={i}
										rowIndex={rowIndex}
										height={rowHeight}
										rowData={
											rowData as PointOrderModalTableBase.OrderModal
										}
										setData={updatedData}
										disabled={disabled}
										customerId={customerId}
										containerId={containerId}
										onAddRow={onAddRow}
										onDelete={onDelete}
										countRow={countRow}
										searchTypes={searchTypes}
										taxiServiceId={taxiServiceId}
									/>
								)}
							</CompactTable.Cell>
						</CompactTable.Column>
					);
				})}
			</Wrap>
		</PointOrderModalTableProvider>
	);
};

declare namespace PointOrderModalTableBase {
	interface Props {
		disabled?: boolean;
		customerId?: number;
		data: OrderModal[];
		defaultColumns: Required<PointsTable.Props["tableSchema"]>;
		tableLoading?: boolean;
		setData: (data: OrderModal[]) => void;
		handleColumnResize?: (
			width?: number,
			columnKey?: string | number,
		) => void;
		headerHeight?: number;
		rowHeight?: number;
		tableWidth?: number;

		handleRowClick?: (data) => void;
		activeClass?: boolean;
		activeRow?: PointsTable.Props["activeRow"];
		containerId?: string;
		onAddRow?: (num: number) => void;
		onDelete?: (id: number) => void;
		searchTypes?: WrapItem.Props["searchTypes"];
		taxiServiceId?: WrapItem.Props["taxiServiceId"];
	}

	type SaveType = "city" | "settlement" | "street" | "house" | "entrance";
	type Sector = IOrderPoint["sector"];
	interface OrderModal {
		id: number;
		city: string;
		street: string;
		house: string;
		entrance: string;
		flat: string;
		polygon: string;
		coordinates: LatLngLiteral;
		type?: IOrderPoint["type"];
		sector?: Sector;
		save?: boolean;
		name?: string;
		saveType?: SaveType;
		customHouse?: string;
		customStreet?: string;
		title?: string;
		raw: IOrderPoint & {
			id: number;
			save: boolean;
			saveType?: SaveType;
			sector?: Sector;
		};
	}
}

export const PointOrderModalTable = memo(PointOrderModalTableBase);
export default PointOrderModalTableBase;
