import React, { useMemo } from "react";
import Order from "../../../../../../../services/Order";
import CellContentRoot from "../CellContentRoot";

const DiscountCellContent: React.FC<DiscountCellContent.Props> = ({ item }) => {
	const content = useMemo(
		() => item.additionalFields?.discount?.active ?? "",
		[item.additionalFields?.discount?.active],
	);

	return (
		<CellContentRoot align="center" maxedHeight maxedWidth>
			{content}
		</CellContentRoot>
	);
};

declare namespace DiscountCellContent {
	interface Props {
		item: Order.Model;
	}
}

export default DiscountCellContent;
