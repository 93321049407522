import { useCallback, useMemo } from "react";

import { useTypedDispatch, useTypedSelector } from "../../../../../redux/store";
import {
	State,
	accountingAction as accounting,
} from "../../../../../redux/reducers/Accounting";

export type UseAccountingExecutors = {
	data: State["executors"]["data"];
	executors: State["executors"]["data"]["executors"];
	executor: State["executors"]["data"]["executor"];
	counters: State["executors"]["data"]["counters"];
	errors: State["executors"]["errors"];
	filters: State["executors"]["filters"];
	/** Set executors */
	setExecutors: (data: State["executors"]["data"]["executors"]) => void;
	/** Set executor */
	setExecutor: (data: State["executors"]["data"]["executor"]) => void;
	/** Set counters */
	setCounters: (data: State["executors"]["data"]["counters"]) => void;
	/** Update executor */
	updateExecutor: (data: State["executors"]["data"]["executor"]) => void;

	/** Set query */
	setQuery: (data: State["executors"]["filters"]["query"]) => void;
	/** Set dateRange */
	setDateRange: (data: State["executors"]["filters"]["dateRange"]) => void;
	/** Set callSigns */
	setCallSigns: (data: State["executors"]["filters"]["callSigns"]) => void;
	/** Set companyIds */
	setCompanyIds: (data: State["executors"]["filters"]["companyIds"]) => void;
	/** Set taxiServiceIds */
	setTaxiServiceIds: (
		data: State["executors"]["filters"]["taxiServiceIds"],
	) => void;
	/** Set executorIds */
	setExecutorIds: (
		data: State["executors"]["filters"]["executorIds"],
	) => void;
	/** Set executorGroupIds */
	setExecutorGroupIds: (
		data: State["executors"]["filters"]["executorGroupIds"],
	) => void;
	/** Set fleetIds */
	setFleetIds: (data: State["executors"]["filters"]["fleetIds"]) => void;
	/** Set `isSortMap`. Gives permission to update  */
	setIsSortMap: (data: State["executors"]["filters"]["isSortMap"]) => void;

	reset: () => void;
	resetFilters: () => void;
};

/** `useAccountingExecutors`
 ** Hook to get all the executor report inside the redux store accounting.
 ** And has functions for changing the state of executor report.
 */
export const useAccountingExecutors = (): UseAccountingExecutors => {
	const dispatch = useTypedDispatch();
	const data = useTypedSelector((state) => state.accounting.executors.data);
	const executors = useTypedSelector(
		(state) => state.accounting.executors.data.executors,
	);
	const executor = useTypedSelector(
		(state) => state.accounting.executors.data.executor,
	);
	const counters = useTypedSelector(
		(state) => state.accounting.executors.data.counters,
	);
	const filters = useTypedSelector(
		(state) => state.accounting.executors.filters,
	);
	const errors = useTypedSelector(
		(state) => state.accounting.executors.errors,
	);

	const setExecutors = useCallback<UseAccountingExecutors["setExecutors"]>(
		(data) => {
			dispatch(accounting.executors.data.setExecutors(data));
		},
		[dispatch],
	);
	const setExecutor = useCallback<UseAccountingExecutors["setExecutor"]>(
		(data) => {
			dispatch(accounting.executors.data.setExecutor(data));
		},
		[dispatch],
	);

	const updateExecutor = useCallback<
		UseAccountingExecutors["updateExecutor"]
	>(
		(data) => {
			dispatch(accounting.executors.data.updateExecutor(data));
		},
		[dispatch],
	);

	const setCounters = useCallback<UseAccountingExecutors["setCounters"]>(
		(data) => {
			dispatch(accounting.executors.data.setCounters(data));
		},
		[dispatch],
	);

	const setQuery = useCallback<UseAccountingExecutors["setQuery"]>(
		(data) => {
			dispatch(accounting.executors.filters.setQuery(data));
		},
		[dispatch],
	);

	const setDateRange = useCallback<UseAccountingExecutors["setDateRange"]>(
		(data) => {
			dispatch(accounting.executors.filters.setDateRange(data));
		},
		[dispatch],
	);

	const setCallSigns = useCallback<UseAccountingExecutors["setCallSigns"]>(
		(data) => {
			dispatch(accounting.executors.filters.setCallSigns(data));
		},
		[dispatch],
	);

	const setCompanyIds = useCallback<UseAccountingExecutors["setCompanyIds"]>(
		(data) => {
			dispatch(accounting.executors.filters.setCompanyIds(data));
		},
		[dispatch],
	);

	const setTaxiServiceIds = useCallback<
		UseAccountingExecutors["setTaxiServiceIds"]
	>(
		(data) => {
			dispatch(accounting.executors.filters.setTaxiServiceIds(data));
		},
		[dispatch],
	);

	const setExecutorIds = useCallback<
		UseAccountingExecutors["setExecutorIds"]
	>(
		(data) => {
			dispatch(accounting.executors.filters.setExecutorIds(data));
		},
		[dispatch],
	);

	const setFleetIds = useCallback<UseAccountingExecutors["setFleetIds"]>(
		(data) => {
			dispatch(accounting.executors.filters.setFleetIds(data));
		},
		[dispatch],
	);

	const setExecutorGroupIds = useCallback<
		UseAccountingExecutors["setExecutorGroupIds"]
	>(
		(data) => {
			dispatch(accounting.executors.filters.setExecutorGroupIds(data));
		},
		[dispatch],
	);

	const setIsSortMap = useCallback<UseAccountingExecutors["setIsSortMap"]>(
		(data) => {
			dispatch(accounting.executors.filters.setIsSortMap(data));
		},
		[dispatch],
	);

	const reset = useCallback<UseAccountingExecutors["reset"]>(() => {
		dispatch(accounting.executors.data.reset({}));
	}, [dispatch]);

	const resetFilters = useCallback<
		UseAccountingExecutors["resetFilters"]
	>(() => {
		dispatch(accounting.executors.filters.reset({}));
	}, [dispatch]);

	return useMemo<UseAccountingExecutors>(
		() => ({
			data,
			executor,
			executors,
			counters,
			errors,
			filters,
			setExecutors,
			setExecutor,
			setCounters,
			reset,
			resetFilters,
			setQuery,
			setExecutorIds,
			setTaxiServiceIds,
			setCallSigns,
			setCompanyIds,
			setDateRange,
			setExecutorGroupIds,
			setFleetIds,
			updateExecutor,
			setIsSortMap,
		}),
		[
			data,
			executor,
			executors,
			counters,
			errors,
			filters,
			setExecutors,
			setExecutor,
			setCounters,
			reset,
			resetFilters,
			setQuery,
			setExecutorIds,
			setTaxiServiceIds,
			setCallSigns,
			setCompanyIds,
			setDateRange,
			setExecutorGroupIds,
			setFleetIds,
			updateExecutor,
			setIsSortMap,
		],
	);
};

export default useAccountingExecutors;
