import React, { Dispatch, useCallback, useLayoutEffect, useMemo } from "react";
import { CheckBox } from "uikit";
import { useTranslation } from "react-i18next";
import { RowDataType } from "rsuite-table";

import Counterparty from "../../../../../../../../services/Counterparty";
import {
	useColumns,
	useVisibleColumns,
} from "../../../../../../../../hooks/useTableSettings";
import LightTable, {
	useTableOptions,
	UseTableOptions,
} from "../../../../../../../../components/LightTable";
import TableSettings from "../../../../../../../../components/TableSettings";

import getCounterpartyCellData from "./getCounterpartyCellData";
import Root from "./components/Root";

const Content: React.FC<Content.Props> = ({
	value,
	selected,
	setSelected,
	onEdit,
	onChangeTable,
	editorTable,
}) => {
	const { t } = useTranslation();
	const {
		sort,
		onSort,
		onScroll,
		tableRef,
		onKeyUp,
		onKeyDown,
		ctrlPressed,
		lang,
		setDataLength,
	} = useTableOptions({
		itemLimit: 2,
		value: editorTable,
		setValue: onChangeTable,
	});

	useLayoutEffect(() => {
		setDataLength(value.length);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value.length]);

	const columns = useColumns("costomerCounterparty");
	const { columnIds, setColumnIds } = useVisibleColumns(
		"customers.costomerCounterparty",
		"costomerCounterparty",
	);

	const updateSelected = useCallback(
		(item: RowDataType<Counterparty.Model>, force = false) => {
			const model = item as Counterparty.Model;
			if (ctrlPressed || force) {
				if (selected.includes(model.id)) {
					setSelected(selected.filter((id) => model.id !== id));
				} else {
					setSelected([...selected, model.id]);
				}
			} else {
				selected.includes(model.id)
					? setSelected([])
					: setSelected([model.id]);
			}
		},
		[ctrlPressed, selected, setSelected],
	);

	const data = useMemo(
		() =>
			columnIds.map((columnId) => {
				const column = columns.find((col) => col.id === columnId);
				if (!column) return null;

				return (
					<LightTable.Column
						resizable
						sortable
						key={columnId}
						verticalAlign="middle"
						flexGrow={1}
					>
						<LightTable.HeaderCell style={{ padding: "0 20px" }}>
							{column.label}
						</LightTable.HeaderCell>
						<LightTable.Cell
							verticalAlign="middle"
							style={{ padding: "0 20px" }}
							dataKey={column.id}
							fullText
						>
							{(rowData: RowDataType<Counterparty.Model>) =>
								getCounterpartyCellData(
									rowData,
									columnId,
									lang,
									t,
								)
							}
						</LightTable.Cell>
					</LightTable.Column>
				);
			}),
		[columnIds, columns, lang, t],
	);

	const getClassName = useCallback(
		(rowData: RowDataType<Counterparty.Model>) => {
			if (rowData && selected.includes(rowData.id)) {
				if (!rowData.active) return "selected not-active";
				return "selected";
			}
			if (rowData && !rowData.active) return "not-active";
			return "";
		},
		[selected],
	);

	const handleOnChangeSelected = useCallback(
		(isAllSelected: boolean) => {
			if (isAllSelected) {
				setSelected(value.map(({ id }) => id));
			} else {
				setSelected([]);
			}
		},
		[setSelected, value],
	);

	return (
		<Root sizes="1fr auto!">
			<div tabIndex={-1} onKeyDown={onKeyDown} onKeyUp={onKeyUp}>
				<LightTable
					ref={tableRef}
					data={value}
					virtualized
					fillHeight
					rowHeight={(data) => {
						const value = data?.checks?.length;
						if (value > 1) return value * 28;
						return 46;
					}}
					shouldUpdateScroll={false}
					rowClassName={getClassName}
					onRowClick={updateSelected}
					onRowDoubleClick={onEdit}
					sortColumn={sort?.dataKey}
					sortType={sort?.sortType}
					onScroll={onScroll}
					onSortColumn={onSort}
				>
					<LightTable.Column verticalAlign="middle" width={50}>
						<LightTable.HeaderCell style={{ padding: "0 25px" }}>
							<CheckBox
								value={
									selected.length === value.length &&
									value.length !== 0
								}
								onChange={handleOnChangeSelected}
							/>
						</LightTable.HeaderCell>
						<LightTable.Cell
							verticalAlign="middle"
							style={{ padding: "0 25px" }}
						>
							{(rowData) => (
								<CheckBox
									value={selected.includes(rowData.id)}
									onChange={() => {
										updateSelected(rowData, true);
									}}
								/>
							)}
						</LightTable.Cell>
					</LightTable.Column>
					{data}
				</LightTable>
			</div>
			<TableSettings
				value={columnIds}
				defaultValue={columnIds}
				columns={columns}
				onChange={setColumnIds}
			/>
		</Root>
	);
};

declare namespace Content {
	interface Props {
		value: Counterparty.Model[];

		selected: number[];
		setSelected: Dispatch<number[]>;

		editorTable: UseTableOptions["editor"];
		onChangeTable: UseTableOptions["onChange"];
		onEdit?: Dispatch<Counterparty.Model>;
	}

	type ColumnType = {
		id: string;
		label: string;
		width?: number;
		justify?: "center";
	};
}

export default Content;
