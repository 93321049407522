import { generateAccessName, AccessKey } from "../../../../../access";

/**
 * Base keys of the access sections on this page.
 */
const ACCESS_SECTION: AccessKey[] = [AccessKey.ACCOUNTS];

/**
 * Enum representing special keys for each tab on this page.
 * These keys are used to uniquely identify and manage individual tabs.
 */
enum TabKeys {
	EXECUTORS = "executors",
	TRANSPORT = "transport",
	USERS = "users",
	ROLES = "roles",
	ROLES_OLD = "rolesOld",
	CAR_PARKS = "carParks",
	SCHEDULES = "schedules",
	EXECUTOR_TEAMS = "executorTeams",
}

/**
 * Constructs an array of access addresses by combining base access addresses
 * with the tab-specific access keys. This builds the full set of addresses
 * needed to manage access from the page level to the specific tab.
 *
 * @param key - Tab-specific access keys.
 * @returns Array of access addresses including base and tab-specific addresses.
 */
const generateAccessSection = (key: AccessKey): AccessKey[] => [
	...ACCESS_SECTION,
	key,
];

/**
 * Maps each tab on the page to its corresponding array of access addresses.
 * This container is used for managing access control not only for the tabs themselves,
 * but also for internal elements such as buttons within these tabs.
 * Each entry provides a set of access addresses specific to the tab.
 */
const ARR_ACCESS_PART_KEY: Record<TabKeys, AccessKey[]> = {
	[TabKeys.EXECUTORS]: generateAccessSection(AccessKey.EXECUTORS),
	[TabKeys.TRANSPORT]: generateAccessSection(AccessKey.TRANSPORT),
	[TabKeys.USERS]: generateAccessSection(AccessKey.USERS),
	[TabKeys.ROLES]: generateAccessSection(AccessKey.ROLES),
	[TabKeys.ROLES_OLD]: generateAccessSection(AccessKey.ROLES),
	[TabKeys.CAR_PARKS]: generateAccessSection(AccessKey.CAR_PARKS),
	[TabKeys.SCHEDULES]: generateAccessSection(AccessKey.SCHEDULES),
	[TabKeys.EXECUTOR_TEAMS]: generateAccessSection(AccessKey.EXECUTOR_TEAMS),
};

/**
 * Type representing the access names for each tab.
 */
type TabAccessNamesType = {
	[key in TabKeys]: string;
};

/**
 * Generates access names from arrays of access keys for each tab.
 * This mapping creates a unique access name for each tab, which is used
 * to determine whether the tab is accessible or not. Each access name represents
 * the combined access keys for the tab.
 *
 * @returns TabAccessNamesType - An object mapping each tab key to its unique access name
 */
const TabAccessNames: TabAccessNamesType = Object.fromEntries(
	Object.entries(ARR_ACCESS_PART_KEY).map(([key, section]) => [
		key,
		generateAccessName(...section),
	]),
) as TabAccessNamesType;

export { TabKeys, TabAccessNames, ARR_ACCESS_PART_KEY };
