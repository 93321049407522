/* eslint-disable no-case-declarations */
import React, { Dispatch, useCallback, useMemo } from "react";
import { CheckBox } from "uikit";
import { RowDataType } from "rsuite-table";

import Employee from "../../../../../../../../../../../services/Employee";
import {
	useColumns,
	useVisibleColumns,
} from "../../../../../../../../../../../hooks/useTableSettings";
import LightTable, {
	UseTableOptions,
	useTableOptions,
} from "../../../../../../../../../../../components/LightTable";
import Accounts from "../../../../Modal/components/Content/tabs/Accounts";
import Cards from "../../../../Modal/components/Content/tabs/Accounts/Cards";
import TableSettings from "../../../../../../../../../../../components/TableSettings";
import DisabledCheckbox from "../../../../../../../../../../../components/DisabledCheckbox";

import GeneralEmployees from "../..";
import Root from "./components/Root";

const Content: React.FC<Content.Props> = ({
	tableFrom,
	columnsFilter,
	columnsName = "counterpartyEmployee",
	checks,
	cards,
	value,
	selected,
	setSelected,
	onEdit,
	onChangeTable,
	editorTable,
}) => {
	const {
		sort,
		onSort,
		onScroll,
		tableRef,
		onKeyUp,
		onKeyDown,
		ctrlPressed,
	} = useTableOptions({
		value: editorTable,
		setValue: onChangeTable,
	});

	const columns = useColumns(columnsName);
	const { columnIds, setColumnIds } = useVisibleColumns(
		tableFrom,
		columnsName,
	);
	const actualColumns = useMemo(
		() =>
			columns.filter((col) => {
				if (columnsFilter.counterparty) return true;
				if (columnsFilter.check && col.id === "check") {
					return false;
				}
				if (
					(columnsFilter.card && col.id === "card") ||
					col.id === "check"
				) {
					return false;
				}
				return true;
			}),
		[
			columns,
			columnsFilter.card,
			columnsFilter.check,
			columnsFilter.counterparty,
		],
	);

	const updateSelected = useCallback(
		(item: RowDataType<Employee.Model | Employee.New>, force = false) => {
			const model = item as Employee.Model | Employee.New;
			if (ctrlPressed || force) {
				if (selected.includes(model.id)) {
					setSelected(selected.filter((id) => model.id !== id));
				} else {
					setSelected([...selected, model.id]);
				}
			} else {
				selected.includes(model.id)
					? setSelected([])
					: setSelected([model.id]);
			}
		},
		[ctrlPressed, selected, setSelected],
	);

	const findCard = useCallback(
		(card, id) => card.employees.some((employee) => employee.id === id),
		[],
	);

	const data = useMemo(
		() =>
			columnIds.map((columnId) => {
				const column = actualColumns.find((col) => col.id === columnId);
				if (!column) return null;

				return (
					<LightTable.Column
						key={columnId}
						verticalAlign="middle"
						flexGrow={1}
						resizable
						sortable
					>
						<LightTable.HeaderCell style={{ padding: "0 20px" }}>
							{column.label}
						</LightTable.HeaderCell>
						<LightTable.Cell
							verticalAlign="middle"
							style={{ padding: "0 20px" }}
							dataKey={column.id}
							fullText
						>
							{(rowData) => {
								const model = rowData as
									| Employee.Model
									| Employee.New;

								switch (columnId) {
									case "name":
										return model.customer?.name;

									case "surname":
										return model.customer?.surname;

									case "fatherName":
										return model.customer?.fatherName;

									case "phones":
										if (!model?.phones?.length) {
											return <></>;
										}
										return model?.phones
											?.map((phone) => {
												if (!phone) return "";
												return phone?.value;
											})
											.join(", ");

									case "personalCardNumber":
										return model?.code?.value;

									case "keyword":
										return model?.code?.keyword;

									case "orderCount":
										return <></>;

									case "registrationDate":
										const existModel =
											model as Employee.Model;
										return existModel?.createdAt
											? new Date(
													existModel?.createdAt,
											  )?.toLocaleDateString()
											: new Date().toLocaleDateString();

									case "firstTripDate":
										return <></>;

									case "lastTripDate":
										return <></>;

									case "mobileApp":
										return (
											<DisabledCheckbox
												value={
													model?.isAccessToCorporateApp
												}
											/>
										);

									case "corporateCabinet":
										return (
											<DisabledCheckbox
												value={
													model?.isAccessToCorporateAccount
												}
											/>
										);

									case "check":
										const check = checks?.find((check) =>
											check.checkCards.some((card) =>
												card.employees.some(
													(employee) =>
														employee.id ===
														model.id,
												),
											),
										);
										return (
											check?.additionalFields
												.checkValue || ""
										);

									case "card":
										const card = cards
											? cards.find((card) =>
													findCard(card, model.id),
											  )
											: checks?.reduce((acc, check) => {
													const card =
														check.checkCards.find(
															(card) =>
																findCard(
																	card,
																	model.id,
																),
														);

													return card || acc;
											  }, undefined as any);

										return card?.code?.value || "";

									default:
										return null;
								}
							}}
						</LightTable.Cell>
					</LightTable.Column>
				);
			}),
		[actualColumns, cards, checks, columnIds, findCard],
	);

	const getClassName = useCallback(
		(rowData) =>
			rowData && selected.includes(rowData.id) ? "selected" : "",
		[selected],
	);

	const handleOnChangeSelected = useCallback(
		(isAllSelected: boolean) => {
			if (isAllSelected) {
				setSelected(value.map(({ id }) => id));
			} else {
				setSelected([]);
			}
		},
		[setSelected, value],
	);

	return (
		<Root sizes="1fr auto!">
			<div tabIndex={-1} onKeyDown={onKeyDown} onKeyUp={onKeyUp}>
				<LightTable
					ref={tableRef}
					data={value}
					virtualized
					fillHeight
					shouldUpdateScroll={false}
					rowClassName={getClassName}
					onRowClick={updateSelected}
					onRowDoubleClick={onEdit}
					sortColumn={sort?.dataKey}
					sortType={sort?.sortType}
					onScroll={onScroll}
					onSortColumn={onSort}
				>
					<LightTable.Column verticalAlign="middle" width={50}>
						<LightTable.HeaderCell style={{ padding: "0 25px" }}>
							<CheckBox
								value={
									selected.length === value.length &&
									value.length !== 0
								}
								onChange={handleOnChangeSelected}
							/>
						</LightTable.HeaderCell>
						<LightTable.Cell
							verticalAlign="middle"
							style={{ padding: "0 25px" }}
						>
							{(rowData) => (
								<CheckBox
									value={selected.includes(rowData.id)}
									onChange={() => {
										updateSelected(rowData, true);
									}}
								/>
							)}
						</LightTable.Cell>
					</LightTable.Column>
					{data}
				</LightTable>
			</div>
			<TableSettings
				value={columnIds}
				defaultValue={columnIds}
				columns={actualColumns}
				onChange={setColumnIds}
			/>
		</Root>
	);
};

declare namespace Content {
	interface Props {
		tableFrom: string;
		value: GeneralEmployees.Value;
		selected: (number | string)[];
		setSelected: Dispatch<(number | string)[]>;
		editorTable: UseTableOptions["editor"];
		onChangeTable: UseTableOptions["onChange"];
		onEdit?: (x: Employee.Modified) => void;
		columnsFilter: {
			counterparty: boolean;
			check: boolean;
			card: boolean;
		};
		checks?: Accounts.Check[];
		cards?: Cards.Value;
		columnsName?: ColumnNames;
	}

	type ColumnNames =
		| "counterpartyEmployeeCheckCard"
		| "counterpartyEmployeeCheck"
		| "counterpartyEmployee";

	type ColumnType = {
		id: string;
		label: string;
		width?: number;
		justify?: "center";
	};
}

export default Content;
