import React, { useMemo } from "react";
import Order from "../../../../../../../services/Order";
import CellContentRoot from "../CellContentRoot";
import { getOrderPassengerPoints } from "../../../../../../../redux/constants/OrdersPage/order";

const PickupSettlementCellContent: React.FC<
	PickupSettlementCellContent.Props
> = ({ item }) => {
	const points = useMemo(() => getOrderPassengerPoints(item), [item]);

	const content = useMemo(() => points[0]?.settlement, [points]);

	return (
		<CellContentRoot align="center" maxedHeight maxedWidth>
			{content}
		</CellContentRoot>
	);
};

declare namespace PickupSettlementCellContent {
	interface Props {
		item: Order.Model;
	}
}

export default PickupSettlementCellContent;
