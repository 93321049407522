/* eslint-disable import/no-unresolved */
/* eslint-disable no-shadow */

import React, {
	RefAttributes,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { react, useRefWithSetter } from "uikit";

import Dispatcher from "../../../../../../../../services/Dispatcher";
import Role from "../../../../../../../../services/Role";
import useObjectEditor from "../../../../../../../../hooks/useObjectEditor";
import useKeepCardsOpen from "../../../../../../../../hooks/useKeepCardsOpen";
import useCardSubscriber from "../../../../../../../../hooks/useCardSubscriber";
import Map from "../../../../../../../../redux/services/Map";
import WithoutNullableKeys from "../../../../../../../../types/WithoutNullableKeys";
import EditModalBase from "../../../../../../../../components/EditModal";

import { Footer, Root, Header, Content } from "./components";
import InternalController from "./Controller";

const EditModal = react.withController<
	EditModal.PropsBase,
	EditModal.Controller
>(({ controller, value, language, onCancel, onSave }) => {
	const [contentRef, setContentRef] = useRefWithSetter<Content.Ref | null>(
		null,
	);

	const { t } = useTranslation();

	const cardIds = useMemo(
		() => (typeof value.id === "number" ? [value.id] : []),
		[value.id],
	);

	useKeepCardsOpen(cardIds, Role.Card);

	const cardSession = useCardSubscriber(Role.Card);

	const currentCard = useMemo(
		() =>
			cardSession?.cards.find((card) => cardIds.includes(card.id)) ??
			null,
		[cardIds, cardSession?.cards],
	);

	const [history, setHistory] = useState<Dispatcher.History>([]);

	useEffect(() => {
		// (async () => {
		// 	if (value.id) setHistory(await Dispatcher.getHistory(value.id));
		// })();
	}, [value.id]);

	controller.setContext({ contentRef });

	const [tab, setTab] = useState<Header.Tab>("main");
	const [internalValue, setInternalValue] = useState(value);

	const valueEditor = useObjectEditor(internalValue, setInternalValue);

	const assignValue = valueEditor.useAssigner();

	const assignableTo = valueEditor.useGetter("assignableTo");

	const name = valueEditor.useGetter("name");

	const headerTitle = useMemo(
		() =>
			typeof value.id === "number"
				? name
				: t(
						"pages.mainPage.pages.accounts.tabs.roles.editModal.str200",
				  ) ?? "",
		[name, value.id, t],
	);

	const headerLockedBy = useMemo(
		() =>
			currentCard?.locked
				? [
						currentCard.lockedBy.person?.lastName ?? "",
						currentCard.lockedBy.person?.firstName ?? "",
						currentCard.lockedBy.person?.fatherName ?? "",
				  ]
						.join(" ")
						.trim()
				: undefined,
		[currentCard?.locked, currentCard?.lockedBy],
	);

	const modalContent = useMemo(
		() => (
			<Root sizes="auto! 1fr">
				<Header
					tab={tab}
					assignableFor={assignableTo}
					title={headerTitle}
					lockedBy={headerLockedBy}
					createdAt={value.createdAt}
					onChangeTab={setTab}
				/>
				<Content
					ref={setContentRef}
					disabled={currentCard?.locked === true}
					value={internalValue}
					type={tab}
					language={language}
					history={history}
					onChange={assignValue}
				/>
			</Root>
		),
		[
			assignValue,
			assignableTo,
			currentCard?.locked,
			headerLockedBy,
			headerTitle,
			history,
			internalValue,
			language,
			setContentRef,
			tab,
			value.createdAt,
		],
	);

	const footerValue = valueEditor.usePicker(["active", "default"]);

	const modalFooter = useMemo(
		() => (
			<Footer
				value={footerValue}
				disabled={currentCard?.locked === true}
				assignableTo={assignableTo}
				onChange={assignValue}
			/>
		),
		[footerValue, currentCard?.locked, assignableTo, assignValue],
	);

	const modalOnSave = useCallback(() => {
		if (!contentRef.current?.validate()) return;

		onSave(valueEditor.value as EditModal.Value.Validated);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contentRef, onSave]);

	return (
		<EditModalBase
			footer={modalFooter}
			canSave={currentCard?.locked !== true}
			onCancel={onCancel}
			onSave={modalOnSave}
		>
			{modalContent}
		</EditModalBase>
	);
}, InternalController);

declare namespace EditModal {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value extends Content.Value, Footer.Value {
		id?: number;

		name: string;

		createdAt?: string;
	}

	namespace Value {
		type ValidationPropertyNames = "assignableTo";

		type Validated = Omit<Value, ValidationPropertyNames> &
			WithoutNullableKeys<Required<Pick<Value, ValidationPropertyNames>>>;
	}

	interface PropsBase {
		value: Value;
		language: Map.Language;

		onCancel: () => void;
		onSave: (value: Value.Validated) => void;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default EditModal;
