import React, { memo, useCallback } from "react";
import { DateAndTimePicker, Row } from "uikit";
import { useTranslation } from "react-i18next";
import useDatePickerLocale from "../../../../../../../../../../hooks/useDatePickerLocale";

const DateRange: React.FC<DateRange.Props> = memo(({ filter, setFilter }) => {
	const { t } = useTranslation();
	const locale = useDatePickerLocale();

	const handleFromChange = useCallback(
		(newFrom) => {
			setFilter((prev) => ({
				...prev,
				dateFrom: newFrom,
			}));
		},
		[setFilter],
	);

	const handleToChange = useCallback(
		(newTo) => {
			setFilter((prev) => ({
				...prev,
				dateTo: newTo,
			}));
		},
		[setFilter],
	);

	return (
		<Row gaps="7px*" align="center">
			{t(`from`, { context: "capitalized" })}
			<DateAndTimePicker
				value={filter.dateFrom}
				onChange={handleFromChange}
				maxDate={new Date()}
				locale={locale}
			/>
			<div>{t(`to`)}</div>
			<DateAndTimePicker
				value={filter.dateTo}
				onChange={handleToChange}
				minDate={filter.dateFrom}
				locale={locale}
			/>
		</Row>
	);
});

declare namespace DateRange {
	interface Props {
		filter: {
			dateFrom: Date;
			dateTo: Date;
		};
		setFilter: React.Dispatch<
			React.SetStateAction<{
				dateFrom: Date;
				dateTo: Date;
			}>
		>;
	}
}

export default DateRange;
