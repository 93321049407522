import React, { Dispatch, RefAttributes, memo, useMemo, useState } from "react";
import { react } from "uikit";
import { useTranslation } from "react-i18next";

import useObjectEditor from "../../../../../../../../../../hooks/useObjectEditor";
import {
	StyledColumn,
	StyledGrid,
	StyledGridItem,
	StyledP,
	StyledRow,
	ValueStepper,
} from "../../../../../../../../../../components/common";

import Title from "../../../Title";
import ValidityTime from "../../../ValidityTime";
import WeekDays from "../../../WeekDays";

import InternalController, { Context } from "./Controller";

const ContentBase = react.withController<Content.PropsBase, Content.Controller>(
	({ value, onChange, controller, allAverageTime, isAdd }) => {
		const { t } = useTranslation();
		const [errors, setErrors] = useState<Content.Errors>({
			end: false,
			start: false,
			speed: false,
			dow: false,
		});

		const valueEditor = useObjectEditor(value, onChange);
		const errorsEditor = useObjectEditor(errors, setErrors);

		const id = valueEditor.useGetter("id");
		const speed = valueEditor.useGetter("speed");
		const setSpeed = valueEditor.useSetter("speed");
		const speedError = errorsEditor.useGetter("speed");

		const validityTime = valueEditor.usePicker(["end", "start"]);
		const validityTimeError = errorsEditor.usePicker(["end", "start"]);
		const weekdays = valueEditor.usePicker(["dow"]);
		const weekdaysError = errorsEditor.usePicker(["dow", "end", "start"]);

		const assigner = valueEditor.useAssigner();
		const assignerError = errorsEditor.useAssigner();

		const title = useMemo(() => {
			if (!isAdd) return t(`preferences.sectors.modal.update_title`);
			return t(`preferences.sectors.modal.add_title`);
		}, [isAdd, t]);

		controller.setContext({
			errors,
			setErrors,
			value,
			id,
			allAverageTime,
		});

		return (
			<StyledGrid
				areas={`'header'
						'main'`}
				rows="50px 1fr"
			>
				<StyledGridItem area="header" bgC="#EBEBEB" p="10px 1rem">
					<Title>{title}</Title>
				</StyledGridItem>
				<StyledGridItem area="main">
					<StyledColumn p="1rem" gap="10px">
						<WeekDays
							value={weekdays}
							onChange={assigner}
							errors={weekdaysError}
							onErrors={assignerError}
						/>

						<StyledRow w="100%" gap="10px" justify="space-between">
							<StyledP>
								{t(
									"preferences.sectors.modal.schedule_selection",
								)}
								:
							</StyledP>

							<ValidityTime
								value={validityTime}
								onChange={assigner}
								errors={validityTimeError}
								onErrors={assignerError}
							/>
						</StyledRow>
						<StyledRow w="100%" gap="1rem" justify="space-between">
							<StyledP>
								{t("preferences.sectors.modal.speed_limit")}
							</StyledP>
							<ValueStepper
								error={speedError}
								value={speed}
								min={0}
								decimalCount={0}
								width="70px"
								onChange={setSpeed}
							/>
						</StyledRow>
						<StyledColumn
							w="100%"
							gap="6px"
							justify="space-between"
						>
							{validityTimeError.start ||
							validityTimeError.end ? (
								<StyledP colors="red">
									{t([
										`preferences.sectors.modal.warm.range`,
										"Time is within the range of the existing schedule!",
									])}
								</StyledP>
							) : (
								<></>
							)}
						</StyledColumn>
					</StyledColumn>
				</StyledGridItem>
			</StyledGrid>
		);
	},
	InternalController,
);
const Content = memo(ContentBase);

declare namespace Content {
	type Ref = InternalController | null;
	type Controller = InternalController;

	interface PropsBase {
		value: Value;
		onChange: Dispatch<Value>;
		allAverageTime: Context["allAverageTime"];
		isAdd: boolean;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	interface Errors extends ValidityTime.Errors, WeekDays.Errors {
		speed: boolean;
	}
	interface Value extends ValidityTime.Value, WeekDays.Value {
		id: number;
		speed: number;
	}
}

export default Content;
