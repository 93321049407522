import React, { Suspense, useMemo, lazy } from "react";
import { Tabs } from "uikit";
import { useTranslation } from "react-i18next";

import { useTypedSelector } from "../../../../redux/store";
import useActiveTab from "../../../../hooks/useActiveTab";
import { hasAccess } from "../../../../access";
import { TabOptions } from "../../../../types";
import BasicPageLayout from "../../../../components/BasicPageLayout";
import { StyledRow, SuspenseLoader } from "../../../../components/common";

import {
	ExecutorBonuses,
	CustomerBonuses,
	BankAccounts,
	Agents,
	LoyaltyProgram,
} from "./tabs";
import { TabKeys, TabAccessNames } from "./constants/access";

const LazyExecutors = lazy<React.FC<any>>(async () => {
	const elem = await import(
		"./tabs/Executors" /* webpackChunkName: "accounting-executors-page" */
	);
	return elem;
});

const Accounting: React.FC = () => {
	const { t } = useTranslation();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);

	const tabs: TabOptions.Array = useMemo(
		() =>
			[
				{
					key: TabKeys.EXECUTORS,
					label: t("mainPage.accounting.executors.title"),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<LazyExecutors />
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.EXECUTORS],
				},
				{
					key: TabKeys.EXECUTOR_BONUSES,
					label: t("mainPage.accounting.executorBonuses.title"),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<ExecutorBonuses />
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.EXECUTOR_BONUSES],
				},
				{
					key: TabKeys.CUSTOMER_BONUSES,
					label: t("mainPage.accounting.customerBonuses.title"),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<CustomerBonuses />
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.CUSTOMER_BONUSES],
				},
				{
					key: TabKeys.BANK_ACCOUNTS,
					label: t("mainPage.accounting.bankAccounts.title"),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<BankAccounts />
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.BANK_ACCOUNTS],
				},
				{
					key: TabKeys.AGENTS,
					label: t("mainPage.accounting.agents.title"),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<Agents />
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.AGENTS],
				},
				{
					key: TabKeys.LOYALTY_PROGRAM,
					label: t("mainPage.accounting.loyaltyProgram.title"),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<LoyaltyProgram />
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.LOYALTY_PROGRAM],
				},
			].filter((btn) => hasAccess(btn.accessName, personalRole)),
		[t, personalRole],
	);

	const { activeKey, activeTab, setActiveKey } = useActiveTab(tabs);

	return (
		<Suspense
			fallback={
				<StyledRow position="absolute" top="50%" left="50%">
					<SuspenseLoader />
				</StyledRow>
			}
		>
			<BasicPageLayout
				navigation={
					<Tabs
						value={activeKey}
						onChange={setActiveKey}
						options={tabs}
						variant="vertical"
					/>
				}
				content={activeTab.value.render()}
			/>
		</Suspense>
	);
};

export default Accounting;
