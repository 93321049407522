import React, { ReactNode, memo } from "react";
import styled from "styled-components";

import { AccessKey } from "../../access";
import { StyledRow } from "../common";

import Root from "./components/Root";
import ControlButtons from "./components/ControlButtons";

const Row = styled(StyledRow)`
	align-items: center;
`;

const DefaultPageHeader = memo<DefaultPageHeader.Props>(
	({
		onAdd,
		onEdit,
		onDelete,
		canAdd,
		canEdit,
		canDelete,
		titleAdd,
		idAdd,
		titleEdit,
		idEdit,
		titleDelete,
		idDelete,

		beforeAdditionalButtons,
		buttons,
		afterAdditionalButtons,
		leftFilters = null,
		filters = null,
		accessName,
		...props
	}) => (
		<Root gaps="10px*" justify="space-between" maxedWidth {...props}>
			<Row gap="10px">
				<ControlButtons
					onAdd={onAdd}
					onEdit={onEdit}
					onDelete={onDelete}
					canAdd={canAdd}
					canEdit={canEdit}
					canDelete={canDelete}
					titleAdd={titleAdd}
					idAdd={idAdd}
					titleEdit={titleEdit}
					idEdit={idEdit}
					titleDelete={titleDelete}
					idDelete={idDelete}
					beforeAdditionalButtons={beforeAdditionalButtons}
					buttons={buttons}
					afterAdditionalButtons={afterAdditionalButtons}
					accessName={accessName}
				/>
				{leftFilters}
			</Row>
			<Row gap="20px">{filters}</Row>
		</Root>
	),
);

declare namespace DefaultPageHeader {
	interface Props extends ControlButtons.Props, Root.Props {
		leftFilters?: ReactNode;
		filters?: ReactNode;
		accessName?: AccessKey[];
	}
}

export default DefaultPageHeader;
