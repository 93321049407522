import React, { useMemo } from "react";
import Order from "../../../../../../../services/Order";
import CellContentRoot from "../CellContentRoot";

const IdleTimeCellContent: React.FC<IdleTimeCellContent.Props> = () => {
	const content = useMemo(() => "", []);

	return (
		<CellContentRoot align="center" maxedHeight maxedWidth>
			{content}
		</CellContentRoot>
	);
};

declare namespace IdleTimeCellContent {
	interface Props {
		item: Order.Model;
	}
}

export default IdleTimeCellContent;
