import React, { Dispatch, useMemo, useState } from "react";
import { Column } from "uikit";
import { isUndefined } from "lodash";

import Header from "./components/Header";
import List from "./components/List";

function filterOptions(
	search: string,
	options: Selector.Options,
): Selector.Options {
	return options
		.map((option) => {
			if (option.label.toLowerCase().startsWith(search.toLowerCase()))
				return option;

			if ("options" in option) {
				const filteredOptions = filterOptions(search, option.options);

				if (filteredOptions.length)
					return {
						...option,

						options: filteredOptions,
					};
			}

			return undefined;
		})
		.filter((option) => !isUndefined(option)) as Selector.Options;
}

const Selector: React.FC<Selector.Props> = ({
	value,
	disabled,
	options,
	onChange,
}) => {
	const [headerValue, setHeaderValue] = useState<Header.Value>({
		search: "",
	});

	const filteredOptions = useMemo(
		() => filterOptions(headerValue.search, options),
		[headerValue.search, options],
	);

	return (
		<Column sizes="auto! 1fr">
			<Header value={headerValue} onChange={setHeaderValue} />
			<List
				value={value}
				disabled={disabled}
				options={filteredOptions}
				onChange={onChange}
			/>
		</Column>
	);
};

declare namespace Selector {
	type Value = string[];

	type Option = List.Option;
	type Options = List.Options;

	interface Props {
		value: Value;

		disabled: boolean;
		options: Options;

		onChange: Dispatch<Value>;
	}
}

export default Selector;
