/* eslint-disable no-shadow */
import React, {
	Dispatch,
	ReactElement,
	useCallback,
	useMemo,
	useRef,
	useState,
} from "react";
import { UseTranslationResponse, useTranslation } from "react-i18next";
import { CheckBox, Column, ToggleButton } from "uikit";
import { SortType } from "rsuite-table";
import { clone, uniq } from "lodash";

import Role from "../../../../../../../../../services/Role";
import TaxiService from "../../../../../../../../../services/TaxiService";
import Language from "../../../../../../../../../services/Language";
import useModelSubscribe2 from "../../../../../../../../../hooks/useModelSubscribe2";
import Map from "../../../../../../../../../redux/services/Map";
import {
	useTypedDispatch,
	useTypedSelector,
} from "../../../../../../../../../redux/store";
import updateProviderSms from "../../../../../../../../../redux/services/Settings/Message/ProviderSms/updateProviderSms";
import { mapByKey, getColumnsModeTwo } from "../../../../../../../../../utils";
import LightTable from "../../../../../../../../../components/LightTable";
import TableSettings from "../../../../../../../../../components/TableSettings";

interface ColumnContext {
	language: Language;

	taxiServiceById: Record<number, TaxiService.Model>;
	roleById: Record<number, Role.Model>;
	taxiServiceSettlements: string;
	dispatch: any;
	t: UseTranslationResponse<"translation", undefined>[0];
}

const Columns: Record<string, (context: ColumnContext) => ReactElement> = {
	active: (context) => (
		<LightTable.Column width={100} sortable>
			<LightTable.HeaderCell>
				{context.t(
					"pages.settings.pages.message.provider.content.modelTable.str100",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell dataKey="active">
				{(rowData) => (
					<div
						onClick={(event) => event.stopPropagation()}
						style={{ maxWidth: "max-content" }}
					>
						<ToggleButton.ToggleButton
							value={rowData.active}
							onChange={(newIsDefault) => {
								const {
									// eslint-disable-next-line @typescript-eslint/no-unused-vars
									id,
									// eslint-disable-next-line @typescript-eslint/no-unused-vars
									v,
									// eslint-disable-next-line @typescript-eslint/no-unused-vars
									activeQueryInterval,
									// eslint-disable-next-line @typescript-eslint/no-unused-vars
									smsProviderToTaxiServices,
									// eslint-disable-next-line @typescript-eslint/no-unused-vars
									connectTimeout,
									// eslint-disable-next-line @typescript-eslint/no-unused-vars
									isConnectTimeout,

									...updatedProvider
								} = rowData;

								updatedProvider.active = newIsDefault;

								context.dispatch(
									updateProviderSms(
										rowData.id,
										updatedProvider,
									),
								);
							}}
						/>
					</div>
				)}
			</LightTable.Cell>
		</LightTable.Column>
	),

	name: ({ t }) => (
		<LightTable.Column width={200} sortable fixed>
			<LightTable.HeaderCell>
				{t(
					"pages.settings.pages.message.provider.content.modelTable.str101",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell dataKey="name">
				{(rowData) => `${rowData.name}`.trim()}
			</LightTable.Cell>
		</LightTable.Column>
	),

	alphaName: ({ t }) => (
		<LightTable.Column width={150} sortable>
			<LightTable.HeaderCell>
				{t(
					"pages.settings.pages.message.provider.content.modelTable.str102",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell dataKey="alphaName">
				{(rowData) => rowData.alphaName}
			</LightTable.Cell>
		</LightTable.Column>
	),
	status: ({ t }) => (
		<LightTable.Column width={150} sortable>
			<LightTable.HeaderCell>
				{t(
					"pages.settings.pages.message.provider.content.modelTable.str103",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell dataKey="status">
				{(rowData) =>
					t(`settings.messages.providerSms.status.${rowData?.type}`)
				}
			</LightTable.Cell>
		</LightTable.Column>
	),

	companies: ({ language, t }) => (
		<LightTable.Column width={300} sortable>
			<LightTable.HeaderCell>
				{t(
					"pages.settings.pages.message.provider.content.modelTable.str104",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell dataKey="company">
				{(rowData) =>
					uniq(
						rowData?.smsProviderToTaxiServices?.map(
							(service) =>
								service.taxiService.company.name[language],
						),
					).join(", ")
				}
			</LightTable.Cell>
		</LightTable.Column>
	),

	branches: ({ language, t }) => (
		<LightTable.Column flexGrow={1} sortable>
			<LightTable.HeaderCell>
				{t(
					"pages.settings.pages.message.provider.content.modelTable.str105",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell dataKey="status">
				{(rowData) =>
					rowData?.smsProviderToTaxiServices
						?.map(
							(service) =>
								service.taxiService.settlement[language],
						)
						.join(", ")
				}
			</LightTable.Cell>
		</LightTable.Column>
	),
};

const ModelTable: React.FC<ModelTable.Props> = ({
	selected,
	sort,
	loading,
	data,
	language,
	onChangeSelected,
	onChangeSort,
	onEdit,
	onLoadMore,
}) => {
	const dispatch = useTypedDispatch();
	const { t } = useTranslation();

	const defaultColumnIds: Parameters<typeof getColumnsModeTwo>[1] = useMemo(
		() => [
			"active",
			"name",
			"alphaName",
			"status",
			"companies",
			"branches",
		],
		[],
	);

	const columns = useMemo(
		() => getColumnsModeTwo(t, defaultColumnIds),
		[t, defaultColumnIds],
	);

	const { models: taxiServices } = useTypedSelector(
		(state) => state.taxiServices,
	);

	const taxiServiceById = useMemo(
		() => mapByKey(taxiServices, "id"),
		[taxiServices],
	);

	const roleData = useModelSubscribe2({}, Role);

	const roles = useMemo(() => roleData.models ?? [], [roleData.models]);

	const roleById = useMemo(() => mapByKey(roles, "id"), [roles]);

	const canLoadMoreRef = useRef(true);
	const tableRef = useRef<LightTable.Ref | null>(null);

	const tableRowClassName = useCallback(
		(rowData) => {
			if (selected.includes(rowData?.id ?? "")) return "selected";
			return "";
		},
		[selected],
	);

	const tableOnScroll = useMemo(() => {
		canLoadMoreRef.current = true;

		return (x: number, y: number) => {
			if (!canLoadMoreRef.current) return;

			const contextHeight = data.length * 44;
			const top = Math.abs(y);
			const tableContainerHeight =
				tableRef.current?.root.getBoundingClientRect().height ?? 0;

			if (contextHeight - top - tableContainerHeight < 300) {
				onLoadMore();

				canLoadMoreRef.current = false;
			}
		};
	}, [data.length, onLoadMore]);

	const setItemSelection = useCallback(
		(id: number, value: boolean) => {
			if (value) {
				const newSelected = clone(selected);

				newSelected.push(id);

				onChangeSelected(newSelected);
			} else
				onChangeSelected(
					selected.filter((selectedId) => selectedId !== id),
				);
		},
		[onChangeSelected, selected],
	);

	const tableOnRowClick = useCallback(
		(rowData) => {
			setItemSelection(rowData.id, !selected.includes(rowData.id));
		},
		[selected, setItemSelection],
	);

	const tableDoubleClick = useCallback(
		(rowData) => {
			onChangeSelected([rowData.id]);
			onEdit();
		},
		[onChangeSelected, onEdit],
	);

	const [columnIds, setColumnIds] = useState(defaultColumnIds);

	const taxiServiceSettlements = useMemo(
		() =>
			uniq(
				taxiServices.map((service) => service.settlement[language]),
			).join(", "),
		[language, taxiServices],
	);

	const columnContext = useMemo<ColumnContext>(
		() => ({
			language,
			taxiServiceById,
			roleById,
			taxiServiceSettlements,
			dispatch,
			t,
		}),
		[
			dispatch,
			language,
			roleById,
			t,
			taxiServiceById,
			taxiServiceSettlements,
		],
	);

	return (
		<Column sizes="1fr auto!" maxedWidth maxedHeight>
			<div>
				<LightTable
					ref={tableRef}
					fillHeight
					virtualized
					shouldUpdateScroll={false}
					headerHeight={44}
					rowHeight={44}
					sortColumn={sort.column}
					sortType={sort.type}
					loading={loading}
					data={data}
					rowClassName={tableRowClassName}
					onScroll={tableOnScroll}
					onRowClick={tableOnRowClick}
					onRowDoubleClick={tableDoubleClick}
					onSortColumn={(column, type) =>
						onChangeSort({ column, type })
					}
				>
					<LightTable.Column width={0}>
						<LightTable.HeaderCell>
							<></>
						</LightTable.HeaderCell>
						<LightTable.Cell />
					</LightTable.Column>
					<LightTable.Column width={60}>
						<LightTable.HeaderCell
							style={{ padding: "0 20px" }}
							verticalAlign="middle"
						>
							<CheckBox
								value={
									selected.length === data.length &&
									data.length !== 0
								}
								onChange={(value) =>
									onChangeSelected(
										value
											? data.map((item) => item.id)
											: [],
									)
								}
							/>
						</LightTable.HeaderCell>
						<LightTable.Cell
							style={{ padding: "0 20px" }}
							verticalAlign="middle"
						>
							{(rowData) => (
								<CheckBox
									value={selected.includes(rowData.id)}
									onChange={(selected) =>
										setItemSelection(rowData.id, selected)
									}
								/>
							)}
						</LightTable.Cell>
					</LightTable.Column>

					{columnIds.map((columnId) =>
						Columns[columnId](columnContext),
					)}

					<LightTable.Column width={0}>
						<LightTable.HeaderCell>
							<></>
						</LightTable.HeaderCell>
						<LightTable.Cell />
					</LightTable.Column>
				</LightTable>
			</div>

			<TableSettings
				value={columnIds}
				defaultValue={defaultColumnIds}
				columns={columns}
				onChange={setColumnIds}
			/>
		</Column>
	);
};

declare namespace ModelTable {
	interface Sort {
		column?: string;
		type?: SortType;
	}

	interface Props {
		selected: number[];
		sort: Sort;

		loading: boolean;
		data: (unknown & { id: number })[];
		language: Map.Language;

		onChangeSelected: Dispatch<number[]>;
		onChangeSort: Dispatch<Sort>;

		onEdit: () => void;
		onLoadMore: () => void;
	}
}

export default ModelTable;
