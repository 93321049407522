/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { State } from "./interface";
import { Archive, ReduxReducer } from "../../interface";

const initialState: State = {
	history: [],
};

const setOrderHistory: ReduxReducer<State, State["history"]> = (
	state,
	{ payload },
) => {
	state.history = payload || [];
};

const resetError: ReduxReducer<State, any> = (state) => {
	Object.keys(initialState).forEach((key) => {
		state[key] = initialState[key];
	});
};

const archiveOrdersHistory = createSlice({
	name: Archive.ConstantOrderSlice.ORDERS_HISTORY,
	initialState,
	reducers: {
		resetError,
		setOrderHistory,
	},
});

export const archiveOrdersHistoryAction = archiveOrdersHistory.actions;
export const archiveOrdersHistoryReducer = archiveOrdersHistory.reducer;
export type archiveOrdersHistoryState = State;

export default archiveOrdersHistory;
