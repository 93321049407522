import React from "react";
import { Column, ColumnId } from ".";
import base from "./base";
import CloseReasonCellContent from "../components/CloseReasonCellContent";

function closeReason({ width, onResize }: Column.Options) {
	return base(
		ColumnId.CloseReason,
		(item) => <CloseReasonCellContent item={item} />,
		{
			width,

			onResize: onResize as (
				columnWidth?: number | undefined,
				dataKey?: string | undefined,
			) => void,
		},
	);
}

export default closeReason;
