import React, {
	PropsWithChildren,
	memo,
	useCallback,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Icon, Key, Option } from "uikit";

import { useTypedSelector } from "../../../../../../../../../../redux/store";
import SIP from "../../../../../../../../../../services/SIP";
import {
	Divider,
	StyledRow,
} from "../../../../../../../../../../components/common";
import {
	BaseListModal,
	ListModal,
} from "../../../../../../../../../../components/Orders";

import { LeftButton, RightButton, CallWrapper } from "../../styled";
import { PhonesSelect } from "../PhonesSelect";

const BaseDriverCall: React.FC<BaseDriverCall.Props> = memo(
	({ mainPhone, phones, disabled }) => {
		const { t } = useTranslation();
		const sipToDispatcherId = useTypedSelector(
			(state) => state.sipToDispatcher.id,
		);

		const [showSelectModal, setShowSelectModal] = useState(false);
		const [selectPhone, setSelectPhone] = useState<Key | undefined>(
			mainPhone,
		);
		const [showModal, setShowModal] = useState(false);
		const isOneSelected = useMemo(() => Boolean(mainPhone), [mainPhone]);
		const handleCall = useCallback(
			async (phone?: string) => {
				if (sipToDispatcherId && mainPhone) {
					await SIP.callToPhone({
						sipToDispatcherId,
						phone: phone || mainPhone,
					});
				}
			},
			[mainPhone, sipToDispatcherId],
		);

		const handleShowModal = useCallback(() => {
			setShowModal((prev) => !prev);
		}, []);

		const handleCloseModal = useCallback(() => {
			setShowModal(false);
		}, []);

		const phoneDisabled = useMemo(
			() =>
				disabled ||
				showModal ||
				!sipToDispatcherId ||
				!isOneSelected ||
				!mainPhone,
			[isOneSelected, mainPhone, showModal, sipToDispatcherId, disabled],
		);

		const onChangePhone = useCallback((value: BaseListModal.Value<Key>) => {
			setSelectPhone(value);
		}, []);

		const onCancelPhone = useCallback(() => {
			setShowSelectModal(false);
		}, []);

		const onSavePhone = useCallback(() => {
			setShowSelectModal(false);
			if (selectPhone) handleCall(`${selectPhone}`.trim());
		}, [handleCall, selectPhone]);

		const phoneOptions = useMemo<Option<string>[]>(() => {
			const payload =
				phones?.map((value) => ({
					key: value,
					value,
					label: value,
				})) || [];

			return payload;
		}, [phones]);

		return (
			<CallWrapper>
				<StyledRow>
					<LeftButton
						disabled={phoneDisabled}
						variant="secondary"
						settingsKeyId="orderModalCallOrderExecutor"
						title={
							t(
								"pages.mainPage.pages.orders.orderModal.footer.driverCall.str0",
							) ?? ""
						}
						onClick={() => {
							if (phoneDisabled) return;
							if (phones.length > 1) {
								setShowSelectModal(true);
							} else handleCall(mainPhone);
						}}
					>
						<Icon id={"orders-call-executor"} size={22} />
					</LeftButton>
					<Divider />
					<RightButton
						disabled={phoneDisabled}
						variant="secondary"
						title={
							t(
								"pages.mainPage.pages.orders.orderModal.footer.driverCall.str1",
							) ?? ""
						}
						onClick={handleShowModal}
					>
						<Icon id="orders-sms-arrow" size={12} />
					</RightButton>
				</StyledRow>

				{showModal && (
					<PhonesSelect
						phones={phones}
						onClose={handleCloseModal}
						onSubmit={handleCall}
					/>
				)}
				{showSelectModal && (
					<ListModal
						title={
							t(
								"pages.mainPage.pages.orders.orderModal.footer.driverCall.str2",
							) ?? ""
						}
						value={selectPhone}
						options={phoneOptions}
						onClose={onCancelPhone}
						onSave={onSavePhone}
						onChange={onChangePhone}
						textSave={
							t(
								"pages.mainPage.pages.orders.orderModal.footer.driverCall.str3",
							) ?? ""
						}
						showCheckBox={false}
					/>
				)}
			</CallWrapper>
		);
	},
);

declare namespace BaseDriverCall {
	interface Props extends PropsWithChildren {
		mainPhone?: string;
		phones: string[];
		disabled?: boolean;
	}
}

export const DriverCall = memo(BaseDriverCall);
export default BaseDriverCall;
