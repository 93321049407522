import React from "react";
import { Column, ColumnId } from ".";
import PickupSectorCellContent from "../components/PickupSectorCellContent";
import base from "./base";

function pickupSector({ width, language, onResize }: Column.Options) {
	return base(
		ColumnId.PickupSector,
		(item) => <PickupSectorCellContent item={item} language={language} />,
		{
			width,

			onResize: onResize as (
				columnWidth?: number | undefined,
				dataKey?: string | undefined,
			) => void,
		},
	);
}

export default pickupSector;
