/* eslint-disable func-call-spacing */
/* eslint-disable import/prefer-default-export */
import React, {
	memo,
	useCallback,
	useMemo,
	useState,
	useRef,
	useLayoutEffect,
} from "react";
import {
	Controller,
	ControllerFieldState,
	ControllerRenderProps,
	UseFormStateReturn,
} from "react-hook-form";
import { useDebouncedCallback } from "use-debounce/lib";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Icon, InputBorders } from "uikit";

import {
	useTypedDispatch,
	useTypedSelector,
} from "../../../../../../../../redux/store";
import closeOrderCard from "../../../../../../../../redux/services/Order/card/closeOrderCard";
import { PhoneBlacklistData } from "../../../../../../../../redux/services/Order/getCustomerByPhone";
import { Schema } from "../../../../../../../../redux/constants/OrdersPage/order";
import getOrderCodeInfo, {
	OrderCodeInfo,
} from "../../../../../../../../redux/services/Order/getOrderCodeInfo";
import { useCurrencyGlobalSettings } from "../../../../../../../../hooks";
import { useKeyBindSettings } from "../../../../../../../../hooks/useKeyBindSettings";
import {
	countryCodePhone,
	validatePhone,
} from "../../../../../../../../utils/validatePhone";
import { Divider, StyledRow } from "../../../../../../../../components/common";
import { WalletIcon } from "../../../../../../../../icons/wallet";
import { OrderFormProps } from "../../types/FormProps";
import {
	CheckCode,
	PhoneBlacklist,
} from "../../../../../../../../components/Orders";
import {
	StyledBalance,
	StyledButton,
	StyledInputGroup,
	StyledTextBox,
} from "../styled";

import CodeConfirmationContent from "./CodeConfirmationContent";
import { useCounterpartyBalance } from "./hooks/useCounterpartyBalance";
import { useAgentBalance } from "./hooks/useAgentBalance";

const StyledCounterpart = styled(StyledRow)`
	flex: 1 1 0;
`;

const Wrap = styled(StyledRow)`
	width: 100%;
`;

type AdditionalPhonesType = {
	field: ControllerRenderProps<Schema, "additionalPhones">;
};

type CardCounterpartType = {
	field: ControllerRenderProps<Schema, "code">;
	fieldState: ControllerFieldState;
	formState: UseFormStateReturn<Schema>;
};

const CodeCounterpartOrAgent: React.FC<CodeCounterpartOrAgent.Props> = ({
	tab,
	form,
	disabled,
}) => {
	const dispatch = useTypedDispatch();
	const { t } = useTranslation();
	const currencyGlobalSettings = useCurrencyGlobalSettings();
	const lang = useTypedSelector((state) => state.session.language);

	const {
		mainBalance,
		mainBalanceType,
		bonusBalance,
		bonusBalanceType,
		setPaymentAccounts,
		resetBalance,
		currency,
	} = useCounterpartyBalance({
		form,
		tab,
	});
	const {
		mainBalance: agentBalance,
		mainBalanceType: agentBalanceType,
		bonusBalance: agentBonusBalance,
		bonusBalanceType: agentBonusBalanceType,
		setPaymentAccounts: setAgentPaymentAccounts,
		resetBalance: resetAgentBalance,
		currency: agentCurrency,
	} = useAgentBalance({
		form,
		tab,
	});

	const language = useTypedSelector((state) => state.session.language);
	const refInputCard = useRef<HTMLInputElement | null>(null);
	const [orderCode, setOrderCode] = useState<OrderCodeInfo.Code | null>(null);
	const [errorPhone, setErrorPhone] = useState<boolean>(false);

	const [phoneBlacklistModal, setPhoneBlacklistModal] = useState<
		PhoneBlacklistData | undefined | null
	>(null);

	const closeCurrentCard = useCallback(() => {
		setPhoneBlacklistModal(null);
		if (!tab.id) {
			console.error(
				"Cannot close order card, because order id is not specified",
			);
			return;
		}
		dispatch(closeOrderCard(tab.id));
	}, [tab, dispatch]);

	const handleSave = useCallback(() => {
		form.setValue("orderSave", true);
		form.setValue("orderPointSave", false);
	}, [form]);

	const cleanupCustomer = useCallback(() => {
		form.setValue("counterparty", undefined);
		form.setValue("agent", undefined);
		if (tab.form?.paymentType !== "cash") {
			form.setValue("paymentType", "cash");
			form.setValue("paymentChangeType", undefined);
			if (tab.form?.paymentType === "company_bill" && tab.form?.phone) {
				form.setValue("paymentChangeType", "company_bill_to_cash");
			}
		}

		form.setValue("companyBillMetadata", null);

		resetBalance();
		resetAgentBalance();
	}, [form, tab.form, resetBalance, resetAgentBalance]);

	useMemo(() => {
		if (
			!tab.form?.counterparty &&
			!tab.form?.code &&
			tab.form?.paymentType === "company_bill"
		) {
			form.setValue("paymentType", "cash");
			form.setValue("phoneType", "customer");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tab.form?.counterparty, tab.form?.code]);

	const debounceCounterpartSearch = useDebouncedCallback((code) => {
		cleanupCustomer();
		if (!code) return;

		dispatch(
			getOrderCodeInfo(code, (response) => {
				console.log("[ OrderModal ] getOrderCodeInfo", {
					code,
					response,
				});
				if (!response) return;
				setOrderCode(response);
			}),
		);
	}, 500);

	const onRenderAdditionalPhones = useCallback(
		({ field }: AdditionalPhonesType) => {
			const placeholder =
				t(`orderPage.order_form.additional_phone`) || "";

			return (
				<StyledTextBox
					autoComplete="off"
					error={errorPhone}
					min={0}
					tabIndex={1}
					type={"number"}
					disabled={disabled}
					placeholder={placeholder}
					value={String(field.value ?? "")}
					onBlur={handleSave}
					onChange={(value) => {
						const modifyPhone = countryCodePhone(value, "uk");
						if (value.length < 11) {
							if (errorPhone) setErrorPhone(false);
						} else {
							const isValid = validatePhone(modifyPhone);
							setErrorPhone(!isValid);
						}

						field.onChange([modifyPhone]);
					}}
				/>
			);
		},
		[t, disabled, handleSave, errorPhone],
	);

	const onRenderCode = useCallback(
		({ field }: CardCounterpartType) => {
			const placeholder =
				t(
					"pages.mainPage.pages.orders.orderModal.personalInformation.str203",
				) || "";

			return (
				<StyledRow flex={{ wrap: "nowrap" }} w="150px">
					<StyledTextBox
						autoComplete="off"
						inputRef={refInputCard}
						tabIndex={1}
						type={"string"}
						disabled={disabled}
						placeholder={placeholder}
						value={String(field.value ?? "")}
						onChange={(value) => {
							field.onChange(value);

							debounceCounterpartSearch(value);
						}}
					/>
				</StyledRow>
			);
		},
		[t, disabled, debounceCounterpartSearch],
	);

	const onCloseInput = useCallback(() => {
		form.setValue("code", "");

		setOrderCode(null);
	}, [form]);

	const onSubmitInput = useCallback(() => {
		if (!orderCode) return;
		try {
			const {
				id,
				name,
				checkCardId,
				paymentAccounts,
				type,
				executorNotes,
				orderNotes,

				/** Only for agent */
				reward,
				/** Only for agent */
				rewards,
				/** Only for agent */
				isArbitraryRewardAllowed,
				/** Only for agent  */
				isRewardAllowed,
			} = orderCode;
			form.setValue("paymentChangeType", undefined);
			form.setValue("counterparty", {
				id: type === "counterparty" ? id : undefined,
				checkCardId,
				name: name?.[language],
			});

			if (type === "counterparty") {
				form.setValue("paymentType", "company_bill");
				if (paymentAccounts) setPaymentAccounts(paymentAccounts);
			}

			if (type === "agent") {
				if (paymentAccounts) setAgentPaymentAccounts(paymentAccounts);
				if (tab.form.paymentType !== "cash") {
					form.setValue("paymentType", "cash");
					form.setValue("paymentChangeType", undefined);
				}

				form.setValue("agent", {
					id,
					checkCardId,
					name: name?.[language],
					reward: reward || null,
					rewards: rewards || [],
					paymentAccounts: paymentAccounts || [],
					isRewardAllowed: isRewardAllowed || false,
					isArbitraryRewardAllowed: isArbitraryRewardAllowed || false,
				});
			}

			const driverNote = tab.form?.driverNote || "";
			const clientNote = tab.form?.clientNote || "";

			if (!driverNote && executorNotes) {
				form.setValue("driverNote", executorNotes);
			}
			if (!clientNote && orderNotes) {
				form.setValue("clientNote", orderNotes);
			}

			setOrderCode(null);
			handleSave();
		} catch (error) {
			console.error(error);
		}
	}, [
		orderCode,
		form,
		language,
		tab.form?.driverNote,
		tab.form?.clientNote,
		tab.form.paymentType,
		handleSave,
		setPaymentAccounts,
		setAgentPaymentAccounts,
	]);

	const onChangeCounterparty = useCallback(
		(value: string) => {
			form.setValue("counterparty.name", value);
		},
		[form],
	);

	const agentName = useMemo(
		() =>
			tab.form?.counterparty?.name || tab.form?.agent?.name?.[lang] || "",
		[tab.form?.counterparty?.name, tab.form?.agent?.name, lang],
	);

	useLayoutEffect(() => {
		setErrorPhone(!tab.validator.additionalPhones);
	}, [tab.validator.additionalPhones]);

	const currencyName = useMemo(
		() => tab.form?.currency?.settings?.name || "",
		[tab.form?.currency],
	);

	const allCurrency = useMemo(
		() => currency || agentCurrency || currencyName,
		[agentCurrency, currency, currencyName],
	);

	const userMainBalance = useMemo(() => {
		if (mainBalance === null) return "";
		return `${mainBalance.toFixed(2)} ${
			allCurrency || currencyGlobalSettings
		}`;
	}, [mainBalance, allCurrency, currencyGlobalSettings]);

	const userBonusBalance = useMemo(() => {
		if (bonusBalance === null) return "";
		return `${bonusBalance.toFixed(2)} ${
			allCurrency || currencyGlobalSettings
		}`;
	}, [bonusBalance, allCurrency, currencyGlobalSettings]);

	const userAgentBalance = useMemo(() => {
		if (agentBalance === null) return "";
		return `${agentBalance.toFixed(2)} ${
			allCurrency || currencyGlobalSettings
		}`;
	}, [agentBalance, allCurrency, currencyGlobalSettings]);

	const userAgentBonusBalance = useMemo(() => {
		if (agentBonusBalance === null) return "";
		return `${agentBonusBalance.toFixed(2)} ${
			allCurrency || currencyGlobalSettings
		}`;
	}, [agentBonusBalance, allCurrency, currencyGlobalSettings]);

	const colorMainBalance = useMemo(() => {
		const allBalance = mainBalance || agentBalance;
		if (allBalance === null) return "#4CAF50";
		if (allBalance < 0) return "#f83528";
		return "#4CAF50";
	}, [mainBalance, agentBalance]);

	const colorBonusBalance = useMemo(() => {
		const allBalance = bonusBalance || agentBonusBalance;
		if (allBalance === null) return "#f83528";
		if (allBalance < 0) return "#f83528";
		return "#03A9F4";
	}, [bonusBalance, agentBonusBalance]);

	const placeholderAgentName =
		t(
			"pages.mainPage.pages.orders.orderModal.personalInformation.str208",
		) ?? "";

	const keyword = useMemo(() => {
		if (!orderCode) return <></>;
		const { keyword } = orderCode.code;
		return <CodeConfirmationContent keyword={keyword} />;
	}, [orderCode]);

	useKeyBindSettings("orderModalFocusCardInput", (event) => {
		const elem = refInputCard.current;
		if (elem) elem.focus();
		event.preventDefault();
		event.stopPropagation();
	});

	useLayoutEffect(() => {
		if (!tab.form.code) {
			cleanupCustomer();
			setOrderCode(null);
			form.setValue("code", "");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tab.form.code]);

	return (
		<StyledCounterpart gap="8px" flex={{ flex: "1 0 100%" }}>
			<Wrap gap="0 8px">
				<StyledRow gap="8px" w={{ min: "144px" }} className="wrap">
					<StyledRow gap="8px">
						<Controller
							name={`additionalPhones`}
							control={form.control}
							render={onRenderAdditionalPhones}
						/>
					</StyledRow>
					{phoneBlacklistModal && (
						<PhoneBlacklist
							createdAt={phoneBlacklistModal.createdAt}
							level={phoneBlacklistModal.level}
							description={phoneBlacklistModal.description}
							onSave={() => setPhoneBlacklistModal(null)}
							onClose={closeCurrentCard}
							footerDivider
						/>
					)}
				</StyledRow>

				<StyledRow flex={{ flex: "0 1 100%" }} className="wrap">
					<StyledInputGroup disabled={disabled}>
						<StyledTextBox
							tabIndex={1}
							disabled={disabled}
							placeholder={placeholderAgentName}
							value={agentName}
							onChange={onChangeCounterparty}
						/>

						<InputBorders.InputBorders>
							{mainBalanceType && (
								<Divider p="0 0 0 10px" gap="5px">
									<StyledBalance
										gap="0 5px"
										alignItems="center"
										flex={{ wrap: "nowrap" }}
										colors={colorMainBalance}
									>
										<WalletIcon fill={colorMainBalance} />
										{userMainBalance}
									</StyledBalance>
									{bonusBalanceType && userBonusBalance && (
										<Divider p="0 0 0 10px">
											<StyledBalance
												gap="0 5px"
												alignItems="center"
												flex={{ wrap: "nowrap" }}
												colors={colorBonusBalance}
											>
												<WalletIcon
													fill={colorBonusBalance}
												/>
												{userBonusBalance}
											</StyledBalance>
										</Divider>
									)}
								</Divider>
							)}

							{agentBalanceType && (
								<Divider p="0 0 0 10px" gap="5px">
									<StyledBalance
										gap="0 5px"
										alignItems="center"
										flex={{ wrap: "nowrap" }}
										colors={colorMainBalance}
									>
										<WalletIcon fill={colorMainBalance} />
										{userAgentBalance}
									</StyledBalance>
									{agentBonusBalanceType &&
										userAgentBonusBalance && (
											<Divider p="0 0 0 10px">
												<StyledBalance
													gap="0 5px"
													alignItems="center"
													flex={{ wrap: "nowrap" }}
													colors={colorBonusBalance}
												>
													<WalletIcon
														fill={colorBonusBalance}
													/>
													{userAgentBonusBalance}
												</StyledBalance>
											</Divider>
										)}
								</Divider>
							)}
						</InputBorders.InputBorders>
					</StyledInputGroup>
				</StyledRow>

				<StyledRow gap="8px" justify="end" w="150px" className="wrap">
					<Controller
						name="code"
						control={form.control}
						render={onRenderCode}
					/>
					{orderCode && (
						<CheckCode
							title={
								t(
									`orderPage.order_form.code_confirmation.check_code`,
									"pages.mainPage.pages.orders.orderModal.personalInformation.str209",
								) || ""
							}
							value={orderCode}
							content={keyword}
							onSubmit={onSubmitInput}
							onClose={onCloseInput}
						/>
					)}
				</StyledRow>
			</Wrap>

			<StyledButton
				disabled
				icon={<Icon id="counterpart" size={18} />}
				variant="primary"
				width="clamp(27px, 3.7vh, 32px)"
				height="clamp(27px, 3.7vh, 32px)"
				minWidth="clamp(27px, 3.7vh, 32px)"
			/>
		</StyledCounterpart>
	);
};

declare namespace CodeCounterpartOrAgent {
	interface Props extends OrderFormProps {
		setTabAccept: () => void;
	}
}

export const CodeCounterpartOrAgentMemo = memo(CodeCounterpartOrAgent);
export default CodeCounterpartOrAgent;
