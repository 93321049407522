/* eslint-disable prettier/prettier */
/* eslint-disable no-case-declarations */
import React, { Dispatch, useCallback, useMemo, useState } from "react";
import { CheckBox, Row } from "uikit";
import { useTranslation } from "react-i18next";
import Root from "./components/Root";
import { Columns, columns, defaultColumnsId } from "./constants";
import tPath from "../../constants/tPath";
import LightTable from "../../../../../../../../components/LightTable";
import TableSettings from "../../../../../../../../components/TableSettings";
import { useTypedSelector } from "../../../../../../../../redux/store";
import OrderSettings from "../../../../../../../../services/OrderSettings";

const Content: React.FC<Content.Props> = ({
	value,
	selected,
	setSelected,
	onEdit,
}) => {
	const { t } = useTranslation();
	const lang = useTypedSelector((state) => state.session.language);

	const tColumns = useMemo(
		() =>
			columns.map((c) => ({
				...c,
				label: t(`${tPath}.columns.${c.id}`),
			})),
		[t],
	);
	const [columnsId, setColumnsId] = useState(defaultColumnsId);

	const updateSelected = useCallback(
		(template) => {
			if (selected.includes(template.id)) {
				setSelected(selected.filter((id) => template.id !== id));
			} else {
				setSelected([...selected, template.id]);
			}
		},
		[setSelected, selected],
	);

	const renderColumns = useMemo(
		() =>
			columnsId.map((columnId) => {
				const column = tColumns.find((col) => col.id === columnId);
				if (!column) return null;

				return (
					<LightTable.Column
						key={columnId}
						verticalAlign="middle"
						width={column.width}
						flexGrow={column.width ? 0 : 1}
					>
						<LightTable.HeaderCell style={{ padding: "0 25px" }}>
							{column.label}
						</LightTable.HeaderCell>
						<LightTable.Cell
							verticalAlign="middle"
							style={{ padding: "0 25px" }}
						>
							{(settings) => {
								switch (columnId) {
									case Columns.NAME:
										return settings.name;

									case Columns.COMPANIES:
										// const companies: string[] = settings.taxiServices?.map(
										// 	(taxiService) => taxiService.company.name[lang]
										// );
										// const uniqueCompanies: string[] = Array.from(new Set(companies));

										// return (
										// 	<Row align="center" gaps="8px*">
										// 		{uniqueCompanies.map((company, index) => (
										// 			<Row align="center" key={index}>
										// 				{company}
										// 				{index !== uniqueCompanies.length - 1 && ","}
										// 			</Row>
										// 		))}
										// 	</Row>
										// );
										return <></>;

									case Columns.BRANCHES:
										return (
											<Row align="center" gaps="8px*">
												{settings?.taxiServices?.map(
													(taxiService, index) => (
														<Row
															align="center"
															key={taxiService.id}
														>
															{
																taxiService
																	?.settlement[
																	lang
																]
															}
															{index !==
																settings
																	.taxiServices
																	.length -
																	1 && ","}
														</Row>
													),
												)}
											</Row>
										);
									default:
										return null;
								}
							}}
						</LightTable.Cell>
					</LightTable.Column>
				);
			}),
		[columnsId, lang, tColumns],
	);

	return (
		<Root sizes="1fr auto!">
			<LightTable
				data={value}
				virtualized
				fillHeight
				shouldUpdateScroll={false}
				rowClassName={(rowData) =>
					rowData && selected.includes(rowData.id) ? "selected" : ""
				}
				onRowClick={updateSelected}
				onRowDoubleClick={onEdit}
			>
				<LightTable.Column width={0}>
					<LightTable.HeaderCell>{null}</LightTable.HeaderCell>
					<LightTable.Cell />
				</LightTable.Column>
				<LightTable.Column verticalAlign="middle" width={50}>
					<LightTable.HeaderCell style={{ padding: "0 25px" }}>
						<CheckBox
							value={
								selected.length === value.length &&
								value.length !== 0
							}
							onChange={(isAllSelected) => {
								if (isAllSelected) {
									setSelected(value.map(({ id }) => id));
								} else {
									setSelected([]);
								}
							}}
						/>
					</LightTable.HeaderCell>
					<LightTable.Cell
						verticalAlign="middle"
						style={{ padding: "0 25px" }}
					>
						{(template) => (
							<CheckBox
								value={selected.includes(template.id)}
								onChange={() => {
									updateSelected(template);
								}}
							/>
						)}
					</LightTable.Cell>
				</LightTable.Column>
				{renderColumns}
				<LightTable.Column width={0}>
					<LightTable.HeaderCell>{null}</LightTable.HeaderCell>
					<LightTable.Cell />
				</LightTable.Column>
			</LightTable>
			<TableSettings
				value={columnsId}
				defaultValue={defaultColumnsId}
				columns={tColumns}
				onChange={setColumnsId}
			/>
		</Root>
	);
};

declare namespace Content {
	interface Props {
		value: OrderSettings.Model[] | [];

		selected: number[];
		setSelected: Dispatch<number[]>;
		onEdit: any;
	}

	type ColumnType = {
		id: string;
		label: string;
		width?: number;
		justify?: "center";
	};
}

export default Content;
