import React, { useLayoutEffect, useMemo, useState, useCallback } from "react";
import { isNumber } from "lodash";

import ExecutorReport from "../../../../../../services/ExecutorReport";
import mapByKey from "../../../../../../utils/mapByKey";
import { useTableOptions } from "../../../../../../components/LightTable";
import {
	StyledGrid,
	StyledGridItem,
} from "../../../../../../components/common";
import { useAccountingExecutors } from "../../hooks";

import Content from "./components/Content";
import Header from "./components/Header";

export interface Filter {
	dateRange: {
		dateFrom: Date;
		dateTo: Date;
	};
}

export interface ModalFilter {
	callSigns: string[] | ["all"];
	fleetIds: number[];
	executorIds: number[];
	executorGroupIds: number[];
	taxiServiceIds: number[] | ["all"];
	companyIds: number[] | ["all"];
}

const Executors: React.FC = () => {
	const {
		executors,
		setExecutors,
		setCounters,
		setFleetIds,
		setExecutorIds,
		setExecutorGroupIds,
		setCompanyIds,
		setTaxiServiceIds,
		setIsSortMap,
		counters,
		filters: filtersRedux,
	} = useAccountingExecutors();

	const {
		editor,
		onChange,
		query,
		filter,
		setDataLength,
		dataLength,
		setQuery,
		setDateRange,
	} = useTableOptions();

	const [executorRateColumns, setExecutorRateColumns] = useState<
		ExecutorReport.ExecutorRateColumn[] | null
	>(null);

	const getExecutorRateColumns = useCallback(async () => {
		const res = await ExecutorReport.getClassifier();
		if (!res) return;
		setExecutorRateColumns(res.items);
	}, []);

	useLayoutEffect(() => {
		if (!executorRateColumns) {
			getExecutorRateColumns();
		}
	}, [executorRateColumns, getExecutorRateColumns]);

	const modelItemById = useMemo(() => mapByKey(executors, "id"), [executors]);

	const [filters, setFilters] = useState<ModalFilter>({
		callSigns: ["all"],
		taxiServiceIds: ["all"],
		companyIds: ["all"],
		executorIds: [],
		executorGroupIds: [],
		fleetIds: [],
	});

	useLayoutEffect(() => {
		const {
			query,
			dateRange,
			callSigns,
			taxiServiceIds,
			companyIds,
			executorIds,
			executorGroupIds,
			fleetIds,
		} = filtersRedux;

		setFilters((prev) => ({
			...prev,
			executorIds,
			callSigns,
			taxiServiceIds,
			companyIds,
			executorGroupIds,
			fleetIds,
		}));

		setQuery(query);
		setDateRange({
			dateFrom: dateRange.from,
			dateTo: dateRange.to,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [selected, setSelected] = useState<number[]>([]);

	const getData = useCallback(async () => {
		const params: ExecutorReport.SearchOptions = {
			dateRange: {
				from: filter.dateRange.dateFrom,
				to: filter.dateRange.dateTo,
			},
		};
		if (query) params.callSigns = [query];

		if (filters.callSigns?.length) {
			const exist = filters.callSigns.filter((item) => item !== "all");
			if (exist.length) params.callSigns = exist;
		}

		setFleetIds(filters.fleetIds);
		if (filters.fleetIds?.length) {
			params.fleetIds = filters.fleetIds;
		}

		setExecutorIds(filters.executorIds);
		if (filters.executorIds?.length) {
			params.executorIds = filters.executorIds;
		}

		setExecutorGroupIds(filters.executorGroupIds);
		if (filters.executorGroupIds?.length) {
			params.executorGroupIds = filters.executorGroupIds;
		}

		setCompanyIds(filters.companyIds);
		setTaxiServiceIds(filters.taxiServiceIds);
		if (filters.taxiServiceIds?.length) {
			const taxiServiceIds: number[] = [];

			filters.taxiServiceIds.forEach((item) => {
				if (isNumber(item)) taxiServiceIds.push(item);
			});

			if (taxiServiceIds.length) params.taxiServiceIds = taxiServiceIds;
		}

		const res = await ExecutorReport.getExecutorReport(params);

		if (res && res.items) {
			setExecutors(res.items);

			setIsSortMap(true);
		}
		if (res && res.counters) setCounters(res.counters);
	}, [
		filter.dateRange.dateFrom,
		filter.dateRange.dateTo,
		filters.callSigns,
		filters.companyIds,
		filters.executorGroupIds,
		filters.executorIds,
		filters.fleetIds,
		filters.taxiServiceIds,
		query,
		setCompanyIds,
		setCounters,
		setExecutorGroupIds,
		setExecutorIds,
		setExecutors,
		setFleetIds,
		setIsSortMap,
		setTaxiServiceIds,
	]);

	const handleFilters = useCallback(
		async (data: ModalFilter) => {
			const params: ExecutorReport.SearchOptions = {
				dateRange: {
					from: filter.dateRange.dateFrom,
					to: filter.dateRange.dateTo,
				},
			};

			if (data.callSigns?.length) {
				const exist = data.callSigns.filter((item) => item !== "all");
				if (exist.length) params.callSigns = exist;
			}

			setFleetIds(data.fleetIds);
			if (data.fleetIds?.length) {
				params.fleetIds = data.fleetIds;
			}

			setExecutorIds(data.executorIds);
			if (data.executorIds?.length) {
				params.executorIds = data.executorIds;
			}

			setExecutorGroupIds(data.executorGroupIds);
			if (data.executorGroupIds?.length) {
				params.executorGroupIds = data.executorGroupIds;
			}

			setCompanyIds(data.companyIds);
			setTaxiServiceIds(data.taxiServiceIds);
			if (data.taxiServiceIds?.length) {
				const taxiServiceIds: number[] = [];

				data.taxiServiceIds.forEach((item) => {
					if (isNumber(item)) taxiServiceIds.push(item);
				});

				if (taxiServiceIds.length)
					params.taxiServiceIds = taxiServiceIds;
			}

			const res = await ExecutorReport.getExecutorReport(params);

			if (res && res.items) {
				setExecutors(res.items);
				setIsSortMap(true);
			}
			if (res && res.counters) setCounters(res.counters);
		},
		[
			filter.dateRange.dateFrom,
			filter.dateRange.dateTo,
			setCompanyIds,
			setCounters,
			setExecutorGroupIds,
			setExecutorIds,
			setExecutors,
			setFleetIds,
			setIsSortMap,
			setTaxiServiceIds,
		],
	);

	useLayoutEffect(() => {
		if (dataLength !== executors.length) {
			setDataLength(executors.length);
		}
	}, [executors.length, dataLength, setDataLength]);

	const countersData = useMemo(() => {
		if (!counters) return [];
		return [
			{
				ordersCounters: counters.orders,
				executorRateColumns: counters.executorRateColumns,
				discountCompensation: counters.discountCompensation,
				dueAmount: counters.dueAmount,
				callSign: `${dataLength || 0}`,
			},
		];
	}, [counters, dataLength]);

	const paymentAccounts = useMemo(() => {
		if (selected.length > 1) return null;
		const paymentAccounts = modelItemById[selected[0]]?.paymentAccounts;
		return paymentAccounts;
	}, [modelItemById, selected]);

	const taxiServiceId = useMemo(() => {
		if (selected.length > 1) return null;
		const id = modelItemById[selected[0]]?.taxiService?.id;
		return id;
	}, [modelItemById, selected]);

	const executorId = useMemo(() => {
		if (selected.length > 1) return null;
		const id = modelItemById[selected[0]]?.id;
		return id;
	}, [modelItemById, selected]);

	const areas = useMemo(() => `'header' 'main'`, []);
	return (
		<StyledGrid areas={areas} rows="50px 1fr" w="100%" h="100%">
			<StyledGridItem area="header">
				<Header
					value={editor}
					onChange={onChange}
					getData={getData}
					taxiServiceId={taxiServiceId}
					executorId={executorId}
					paymentAccounts={paymentAccounts}
					setFilters={setFilters}
					filters={filters}
					handleFilters={handleFilters}
				/>
			</StyledGridItem>
			<StyledGridItem area="main">
				<Content
					value={executors}
					counters={countersData}
					executorRateColumns={executorRateColumns}
					selected={selected}
					setSelected={setSelected}
					editorTable={editor}
					onChangeTable={onChange}
				/>
			</StyledGridItem>
		</StyledGrid>
	);
};

export default Executors;
