/* eslint-disable no-shadow */

import React, { Dispatch, memo } from "react";

import ControlButtons from "../../../../../../../PreferencesPages/components/ScreenDirectory/components/components/ControlButtons";

import InternalFilters from "./components/Filters";
import Root from "./components/Root";

const Header = memo<Header.Props>(
	({
		filters,

		canEdit,
		canDelete,

		onChangeFilters,

		onAdd,
		onEdit,
		onDelete,
	}) => (
		<Root gaps="10px*" align="center" justify="space-between" maxedWidth>
			<ControlButtons
				canEdit={canEdit}
				canDelete={canDelete}
				onAdd={onAdd}
				onEdit={onEdit}
				onDelete={onDelete}
			/>
			<InternalFilters value={filters} onChange={onChangeFilters} />
		</Root>
	),
);

declare namespace Header {
	interface Props {
		filters: InternalFilters.Value;

		canEdit: boolean;
		canDelete: boolean;

		onChangeFilters: Dispatch<InternalFilters.Value>;

		onAdd: () => void;
		onEdit: () => void;
		onDelete: () => void;
	}

	namespace Filters {
		type Value = InternalFilters.Value;
		type Props = InternalFilters.Props;
	}
}

export default Header;
