import React, { useMemo } from "react";
import moment from "moment";
import { isUndefined } from "lodash";
import Order from "../../../../../../../services/Order";
import CellContentRoot from "../CellContentRoot";

const ExecutingStageAcceptedTimeCellContent: React.FC<
	ExecutingStageAcceptedTimeCellContent.Props
> = ({ item }) => {
	const timestamp = useMemo(
		() => item.executingStages?.accepted?.timestamp,
		[item.executingStages?.accepted?.timestamp],
	);

	const content = useMemo(
		() =>
			isUndefined(timestamp) ? "" : moment(timestamp).format("HH:mm:ss"),
		[timestamp],
	);

	const backgroundColor = useMemo(
		() => (timestamp ? "#4CAF50" : undefined),
		[timestamp],
	);

	return (
		<CellContentRoot
			align="center"
			backgroundColor={backgroundColor}
			maxedHeight
			maxedWidth
		>
			{content}
		</CellContentRoot>
	);
};

declare namespace ExecutingStageAcceptedTimeCellContent {
	interface Props {
		item: Order.Model;
	}
}

export default ExecutingStageAcceptedTimeCellContent;
