import React, {
	RefAttributes,
	useCallback,
	useLayoutEffect,
	useMemo,
	useState,
} from "react";
import { react, useRefWithSetter } from "uikit";
import { useTranslation } from "react-i18next";
import { isEqual } from "lodash";

import Card from "../../../../../../../../services/Card";
import Map from "../../../../../../../../redux/services/Map";
import useObjectEditor from "../../../../../../../../hooks/useObjectEditor";
import EditModalBase from "../../../../../../../../components/EditModal";
import DeleteModal from "../../../../../../../../components/DeleteModal";

import InternalController from "./Controller";
import Root from "./components/Root";
import Header from "./components/Header";
import Content from "./components/Content";
import Footer from "./components/Footer";

const EditModal = react.withController<
	EditModal.PropsBase,
	EditModal.Controller
>(({ controller, value, language, onCancel, onSave, loading }) => {
	const { t } = useTranslation();
	const [contentRef, setContentRef] = useRefWithSetter<Content.Ref | null>(
		null,
	);

	const [showModal, setShowModal] = useState(false);
	// const cardIds = useMemo(
	// 	() => (typeof value.id === "number" ? [value.id] : []),
	// 	[value.id],
	// );

	// useKeepCardsOpen(cardIds, Car.Card);

	// const cardSession = useCardSubscriber(Car.Card);

	const currentCard =
		null as Card.Session.Card | null; /* useMemo<Card.Session.Card | null>(
		() =>
		cardSession?.cards.find((card) => cardIds.includes(card.id)) ??
		null,
		[cardIds, cardSession?.cards],
		); */

	const currentCardLocked = useMemo(
		() => currentCard?.locked === true,
		[currentCard?.locked],
	);

	controller.setContext({ contentRef });

	const [tab, setTab] = useState<
		| "main"
		| "subscriptions"
		| "commissionPlans"
		| "discountedPlans"
		| "executors"
	>("main");
	const [internalValue, setInternalValue] = useState(value);

	useLayoutEffect(() => {
		if (value?.executorIds && value?.id) {
			setInternalValue((prev) => ({
				...prev,
				executorIds: value.executorIds,
			}));
		}
	}, [value?.executorIds, value?.id]);

	const valueEditor = useObjectEditor(internalValue, setInternalValue);

	const name = useMemo(() => internalValue.name ?? "", [internalValue.name]);

	const headerTitle = useMemo(
		() =>
			typeof value.id === "number"
				? name
				: t(
						"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.str200",
				  ) ?? "",
		[name, value.id, t],
	);

	const headerLockedBy = useMemo(
		() =>
			currentCard?.locked
				? [
						currentCard.lockedBy.person?.lastName ?? "",
						currentCard.lockedBy.person?.firstName ?? "",
						currentCard.lockedBy.person?.fatherName ?? "",
				  ]
						.join(" ")
						.trim()
				: undefined,
		[currentCard?.locked, currentCard?.lockedBy],
	);

	const assignValue = valueEditor.useAssigner();

	const modalContent = useMemo(
		() => (
			<Root sizes="auto! 1fr">
				<Header
					tab={tab}
					title={headerTitle}
					lockedBy={headerLockedBy}
					createdAt={value.createdAt}
					onChangeTab={setTab}
				/>
				<Content
					ref={setContentRef}
					executorId={value.id}
					value={internalValue}
					type={tab}
					disabled={currentCardLocked}
					language={language}
					onChange={assignValue}
				/>
			</Root>
		),
		[
			assignValue,
			currentCardLocked,
			headerLockedBy,
			headerTitle,
			internalValue,
			language,
			setContentRef,
			tab,
			value.createdAt,
			value.id,
		],
	);

	const footerValue = valueEditor.usePicker(["active", "default", "root"]);
	const modalFooter = useMemo(
		() => (
			<Footer
				value={footerValue}
				disabled={currentCard?.locked === true}
				onChange={assignValue}
				loading={loading}
			/>
		),
		[footerValue, assignValue, loading],
	);

	const modalOnSave = useCallback(
		(allow = false) => {
			if (!contentRef.current?.validate()) return;

			if (!internalValue.id) {
				onSave(internalValue as EditModal.Value.Validated);
				return;
			}
			if (!allow) {
				const equal = isEqual(value, internalValue);
				if (!equal) {
					setShowModal(true);
					return;
				}

				onSave(internalValue as EditModal.Value.Validated);
			} else onSave(internalValue as EditModal.Value.Validated);
		},
		[contentRef, internalValue, onSave, value],
	);

	const textModal = useMemo(() => {
		const textTranslate =
			t(
				"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.str201",
			) ?? "";

		return textTranslate;
	}, [t]);

	const modalOnCancel = useCallback(() => {
		setShowModal(false);
	}, []);

	const modalOnConfirm = useCallback(async () => {
		setShowModal(false);
		modalOnSave(true);
	}, [modalOnSave]);

	return (
		<EditModalBase
			footer={modalFooter}
			canSave={!currentCardLocked || !loading}
			onCancel={onCancel}
			onSave={modalOnSave}
			loading={loading}
		>
			{modalContent}

			{showModal && (
				<DeleteModal
					label={textModal}
					onCancel={modalOnCancel}
					onConfirm={modalOnConfirm}
				/>
			)}
		</EditModalBase>
	);
}, InternalController);

declare namespace EditModal {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value extends Footer.Value, Content.Value {
		id?: number;

		name: string;

		createdAt?: string;
	}

	namespace Value {
		type Validated = Value;
	}

	interface PropsBase {
		value: Value;
		language: Map.Language;

		onCancel: () => void;
		onSave: (value: Value.Validated) => void;
		loading: boolean;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default EditModal;
