import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";

import { ordersAction as orders } from "../../../../redux/reducers/Orders"; // TODO replace in chat, all addresses with direct ones from src/redux/reducers/Orders

const useExecutorsActions = (): useExecutorsActions.Props => {
	const dispatch = useDispatch();

	const onSaveColumnsIdsTable = useCallback(
		(value: string[]) => {
			dispatch(orders.executors.tableSettings.setColumnIds(value));
		},
		[dispatch],
	);

	return useMemo(
		() => ({
			onSaveColumnsIdsTable,
		}),
		[onSaveColumnsIdsTable],
	);
};

declare namespace useExecutorsActions {
	interface Props {
		onSaveColumnsIdsTable: (value: string[]) => void;
	}
}

export default useExecutorsActions;
