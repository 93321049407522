import React, {
	memo,
	useCallback,
	useState,
	useMemo,
	useLayoutEffect,
} from "react";
import { useTranslation } from "react-i18next";
import { Stepper, CheckBox } from "uikit";
import { cloneDeep, isNumber } from "lodash";
import { useDebouncedCallback } from "use-debounce";

import { Schema } from "../../../../../../../../redux/constants/OrdersPage/order";
import {
	CustomerBonusUsageType,
	Discount,
} from "../../../../../../../../services/Order";
import { UseCreateFormReturn } from "../../../../../../../../hooks/useCreateForm";
import useObjectEditor from "../../../../../../../../hooks/useObjectEditor";
import { DiscountUnit } from "../../../../../../../../redux/services/Order/getPhoneInfo";
import {
	StyledColumn,
	StyledP,
	StyledRow,
	StyledSpan,
	Text,
} from "../../../../../../../../components/common";
import { Tab } from "../../types/Tab";

const DiscountPlaneBase: React.FC<DiscountPlaneBase.Props> = ({
	disabled,
	form,
	tab,
}) => {
	const { t } = useTranslation();

	const defaultValue = useMemo<DiscountPlaneBase.Value>(
		() => ({
			activeDiscount: true,
			activeDiscountUsage: true,
			activeBonusUsage: false,
			typeBonusUsage: CustomerBonusUsageType.BonusBalance,
			activeBonusUsageLimit: false,
			maxAmountBonusUsageLimit: 0,
		}),
		[],
	);

	const [intervalValue, setIntervalValue] =
		useState<DiscountPlaneBase.Value>(defaultValue);
	const valueEditor = useObjectEditor(intervalValue, setIntervalValue);

	const activeDiscount = valueEditor.useGetter("activeDiscount");

	const typeBonusUsage = valueEditor.useGetter("typeBonusUsage");
	const setTypeBonusUsage = valueEditor.useSetter("typeBonusUsage");

	const activeDiscountUsage = valueEditor.useGetter("activeDiscountUsage");
	const setActiveDiscountUsage = valueEditor.useSetter("activeDiscountUsage");

	const activeBonusUsage = valueEditor.useGetter("activeBonusUsage");
	const setActiveBonusUsage = valueEditor.useSetter("activeBonusUsage");
	const activeBonusUsageLimit = valueEditor.useGetter(
		"activeBonusUsageLimit",
	);
	const setActiveBonusUsageLimit = valueEditor.useSetter(
		"activeBonusUsageLimit",
	);
	const maxAmountBonusUsageLimit = valueEditor.useGetter(
		"maxAmountBonusUsageLimit",
	);
	const setMaxAmountBonusUsageLimit = valueEditor.useSetter(
		"maxAmountBonusUsageLimit",
	);

	const [show, setShow] = useState(false);

	const currencyName = useMemo(
		() => tab.form?.currency?.settings?.name || "",
		[tab.form?.currency],
	);

	const customerId = useMemo(
		() => tab.state?.customerId || "",
		[tab.state?.customerId],
	);

	const discount = useMemo(() => tab.form.discount, [tab.form.discount]);
	const discountCost = useMemo(
		() => tab.form.discountCost,
		[tab.form.discountCost],
	);
	const additionalPlan = useMemo(
		() => tab.form.additionalPlan,
		[tab.form.additionalPlan],
	);
	const mainPlan = useMemo(() => tab.form?.mainPlan, [tab.form?.mainPlan]);

	const modifyDiscountToForm = useCallback(
		(value: DiscountPlaneBase.Value) => {
			const payload = {
				active: value.activeDiscount,
				bonusUsage: {
					active: value.activeBonusUsage,
					type: value.typeBonusUsage,
					limit: {
						active: value.activeBonusUsageLimit,
						maxAmount: value.maxAmountBonusUsageLimit,
					},
				},
				discountUsage: {
					active: value.activeDiscountUsage,
				},
			};
			return payload;
		},
		[],
	);

	useLayoutEffect(() => {
		if (discount) {
			const payload: DiscountPlaneBase.Value = {
				...defaultValue,
				activeDiscount: discount?.active || false,
				activeDiscountUsage: discount.discountUsage?.active || false,
				activeBonusUsage: discount.bonusUsage?.active || false,
				typeBonusUsage:
					discount.bonusUsage?.type ||
					CustomerBonusUsageType.BonusBalance,
				activeBonusUsageLimit:
					discount.bonusUsage?.limit?.active || false,
				maxAmountBonusUsageLimit:
					discount.bonusUsage?.limit?.maxAmount || 0,
			};

			setIntervalValue(payload);
		} else setIntervalValue(defaultValue);
	}, [defaultValue, discount]);

	useLayoutEffect(() => {
		if (customerId) {
			if (additionalPlan) setShow(true);
			if (mainPlan) setShow(true);
			if (discount) setShow(true);
		}
	}, [additionalPlan, mainPlan, discount, discountCost, customerId]);

	useLayoutEffect(() => {
		if (show && !discount) {
			const data = modifyDiscountToForm(cloneDeep(intervalValue));
			form.setValue("discount", data);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [show, discount]);

	const handelDiscount = useCallback(
		(value) => {
			setActiveDiscountUsage(value);

			const data = modifyDiscountToForm({
				...cloneDeep(intervalValue),
				activeDiscountUsage: value,
			});
			form.setValue("discount", data);
		},
		[setActiveDiscountUsage, modifyDiscountToForm, intervalValue, form],
	);

	const handelActionBonusUsage = useCallback(
		(value) => {
			setActiveBonusUsage(value);
			const data = modifyDiscountToForm({
				...cloneDeep(intervalValue),
				activeBonusUsage: value,
			});
			form.setValue("discount", data);
		},
		[setActiveBonusUsage, modifyDiscountToForm, intervalValue, form],
	);

	const handelActionBonusUsageLimit = useCallback(
		(value) => {
			setActiveBonusUsageLimit(value);
			const data = modifyDiscountToForm({
				...cloneDeep(intervalValue),
				activeBonusUsageLimit: value,
			});
			form.setValue("discount", data);
		},
		[setActiveBonusUsageLimit, modifyDiscountToForm, intervalValue, form],
	);

	const handelBonusTripType = useCallback(() => {
		setTypeBonusUsage(CustomerBonusUsageType.BonusTrip);
		const data = modifyDiscountToForm({
			...cloneDeep(intervalValue),
			typeBonusUsage: CustomerBonusUsageType.BonusTrip,
			activeBonusUsage: true,
			activeBonusUsageLimit: false,
		});
		form.setValue("discount", data);
	}, [setTypeBonusUsage, modifyDiscountToForm, intervalValue, form]);

	const handelBonusBalanceType = useCallback(() => {
		setTypeBonusUsage(CustomerBonusUsageType.BonusBalance);
		const data = modifyDiscountToForm({
			...cloneDeep(intervalValue),
			typeBonusUsage: CustomerBonusUsageType.BonusBalance,
			activeBonusUsage: false,
		});
		form.setValue("discount", data);
	}, [setTypeBonusUsage, modifyDiscountToForm, intervalValue, form]);

	const handelMaxAmountBonusUsageLimit = useCallback(
		(value: Stepper.Value) => {
			if (isNumber(value)) {
				setMaxAmountBonusUsageLimit(value);
				const data = modifyDiscountToForm({
					...cloneDeep(intervalValue),
					maxAmountBonusUsageLimit: value,
				});
				form.setValue("discount", data);
			}
		},
		[
			setMaxAmountBonusUsageLimit,
			modifyDiscountToForm,
			intervalValue,
			form,
		],
	);

	useDebouncedCallback((value) => {
		handelMaxAmountBonusUsageLimit(value);
	}, 300);

	const disabledBonusCheckBox = useMemo(() => {
		if (disabled) return true;
		return !activeDiscount;
	}, [disabled, activeDiscount]);

	const disabledBonusLimitCheckBox = useMemo(() => {
		if (disabled) return true;
		if (typeBonusUsage === CustomerBonusUsageType.BonusTrip) return true;
		return !activeBonusUsage;
	}, [disabled, typeBonusUsage, activeBonusUsage]);

	const disabledBonusValue = useMemo(() => {
		if (disabled) return true;
		return !activeBonusUsageLimit;
	}, [disabled, activeBonusUsageLimit]);

	const mainDiscount = useMemo(() => {
		if (!mainPlan || !mainPlan?.discount) return "0";
		const { unit, value } = mainPlan.discount;
		if (unit === DiscountUnit.Amount) return `${value}${currencyName}`;
		return `${value}%`;
	}, [mainPlan, currencyName]);

	const mainDiscountMax = useMemo(() => {
		if (!mainPlan || !mainPlan?.maxDiscount) return "";
		const { active, value } = mainPlan.maxDiscount;
		if (!active) return ``;
		return `<= ${value}${currencyName}`;
	}, [currencyName, mainPlan]);

	const mainDiscountActive = useMemo(
		() => mainPlan?.active,
		[mainPlan?.active],
	);

	const additionalDiscount = useMemo(() => {
		if (!additionalPlan || !additionalPlan?.discount) return "0";
		const { unit, value } = additionalPlan.discount;
		if (unit === DiscountUnit.Amount) return `${value}${currencyName}`;
		return `${value}%`;
	}, [additionalPlan, currencyName]);

	const additionalDiscountMax = useMemo(() => {
		if (!additionalPlan || !additionalPlan?.maxDiscount) return "";
		const { active, value } = additionalPlan.maxDiscount;
		if (!active) return ``;
		return `<= ${value}${currencyName}`;
	}, [additionalPlan, currencyName]);

	const additionalDiscountActive = useMemo(
		() => additionalPlan?.active,
		[additionalPlan?.active],
	);

	return (
		<StyledRow
			alignItems="center"
			justify="start"
			p="0 12px"
			gap="0 10px"
			w="100%"
		>
			{!show && (
				<StyledSpan colors="#9BA3A8">
					{t(["discount.discountPlanClient"])}
				</StyledSpan>
			)}
			{show && (
				<>
					<StyledColumn
						justify="start"
						alignItems="start"
						gap="2px 10px"
					>
						<CheckBox
							disabled
							label={t(["discount.discountPlan"]) || ""}
							value={activeDiscountUsage}
							onChange={handelDiscount}
						/>
						<StyledRow alignItems="start" gap="0 5px">
							<CheckBox
								disabled={disabledBonusCheckBox}
								label={t(["discount.bonusTrip"]) || ""}
								value={
									typeBonusUsage ===
									CustomerBonusUsageType.BonusTrip
								}
								onChange={handelBonusTripType}
							/>
							<CheckBox
								disabled={disabledBonusCheckBox}
								label={t(["discount.bonusBalance"]) || ""}
								value={
									typeBonusUsage ===
									CustomerBonusUsageType.BonusBalance
								}
								onChange={handelBonusBalanceType}
							/>
						</StyledRow>
					</StyledColumn>

					{mainDiscountActive && (
						<StyledColumn
							alignItems="start"
							justify="space-between"
							gap="2px"
						>
							<StyledP>{`${t(["discount.mainPlan"])}`}</StyledP>
							<StyledRow alignItems="center">
								<Text
									textStyle={{
										font: {
											size: "13px",
											fw: "400",
											line: "1",
										},
									}}
									valueStyle={{
										font: {
											size: "13px",
											fw: "400",
											line: "1",
										},
										textAlign: "center",
									}}
									text={mainDiscount}
									value={mainDiscountMax}
								/>
							</StyledRow>
						</StyledColumn>
					)}
					{additionalDiscountActive && (
						<StyledColumn
							alignItems="start"
							justify="space-between"
							gap="2px"
						>
							<StyledP>{`${t([
								"discount.additionalPlan",
							])}`}</StyledP>
							<StyledRow alignItems="center">
								<Text
									textStyle={{
										font: {
											size: "13px",
											fw: "400",
											line: "1",
										},
									}}
									valueStyle={{
										font: {
											size: "13px",
											fw: "400",
											line: "1",
										},
										textAlign: "center",
									}}
									text={additionalDiscount}
									value={additionalDiscountMax}
								/>
							</StyledRow>
						</StyledColumn>
					)}

					<StyledRow alignItems="center" gap="0 10px" m="0 0 0 auto">
						<StyledColumn alignItems="start" gap="2px">
							<CheckBox
								label={t(["discount.useDiscount"]) || ""}
								disabled={disabledBonusCheckBox}
								value={activeBonusUsage}
								onChange={handelActionBonusUsage}
							/>
							<CheckBox
								label={t(["discount.useBonus"]) || ""}
								disabled={disabledBonusLimitCheckBox}
								value={activeBonusUsageLimit}
								onChange={handelActionBonusUsageLimit}
							/>
						</StyledColumn>
						<StyledRow
							flex={{ wrap: "nowrap" }}
							alignItems="center"
							gap="0px 5px"
						>
							<Stepper
								disabled={disabledBonusValue}
								value={maxAmountBonusUsageLimit}
								min={0}
								onChange={handelMaxAmountBonusUsageLimit}
							/>
							{`${currencyName}`}
						</StyledRow>
					</StyledRow>
				</>
			)}
		</StyledRow>
	);
};

declare namespace DiscountPlaneBase {
	interface Value {
		activeDiscount: Discount["active"];
		activeDiscountUsage: Discount["discountUsage"]["active"];
		activeBonusUsage: Discount["bonusUsage"]["active"];
		typeBonusUsage: Discount["bonusUsage"]["type"];
		activeBonusUsageLimit: Discount["bonusUsage"]["limit"]["active"];
		maxAmountBonusUsageLimit: Discount["bonusUsage"]["limit"]["maxAmount"];
	}

	interface Props {
		form: UseCreateFormReturn<Schema>;
		tab: Tab;
		disabled: boolean;
	}
}

export const DiscountPlane = memo(DiscountPlaneBase);
export default DiscountPlaneBase;
