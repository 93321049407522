import { ReactNode } from "react";

import Language from "../../../../../../../../../services/Language";
import { translationTablePath } from "../../constants/translationPaths";

import lastName from "./lastName";
import callSign from "./callSign";
import firstName from "./firstName";
import fatherName from "./fatherName";
import balance from "./balance";
import cashAmount from "./cashAmount";
import cashCount from "./cashCount";
import cashlessAmount from "./cashlessAmount";
import cashlessCount from "./cashlessCount";
import overallAmount from "./overallAmount";
import overallCount from "./overallCount";
import Commission from "./commission";
import Cash from "./cash";
import cashCommissionGross from "./cashCommissionGross";
import cashCashDeskGross from "./cashCashDeskGross";
import cashlessCommissionGross from "./cashlessCommissionGross";
import cashlessCashDeskGross from "./cashlessCashDeskGross";
import ordersAmount from "./ordersAmount";
import ordersCount from "./ordersCount";
import onlineOrdersCount from "./onlineOrdersCount";
import bonusOrdersCount from "./bonusOrdersCount";
import ordersCountToCardService from "./ordersCountToCardService";
import ordersCountToCardExecutor from "./ordersCountToCardExecutor";
import ordersCountToTerminalService from "./ordersCountToTerminalService";
import ordersCountToTerminalExecutor from "./ordersCountToTerminalExecutor";
import ordersCountWithLocal from "./ordersCountWithLocal";
import ordersCountWithGlobal from "./ordersCountWithGlobal";
import ordersCountWithOutSide from "./ordersCountWithOutSide";
import penaltyCount from "./penaltyCount";
import penaltyAmount from "./penaltyAmount";
import fee from "./fee";
import rent from "./rent";
import additionalFee from "./additionalFee";
import totalAmountPayable from "./totalAmountPayable";
import paymentFromExecutorCash from "./paymentFromExecutorCash";
import paymentFromExecutorCashPeriod from "./paymentFromExecutorCashPeriod";
import paymentFromExecutorToAccount from "./paymentFromExecutorToAccount";
import paymentFromExecutorToAccountPeriod from "./paymentFromExecutorToAccountPeriod";
import residue from "./residue";
import dataChangeBalance from "./dataChangeBalance";
import markUpOnAgencyOrder from "./markUpOnAgencyOrder";
import compensationByBonusOrders from "./compensationByBonusOrders";
import incomeExecutor from "./incomeExecutor";
import ordersAmountWithOutSideCashDeskGross from "./ordersAmountWithOutSideCashDeskGross";
import ordersAmountWithOutSideCommissionGross from "./ordersAmountWithOutSideCommissionGross";
import ordersAmountWithOutSide from "./ordersAmountWithOutSide";
import ordersAmountWithGlobalCashDeskGross from "./ordersAmountWithGlobalCashDeskGross";
import ordersAmountWithGlobalCommissionGross from "./ordersAmountWithGlobalCommissionGross";
import ordersAmountWithGlobal from "./ordersAmountWithGlobal";
import ordersAmountWithLocalCashDeskGross from "./ordersAmountWithLocalCashDeskGross";
import ordersAmountWithLocalCommissionGross from "./ordersAmountWithLocalCommissionGross";
import ordersAmountWithLocal from "./ordersAmountWithLocal";
import ordersAmountToTerminalExecutorCashDeskGross from "./ordersAmountToTerminalExecutorCashDeskGross";
import ordersAmountToTerminalExecutorCommissionGross from "./ordersAmountToTerminalExecutorCommissionGross";
import ordersAmountToTerminalExecutor from "./ordersAmountToTerminalExecutor";
import ordersAmountToTerminalServiceCashDeskGross from "./ordersAmountToTerminalServiceCashDeskGross";
import ordersAmountToTerminalServiceCommissionGross from "./ordersAmountToTerminalServiceCommissionGross";
import ordersAmountToTerminalService from "./ordersAmountToTerminalService";
import ordersAmountToCardExecutorCashDeskGross from "./ordersAmountToCardExecutorCashDeskGross";
import ordersAmountToCardExecutorCommissionGross from "./ordersAmountToCardExecutorCommissionGross";
import ordersAmountToCardExecutor from "./ordersAmountToCardExecutor";
import ordersAmountToCardServiceCashDeskGross from "./ordersAmountToCardServiceCashDeskGross";
import ordersAmountToCardServiceCommissionGross from "./ordersAmountToCardServiceCommissionGross";
import ordersAmountToCardService from "./ordersAmountToCardService";
import bonusOrdersAmountCashDeskGross from "./bonusOrdersAmountCashDeskGross";
import bonusOrdersAmountCommissionGross from "./bonusOrdersAmountCommissionGross";
import bonusOrdersAmount from "./bonusOrdersAmount";
import onlineOrdersAmountCashDeskGross from "./onlineOrdersAmountCashDeskGross";
import onlineOrdersAmountCommissionGross from "./onlineOrdersAmountCommissionGross";
import onlineOrdersAmount from "./onlineOrdersAmount";
import ordersAmountCashDeskGross from "./ordersAmountCashDeskGross";
import ordersAmountCommissionGross from "./ordersAmountCommissionGross";
import group from "./group";
import fleet from "./fleet";
import invoiceAmount from "./invoiceAmount";
import invoiceCount from "./invoiceCount";
import dueAmount from "./dueAmount";
import id from "./id";

export enum ColumnId {
	Id = "id",
	Group = "group",
	Fleet = "fleet",
	DueAmount = "dueAmount",

	InvoiceAmount = "invoiceAmount",
	InvoiceCount = "invoiceCount",

	CallSign = "callSign",
	LastName = "lastName",
	FirstName = "firstName",
	FatherName = "fatherName",

	OverallAmount = "overallAmount",
	OverallCount = "overallCount",
	Commission = "commission",
	Cash = "cash",

	CashAmount = "cashAmount",
	CashCount = "cashCount",
	CashCommissionGross = "cashCommissionGross",
	CashCashDeskGross = "cashCashDeskGross",

	CashlessAmount = "cashlessAmount",
	CashlessCount = "cashlessCount",
	CashlessCommissionGross = "cashlessCommissionGross",
	CashlessCashDeskGross = "cashlessCashDeskGross",

	OrdersCount = "ordersCount",
	OrdersAmount = "ordersAmount",
	OrdersAmountCommissionGross = "ordersAmountCommissionGross",
	OrdersAmountCashDeskGross = "ordersAmountCashDeskGross",

	OnlineOrdersCount = "onlineOrdersCount",
	OnlineOrdersAmount = "onlineOrdersAmount",
	OnlineOrdersAmountCommissionGross = "onlineOrdersAmountCommissionGross",
	OnlineOrdersAmountCashDeskGross = "onlineOrdersAmountCashDeskGross",

	BonusOrdersCount = "bonusOrdersCount",
	BonusOrdersAmount = "bonusOrdersAmount",
	BonusOrdersAmountCommissionGross = "bonusOrdersAmountCommissionGross",
	BonusOrdersAmountCashDeskGross = "bonusOrdersAmountCashDeskGross",

	OrdersCountToCardService = "ordersCountToCardService",
	OrdersAmountToCardService = "ordersAmountToCardService",
	OrdersAmountToCardServiceCommissionGross = "ordersAmountToCardServiceCommissionGross",
	OrdersAmountToCardServiceCashDeskGross = "ordersAmountToCardServiceCashDeskGross",

	OrdersCountToCardExecutor = "ordersCountToCardExecutor",
	OrdersAmountToCardExecutor = "ordersAmountToCardExecutor",
	OrdersAmountToCardExecutorCommissionGross = "ordersAmountToCardExecutorCommissionGross",
	OrdersAmountToCardExecutorCashDeskGross = "ordersAmountToCardExecutorCashDeskGross",

	OrdersCountToTerminalService = "ordersCountToTerminalService",
	OrdersAmountToTerminalService = "ordersAmountToTerminalService",
	OrdersAmountToTerminalServiceCommissionGross = "ordersAmountToTerminalServiceCommissionGross",
	OrdersAmountToTerminalServiceCashDeskGross = "ordersAmountToTerminalServiceCashDeskGross",

	OrdersCountToTerminalExecutor = "ordersCountToTerminalExecutor",
	OrdersAmountToTerminalExecutor = "ordersAmountToTerminalExecutor",
	OrdersAmountToTerminalExecutorCommissionGross = "ordersAmountToTerminalExecutorCommissionGross",
	OrdersAmountToTerminalExecutorCashDeskGross = "ordersAmountToTerminalExecutorCashDeskGross",

	OrdersCountWithLocal = "ordersCountWithLocal",
	OrdersAmountWithLocal = "ordersAmountWithLocal",
	OrdersAmountWithLocalCommissionGross = "ordersAmountWithLocalCommissionGross",
	OrdersAmountWithLocalCashDeskGross = "ordersAmountWithLocalCashDeskGross",

	OrdersCountWithGlobal = "ordersCountWithGlobal",
	OrdersAmountWithGlobal = "ordersAmountWithGlobal",
	OrdersAmountWithGlobalCommissionGross = "ordersAmountWithGlobalCommissionGross",
	OrdersAmountWithGlobalCashDeskGross = "ordersAmountWithGlobalCashDeskGross",

	OrdersCountWithOutSide = "ordersCountWithOutSide",
	OrdersAmountWithOutSide = "ordersAmountWithOutSide",
	OrdersAmountWithOutSideCommissionGross = "ordersAmountWithOutSideCommissionGross",
	OrdersAmountWithOutSideCashDeskGross = "ordersAmountWithOutSideCashDeskGross",

	PenaltyCount = "penaltyCount",
	PenaltyAmount = "penaltyAmount",

	Rent = "rent",
	Fee = "fee",
	AdditionalFee = "additionalFee",

	TotalAmountPayable = "totalAmountPayable",

	Balance = "balance",

	PaymentFromExecutorCash = "paymentFromExecutorCash",
	PaymentFromExecutorCashPeriod = "paymentFromExecutorCashPeriod",
	PaymentFromExecutorToAccount = "paymentFromExecutorToAccount",
	PaymentFromExecutorToAccountPeriod = "paymentFromExecutorToAccountPeriod",

	Residue = "residue",

	DataChangeBalance = "dataChangeBalance",

	MarkUpOnAgencyOrder = "markUpOnAgencyOrders",
	CompensationByBonusOrders = "compensationByBonusOrders",

	IncomeExecutor = "incomeExecutor",
}

export interface Column {
	render: (options: Column.Options) => ReactNode;
	label: string | string[];
}

export declare namespace Column {
	interface Options {
		width?: number;

		language: Language;

		onResize?: (width: number, columnId: ColumnId) => void;
	}
}

export const columns: Record<ColumnId, Column> = {
	[ColumnId.Id]: {
		render: id,
		label: [
			`${translationTablePath}.${ColumnId.Id}`,
			"Executor ID (unique number in DB)",
		],
	},
	[ColumnId.CallSign]: {
		render: callSign,
		label: [`${translationTablePath}.${ColumnId.CallSign}`, "Call Sign"],
	},

	[ColumnId.LastName]: {
		render: lastName,
		label: [`${translationTablePath}.${ColumnId.LastName}`, "Last Name"],
	},
	[ColumnId.FirstName]: {
		render: firstName,
		label: [`${translationTablePath}.${ColumnId.FirstName}`, "First Name"],
	},
	[ColumnId.FatherName]: {
		render: fatherName,
		label: [`${translationTablePath}.${ColumnId.FatherName}`, "Patronymic"],
	},

	[ColumnId.CashAmount]: {
		render: cashAmount,
		label: [
			`${translationTablePath}.${ColumnId.CashAmount}`,
			"Amount for Cash Orders",
		],
	},
	[ColumnId.CashCount]: {
		render: cashCount,
		label: [
			`${translationTablePath}.${ColumnId.CashCount}`,
			"Count of Cash Orders",
		],
	},
	[ColumnId.CashlessAmount]: {
		render: cashlessAmount,
		label: [
			`${translationTablePath}.${ColumnId.CashlessAmount}`,
			"Amount for Cashless Orders",
		],
	},
	[ColumnId.CashlessCount]: {
		render: cashlessCount,
		label: [
			`${translationTablePath}.${ColumnId.CashlessCount}`,
			"Count of Cashless Orders",
		],
	},
	[ColumnId.OverallAmount]: {
		render: overallAmount,
		label: [
			`${translationTablePath}.${ColumnId.OverallAmount}`,
			"Total Order Amount",
		],
	},
	[ColumnId.OverallCount]: {
		render: overallCount,
		label: [
			`${translationTablePath}.${ColumnId.OverallCount}`,
			"Total Order Count",
		],
	},
	[ColumnId.Commission]: {
		render: Commission,
		label: [
			`${translationTablePath}.${ColumnId.Commission}`,
			"Total Commission for Orders",
		],
	},
	[ColumnId.Cash]: {
		render: Cash,
		label: [`${translationTablePath}.${ColumnId.Cash}`, "Total Cash"],
	},

	[ColumnId.CashCommissionGross]: {
		render: cashCommissionGross,
		label: [
			`${translationTablePath}.${ColumnId.CashCommissionGross}`,
			"Commission for Cash Orders",
		],
	},

	[ColumnId.CashCashDeskGross]: {
		render: cashCashDeskGross,
		label: [
			`${translationTablePath}.${ColumnId.CashCashDeskGross}`,
			"Cash Desk for Cash Orders",
		],
	},

	[ColumnId.CashlessCommissionGross]: {
		render: cashlessCommissionGross,
		label: [
			`${translationTablePath}.${ColumnId.CashlessCommissionGross}`,
			"Commission for Cashless Orders",
		],
	},

	[ColumnId.CashlessCashDeskGross]: {
		render: cashlessCashDeskGross,
		label: [
			`${translationTablePath}.${ColumnId.CashlessCashDeskGross}`,
			"Cash Desk for Cashless Orders",
		],
	},

	[ColumnId.OrdersCount]: {
		render: ordersCount,
		label: [
			`${translationTablePath}.${ColumnId.OrdersCount}`,
			"Count of Out-of-Town Orders",
		],
	},
	[ColumnId.OrdersAmount]: {
		render: ordersAmount,
		label: [
			`${translationTablePath}.${ColumnId.OrdersAmount}`,
			"Amount for Out-of-Town Orders",
		],
	},
	[ColumnId.OnlineOrdersCount]: {
		render: onlineOrdersCount,
		label: [
			`${translationTablePath}.${ColumnId.OnlineOrdersCount}`,
			"Count of Online Orders",
		],
	},
	[ColumnId.BonusOrdersCount]: {
		render: bonusOrdersCount,
		label: [
			`${translationTablePath}.${ColumnId.BonusOrdersCount}`,
			"Count of Bonus Orders",
		],
	},
	[ColumnId.OrdersCountToCardService]: {
		render: ordersCountToCardService,
		label: [
			`${translationTablePath}.${ColumnId.OrdersCountToCardService}`,
			"Count of Orders Paid by Card (to Service Account)",
		],
	},
	[ColumnId.OrdersCountToCardExecutor]: {
		render: ordersCountToCardExecutor,
		label: [
			`${translationTablePath}.${ColumnId.OrdersCountToCardExecutor}`,
			"Count of Orders Paid by Card (to Executor Account)",
		],
	},
	[ColumnId.OrdersCountToTerminalService]: {
		render: ordersCountToTerminalService,
		label: [
			`${translationTablePath}.${ColumnId.OrdersCountToTerminalService}`,
			"Count of Orders Paid via Terminal (to Service Account)",
		],
	},
	[ColumnId.OrdersCountToTerminalExecutor]: {
		render: ordersCountToTerminalExecutor,
		label: [
			`${translationTablePath}.${ColumnId.OrdersCountToTerminalExecutor}`,
			"Count of Orders Paid via Terminal (to Executor Account)",
		],
	},
	[ColumnId.OrdersCountWithLocal]: {
		render: ordersCountWithLocal,
		label: [
			`${translationTablePath}.${ColumnId.OrdersCountWithLocal}`,
			"Count of Orders with Local Transfer",
		],
	},
	[ColumnId.OrdersCountWithGlobal]: {
		render: ordersCountWithGlobal,
		label: [
			`${translationTablePath}.${ColumnId.OrdersCountWithGlobal}`,
			"Count of Orders with Global Transfer",
		],
	},
	[ColumnId.OrdersCountWithOutSide]: {
		render: ordersCountWithOutSide,
		label: [
			`${translationTablePath}.${ColumnId.OrdersCountWithOutSide}`,
			"Count of Orders with External Transfer",
		],
	},
	[ColumnId.PenaltyCount]: {
		render: penaltyCount,
		label: [
			`${translationTablePath}.${ColumnId.PenaltyCount}`,
			"Count of Penalties",
		],
	},
	[ColumnId.PenaltyAmount]: {
		render: penaltyAmount,
		label: [
			`${translationTablePath}.${ColumnId.PenaltyAmount}`,
			"Amount of Penalties",
		],
	},
	[ColumnId.Rent]: {
		render: rent,
		label: [`${translationTablePath}.${ColumnId.Rent}`, "Rent"],
	},
	[ColumnId.Fee]: {
		render: fee,
		label: [`${translationTablePath}.${ColumnId.Fee}`, "Subscription Fee"],
	},
	[ColumnId.AdditionalFee]: {
		render: additionalFee,
		label: [
			`${translationTablePath}.${ColumnId.AdditionalFee}`,
			"Additional Subscription Fee",
		],
	},
	[ColumnId.TotalAmountPayable]: {
		render: totalAmountPayable,
		label: [
			`${translationTablePath}.${ColumnId.TotalAmountPayable}`,
			"Total Amount Payable",
		],
	},
	[ColumnId.Balance]: {
		render: balance,
		label: [
			`${translationTablePath}.${ColumnId.Balance}`,
			"Current Balance",
		],
	},
	[ColumnId.PaymentFromExecutorCash]: {
		render: paymentFromExecutorCash,
		label: [
			`${translationTablePath}.${ColumnId.PaymentFromExecutorCash}`,
			"Payment from Executor (Cash)",
		],
	},
	[ColumnId.PaymentFromExecutorCashPeriod]: {
		render: paymentFromExecutorCashPeriod,
		label: [
			`${translationTablePath}.${ColumnId.PaymentFromExecutorCashPeriod}`,
			"Payment from Executor (Cash, Period)",
		],
	},
	[ColumnId.PaymentFromExecutorToAccount]: {
		render: paymentFromExecutorToAccount,
		label: [
			`${translationTablePath}.${ColumnId.PaymentFromExecutorToAccount}`,
			"Payment from Executor (to Account)",
		],
	},
	[ColumnId.PaymentFromExecutorToAccountPeriod]: {
		render: paymentFromExecutorToAccountPeriod,
		label: [
			`${translationTablePath}.${ColumnId.PaymentFromExecutorToAccountPeriod}`,
			"Payment from Executor (to Account, Period)",
		],
	},
	[ColumnId.Residue]: {
		render: residue,
		label: [
			`${translationTablePath}.${ColumnId.Residue}`,
			"Balance (Debt / Overpayment)",
		],
	},
	[ColumnId.DataChangeBalance]: {
		render: dataChangeBalance,
		label: [
			`${translationTablePath}.${ColumnId.DataChangeBalance}`,
			"Date of Last Balance Change",
		],
	},
	[ColumnId.MarkUpOnAgencyOrder]: {
		render: markUpOnAgencyOrder,
		label: [
			`${translationTablePath}.${ColumnId.MarkUpOnAgencyOrder}`,
			"Markup Amount on Agency Orders",
		],
	},
	[ColumnId.CompensationByBonusOrders]: {
		render: compensationByBonusOrders,
		label: [
			`${translationTablePath}.${ColumnId.CompensationByBonusOrders}`,
			"Compensation Amount for Executor for Bonus Orders",
		],
	},
	[ColumnId.IncomeExecutor]: {
		render: incomeExecutor,
		label: [
			`${translationTablePath}.${ColumnId.IncomeExecutor}`,
			"Executor's Income",
		],
	},
	[ColumnId.OrdersAmountCommissionGross]: {
		render: ordersAmountCommissionGross,
		label: [
			`${translationTablePath}.${ColumnId.OrdersAmountCommissionGross}`,
			"Commission for Out-of-Town Orders",
		],
	},
	[ColumnId.OrdersAmountCashDeskGross]: {
		render: ordersAmountCashDeskGross,
		label: [
			`${translationTablePath}.${ColumnId.OrdersAmountCashDeskGross}`,
			"Cash Desk for Out-of-Town Orders",
		],
	},
	[ColumnId.OnlineOrdersAmount]: {
		render: onlineOrdersAmount,
		label: [
			`${translationTablePath}.${ColumnId.OnlineOrdersAmount}`,
			"Amount for Online Orders",
		],
	},
	[ColumnId.OnlineOrdersAmountCommissionGross]: {
		render: onlineOrdersAmountCommissionGross,
		label: [
			`${translationTablePath}.${ColumnId.OnlineOrdersAmountCommissionGross}`,
			"Commission for Online Orders",
		],
	},
	[ColumnId.OnlineOrdersAmountCashDeskGross]: {
		render: onlineOrdersAmountCashDeskGross,
		label: [
			`${translationTablePath}.${ColumnId.OnlineOrdersAmountCashDeskGross}`,
			"Cash Desk for Online Orders",
		],
	},
	[ColumnId.BonusOrdersAmount]: {
		render: bonusOrdersAmount,
		label: [
			`${translationTablePath}.${ColumnId.BonusOrdersAmount}`,
			"Amount for Bonus Orders",
		],
	},
	[ColumnId.BonusOrdersAmountCommissionGross]: {
		render: bonusOrdersAmountCommissionGross,
		label: [
			`${translationTablePath}.${ColumnId.BonusOrdersAmountCommissionGross}`,
			"Commission for Bonus Orders",
		],
	},
	[ColumnId.BonusOrdersAmountCashDeskGross]: {
		render: bonusOrdersAmountCashDeskGross,
		label: [
			`${translationTablePath}.${ColumnId.BonusOrdersAmountCashDeskGross}`,
			"Cash Desk for Bonus Orders",
		],
	},
	[ColumnId.OrdersAmountToCardService]: {
		render: ordersAmountToCardService,
		label: [
			`${translationTablePath}.${ColumnId.OrdersAmountToCardService}`,
			"Amount for Card Payments (to Service Account)",
		],
	},
	[ColumnId.OrdersAmountToCardServiceCommissionGross]: {
		render: ordersAmountToCardServiceCommissionGross,
		label: [
			`${translationTablePath}.${ColumnId.OrdersAmountToCardServiceCommissionGross}`,
			"Commission for Card Payments (to Service Account)",
		],
	},
	[ColumnId.OrdersAmountToCardServiceCashDeskGross]: {
		render: ordersAmountToCardServiceCashDeskGross,
		label: [
			`${translationTablePath}.${ColumnId.OrdersAmountToCardServiceCashDeskGross}`,
			"Cash Desk for Card Payments (to Service Account)",
		],
	},
	[ColumnId.OrdersAmountToCardExecutor]: {
		render: ordersAmountToCardExecutor,
		label: [
			`${translationTablePath}.${ColumnId.OrdersAmountToCardExecutor}`,
			"Amount for Card Payments (to Executor Account)",
		],
	},
	[ColumnId.OrdersAmountToCardExecutorCommissionGross]: {
		render: ordersAmountToCardExecutorCommissionGross,
		label: [
			`${translationTablePath}.${ColumnId.OrdersAmountToCardExecutorCommissionGross}`,
			"Commission for Card Payments (to Executor Account)",
		],
	},
	[ColumnId.OrdersAmountToCardExecutorCashDeskGross]: {
		render: ordersAmountToCardExecutorCashDeskGross,
		label: [
			`${translationTablePath}.${ColumnId.OrdersAmountToCardExecutorCashDeskGross}`,
			"Cash Desk for Card Payments (to Executor Account)",
		],
	},

	[ColumnId.OrdersAmountToTerminalService]: {
		render: ordersAmountToTerminalService,
		label: [
			`${translationTablePath}.${ColumnId.OrdersAmountToTerminalService}`,
			"Amount for Terminal Payments (to Service Account)",
		],
	},
	[ColumnId.OrdersAmountToTerminalServiceCommissionGross]: {
		render: ordersAmountToTerminalServiceCommissionGross,
		label: [
			`${translationTablePath}.${ColumnId.OrdersAmountToTerminalServiceCommissionGross}`,
			"Commission for Terminal Payments (to Service Account)",
		],
	},
	[ColumnId.OrdersAmountToTerminalServiceCashDeskGross]: {
		render: ordersAmountToTerminalServiceCashDeskGross,
		label: [
			`${translationTablePath}.${ColumnId.OrdersAmountToTerminalServiceCashDeskGross}`,
			"Cash Desk for Terminal Payments (to Service Account)",
		],
	},
	[ColumnId.OrdersAmountToTerminalExecutor]: {
		render: ordersAmountToTerminalExecutor,
		label: [
			`${translationTablePath}.${ColumnId.OrdersAmountToTerminalExecutor}`,
			"Amount for Terminal Payments (to Executor Account)",
		],
	},
	[ColumnId.OrdersAmountToTerminalExecutorCommissionGross]: {
		render: ordersAmountToTerminalExecutorCommissionGross,
		label: [
			`${translationTablePath}.${ColumnId.OrdersAmountToTerminalExecutorCommissionGross}`,
			"Commission for Terminal Payments (to Executor Account)",
		],
	},
	[ColumnId.OrdersAmountToTerminalExecutorCashDeskGross]: {
		render: ordersAmountToTerminalExecutorCashDeskGross,
		label: [
			`${translationTablePath}.${ColumnId.OrdersAmountToTerminalExecutorCashDeskGross}`,
			"Cash Desk for Terminal Payments (to Executor Account)",
		],
	},
	[ColumnId.OrdersAmountWithLocal]: {
		render: ordersAmountWithLocal,
		label: [
			`${translationTablePath}.${ColumnId.OrdersAmountWithLocal}`,
			"Amount for Local Transfers",
		],
	},
	[ColumnId.OrdersAmountWithLocalCommissionGross]: {
		render: ordersAmountWithLocalCommissionGross,
		label: [
			`${translationTablePath}.${ColumnId.OrdersAmountWithLocalCommissionGross}`,
			"Commission for Local Transfers",
		],
	},
	[ColumnId.OrdersAmountWithLocalCashDeskGross]: {
		render: ordersAmountWithLocalCashDeskGross,
		label: [
			`${translationTablePath}.${ColumnId.OrdersAmountWithLocalCashDeskGross}`,
			"Cash Desk for Local Transfers",
		],
	},
	[ColumnId.OrdersAmountWithGlobal]: {
		render: ordersAmountWithGlobal,
		label: [
			`${translationTablePath}.${ColumnId.OrdersAmountWithGlobal}`,
			"Amount for Global Transfers",
		],
	},
	[ColumnId.OrdersAmountWithGlobalCommissionGross]: {
		render: ordersAmountWithGlobalCommissionGross,
		label: [
			`${translationTablePath}.${ColumnId.OrdersAmountWithGlobalCommissionGross}`,
			"Commission for Global Transfers",
		],
	},
	[ColumnId.OrdersAmountWithGlobalCashDeskGross]: {
		render: ordersAmountWithGlobalCashDeskGross,
		label: [
			`${translationTablePath}.${ColumnId.OrdersAmountWithGlobalCashDeskGross}`,
			"Cash Desk for Global Transfers",
		],
	},
	[ColumnId.OrdersAmountWithOutSide]: {
		render: ordersAmountWithOutSide,
		label: [
			`${translationTablePath}.${ColumnId.OrdersAmountWithOutSide}`,
			"Amount for External Transfers",
		],
	},
	[ColumnId.OrdersAmountWithOutSideCommissionGross]: {
		render: ordersAmountWithOutSideCommissionGross,
		label: [
			`${translationTablePath}.${ColumnId.OrdersAmountWithOutSideCommissionGross}`,
			"Commission for External Transfers",
		],
	},
	[ColumnId.OrdersAmountWithOutSideCashDeskGross]: {
		render: ordersAmountWithOutSideCashDeskGross,
		label: [
			`${translationTablePath}.${ColumnId.OrdersAmountWithOutSideCashDeskGross}`,
			"Cash Desk for External Transfers",
		],
	},
	[ColumnId.Group]: {
		render: group,
		label: [`${translationTablePath}.${ColumnId.Group}`, "Team"],
	},
	[ColumnId.Fleet]: {
		render: fleet,
		label: [`${translationTablePath}.${ColumnId.Fleet}`, "Fleet"],
	},
	[ColumnId.InvoiceAmount]: {
		render: invoiceAmount,
		label: [
			`${translationTablePath}.${ColumnId.InvoiceAmount}`,
			"Amount for Invoice Orders",
		],
	},
	[ColumnId.InvoiceCount]: {
		render: invoiceCount,
		label: [
			`${translationTablePath}.${ColumnId.InvoiceCount}`,
			"Number of Invoice Orders",
		],
	},
	[ColumnId.DueAmount]: {
		render: dueAmount,
		label: [`${translationTablePath}.${ColumnId.DueAmount}`, "Amount Due"],
	},
};

export const defaultColumnIds = [
	ColumnId.CallSign,
	ColumnId.LastName,
	ColumnId.FirstName,
	ColumnId.FatherName,
	ColumnId.Group,
	ColumnId.Fleet,
	ColumnId.OverallCount,
	ColumnId.DueAmount,
	ColumnId.OverallAmount,
	ColumnId.Commission,
	ColumnId.Fee,
	ColumnId.MarkUpOnAgencyOrder,
	ColumnId.Balance,
	ColumnId.CashAmount,
	ColumnId.CashlessAmount,
	ColumnId.PenaltyAmount,
	ColumnId.CompensationByBonusOrders,
	ColumnId.OrdersAmountToTerminalService,
	ColumnId.OrdersAmountToCardService,
	ColumnId.PaymentFromExecutorCash,
	ColumnId.PaymentFromExecutorToAccount,
	ColumnId.DataChangeBalance,
];

export const disabledColumnsIds = [
	ColumnId.CashCommissionGross,
	ColumnId.CashCashDeskGross,
	ColumnId.CashlessCommissionGross,
	ColumnId.CashlessCashDeskGross,
	ColumnId.OrdersAmountCommissionGross,
	ColumnId.OrdersAmountCashDeskGross,
	ColumnId.OnlineOrdersCount,
	ColumnId.OnlineOrdersAmount,
	ColumnId.OnlineOrdersAmountCommissionGross,
	ColumnId.OnlineOrdersAmountCashDeskGross,

	ColumnId.BonusOrdersAmountCommissionGross,
	ColumnId.BonusOrdersAmountCashDeskGross,

	ColumnId.OrdersCountToCardService,
	ColumnId.OrdersAmountToCardExecutor,

	ColumnId.OrdersCountToCardExecutor,
	ColumnId.OrdersAmountToCardServiceCommissionGross,
	ColumnId.OrdersAmountToCardServiceCashDeskGross,
	ColumnId.OrdersAmountToCardExecutorCommissionGross,
	ColumnId.OrdersAmountToCardExecutorCashDeskGross,

	ColumnId.OrdersAmountToTerminalServiceCommissionGross,
	ColumnId.OrdersAmountToTerminalServiceCashDeskGross,
	ColumnId.OrdersCountToTerminalExecutor,
	ColumnId.OrdersAmountToTerminalExecutor,
	ColumnId.OrdersAmountToTerminalExecutorCommissionGross,
	ColumnId.OrdersAmountToTerminalExecutorCashDeskGross,

	ColumnId.OrdersCountWithLocal,
	ColumnId.OrdersAmountWithLocal,
	ColumnId.OrdersAmountWithLocalCommissionGross,
	ColumnId.OrdersAmountWithLocalCashDeskGross,
	ColumnId.OrdersCountWithGlobal,
	ColumnId.OrdersAmountWithGlobal,
	ColumnId.OrdersAmountWithGlobalCommissionGross,
	ColumnId.OrdersAmountWithGlobalCashDeskGross,
	ColumnId.OrdersCountWithOutSide,
	ColumnId.OrdersAmountWithOutSide,
	ColumnId.OrdersAmountWithOutSideCommissionGross,
	ColumnId.OrdersAmountWithOutSideCashDeskGross,
	ColumnId.TotalAmountPayable,

	ColumnId.Residue,

	ColumnId.IncomeExecutor,

	ColumnId.OrdersAmountWithLocal,
	ColumnId.OrdersAmountWithLocalCommissionGross,
	ColumnId.OrdersAmountWithLocalCashDeskGross,
	ColumnId.OrdersAmountWithGlobal,
	ColumnId.OrdersAmountWithGlobalCommissionGross,
	ColumnId.OrdersAmountWithGlobalCashDeskGross,
	ColumnId.OrdersAmountWithOutSideCashDeskGross,
	ColumnId.OrdersAmountWithOutSideCommissionGross,
	ColumnId.OrdersAmountWithOutSide,
];

export default columns;
