import styled from "styled-components";

const Root = styled.div<Root.Props>`
	padding: 10px;

	${({ nested }) => (nested ? "padding: 10px 0 0 25px;" : "")}
`;

declare namespace Root {
	interface Props {
		nested?: boolean;
	}
}

export default Root;
