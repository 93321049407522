import React, { memo, PropsWithChildren } from "react";
import { theme, ToggleButton } from "uikit";

import FieldsContainer from "../../FieldsContainer";

const FieldsContainerWithToggleBase: React.FC<
	FieldsContainerWithToggleBase.Props
> = ({ label, children, disabled, value, onChange, maxedWidth }) => (
	<FieldsContainer
		labelColor={theme.colors.secondary}
		label={label}
		additionalFields={
			<ToggleButton.ToggleButton
				value={value}
				onChange={onChange}
				disabled={disabled}
			/>
		}
		gaps="10px*"
		maxedWidth={maxedWidth}
	>
		{children}
	</FieldsContainer>
);

export const FieldsContainerWithToggle = memo(FieldsContainerWithToggleBase);

declare namespace FieldsContainerWithToggleBase {
	interface Props extends PropsWithChildren {
		label?: string;
		disabled?: boolean;
		value?: boolean;
		onChange?: (value: boolean) => void;
		maxedWidth?: boolean;
	}
}

export default FieldsContainerWithToggleBase;
