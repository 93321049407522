import React, {
	memo,
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import styled from "styled-components";
import { Icon, useInternal } from "uikit";

import { CardModal, StyledP, StyledRow, StyledWrapIcon } from "../../../common";

import TimeSelect, { TimeSelectMemo } from "./TimeSelect";

const Wrap = styled(StyledRow)`
	justify-content: center;
	align-items: center;
	height: 28px;
	padding-inline: 10px;
`;

const OrderTimeSelect: React.FC<OrderTimeSelect.Props> = ({
	value,
	onChange,
	width = "auto",
	displayModal = false,
	onChangeDisplayModal,
	disabled = false,
}) => {
	const { t } = useTranslation();
	const [display, setDisplay] = useInternal<boolean>(displayModal);
	const [nowLater, setNowLater] = useInternal<boolean>(false);

	const newDate = () => {
		const time = new Date();
		time.setSeconds(0, 0);
		return time;
	};

	const [BaseValue, setBaseValue] = useState<TimeSelect.ScheduledTime>({
		on: "later",
		time: new Date(),
	});

	const [inputValue, setInputValue] = useState<TimeSelect.ScheduledTime>({
		on: "later",
		time: value || newDate(),
	});

	useLayoutEffect(() => {
		setBaseValue({
			on: value ? "later" : "now",
			time: value || newDate(),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useLayoutEffect(() => {
		setNowLater(Boolean(value));
		setInputValue((prev) => ({
			...prev,
			time: value || newDate(),
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	const onClick = useCallback(() => {
		if (!disabled) {
			setDisplay(true);
			onChangeDisplayModal?.(true);
		}
	}, [disabled, setDisplay, onChangeDisplayModal]);

	const onClose = useCallback(() => {
		setDisplay(false);
		onChangeDisplayModal?.(false);
		setInputValue(BaseValue);
		if (onChange) {
			setInputValue((prev) => ({ ...prev, on: "later" }));
			onChange(value);
		}
	}, [setDisplay, onChangeDisplayModal, BaseValue, onChange, value]);

	useEffect(() => {
		if (disabled) setDisplay(false);
	}, [disabled, setDisplay]);

	const onSubmit = useCallback(() => {
		if (onChange) {
			if (inputValue.on === "now") {
				onChange(null);
				setInputValue((prev) => ({ ...prev, on: "later" }));
			}
			if (inputValue.on === "later") onChange(inputValue.time);
		}
		setDisplay(false);
		onChangeDisplayModal?.(false);
	}, [
		inputValue.on,
		inputValue.time,
		setInputValue,
		onChange,
		onChangeDisplayModal,
		setDisplay,
	]);

	const styles = useMemo(
		() => ({
			arrow: {
				w: "16px",
				h: "16px",
				colors: disabled ? "#B5C0C7" : "#5e6b73",
				cursor: disabled ? "not-allowed" : "pointer",
			},
			time: {
				cursor: disabled ? "not-allowed" : "pointer",
				colors: disabled ? "#9BA3A8" : "#21333F",
				over: "hidden",
			},
		}),
		[disabled],
	);

	const time = useMemo(
		() =>
			(inputValue.on === "later" && !nowLater) || inputValue.on === "now"
				? t([`orderPage.order_form.order_time.now`, "Not now"])
				: moment(inputValue.time).format(`HH:mm DD/MM/yyyy`),
		[inputValue.on, inputValue.time, nowLater, t],
	);

	return (
		<Wrap w={width} bgC={`#F6F6F6`} h="378px" onClick={onClick}>
			{display && (
				<CardModal
					title={
						t(`orderPage.order_form.order_time.time_select`) ?? ""
					}
					onClose={onClose}
					onSave={onSubmit}
				>
					<TimeSelectMemo
						value={inputValue}
						setValue={setInputValue}
						onSubmit={onSubmit}
						onClose={onClose}
					/>
				</CardModal>
			)}

			<StyledP {...styles.time} text={{ tt: "none", ws: "nowrap" }}>
				{time}
			</StyledP>
			<StyledWrapIcon {...styles.arrow}>
				<Icon id="arrow-select-down" size={16} />
			</StyledWrapIcon>
		</Wrap>
	);
};

declare namespace OrderTimeSelect {
	interface Props {
		width?: string;
		value: Date | null;
		onChange?: (value: Date | null) => void;
		onChangeDisplayModal?: (displayModal?: boolean) => void;
		displayModal?: boolean;
		disabled?: boolean;
	}
}

export const OrderTimeSelectMemo = memo(OrderTimeSelect);
export default OrderTimeSelect;
