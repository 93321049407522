import React from "react";
import Root from "./components/Root";

const Header: React.FC<Header.Props> = ({ label }) => <Root>{label}</Root>;

declare namespace Header {
	interface Props {
		label: string;
	}
}

export default Header;
