/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextSelect } from "uikit";

import Executor from "../../../../../../../services/Executor";
import useModelSubscribe from "../../../../../../../hooks/useModelSubscribe2";
import LabeledField from "../../../../../../LabeledField";
import { getExecutorFullName } from "../../utils";

const SearchExecutor: React.FC<SearchExecutor.Props> = ({
	alias,
	handleSelect,
	selectedId,
	isEdit,
	isAliasWasSelected,
	setAlias,
}) => {
	const { t } = useTranslation();
	const [firstOpenModal, setFirstOpenModal] = useState<boolean>(true);
	const [isFoundExecutor, setIsFoundExecutor] = useState<boolean>(true);
	const [isCheck, setIsCheck] = useState<boolean>(false);

	const modelSearchAlias = useMemo<Executor.SubscribeOptions>(() => {
		const result: Executor.SubscribeOptions = {
			query: alias,
		};

		return result;
	}, [alias]);
	const searchAliasModelData = useModelSubscribe(modelSearchAlias, Executor);

	const searchAliasModelItems = useMemo(
		() => searchAliasModelData.models ?? [],
		[searchAliasModelData.models],
	);

	const executorOptions = useMemo(() => {
		// eslint-disable-next-line array-callback-return, consistent-return
		const options = searchAliasModelItems?.map((tip, index) => {
			const labelString = getExecutorFullName(tip);
			return {
				key: tip.id,
				label: labelString,
				value: tip,
			};
		});

		const filtredOptions = options.filter((option) =>
			option.value.alias.includes(alias),
		);

		return filtredOptions;
	}, [alias, searchAliasModelData.models]);

	useEffect(() => {
		if (firstOpenModal && !searchAliasModelItems.length) {
			setIsFoundExecutor(true);
		}
		if (searchAliasModelItems.length) setFirstOpenModal(false);
	}, [searchAliasModelItems]);

	useEffect(() => {
		const value = alias.trim();
		if (value.length >= 1) {
			if (firstOpenModal || searchAliasModelItems.length >= 1) {
				setIsFoundExecutor(true);
			}
		} else {
			setIsFoundExecutor(false);
		}
	}, [alias, searchAliasModelItems]);

	useEffect(() => {
		if (!isCheck && executorOptions?.length) {
			const exist = executorOptions.find(
				(item) => item?.key === selectedId,
			);
			if (exist) {
				handleSelect(exist);
				setAlias(exist.label);
			}
			setIsCheck(true);
		}
	}, [isCheck, executorOptions]);

	return (
		<LabeledField
			label={
				t(
					"orderPageWidgets.executors.executorModal.searchExecutor.str0",
				) ?? ""
			}
		>
			<TextSelect
				placeholder={
					t(
						"orderPageWidgets.executors.executorModal.searchExecutor.str0",
					) ?? ""
				}
				style={{
					minHeight: "32px",
					flex: "1 0 0",
				}}
				value={alias}
				onChange={(value) => {
					setAlias(value);
				}}
				error={!isAliasWasSelected || !isFoundExecutor}
				onSelect={(option) => {
					handleSelect(option);
					setIsFoundExecutor(true);
				}}
				focused
				closeOnSelect
				options={alias ? executorOptions : []}
			/>
		</LabeledField>
	);
};

declare namespace SearchExecutor {
	interface Props {
		alias: string;
		isEdit: boolean;
		isAliasWasSelected: boolean;
		selectedId: number | null;
		setAlias: React.Dispatch<React.SetStateAction<string>>;
		handleSelect: (option) => void;
	}

	type Option = {
		key: number;
		label: string;
		value: {
			id: number;
			alias: string;
		};
	};
}

export default SearchExecutor;
