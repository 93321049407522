import React, { useCallback } from "react";
import { Option, useInternal, Key } from "uikit";

import Content from "./components/Content";
import { CardModal, CardModalBase } from "../../../common/Modal";

const SelectModal = <OptionValue, ValueType extends Key>({
	value,
	options,
	onChange,
	onSubmit,
	pullUpItemInsideArray,
	...props
}: SelectModal.Props<OptionValue, ValueType>) => {
	const [internalValue, setInternalValue] = useInternal(value);

	const contentOnChange = useCallback(
		(contentValue: Content.Value<ValueType>) => {
			setInternalValue(contentValue);
			onChange?.(contentValue);
		},
		[onChange, setInternalValue],
	);

	const onSave = useCallback<CardModalBase.Props["onSave"]>(
		(event) => {
			onSubmit?.(internalValue, event);
		},
		[internalValue, onSubmit],
	);

	return (
		<CardModal {...props} onSave={onSave}>
			<Content
				value={internalValue}
				options={options ?? []}
				onChange={contentOnChange}
				onSave={onSave}
				pullUpItemInsideArray={pullUpItemInsideArray}
			/>
		</CardModal>
	);
};

declare namespace SelectModal {
	type Value<Type extends Key> = Type | undefined;

	interface Props<OptionValue, ValueType extends Key>
		extends Omit<CardModalBase.Props, "onSave"> {
		pullUpItemInsideArray?: boolean;
		value?: Value<ValueType>;
		options?: Option<OptionValue>[];
		onChange?: (value: Value<ValueType>) => void;
		onSubmit: (data: Value<ValueType>, event?: CardModalBase.Event) => void;
	}
}

export default SelectModal;
