import styled from "styled-components";
import { theme } from "uikit";

const Root = styled.div`
	flex-grow: 1;
	position: relative;
	background-color: #dbe0e7;
	padding: 10px;

	& > .dock-layout {
		position: absolute;

		left: 10px;
		top: 10px;
		right: 10px;
		bottom: 10px;

		& > .dock-drop-indicator {
			z-index: 500;
		}

		.dock-drop-layer .dock-drop-square {
			z-index: 1000;
		}

		.dock-box {
			.dock-divider {
				flex-basis: 10px;
			}

			&.dock-fbox {
				z-index: 500;
			}
		}

		.dock-panel {
			border-radius: 5px;

			overflow: hidden;

			.dock-panel-drag-size {
				z-index: 1001;
			}

			&.dock-style-static {
				.dock-bar {
					display: none;
				}
			}

			&.dock-style-default {
				.dock-bar {
					padding: 4px 4px 0;

					background: ${theme.colors.button_secondary};

					border: none;

					.dock-nav {
						.dock-ink-bar {
							display: none;
						}

						.dock-tab {
							height: 30px;

							margin: 0;

							background: transparent;

							border: none;

							&.dock-tab-active {
								background: ${theme.colors.white};

								border-radius: 5px 5px 0 0;

								.dock-tab-btn .drag-initiator {
									color: ${theme.colors.accent};

									.dock-tab-close-btn:hover {
										color: ${theme.colors.secondary};
									}
								}
							}

							.dock-tab-btn {
								padding: 0;

								.drag-initiator {
									display: flex;

									flex-direction: row;
									flex-wrap: nowrap;

									gap: 6px;

									align-items: center;

									padding: 6px 4px 6px 11px;

									border-top: 2px solid transparent;

									font-family: "Lato";
									font-style: normal;
									font-weight: 600;
									font-size: 13px;
									line-height: 16px;

									color: ${theme.colors.secondary};

									.dock-tab-close-btn {
										position: unset;

										color: transparent;

										&:hover {
											color: ${theme.colors.secondary};
											transform: none;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

export default Root;
