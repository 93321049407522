import React, { useMemo } from "react";
import { isNumber } from "lodash";

import ExecutorReport from "../../../../../../../../../../services/ExecutorReport";
import formatNumber from "../../../../../../../../../../utils/formatNumber";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";
import Language from "../../../../../../../../../../services/Language";

const Constant: Record<Language, string> = {
	ru: "Абонентская плата",
	uk: "Абонентська плата",
	en: "Subscription fee",
	tr: "Subscription fee",
	az: "Subscription fee",
};

const FeeCellContent: React.FC<FeeCellContent.Props> = ({ item }) => {
	const content = useMemo(() => {
		if (!item || !item.executorRateColumns) return "";
		const exist = item.executorRateColumns.find(
			(data) => data.name?.en === Constant.en,
		);

		if (!exist) return "";
		if (!isNumber(exist?.amount)) return "";

		return formatNumber(exist?.amount);
	}, [item]);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace FeeCellContent {
	interface Props {
		item: ExecutorReport.Model;
		language: Language;
	}
}

export default FeeCellContent;
