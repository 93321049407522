import {
	Dispatch,
	SetStateAction,
	useCallback,
	useMemo,
	useState,
} from "react";

import { archiveAction as archive } from "../../../../../redux/reducers/Archives";
import { Archive } from "../../../../../redux/reducers/Archives/interface";
import Order from "../../../../../services/Order";
import {
	Schema,
	prepareOrderByForm,
} from "../../../../../redux/constants/OrdersPage/order";
import { useTypedDispatch } from "../../../../../redux/store";
import useArchiveOrders from "./useArchiveOrders";
import useArchiveSwisher from "./useArchiveSwitcher";
import calcRoute from "../../../../../redux/services/Map/calcRoute";
import { revertOrder } from "../../../../../redux/services/Order/revertOrder";

type UseOrderUpdate = {
	loading: boolean;
	order: Order.Model | null;
	update: () => void;
	setRouteToActiveOrder: (data: Archive.Orders.Route) => void;
	setParams: Dispatch<SetStateAction<Schema | null>>;
	addParams: (schema: Schema, passenger?: number) => void;
	setDataToOrder: (
		schema: Schema,
		isAdd?: boolean,
		passenger?: number,
	) => void;
	revertActiveOrder: (id: number) => Promise<void>;
};

/**
 *  `useArchiveOrderUpdate`
 */
export const useOrderUpdate = (): UseOrderUpdate => {
	const dispatch = useTypedDispatch();
	const {
		setOrderLoading,
		orderLoading: { isOrder },
	} = useArchiveSwisher();
	const {
		orders: { order, activeOrderId },
	} = useArchiveOrders();

	const [params, setParams] = useState<Schema | null>(null);

	const addParams = useCallback<UseOrderUpdate["addParams"]>(
		(schema) => {
			if (!order || !activeOrderId) return;
			setParams(schema);
		},
		[activeOrderId, order],
	);

	const setRouteToActiveOrder = useCallback<
		UseOrderUpdate["setRouteToActiveOrder"]
	>(
		(data: Archive.Orders.Route) => {
			dispatch(archive.orders.orders.setRouteToActiveOrder(data));
		},
		[dispatch],
	);

	const revertActiveOrder = useCallback<UseOrderUpdate["revertActiveOrder"]>(
		async (id) => {
			if (!order || !activeOrderId) return;
			if (order.status !== "closed") return;
			if (order?.executingStage && !order?.closedAt) return;
			if (!order?.executorToOrder?.length) return;
			dispatch(revertOrder({ id: id || activeOrderId }));
			dispatch(
				archive.orders.orders.removeOrder({ id: id || activeOrderId }),
			);
			dispatch(archive.orders.orders.setOrder(null));
		},
		[dispatch, order, activeOrderId],
	);

	const setDataToOrder = useCallback<UseOrderUpdate["setDataToOrder"]>(
		(schema, isAdd = true, passenger = 0) => {
			const points = schema.points.map((item) => item?.coordinates);
			const passengerPoints = schema.points?.map((data) => ({
				feature: data,
				coordinates: data.coordinates,
			}));

			dispatch(
				archive.orders.orders.setPointsToActiveOrder({
					passenger,
					points: passengerPoints,
				}),
			);
			dispatch(
				calcRoute(points, (result) => {
					if (result) setRouteToActiveOrder(result);
				}),
			);
			if (isAdd) addParams(schema);
		},
		[dispatch, addParams, setRouteToActiveOrder],
	);

	const update = useCallback<UseOrderUpdate["update"]>(async () => {
		if (!params || !order) return;
		setOrderLoading(true);
		try {
			const passenger = order?.passengers.at(0);
			const payload: Order.Model.Modified = prepareOrderByForm(
				params,
				passenger?.customer?.id,
			);

			await Order.update({ ...payload, id: order.id });
			setOrderLoading(false);
		} catch (error) {
			setOrderLoading(false);
		}
	}, [params, order, setOrderLoading]);

	return useMemo<UseOrderUpdate>(
		() => ({
			update,
			order,
			loading: isOrder,
			setParams,
			addParams,
			setDataToOrder,
			setRouteToActiveOrder,
			revertActiveOrder,
		}),
		[
			update,
			order,
			isOrder,
			addParams,
			setParams,
			setDataToOrder,
			setRouteToActiveOrder,
			revertActiveOrder,
		],
	);
};

export default useOrderUpdate;
