/* eslint-disable no-bitwise */

import { LatLngLiteral } from "leaflet";

type LatLngTuple = [number, number];
const ENCODE_PRECISION = 5;

/**
 * Decodes an encoded path string into a sequence of LatLngs.
 *
 */
const decode = function decodeEncodedPath(
	encodedPath: string,
	precision = ENCODE_PRECISION,
): LatLngTuple[] {
	const factor = 10 ** precision;

	const len = encodedPath.length;

	const path = new Array(Math.floor(encodedPath.length / 2));
	let index = 0;
	let lat = 0;
	let lng = 0;
	let pointIndex = 0;

	for (; index < len; ++pointIndex) {
		let result = 1;
		let shift = 0;
		let b: number;
		do {
			b = encodedPath.charCodeAt(index++) - 63 - 1;
			result += b << shift;
			shift += 5;
		} while (b >= 0x1f);
		lat += result & 1 ? ~(result >> 1) : result >> 1;

		result = 1;
		shift = 0;
		do {
			b = encodedPath.charCodeAt(index++) - 63 - 1;
			result += b << shift;
			shift += 5;
		} while (b >= 0x1f);
		lng += result & 1 ? ~(result >> 1) : result >> 1;

		path[pointIndex] = [lat / factor, lng / factor];
	}
	// truncate array
	path.length = pointIndex;

	return path;
};

const decodeToPoint = (encodedPath: string) => {
	const points = decode(encodedPath, ENCODE_PRECISION);
	return points.map((point) => ({ lat: point[0], lng: point[1] }));
};

export const isCompressed = (route: any) => {
	if (!route) return false;
	return typeof route?.points === "string";
};

interface PriceZoneSegments extends Record<string, any> {
	distance: number;
	inside: boolean;
	polygon: Record<string, any> & {
		suburban: boolean;
	};
}

/**
 * Decompress the distance outside price zones from backend.
 */
export const decompressDistanceOutSidePriceZones = (
	data: PriceZoneSegments[],
): number => {
	if (!data || !data.length) return 0;

	const distance = [...data]
		.map((segment) => ({
			distance: segment?.distance || 0,
			suburban: !segment.inside || segment.polygon?.suburban,
		}))
		.filter((item) => item.suburban)
		.reduce((acc, item) => acc + item.distance, 0);

	return distance;
};

export interface DecompressedRoute {
	distance: number;
	points: LatLngLiteral[];
	segments: LatLngLiteral[][];
	sectors: any[];
	priceZones: any[];
	priceZoneSegments: PriceZoneSegments[];
	suburbanPriceZones: number;
}

/**
 * Decompress the route from backend.
 * By default, we get a compressed string.
 */
export const decompressRoute = (route: any): DecompressedRoute => {
	if (!route)
		return {
			distance: 0,
			suburbanPriceZones: 0,
			points: [],
			segments: [],
			sectors: [],
			priceZones: [],
			priceZoneSegments: [],
		};

	if (isCompressed(route)) {
		const priceZoneSegments = route.priceZoneSegments.map((segment) => ({
			...segment,
			points: decodeToPoint(segment.points),
		}));

		return {
			distance: route?.distance,
			points: decodeToPoint(route?.points),
			segments: route?.segments?.map(decodeToPoint),
			sectors: route?.sectors,
			priceZones: route?.priceZones,
			priceZoneSegments,
			suburbanPriceZones:
				decompressDistanceOutSidePriceZones(priceZoneSegments),
		};
	}

	return route;
};
