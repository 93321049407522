import { compact, uniq } from "lodash";
import * as yup from "yup";
import { LatLngLiteral } from "leaflet";

import Order from "../../../services/Order";
import Language from "../../../services/Language";
import Reward from "../../../services/Reward";
import {
	DiscountPlan,
	ValueClientPhoneType,
} from "../../services/Order/getPhoneInfo";
import { Archive } from "../../reducers/Archives/interface";
import { useCreateForm } from "../../../hooks/useCreateForm";
import getValidCityName from "../../../utils/getValidCityName";
import { Bonus } from "../../../pages/MainPage/pages/Orders/components/OrderModal/types/bonuses";
import { Scaling } from "../../../pages/MainPage/pages/Orders/components/OrderModal/types/scaling";
import { PaymentMethod } from "../../../pages/MainPage/pages/Orders/components/OrderModal/types/paymentMethods";
import { PaymentParam } from "../../../pages/MainPage/pages/Orders/components/OrderModal/types/paymentParams";
import { Service } from "../../../pages/MainPage/pages/Orders/components/OrderModal/types/services";
import { TaxiClass } from "../../../pages/MainPage/pages/Orders/components/OrderModal/types/taxiClass";
import { TransportationType } from "../../../pages/MainPage/pages/Orders/components/OrderModal/types/transportationTypes";
import { OrderCostRequest } from "../../../pages/MainPage/pages/Orders/components/OrderModal/hooks/useCalcOrderCost";
import {
	isCompressed,
	decompressRoute,
} from "../../../utils/decompressRoute.util";

export enum RoundingMethod {
	GENERAL = "general",
	CEIL = "ceil",
	FLOOR = "floor",
}

export const maxAdditionalPhoneCount = 2;

export enum ExecuteOrderStage {
	ASSIGNED = "assigned",
	ACCEPTED = "accepted",
	DELAYED = "delayed",
	ARRIVED = "arrived",
	WAITING_CLIENT = "waiting_client",
	WITH_CLIENTS = "with_clients",
	FAILED = "failed",
	EXECUTED = "executed",
}

export enum OrderPaymentStatus {
	CLOSED = "closed",
	CARD_VALIDATION = "card_validation", // orange
	CARD_VALIDATION_FAILED = "card_validation_failed", // red
	CARD_VALIDATION_SUCCESS = "card_validation_success", // green

	PAYMENT_PART = "payment_part", // pink
	PAYMENT_SUCCESS = "payment_success", // green
	PAYMENT_PENDING = "payment_pending", // orange
	PAYMENT_FAILED = "payment_failed", // red

	REFUND_SUCCESS = "refund_success",
	REFUND_PENDING = "refund_pending",
	REFUND_FAILED = "refund_failed",
}
export enum ThresholdPaymentStatus {
	THRESHOLD_PENDING = "threshold_pending",
	THRESHOLD_FAILED = "threshold_failed",
	THRESHOLD_SUCCESS = "threshold_success",
}

export enum CheckPaymentProcess {
	PAY_CHECK_MAIN_UNLIMITED = "check_pay_main_unlimited",
	PAY_CHECK_MAIN_THRESHOLD = "check_pay_main_threshold",
	PAY_CHECK_MAIN_THRESHOLD_BONUS = "check_pay_main_threshold_bonus",
	PAY_CHECK_MAIN_THRESHOLD_GENERAL_BONUS = "pay_check_main_threshold_general_bonus",
	PAY_CHECK_MAIN_GENERAL_THRESHOLD = "check_pay_main_general_threshold",
	PAY_CHECK_MAIN_GENERAL_THRESHOLD_BONUS = "check_pay_main_general_threshold_bonus",
	PAY_CHECK_MAIN_GENERAL_THRESHOLD_GENERAL_BONUS = "check_pay_main_general_threshold_general_bonus",
	PAY_GENERAL_MAIN_UNLIMITED = "general_pay_main_unlimited",
	PAY_GENERAL_MAIN_THRESHOLD = "general_pay_main_threshold",
	PAY_GENERAL_MAIN_THRESHOLD_BONUS = "general_pay_main_threshold_bonus",
	PAY_GENERAL_MAIN_GENERAL_THRESHOLD = "general_pay_main_threshold",
	PAY_GENERAL_MAIN_THRESHOLD_GENERAL_BONUS = "general_pay_main_threshold_bonus",
	PAY_GENERAL_MAIN_GENERAL_THRESHOLD_BONUS = "general_pay_main_general_threshold_bonus",
	PAY_GENERAL_MAIN_GENERAL_THRESHOLD_GENERAL_BONUS = "general_pay_main_threshold_bonus",
}
export interface IOrderPoint {
	type: string;
	country: string;
	countryCode: string;
	settlement: string;
	settlementType: string;
	district: string;
	region: string;
	coordinates: LatLngLiteral;
	sector?: {
		id: number;
		name: Record<Language, string>;
	};

	flat: string;
	entrance: string;

	// house
	number?: string;
	house: string;
	customHouse?: string; // custom house name given by the user

	// street
	streetType: string;
	street: string;
	customStreet?: string; // custom street name given by the user
	name: string;
	title?: string;
}

export interface OrderPaymentAccount {
	id: number;
	amount: number;
	type: "main" | "bonus" | "threshold";
	currency?: string;
}

export interface OrderCompanyBillMetadata {
	status: ThresholdPaymentStatus;
	orderId: number;
	threshold: number;
	missingAmount: number;
	paymentAccountAmount: number;
	paymentProcess: CheckPaymentProcess;
	previousCalculationData: OrderCostRequest;
	tabs: {
		live: { amount: number; orders: number };
		executable: { amount: number; orders: number };
		preliminary: { amount: number; orders: number };
	};
}

export interface OrderCounterparty {
	id?: number;
	checkCardId?: number;
	paymentAccountId?: number;

	name: string;
	amount?: number;
	type?: "main" | "bonus";
	currency?: string;
}

export interface OrderAgent {
	id: number;
	reward: Reward.Model | null;
	rewards: Reward.Model[];
	name: string;
	checkCardId: number;
	paymentAccounts: OrderPaymentAccount[];
	isRewardAllowed: boolean;
	isArbitraryRewardAllowed: boolean;
}

export interface Schema {
	id?: number;
	points: IOrderPoint[];
	arrivalTime?: Date;
	orderDate?: Date;
	orderNumber?: number;
	orderType?: any;

	createdAt: number;

	rideNumber: number;
	passengersCount: number;

	totalRideCount: number;
	counterRideTotal: number;
	counterRideCancelled: number;
	counterRideFailed: number;
	counterRideSuccess: number;
	city?: string;

	transportationType: TransportationType;
	fullName?: string;

	firstAddress?: string;
	lastAddress?: string;
	createDateOrder?: string | number;
	createTimeOrder?: string | number;

	billingCustomer?: number | Record<string, unknown>;
	phone?: string;
	additionalPhones: string[];
	confirmationMethod?: "by_code";
	code?: string;
	cardCounterpart?: string;
	displaySettings?: Order.IAdditionalFields["displaySettings"];

	clientNote?: string;
	driverNote?: string;

	rateId?: number;
	/** `order.isPreliminary` or `order.orderDate` */
	scheduledTime?: number;
	paymentMethod?: PaymentMethod;
	params?: PaymentParam;
	taxiClass?: TaxiClass;
	services?: Service[];

	bonus?: Bonus;
	accumulativeDiscountToUse?: number;

	rideBonus?: number;
	rideBonusPercent?: number;

	hasExtraCharge?: boolean;
	extraCharge?: number;
	extraChargeScaling?: Scaling;

	rateIncludePickupCost?: boolean;
	isIntercityRoadTrip?: boolean;
	isAirwaves?: boolean;
	isAroundTown?: boolean;
	isTaximeter?: boolean;
	isTerminal?: boolean;
	taximeter?: boolean;
	enableHourlyService?: boolean;
	includePickupMargin?: boolean;

	hasCompensation?: boolean;
	compensationToWhom?: "customer" | "taxiService";
	compensationAmount?: number;
	compensationScaling?: Scaling;

	hasDiscount?: boolean;

	doTopUpPhone?: boolean;
	phoneTopUpAmount?: number;

	discount?: Archive.Orders.Discount;
	discountCost?: Archive.Orders.DiscountCost;
	additionalPlan?: DiscountPlan;
	mainPlan?: DiscountPlan;

	price?: number;
	priceRaw?: number;
	priceSettings?: {
		rounding: {
			method: RoundingMethod;
			multiple: {
				active: boolean;
				value: number;
			};
			precision: number;
		};
	};

	suburbanDistanceOverride: number;
	suburbanDistanceOverrideState: boolean;

	arbitraryCost?: number;
	additionalCost?: number;

	hourly?: number;
	companyID?: number;
	taxiServiceId?: number;

	car?: string;

	/** order.cost.distances.total in meters (Distance) */
	distance?: number;
	/** order.cost.distances.suburban in meters (Including suburban) */
	suburban?: number;

	agent?: OrderAgent;
	agentBonusPaymentAccount?: OrderPaymentAccount;
	agentMainPaymentAccount?: OrderPaymentAccount;
	agentRewardAddToPrice?: number;

	counterparty?: OrderCounterparty;
	counterpartyBonusPaymentAccount?: OrderPaymentAccount;
	counterpartyMainPaymentAccount?: OrderPaymentAccount;

	paymentAccount?: OrderPaymentAccount;

	bonusPaymentAccount?: OrderPaymentAccount;
	mainPaymentAccount?: OrderPaymentAccount;
	paymentType?: Archive.ValueOrderPayment;
	paymentChangeType?: Archive.ValueOrderPaymentChangeType;
	phoneType?: ValueClientPhoneType;
	companyBillMetadata?: OrderCompanyBillMetadata | null;
	paymentStatus?: {
		status: OrderPaymentStatus;
		timestamp: number;
	} | null;
	status?: Archive.ValueOrderStatus;
	source?: Archive.ValueOrderSource;

	customerName: string;
	customerSurname: string;
	customerFatherName: string;
	customerBirthday: Date | null | undefined;
	customerCarClassId: number | null | undefined;
	customerCarClassName: string;
	customerNotes: string;

	executorName: string;
	executorCarName: string;
	executorCarNumber: string;
	executorPhone: string;
	executorPhones: string[];
	executorSip: string;
	orderSave: boolean;
	orderPointSave: boolean;

	downtimesCurrent: number | null;
	downtimesTotal: number;
	downtimesArriving: number;
	/** The waiting time in milliseconds from executors */
	downtimesWaitingClient: number;
	/** The waiting time in milliseconds */
	waitingTime?: number;
	/** Idle time in milliseconds from executors */
	downtimesWithClients: number;
	/** Idle time in milliseconds */
	idleTime?: number;

	currency?: {
		name?: Record<Language, string>;
		settings?: {
			code: string;
			name: string;
			symbol: string;
		};
	};

	clearPhone?: boolean;
	clearAgent?: boolean;
	clearCounterparty?: boolean;
}

const schema = {
	createdAt: yup.number().required(),
	rideNumber: yup.number().positive().integer().required(),
	totalRideCount: yup.number().positive().integer().required(),
	city: yup.string(),
	cardCounterpart: yup.string(),

	transportationType: yup.string().required(),
	fullName: yup.string(),

	phone: yup.number().required(),
	additionalPhones: yup
		.array()
		.of(yup.string().required())
		.max(2)
		.required()
		.default([]),
	confirmationMethod: yup.string(),
	code: yup.string(),

	clientNote: yup.string(),
	driverNote: yup.string(),

	scheduledTime: yup.number().required(),
	paymentMethod: yup.string(),
	tariff: yup.string(),
	params: yup.string(),
	taxiClass: yup.string(),
	services: yup.array().of(yup.string().required()).required(),

	bonus: yup.string(),
	accumulativeDiscountToUse: yup.number(),
	rideBonus: yup.number(),
	rideBonusPercent: yup.number(),
	hasExtraCharge: yup.bool(),
	extraCharge: yup.number(),
	extraChargeScaling: yup.string(),
	hasCompensation: yup.bool(),
	compensationToWhom: yup.string(),
	compensationAmount: yup.number(),
	compensationScaling: yup.string(),
	hasDiscount: yup.bool(),
	discount: yup.number(),
	doTopUpPhone: yup.bool(),
	phoneTopUpAmount: yup.number(),
	taxiServiceId: yup.number(),

	rateIncludePickupCost: yup.bool(),
	isIntercityRoadTrip: yup.bool(),

	price: yup.number(),
	outOfTown: yup.number(),
	idleTime: yup.number(),
	additionalCost: yup.number(),
	hourly: yup.number(),
	car: yup.string(),
	distance: yup.number(),
	paymentType: yup.string(),
	status: yup.string(),
	source: yup.string(),

	customerName: yup.string(),
	customerSurname: yup.string(),
	customerFatherName: yup.string(),
	customerNotes: yup.string(),
};

export const emptySchema: Schema = {
	createdAt: 0,
	rideNumber: 0,
	suburbanDistanceOverride: 0,
	suburbanDistanceOverrideState: false,
	price: 0,
	additionalCost: 0,
	hourly: 0,

	totalRideCount: 0,
	counterRideTotal: 0,
	counterRideCancelled: 0,
	counterRideFailed: 0,
	counterRideSuccess: 0,

	scheduledTime: 0,
	passengersCount: 1,
	transportationType: "passenger",
	additionalPhones: [],

	bonus: "bonus",
	compensationToWhom: "customer",
	phone: "",

	points: [],
	paymentType: Archive.ConstantOrderPayment.CASH,
	// Order ride information
	rateIncludePickupCost: false,
	isIntercityRoadTrip: false,
	enableHourlyService: false,
	includePickupMargin: false,
	isAirwaves: false,
	isAroundTown: false,
	isTaximeter: false,
	isTerminal: false,
	taximeter: false,
	cardCounterpart: "",

	customerName: "",
	customerSurname: "",
	customerFatherName: "",
	customerBirthday: null,
	customerCarClassId: null,
	customerCarClassName: "",
	customerNotes: "",

	executorName: "",
	executorCarName: "",
	executorPhone: "",
	executorSip: "",
	executorCarNumber: "",
	executorPhones: [],

	orderSave: false,
	orderPointSave: false,

	paymentAccount: undefined,
	bonusPaymentAccount: undefined,
	mainPaymentAccount: undefined,

	counterparty: undefined,
	counterpartyMainPaymentAccount: undefined,
	counterpartyBonusPaymentAccount: undefined,

	agent: undefined,
	agentMainPaymentAccount: undefined,
	agentBonusPaymentAccount: undefined,

	discount: undefined,
	discountCost: undefined,
	additionalPlan: undefined,
	mainPlan: undefined,

	downtimesCurrent: null,
	downtimesTotal: 0,
	downtimesArriving: 0,
	downtimesWaitingClient: 0,
	downtimesWithClients: 0,
	idleTime: 0,
	priceRaw: 0,
	waitingTime: 0,
};

export default function createEmptyOrder(): Schema {
	return { ...emptySchema };
}

export const createOrderFormBy = (
	order: Record<string, any>,
	lang = "ru",
): Schema => {
	const passenger = order?.passengers?.at(0);

	const { additionalFields = {} } = order;
	const DEFAULT_PRICE_SETTINGS = { rounding: { precision: 2 } };
	const schema: Partial<Schema> = {
		// order
		id: order?.id,
		status: order?.status || "",
		source: order?.source || "",
		orderType: order?.type || "",
		orderNumber: order?.orderNumber || 0,
		orderDate: order?.orderDate,
		scheduledTime: order?.isPreliminary ? order?.orderDate : null,
		createdAt: order?.createdAt || 0,
		cardCounterpart: "",
		// cost
		price: order?.cost?.value || 0,
		priceSettings: order?.cost?.settings || DEFAULT_PRICE_SETTINGS,
		companyBillMetadata: order?.cost?.companyBillMetadata || null,
		discount: order?.additionalFields?.discount || undefined,
		discountCost: order?.cost?.discount || undefined,
		additionalPlan: undefined,
		mainPlan: undefined,
		paymentType: order?.paymentType || Archive.ConstantOrderPayment.CARD,
		paymentStatus: order?.paymentStatus || null,
		paymentMethod: order?.cost?.paymentMethod || {
			name: "cash",
			id: 0,
		},
		currency: order?.cost?.currency,
		// passenger
		points: getOrderPassengerPoints(order),
		// passenger counter
		counterRideTotal: passenger?.customer?.counters?.total || 0,
		counterRideCancelled: passenger?.customer?.counters?.cancelled || 0,
		counterRideFailed: passenger?.customer?.counters?.failed || 0,
		counterRideSuccess: passenger?.customer?.counters?.success || 0,
		rideNumber: passenger?.customer?.counters?.total || 0,
		totalRideCount: passenger?.customer?.ordersCount || 0,
		// passenger name
		customerBirthday: passenger?.customer?.person?.birthday || "",
		customerNotes: passenger?.customer?.notes || "",
		// additionalFields
		transportationType: additionalFields?.transportationType || "passenger",
		clientNote: additionalFields?.customerNotes || "",
		driverNote: additionalFields?.executorNotes || "",
		accumulativeDiscountToUse: additionalFields?.accumulativeDiscount || 0,
		rideBonus: additionalFields?.rideBonus?.number || 0,
		rideBonusPercent: additionalFields?.rideBonus?.value || 0,
		doTopUpPhone: additionalFields?.phoneTopUp?.active,
		phoneTopUpAmount: additionalFields?.phoneTopUp?.value || 0,
		additionalCost: additionalFields?.additionalCost || 0,
		arbitraryCost: additionalFields?.arbitraryCost,
		hourly: additionalFields?.hourlyMilliseconds || 0,

		distance: order.cost?.distances?.total || 0,
		suburban: order.cost.distances.suburban || 0,

		suburbanDistanceOverride:
			additionalFields?.suburbanDistanceOverride?.value || 0,
		suburbanDistanceOverrideState:
			additionalFields?.suburbanDistanceOverride?.shrink || false,

		passengersCount: additionalFields?.passengersCount || 1,
		bonus: "bonus",
		isIntercityRoadTrip: false,
		taximeter: additionalFields.taximeter || false,
		displaySettings: additionalFields?.displaySettings,

		downtimesCurrent: order.downtimes.current,
		downtimesTotal: order.downtimes.total,
		downtimesArriving: order.downtimes.arriving,
		downtimesWaitingClient: order.downtimes.waitingClient,
		downtimesWithClients: order.downtimes.withClients,

		idleTime: additionalFields?.idleTimeMilliseconds || 0,
		waitingTime: additionalFields?.waitingTimeMilliseconds || 0,
		counterparty: undefined,
		agent: undefined,
		phoneType: undefined,
	};

	try {
		if (order?.agent) {
			schema.agent = order.agent;

			if (order.agent?.code?.value) {
				schema.code = order.agent?.code?.value;
			}
			if (order.agent.paymentAccounts) {
				const paymentAccounts = [...order.agent.paymentAccounts];
				const main = paymentAccounts.find(
					(item) => item.type === "main",
				);
				const bonus = paymentAccounts.find(
					(item) => item.type === "bonus",
				);

				schema.agentMainPaymentAccount = main;
				schema.agentBonusPaymentAccount = bonus;
			}

			if (order.agentReward) {
				const rewards = compact(
					order.agent?.agentToRewards?.map((item) => item?.reward) ||
						[],
				);

				const agent = {
					...order.agent,
					reward: order.agentReward,
					rewards,
					isArbitraryRewardAllowed:
						order.agent.isArbitraryRewardAllowed,
					isRewardAllowed: order.agent.isRewardAllowed,
				};
				schema.agent = agent;
				schema.agentRewardAddToPrice = order?.cost?.stages?.find(
					(item) => item?.stage === "agent_reward",
				)?.value;
			}
		}

		// taxiService
		if (order?.taxiService) {
			schema.companyID = order.taxiService?.company?.id;
			schema.taxiServiceId = order.taxiService?.id;
		}
		// billingCustomer
		if (order?.billingCustomer) {
			schema.billingCustomer = order.billingCustomer;
		}

		// counterparty
		if (order?.counterparty) {
			schema.code = order?.checkCard?.additionalFields?.cardNumber;
			schema.counterparty = {
				/** The agent or counterparty id */
				id: order.counterparty?.id,
				checkCardId: order?.checkCard?.id,
				//
				name: order.counterparty?.additionalFields?.name || "",
				paymentAccountId: order?.paymentAccount?.id || 0,
				amount: order?.paymentAccount?.amount || 0,
				type: order?.paymentAccount?.type || null,
				currency: order?.paymentAccount?.currency || "",
			};
			schema.counterpartyMainPaymentAccount = order?.paymentAccount;
			schema.counterpartyBonusPaymentAccount = order?.bonusPaymentAccount;
		}

		// paymentAccount
		if (order?.paymentAccount && !order?.counterparty) {
			schema.paymentAccount = {
				id: order?.paymentAccount?.id || 0,
				amount: order?.paymentAccount?.amount || 0,
				type: order?.paymentAccount?.type || null,
				currency: order?.paymentAccount?.currency || "",
			};

			if (order?.paymentAccount?.type === "card") {
				schema.paymentMethod = { name: "card", id: 1 };
			}
		}

		// bonusPaymentAccount
		if (order?.bonusPaymentAccount) {
			schema.bonusPaymentAccount = order?.bonusPaymentAccount;
		}
		// mainPaymentAccount
		if (order?.paymentAccount) {
			schema.mainPaymentAccount = order?.paymentAccount;
		}

		// phone
		if (Array.isArray(order?.phones) && order?.phones.length) {
			try {
				const sortPhone: Order.Phone[] = [...order.phones].sort(
					(a, b) => a.group - b.group,
				);
				schema.phone = sortPhone.at(0)?.number || "";
				sortPhone.shift();
				schema.additionalPhones = sortPhone.map(
					(phone) => phone.number,
				);
			} catch (error) {
				if (error instanceof Error) {
					// eslint-disable-next-line no-console
					console.log("[Error]:", error.message);
				}
			}
		}

		if (order?.orderToCarClasses && order?.orderToCarClasses.length !== 0) {
			schema.taxiClass = order.orderToCarClasses?.map((item) => ({
				value: item.carClass?.id,
				name: item.carClass?.name?.[lang],
			}));
		}
		// customer
		if (passenger?.customer) {
			const name = passenger?.customer?.person?.name || "";
			const surname = passenger?.customer?.person?.surname || "";
			const fatherName = passenger?.customer?.person?.fatherName || "";

			schema.customerName = name;
			schema.customerSurname = surname;
			schema.customerFatherName = fatherName;
			schema.fullName = `${surname || ""} ${name || ""} ${
				fatherName || ""
			}`.trim();
		}
		if (passenger?.customer?.carClass?.id) {
			const taxiClass = {
				value: passenger.customer.carClass.id,
				name: passenger.customer.carClass?.name?.[lang],
			};

			schema.customerCarClassId = taxiClass.value;
			schema.customerCarClassName = taxiClass.name;

			if (schema.status === "creating") {
				if (schema.taxiClass && schema.taxiClass.length) {
					const exist = schema.taxiClass?.find(
						(item) => item.value === taxiClass.value,
					);
					if (!exist) schema.taxiClass?.push(taxiClass);
				} else {
					schema.taxiClass = [taxiClass];
				}
			}
		}

		if (order?.orderToServices && order?.orderToServices?.length !== 0) {
			schema.services =
				order.orderToServices?.map((item) => ({
					value: item?.service?.id,
					name: item?.service?.name?.[lang] || "",
				})) || [];
		}

		if (order?.rate) {
			schema.rateId = order.rate?.id;
		}
		// additionalFields rateSettings
		if (order.additionalFields?.rateSettings) {
			const {
				includePickupCost,
				isRoundTrip,
				enableHourlyService,
				includePickupMargin,
			} = order.additionalFields.rateSettings;
			schema.rateIncludePickupCost = includePickupCost;
			schema.isIntercityRoadTrip = isRoundTrip;
			schema.includePickupMargin = includePickupMargin;
			schema.enableHourlyService = enableHourlyService;
		}

		if (schema.points?.length) {
			const [firstPoint] = schema.points;
			schema.city = firstPoint?.settlement;
		}
		// car
		if (order?.carToOrder?.length) {
			const carToOrder = order.carToOrder?.at(0);
			const { car } = carToOrder;
			if (car) {
				const number = car?.additionalFields?.registrationNumber || "";
				const model = car?.modelToBodyType?.model?.name || "";
				const brand =
					car?.modelToBodyType?.model?.baseTypeToBrand?.brand?.name ||
					"";
				const color = car?.color?.name?.[lang] || "";
				const label = `${brand} ${model} ${
					number ? `(${number})` : ""
				}, ${color}`;

				schema.executorCarName = label;
				schema.executorCarNumber = number;
			}
		}
		// executor
		if (order?.executorToOrder?.length) {
			const executorToOrder = order?.executorToOrder?.at(0);

			if (executorToOrder?.additionalFields?.arrivalTime) {
				schema.arrivalTime =
					executorToOrder?.additionalFields?.arrivalTime;
			}

			if (executorToOrder) {
				const { executor } = executorToOrder;

				const executorPhones =
					executorToOrder?.executor?.person?.phones?.map(
						(phone) => phone.number,
					) || [];
				schema.executorPhone = executorPhones?.[0];
				schema.executorPhones = executorPhones;

				if (executor) {
					schema.executorName = executor?.person?.name;
					schema.executorSip = executor.callSign;
				}
			}
		}

		if (order?.checkCard?.code?.value) {
			schema.code = order?.checkCard?.code?.value;
		}

		return schema as Schema;
	} catch (error) {
		console.log("[OrderModal] Error createOrderFormBy", { error, order });
		return schema as Schema;
	}
};

export const crateAdditionalFields = (
	form: Schema,
): Order.IAdditionalFields => ({
	rateSettings: {
		includePickupCost: Boolean(form.rateIncludePickupCost),
		isRoundTrip: Boolean(form.isIntercityRoadTrip),
		enableHourlyService: Boolean(form.enableHourlyService),
		includePickupMargin: Boolean(form.includePickupMargin),
	},
	passengersCount: form.passengersCount || 1,
	customerNotes: form.clientNote || "",
	executorNotes: form.driverNote || "",
	additionalCost: form.additionalCost || 0,
	arbitraryCost: form.arbitraryCost,
	hourlyMilliseconds: form.hourly || 0,
	idleTimeMilliseconds: form.idleTime || 0,
	waitingTimeMilliseconds: form.waitingTime || 0,
	suburbanDistanceOverride: {
		value: form?.suburbanDistanceOverride || 0,
		shrink: form?.suburbanDistanceOverrideState || false,
	},
	transportationType: form.transportationType,
	displaySettings: {
		color: form.displaySettings?.color,
		showCustomerPhone: form.displaySettings?.showCustomerPhone,
	},
	discount: form?.discount,
	taximeter: form?.taximeter || false,
});

export const prepareOrderByForm = (form: Schema, customerId?: number): any => {
	const additionalFields = crateAdditionalFields(form);

	const newDateFn = (value?: number | string | Date) => {
		const time = value ? new Date(value) : new Date();
		time.setSeconds(0, 0);
		return time;
	};

	const personPhones = [form.phone, ...(form.additionalPhones || [])]
		.filter((phone) => Boolean(phone))
		.map((number, index) => ({ number, group: index }));

	const isPreliminary = Boolean(form.scheduledTime);
	const orderDate =
		form.orderDate && form.status !== "creating"
			? newDateFn(form.orderDate)
			: newDateFn();

	const data: any = {
		paymentType: form.paymentType || Archive.ConstantOrderPayment.CASH,
		phones: personPhones,
		additionalFields,
		isPreliminary,
		orderNote: form.driverNote || "",
		orderDate: form.scheduledTime ? form.scheduledTime : orderDate,
		taxiServiceId: form.taxiServiceId as number,
		// TODO: Must be changed if passengers are more than 1
		passengers: [
			{
				customer: {
					id: customerId,
					companyId: form.companyID,

					// phones: personPhones,

					name: form.customerName || form.fullName || "",
					surname: form.customerSurname || "",
					fatherName: form.customerFatherName || "",
					birthday: form.customerBirthday || null,
					carClassId: form.customerCarClassId || null,
					notes: form.customerNotes || "",
				},
				location: form.points.length
					? {
							lng: form.points[0].coordinates.lng,
							lat: form.points[0].coordinates.lat,
					  }
					: null,

				points: [...form.points]
					.filter((item) => {
						if (!item) return false;
						return true;
					})
					.map((item) => {
						const feature: IOrderPoint = {
							type: item.type,
							country: item.country,
							countryCode: item.countryCode,
							settlement: item.settlement,
							settlementType: item.settlementType,

							district: item.district,
							region: item.region,
							coordinates: item.coordinates,

							flat: item.flat,
							entrance: item.entrance,

							sector: item.sector,

							// street
							streetType: item.streetType,
							street: item.street,
							name: item.name || item.title || "",
							customStreet: item.customStreet,
							// house
							house: item.house || item.number || "",
							customHouse: item.customHouse,
						};

						return {
							coordinates: item.coordinates,
							feature,
						};
					}),
			},
		],
	};

	// billingCustomer
	if (form.billingCustomer || customerId) {
		data.billingCustomer = form.billingCustomer || customerId;
	}

	if (form.paymentAccount) {
		data.paymentAccountId = form.paymentAccount.id;
	}

	if (form.bonusPaymentAccount || form.counterpartyBonusPaymentAccount) {
		data.bonusPaymentAccountId =
			form.counterpartyBonusPaymentAccount?.id ||
			form.bonusPaymentAccount?.id;
	}

	if (form.counterparty) {
		/** The agent or counterparty id */
		data.counterpartyId = form.counterparty?.id;
		data.checkCardId = form.counterparty?.checkCardId;
		if (form.counterparty.paymentAccountId) {
			data.paymentAccountId = form.counterparty.paymentAccountId;
		}
	}

	if (form.taxiClass) {
		data.carClassIds = compact(
			uniq(form.taxiClass.map((item) => item.value)),
		);
	}

	if (form.rateId) data.rateId = form.rateId;

	if (form.services) {
		data.serviceIds = compact(
			uniq(form.services.map((item) => item.value)),
		);
	} else data.serviceIds = [];

	if (form.paymentType) {
		const values = Object.values(Archive.ConstantOrderPayment);
		if (values.includes(form.paymentType)) {
			data.paymentType = form.paymentType;
		}
	}
	if (form.paymentChangeType) data.paymentChangeType = form.paymentChangeType;

	if (form.agent) {
		if (form.agent?.id) {
			data.agentId = form.agent.id;
		}
		if (form.agent.reward?.id) {
			data.agentRewardId = form.agent.reward?.id;
		}
	} else {
		data.agentId = null;
		data.agentRewardId = null;
	}

	console.log("[ OrderModal ] prepareOrderByForm", {
		customerId,
		form,
		data,
	});
	return data;
};

export const useOrderForm = (values: Schema | undefined) =>
	useCreateForm<Schema>({
		yupObject: schema,
		values,
	});

export const formatOrderRoute = (route): any =>
	isCompressed(route) ? decompressRoute(route) : null;

export const formatOrder = (raw): any => ({
	...raw,
	route: isCompressed(raw?.route) ? decompressRoute(raw?.route) : null,
	points: getOrderPassengerPoints(raw),
});

export const getOrderPassengerPoints = (order: any) => {
	try {
		const passengers = order?.passengers?.length
			? [...order.passengers]
			: [];
		const points = passengers.flatMap((passenger) =>
			[...passenger.points]
				.sort((a, b) => a.position - b.position)
				.flatMap((passengerPoint) => ({
					...passengerPoint.feature,
					coordinates: passengerPoint.coordinates,
				})),
		);
		return points;
	} catch (error) {
		return [];
	}
};

export const createOrderPointFromQuery = (response, lang: string) => {
	const details = response?.properties?.language?.[lang] || {};

	const name =
		`${details?.detail?.vitrine}`.toLowerCase() ===
		`${details?.name}`.toLowerCase()
			? ""
			: details?.name;

	return createOrderPoint({
		...details,
		name,
		house: details?.number || "",
		type: response?.type,
		coordinates: response?.coordinates || response?.properties?.geoCentroid,
		sector: response?.sector,
	});
};

export const createOrderPoint = (
	params: Partial<IOrderPoint> & { type: string; coordinates: LatLngLiteral },
): IOrderPoint => ({
	type: params.type,
	flat: params.flat || "",
	entrance: params.entrance || "",
	name: params.name || params.title || "",
	house: params.house || params.number || "",
	country: params.country || "",
	countryCode: params.countryCode || "",
	sector: params?.sector,
	street: params.street || "",
	streetType: params.streetType || "",
	settlement: params.settlement || "",
	settlementType: params.settlementType || "",
	district: params.district || "",
	region: params.region || "",
	coordinates: params.coordinates,
	customStreet: params?.customStreet || "",
	customHouse: params?.customHouse || "",
});

interface IPersonPhone {
	number: number;
	group?: number;
}

interface IPersonEmail {
	value: string;
	group?: number;
}
export interface ICreateCustomerParams {
	readonly password?: string;
	readonly name: string;
	readonly phones: IPersonPhone[];
	readonly emails: IPersonEmail[];

	readonly surname?: string;
	readonly fatherName?: string;
	readonly birthday?: Date;
	readonly city?: string;
	readonly country?: string;
	readonly address?: string;
}

export const STRINGIFY_POINT_TYPES = {
	/**
	 * Address format. Example:
	 * "ул. Шевченко, 1"
	 * "пл. Степана Бандеры, 13"
	 * "Точка на карте"
	 */
	ADDRESS: "address",

	/**
	 * Settlement format. Example:
	 * "г. Львов"
	 * "с. Люботин (Львівська область)"
	 * "г. Борисполь (Киевская область, Бориспольский район)"
	 * "Точка на карте"
	 */
	SETTLEMENT: "settlement",

	/**
	 * District format. Example:
	 * "Львівська область"
	 */
	STREET: "street_district",
	FULL: "full",
} as const;

type StringifyPointType =
	(typeof STRINGIFY_POINT_TYPES)[keyof typeof STRINGIFY_POINT_TYPES];

const splitStrings = (separator: string, ...strings: any[]) =>
	strings
		.filter((a) => a?.trim())
		.join(separator)
		.trim();

export const stringifyPoint = (
	point: Partial<IOrderPoint>,
	type: StringifyPointType = STRINGIFY_POINT_TYPES.ADDRESS,
	pointOnMap = "",
): string => {
	switch (type) {
		case STRINGIFY_POINT_TYPES.FULL: {
			let districtBuffer = "";
			let streetBuffer = "";
			let settlementBuffer = "";

			if (point.settlementType) {
				const prefix = `${point.settlementType[0]}.`;
				settlementBuffer += `${prefix} ${point.settlement}`;
			}

			if (point.region) {
				districtBuffer += point.region;
			}
			if (point.district) {
				districtBuffer += districtBuffer
					? `, ${point.district}`
					: point.district;
			}

			const distBrackets = districtBuffer ? `(${districtBuffer})` : "";

			if (point.street) {
				const street = splitStrings(
					" ",
					point.streetType,
					point.street,
					point.house || point.customHouse || point.number || "",
				);

				streetBuffer = street || "";
			}

			if (point.type === "object" || point.type === "adminLevel3") {
				const firstP = splitStrings(
					", ",
					settlementBuffer,
					point.name,
					streetBuffer,
				);

				return `${firstP} ${distBrackets}`.trim();
			}

			if (streetBuffer) {
				const parts = splitStrings(
					", ",
					settlementBuffer,
					streetBuffer,
				);
				return `${parts} ${distBrackets}`.trim();
			}

			const a = settlementBuffer
				? `${settlementBuffer} ${distBrackets}`
				: districtBuffer;

			return a || pointOnMap || "Точка на карте";
		}

		case STRINGIFY_POINT_TYPES.SETTLEMENT: {
			let buffer = getValidCityName(
				point.settlementType,
				point.settlement,
			).trim();

			if (buffer && (point.region || point.district)) {
				buffer += ` (${splitStrings(
					", ",
					point.region,
					point.district,
				)})`;
			}

			return buffer || pointOnMap || "Точка на карте";
		}

		case STRINGIFY_POINT_TYPES.ADDRESS:
		case STRINGIFY_POINT_TYPES.STREET: {
			let buffer = "";
			const typeStreet = point.streetType || "";
			const street = point.street || point.customStreet || "";
			const showStreet = `${street} ${typeStreet}`.trim();

			if (point.type === "localObject") {
				buffer = splitStrings(" ", point.streetType, point.street);

				if (buffer && (point.house || point.customHouse)) {
					buffer = ` (${buffer}, ${point.house})`;
				}

				return `${point.name || (point as any).title} ${buffer}`.trim();
			}

			if (point.name && point.type !== "street") {
				const name = point.name || point.title || "";
				return `${name} ${showStreet ? `(${showStreet})` : ""}`.trim();
			}

			if (point.customStreet) buffer = point.customStreet;
			else if (point.street) {
				buffer = `${point.streetType} ${point.street}`.trim();
				if (point.district) buffer += ` (${point.district})`;
			} else if (point.district) {
				return point.district;
			} else {
				return pointOnMap || "Точка на карте";
			}

			if (type === STRINGIFY_POINT_TYPES.ADDRESS) {
				return [buffer, point.customHouse || point.house]
					.filter((a) => a)
					.join(", ");
			}

			return buffer;
		}

		default: {
			return `Unexpected stringify point type '${type}'. Please check stringifyPoint function call`;
		}
	}
};
