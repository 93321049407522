import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Button, theme } from "uikit";
import styled from "styled-components";

import { StyledRow } from "../../../../../../../../../../../../../../components/common";

const ColumnCount = styled.div`
	font-family: "Lato";
	font-style: normal;
	font-weight: 600;
	font-size: 13px;
	line-height: 13px;

	color: ${theme.colors.accent};
`;

const ColumnCountText = styled.div`
	font-family: "Lato";
	font-style: normal;
	font-weight: 600;
	font-size: 13px;
	line-height: 13px;
	color: #575757;
	display: flex;
`;

const Root = styled(StyledRow)`
	height: 49.5px;
	padding: 8px 12px;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
	z-index: 1;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const NumericDial = memo<NumericDial.Props>(({ disabled, count, onClear }) => {
	const { t } = useTranslation();
	return (
		<Root gap="5px*">
			<ColumnCountText>
				{t(
					"pages.mainPage.pages.accounts.tabs.roles.editModal.content.permissionsTab.select.viewer.header.str200",
				) ?? ""}
				:&nbsp;&nbsp;<ColumnCount>{count}</ColumnCount>
			</ColumnCountText>
			<Button.Button
				text={
					t(
						"pages.mainPage.pages.accounts.tabs.roles.editModal.content.permissionsTab.select.viewer.header.str0",
					) ?? ""
				}
				disabled={disabled}
				variant="negative"
				transparent
				hasPaddings={false}
				onClick={onClear}
			/>
		</Root>
	);
});

declare namespace NumericDial {
	interface Props {
		disabled: boolean;
		count: number;

		onClear: () => void;
	}
}

export default NumericDial;
