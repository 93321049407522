import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "uikit";
import Button from "./components/Button";
import Root from "./components/Root";

const ControlsBase: React.FC<Controls.Props> = ({
	onAdd,
	onInsert,
	onEdit,
	onDelete,
	onMoveUp,
	onMoveDown,
}) => {
	const { t } = useTranslation();
	return (
		<Root gaps="3px* 1px" sizes="auto!*">
			<Button
				variant="primary"
				tabIndex={-1}
				title={t("orderPageWidgets.orderDetails.controls.str0") ?? ""}
				settingsKeyId="addPoint"
				onClick={onAdd}
			>
				<Icon id="plus" size={16} />
			</Button>
			<Button
				variant="secondary"
				tabIndex={-1}
				title={t("orderPageWidgets.orderDetails.controls.str1") ?? ""}
				settingsKeyId="insertPoint"
				onClick={onInsert}
			>
				<Icon id="clipboard" size={20} />
			</Button>
			<Button
				variant="secondary"
				tabIndex={-1}
				title={t("orderPageWidgets.orderDetails.controls.str2") ?? ""}
				settingsKeyId="editPoint"
				onClick={onEdit}
			>
				<Icon id="pencil" size={20} />
			</Button>
			<Button
				variant="secondary"
				tabIndex={-1}
				title={t("orderPageWidgets.orderDetails.controls.str3") ?? ""}
				settingsKeyId="deletePoint"
				onClick={onDelete}
			>
				<Icon id="trash" size={20} />
			</Button>
			<Button
				style={{
					height: 16,
					borderBottomLeftRadius: 0,
					borderBottomRightRadius: 0,
				}}
				variant="secondary"
				tabIndex={-1}
				title={t("orderPageWidgets.orderDetails.controls.str4") ?? ""}
				settingsKeyId="movePointUp"
				onClick={onMoveUp}
			>
				<Icon id="arrow-up" size={16} />
			</Button>
			<Button
				style={{
					height: 16,
					borderTopLeftRadius: 0,
					borderTopRightRadius: 0,
				}}
				variant="secondary"
				tabIndex={-1}
				title={t("orderPageWidgets.orderDetails.controls.str5") ?? ""}
				settingsKeyId="movePointDown"
				onClick={onMoveDown}
			>
				<Icon id="arrow-down" size={16} />
			</Button>
		</Root>
	);
};

const Controls = memo(ControlsBase);

declare namespace Controls {
	interface Props {
		onAdd: () => void;
		onInsert: () => void;
		onEdit: () => void;
		onDelete: () => void;
		onMoveUp: () => void;
		onMoveDown: () => void;
	}
}

export default Controls;
