/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { useCallback, useMemo } from "react";
import { isEqual } from "lodash";

import Permission from "../services/Permission";

import useModelSubscribe from "./useModelSubscribe";

function minimizeTrees(
	name: string,
	availablePermissionTree: Permission.Tree,
	permissionTree: Permission.Tree,
) {
	if (isEqual(availablePermissionTree, permissionTree))
		return [name ? `${name}.*` : "*"];

	return Object.entries(availablePermissionTree)
		.map(([nodeName, nodeTree]) => {
			if (permissionTree[nodeName]) {
				const currentName = name ? `${name}.${nodeName}` : nodeName;
				return nodeTree === true
					? [currentName]
					: minimizeTrees(
							currentName,
							nodeTree,
							permissionTree[nodeName] as Permission.Tree,
					  );
			}

			return [];
		})
		.flat();
}

function usePermissionService() {
	const availablePermissionData = useModelSubscribe({}, Permission);

	const availablePermissions = useMemo(
		() =>
			availablePermissionData?.cache.map(
				(availablePermission) => availablePermission.value,
			) ?? [],
		[availablePermissionData?.cache],
	);

	const decode = useCallback(
		(
			permissions: string[],
			customAvailablePermissions: string[] = availablePermissions,
		) => {
			permissions = permissions.map(
				(permission) => permission.split("*")[0],
			);

			return customAvailablePermissions.filter((availablePermission) =>
				permissions.some((permission) =>
					availablePermission.startsWith(permission),
				),
			);
		},
		[availablePermissions],
	);

	const encode = useCallback(
		(permissions: string[]) => {
			const availablePermissionTree =
				Permission.toTree(availablePermissions);
			const permissionTree = Permission.toTree(permissions);

			return minimizeTrees("", availablePermissionTree, permissionTree);
		},
		[availablePermissions],
	);

	const has = useCallback(
		(permissions: string[], permission: string) => {
			const availablePermissions = decode([permission]);

			return availablePermissions.every((availablePermission) =>
				permissions.includes(availablePermission),
			);
		},
		[decode],
	);

	const pick = useCallback((permissions: string[], permission: string) => {
		// eslint-disable-next-line prefer-destructuring
		permission = permission.split(".*")[0];

		return permissions.filter((currentPermission) =>
			currentPermission.startsWith(permission),
		);
	}, []);

	const service = useMemo(
		() => ({
			decode,
			encode,
			has,
			pick,
		}),
		[decode, encode, has, pick],
	);

	return service;
}

export default usePermissionService;
