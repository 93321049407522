import React from "react";

import Content from "../components/CashCountCellContent";

import base from "./base";
import { Column, ColumnId } from ".";

function cashCount({ width, onResize }: Column.Options) {
	return base(ColumnId.CashCount, (item) => <Content item={item} />, {
		width,

		onResize: onResize as (
			columnWidth?: number | undefined,
			dataKey?: string | undefined,
		) => void,
	});
}

export default cashCount;
