import React, { useMemo } from "react";

import ExecutorReport from "../../../../../../../../../../services/ExecutorReport";

import { CellContentRoot } from "../../../../../../../../../../components/LightTable";

const FatherNameCellContent: React.FC<FatherNameCellContent.Props> = ({
	item,
}) => {
	const content = useMemo(
		() => item?.person?.fatherName || "",
		[item?.person?.fatherName],
	);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace FatherNameCellContent {
	interface Props {
		item: ExecutorReport.Model;
	}
}

export default FatherNameCellContent;
