import React, { useMemo } from "react";
import moment from "moment";

import ExecutorReport from "../../../../../../../../../../services/ExecutorReport";
import { PaymentAccountType } from "../../../../../../../../../../types/PaymentAccount";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";

const DataChangeBalanceCellContent: React.FC<
	DataChangeBalanceCellContent.Props
> = ({ item }) => {
	const paymentAccount = useMemo(() => {
		if (!item?.paymentAccounts) return null;
		return item.paymentAccounts.find(
			(a) => a.type === PaymentAccountType.MAIN,
		);
	}, [item.paymentAccounts]);

	const content = useMemo(() => {
		try {
			if (!paymentAccount || !paymentAccount?.amountUpdatedAt) {
				return null;
			}

			return moment(paymentAccount?.amountUpdatedAt).format(
				"DD-MM-YYYY HH:mm:ss",
			);
		} catch {
			return "";
		}
	}, [paymentAccount]);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace DataChangeBalanceCellContent {
	interface Props {
		item: ExecutorReport.Model;
	}
}

export default DataChangeBalanceCellContent;
