/* eslint-disable no-shadow */

import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { isUndefined, isString } from "lodash";
import DockLayout, { LayoutBase, TabBase, TabData } from "rc-dock";

import {
	useTypedDispatch,
	useTypedSelector,
} from "../../../../../../redux/store";
import useModelSubscribe from "../../../../../../hooks/useModelSubscribe2";
import useModelSubscribeOld from "../../../../../../hooks/useModelSubscribe";
import Parking from "../../../../../../services/Parking2";
import TaxiService from "../../../../../../services/TaxiService";
import WidgetRouter from "../../../../../../components/OrderPageWidgets/WidgetRouter";
import { ModalInterface } from "../../../../../../redux/constants/OrdersPage";
import widgets from "../../../../../../redux/reducers/widgets";

import SubscribeProvider from "./components/SubscribeProvider";
import Root from "./components/Root";
import { defaultTab, defaultTabs, groups, pathTranslationsMain } from "./data";

const MainOrderPage: React.FC<any> = () => {
	const dispatch = useTypedDispatch();
	const { t } = useTranslation();

	const modals = useTypedSelector((state) => state.ordersPageReducer.modals);
	const savedLayout = useTypedSelector((state) => state.widgets.layout);
	const lang = useTypedSelector((state) => state.session.language);
	const { parkings } = useTypedSelector((state) => state.orderPageFilters);

	const dockLayoutRef = useRef<DockLayout | null>(null);

	const taxiServiceData = useModelSubscribeOld({}, TaxiService);

	const fullModelOptions = useMemo(() => {
		const result: Parking.SubscribeOptions = {
			query: parkings.filters.query,
			language: lang,
			taxiServiceIds: parkings.filters.taxiServiceIds,
		};

		return result;
	}, [parkings.filters.query, parkings.filters.taxiServiceIds, lang]);

	const parkingsModelData = useModelSubscribe(fullModelOptions, Parking);
	const updatedParkingsModels = useMemo(() => {
		const checkedExecutors: Parking.Model[] = [];
		for (let i = 0; i < parkingsModelData.models.length; i++) {
			const parking = parkingsModelData.models[i];
			const checkStatus = parkings.filters.statuses.length
				? parking.executorsQueue.some((queue) =>
						parkings.filters.statuses.includes(
							queue.executor.status,
						),
				  )
				: true;
			const checkOnline = parkings.filters.online.length
				? parking.executorsQueue.some((queue) => {
						const executorOnline = queue.executor.online
							? "online"
							: "offline";
						return parkings.filters.online.includes(executorOnline);
				  })
				: true;
			const checkWorkingStatus = parkings.filters.workingStatus.length
				? parking.executorsQueue.some((queue: any) => {
						const workingStatus = queue.executor.workingStatus
							? "working"
							: "notWorking";
						return parkings.filters.workingStatus.includes(
							workingStatus,
						);
				  })
				: true;
			const isYourOrderChecked = parkings.filters.isYourOrderStatus
				? parking.executorsQueue.some((queue) =>
						queue.executor.executorToOrders.find(
							(order) =>
								order.source === "executor" && !order.closedAt,
						),
				  )
				: true;
			if (
				checkStatus &&
				checkOnline &&
				checkWorkingStatus &&
				isYourOrderChecked
			) {
				checkedExecutors.push(parkingsModelData.models[i]);
			}
		}
		return {
			...parkingsModelData,
			models: checkedExecutors,
		};
	}, [
		parkings.filters.isYourOrderStatus,
		parkings.filters.online,
		parkings.filters.statuses,
		parkings.filters.workingStatus,
		parkingsModelData,
	]);

	const contents = useMemo(
		() =>
			modals
				.filter((item) => item.visible)
				.reduce((accumulator, item: ModalInterface) => {
					if (item.type === "parking") {
						accumulator[item.type] = (
							<WidgetRouter
								key={item.type}
								{...item}
								parkingsSubscribeData={updatedParkingsModels}
								taxiServiceData={taxiServiceData}
							/>
						);
					} else {
						accumulator[item.type] = (
							<WidgetRouter key={item.type} {...item} />
						);
					}

					return accumulator;
				}, {} as Record<string, React.ReactElement>),
		[modals, taxiServiceData, updatedParkingsModels],
	);

	const dockLayoutLoadTab = useCallback(
		({ id }: TabBase) => {
			if (!id) return defaultTab;

			const tab = defaultTabs[id] as TabData;

			const retval = isUndefined(tab)
				? defaultTab
				: {
						...tab,
						...(isString(tab.title)
							? { title: t(tab.title) ?? "" }
							: {}),
						content: contents[id],
				  };

			return retval;
		},
		[contents, t],
	);

	const dockLayoutOnLayoutChange = useCallback(
		(newLayout: LayoutBase) => {
			dispatch(widgets.actions.setLayout(newLayout));
		},
		[dispatch],
	);

	useEffect(() => {
		if (!dockLayoutRef.current) return;

		const dockLayout = dockLayoutRef.current;

		Object.entries(contents).forEach(([id]) => {
			dockLayout.updateTab(id, { id } as TabData, false);
		});

		dockLayout.panelToFocus = null as unknown as string;
	}, [contents]);

	return (
		<Root>
			<DockLayout
				ref={dockLayoutRef}
				dropMode="edge"
				loadTab={dockLayoutLoadTab}
				layout={savedLayout}
				groups={groups}
				onLayoutChange={dockLayoutOnLayoutChange}
			/>
			<SubscribeProvider />
		</Root>
	);
};

export interface ParkingsFilter {
	taxiServiceIds: number[];
	companyIds: number[];
	taxiServiceIdsPosition: number[];
	statuses: string[];
	online?: boolean;
	isTwoOnlineStatus: boolean;
	isTwoWorkingStatus: boolean;
	workingStatus?: boolean;
	isYourOrderStatus: boolean;
}

export default MainOrderPage;
