import React, { Children, forwardRef, memo, useMemo } from "react";
import { extend } from "lodash";

import Root from "./components/Root";

export * from "./hooks/useTableOptions";
export { default as CellContentRoot } from "./components/CellContentRoot";

const LightTableBase = forwardRef<LightTable.Ref, LightTable.Props>(
	({ children, ...props }, ref) => {
		const isFirstGapFixed = useMemo(
			() =>
				Children.toArray(children).some(
					(child) => (child as any)?.props?.fixed ?? false,
				),
			[children],
		);

		return (
			<Root ref={ref} {...props}>
				<Root.Column width={8} fixed={isFirstGapFixed}>
					<Root.HeaderCell hint={false}>
						<></>
					</Root.HeaderCell>
					<Root.Cell />
				</Root.Column>

				{children}

				<Root.Column width={8}>
					<Root.HeaderCell hint={false}>
						<></>
					</Root.HeaderCell>
					<Root.Cell />
				</Root.Column>
			</Root>
		);
	},
);

const LightTableMemo = memo(LightTableBase);

const LightTable = extend(LightTableMemo, {
	Context: Root.Context,
	Column: Root.Column,
	ColumnGroup: Root.ColumnGroup,
	Cell: Root.Cell,
	HeaderCell: Root.HeaderCell,
});

declare namespace LightTable {
	type Ref = Root.Ref;
	type Props = Root.Props;

	namespace Context {
		type Value = Root.Context.Value;
	}

	namespace Column {
		type Props = Root.Column.Props;
	}

	namespace ColumnGroup {
		type Props = Root.ColumnGroup.Props;
	}

	namespace Cell {
		type Props = Root.Cell.Props;
	}

	namespace HeaderCell {
		type Props = Root.Props;
	}
}

export default LightTable;
