import React from "react";
import { Column, ColumnId } from ".";
import PickupAddressCellContent from "../components/PickupAddressCellContent";
import base from "./base";

function pickupAddress({ width, onResize }: Column.Options) {
	return base(
		ColumnId.PickupAddress,
		(item) => <PickupAddressCellContent item={item} />,
		{
			width,

			onResize: onResize as (
				columnWidth?: number | undefined,
				dataKey?: string | undefined,
			) => void,
		},
	);
}

export default pickupAddress;
