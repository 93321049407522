import React, { useCallback, useEffect, useMemo, useState } from "react";
import { compact, isEqual, uniq } from "lodash";

import Service from "../../../../../../services/Car";
import CarClass from "../../../../../../services/CarClass";
import CarBaseType from "../../../../../../services/CarBaseType";
import { useTypedSelector } from "../../../../../../redux/store";
import useModelSubscribe from "../../../../../../hooks/useModelSubscribe2";
import { useSort } from "../../../../../../hooks/useTableSettings";
import mapByKey from "../../../../../../utils/mapByKey";
import DeleteModal from "../../../../../../components/DeleteModal";

import useCarEditModalSaver from "./hooks/useCarEditModalSaver";
import Content from "./components/Content";
import Header from "./components/Header";
import EditModal from "./components/EditModal";
import Root from "./components/Root";

const Car: React.FC = () => {
	const settingsLanguage = useTypedSelector(
		(state) => state.settings.map.mapLanguage,
	);

	const currentUser = useTypedSelector((state) => state.account.user);

	const carBaseTypeData = useModelSubscribe({ default: true }, CarBaseType);

	const carBaseTypes = useMemo(
		() => carBaseTypeData.models,
		[carBaseTypeData.models],
	);

	const defaultCarBaseType = useMemo(
		() => carBaseTypes.find((role) => role.default),
		[carBaseTypes],
	);

	const carClassData = useModelSubscribe({ default: true }, CarClass);

	const carClasses = useMemo(
		() => carClassData.models,
		[carClassData.models],
	);

	const defaultCarClass = useMemo(
		() => carClasses.find((carClass) => carClass.default),
		[carClasses],
	);

	const [headerFilters, setHeaderFilters] = useState<Header.Filters.Value>({
		search: "",
	});

	const { sortMethod: sort, setSortMethod: setSort } = useSort(
		"accounts.vehicles",
		"vehicle",
	);

	const modelSubscriptionOptions = useMemo<Service.SubscribeOptions>(() => {
		const result: Service.SubscribeOptions = {
			query: headerFilters.search,
			language: settingsLanguage,
		};

		if (sort.column && sort.type)
			result.order = {
				[sort.column]: sort.type,
			} as Service.SubscribeOptions["order"];

		return result;
	}, [headerFilters.search, settingsLanguage, sort.column, sort.type]);

	const modelData = useModelSubscribe(modelSubscriptionOptions, Service);

	const modelItems = useMemo(
		() => modelData.models ?? [],
		[modelData.models],
	);

	const modelItemById = useMemo(
		() => mapByKey(modelItems, "id"),
		[modelItems],
	);

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [editingItem, setEditingItem] = useState<EditModal.Value | null>(
		null,
	);

	const [selected, setSelected] = useState<number[]>([]);

	const edit = useCallback(
		(id: number) => {
			const item = modelItemById[id];

			const distributableCarClassIds = item.distributableCarClass
				? compact(
						uniq(item.distributableCarClass.map((item) => item.id)),
				  )
				: [];
			const broadcastableCarClassIds = item.broadcastableCarClass
				? compact(
						uniq(item.broadcastableCarClass.map((item) => item.id)),
				  )
				: [];

			setEditingItem({
				id: item.id,

				name: `${
					item.model?.carBaseTypeToCarBrand?.carBrand?.name ?? ""
				} ${item.model?.name ?? ""}, ${
					item.color?.name[settingsLanguage] ?? ""
				} (${item.parkNumber})`,

				parkNumber: item.parkNumber,
				registrationNumber: item.registrationNumber,
				vehicleNumber: item.vehicleNumber,
				manufactureYear: item.manufactureYear,
				seats: item.seats,

				carColorId: item.color?.id,
				carBaseTypeId:
					item.model?.carBaseTypeToCarBrand?.carBaseType?.id,
				carBrandId: item.model?.carBaseTypeToCarBrand?.carBrand?.id,
				carModelId: item.model?.id,
				carBodyTypeId: item.bodyType?.id,

				carClassId: item.class?.id,

				carClass: item.class,
				distributableCarClass: item.distributableCarClass,
				broadcastableCarClass: item.broadcastableCarClass,

				distributableCarClassIds,
				broadcastableCarClassIds,

				taxiServiceId: item.taxiService?.id,
				carParkId: item.park?.id,
				responsibleDispatcherId: item.responsibleDispatcher?.id,

				notes: item.notes,

				serviceIds: item.services?.map((service) => service.id) ?? [],

				registrationCertificate: item.registrationCertificate,
				licenseCard: item.licenseCard,
				radioStation: item.radioStation,
				taximeter: item.taximeter,
				insurances: {
					wc: {
						series: item.insurances?.wc?.series ?? "",
						number: item.insurances?.wc?.number ?? "",
						expirationDate: item.insurances?.wc?.expirationDate,
					},
					additional:
						item.insurances?.additional?.map((item) => ({
							name: item.name ?? "",
							data: item.data ?? "",
							expirationDate: item.expirationDate,
						})) ?? [],
				},

				executorIds:
					item.executors?.map((executor) => executor.id) ?? [],

				otherFiles: item.otherFiles ?? [],
				transportFiles: item.transportFiles ?? [],
				registrationCertificateFiles:
					item.registrationCertificateFiles ?? [],
				insuranceFiles: item.insuranceFiles ?? [],
				licenseCardFiles: item.licenseCardFiles ?? [],
				wcInsuranceFiles: [],

				active: item.active,

				createdAt: item.createdAt ?? undefined,
			});
		},
		[modelItemById, settingsLanguage],
	);

	const headerCanEdit = useMemo(
		() => selected.length === 1,
		[selected.length],
	);

	const headerCanDelete = useMemo(
		() => selected.length !== 0,
		[selected.length],
	);

	const headerOnAdd = useCallback(() => {
		setEditingItem({
			parkNumber: "",
			registrationNumber: "",
			vehicleNumber: "",
			manufactureYear: "",
			responsibleDispatcherId: currentUser?.id,
			carBaseTypeId: defaultCarBaseType?.id,
			carClassId: defaultCarClass?.id,

			active: true,

			notes: "",

			licenseCard: "",
			registrationCertificate: "",
			radioStation: "",
			taximeter: "",
			insurances: {
				wc: {
					series: "",
					number: "",
				},
				additional: [
					{
						name: "",
						data: "",
					},
				],
			},

			otherFiles: [],
			transportFiles: [],
			registrationCertificateFiles: [],
			insuranceFiles: [],
			licenseCardFiles: [],
			wcInsuranceFiles: [],

			executorIds: [],

			carClass: defaultCarClass,
			serviceIds: defaultCarClass?.serviceDefaultIds || [],
			distributableCarClass: defaultCarClass?.compatibleCarClasses || [],
			broadcastableCarClass:
				defaultCarClass?.compatibleCarClassesToBroadcastable || [],
			distributableCarClassIds:
				defaultCarClass?.compatibleCarClassIds || [],
			broadcastableCarClassIds:
				defaultCarClass?.compatibleCarClassIdsToBroadcastable || [],
		});
	}, [currentUser?.id, defaultCarBaseType?.id, defaultCarClass]);

	const headerOnEdit = useCallback(() => {
		edit(selected[0]);
	}, [edit, selected]);

	const headerOnDelete = useCallback(() => {
		setShowDeleteModal(true);
	}, []);

	const contentOnEdit = useCallback((item) => edit(item.id), [edit]);

	const contentOnLoadMore = useCallback(() => {}, []);

	const content = useMemo(
		() => (
			<>
				<Header
					filters={headerFilters}
					canEdit={headerCanEdit}
					canDelete={headerCanDelete}
					onChangeFilters={setHeaderFilters}
					onAdd={headerOnAdd}
					onEdit={headerOnEdit}
					onDelete={headerOnDelete}
				/>
				<Content
					selected={selected}
					sort={sort}
					loading={false}
					data={modelItems}
					language={settingsLanguage}
					onChangeSelected={setSelected}
					onChangeSort={setSort}
					onEdit={contentOnEdit}
					onLoadMore={contentOnLoadMore}
				/>
			</>
		),
		[
			contentOnEdit,
			contentOnLoadMore,
			headerCanDelete,
			headerCanEdit,
			headerFilters,
			headerOnAdd,
			headerOnDelete,
			headerOnEdit,
			modelItems,
			selected,
			setSort,
			settingsLanguage,
			sort,
		],
	);

	const editModalOnCancel = useCallback(() => {
		setEditingItem(null);
	}, []);

	const editModalSaver = useCarEditModalSaver();

	const editModalOnSave = useCallback(
		async (newItem: EditModal.Value.Validated) => {
			if (isEqual(editingItem, newItem)) {
				setEditingItem(null);
				return;
			}

			editModalSaver(newItem);

			setEditingItem(null);
		},
		[editModalSaver, editingItem],
	);

	const deleteModalOnCancel = useCallback(() => {
		setShowDeleteModal(false);
	}, []);

	const deleteModalOnConfirm = useCallback(async () => {
		setShowDeleteModal(false);
		await Service.destroy(selected);
		setSelected([]);
	}, [selected]);

	useEffect(() => {
		if (editingItem?.id) {
			const car = modelItemById?.[editingItem.id];

			const carClassId = car.class?.id;
			const carClass = car.class;
			const serviceIds = car.services
				? compact(uniq(car.services.map((item) => item.id)))
				: [];
			const distributableCarClass = car.distributableCarClass || [];
			const broadcastableCarClass = car.broadcastableCarClass || [];
			const distributableCarClassIds = car.distributableCarClass
				? compact(uniq(distributableCarClass.map((item) => item.id)))
				: [];
			const broadcastableCarClassIds = broadcastableCarClass
				? compact(uniq(broadcastableCarClass.map((item) => item.id)))
				: [];

			if (car) {
				setEditingItem({
					...editingItem,
					carClassId,
					carClass,
					serviceIds,
					distributableCarClass,
					distributableCarClassIds,
					broadcastableCarClassIds,
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modelItems]);

	return (
		<Root sizes="auto! 1fr" gaps="16px" maxedWidth maxedHeight>
			{content}
			{editingItem && (
				<EditModal
					value={editingItem}
					language={settingsLanguage}
					onCancel={editModalOnCancel}
					onSave={editModalOnSave}
				/>
			)}
			{showDeleteModal && (
				<DeleteModal
					onCancel={deleteModalOnCancel}
					onConfirm={deleteModalOnConfirm}
				/>
			)}
		</Root>
	);
};

export default Car;
