import { RefObject } from "react";
import { react } from "uikit";
import Access from "./components/Access";
import DefaultTaxiServiceId from "./components/DefaultTaxiServiceId";
import RoleIds from "./components/RoleIds";

interface Context {
	accessRef: RefObject<Access.Ref | null>;
	defaultTaxiServiceIdRef: RefObject<DefaultTaxiServiceId.Ref | null>;
	roleIdsRef: RefObject<RoleIds.Ref | null>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isRoleIdValid = !!this.context?.roleIdsRef.current?.validate();
		const isDefaultTaxiServiceIdRefValid =
			!!this.context?.defaultTaxiServiceIdRef.current?.validate();
		const isAccessValid = !!this.context?.accessRef.current?.validate();

		return isRoleIdValid && isDefaultTaxiServiceIdRefValid && isAccessValid;
	}
}
