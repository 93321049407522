import React, { memo, useLayoutEffect } from "react";
import { useRefSize } from "uikit";

import CarClass from "../../../../../../../../../../../../../../../../../../services/CarClass";
import Sector from "../../../../../../../../../../../../../../../../../../services/Sector";
import { StyledRow } from "../../../../../../../../../../../../../../../../../../components/common";
import Rules from "../../../..";

import TariffZonesOutput from "./components/TariffZonesOutput";
import ValidityDateOutput from "./components/ValidityDateOutput";
import ValidityTimeOutput from "./components/ValidityTimeOutput";
import WeekDaysOutput from "./components/WeekDaysOutput";
import TariffCalculationOutput from "./components/TariffCalculationOutput";
import PriceZoneChangeDistanceOutput from "./components/PriceZoneChangeDistanceOutput";
import ApplyToOrderPointsOutput from "./components/ApplyToOrderPointsOutput";
import OrderSourceOutput from "./components/OrderSourceOutput";
import OrdersOutput from "./components/OrdersOutput";
import AutoClassesOutput from "./components/AutoClassesOutput";
import MinOrderCostOutput from "./components/MinOrderCostOutput";
import ConsiderSuburbanInTaximeterOutput from "./components/ConsiderSuburbanInTaximeterOutput";
import SectorsOutput from "./components/SectorsOutput";

const RuleOutput: React.FC<RuleOutput.Props> = memo(
	({ getWidth, rule, autoClasses, sectorModels }) => {
		const {
			validityDate,
			validityTime,
			weekdays,
			calculationType,
			priceZoneChangeDistanceThreshold,
			applyToOrderPoints,
			orderSource,
			orders,
			carClasses,
			minOrderCost,
			considerSuburbanInTaximeter,
			sectors,
			polygonType,
			outsidePriceZones,
		} = rule.additionalFields;
		const { priceZoneIds, carClassIds, sectorIds } = rule;
		const { ref, size } = useRefSize();

		useLayoutEffect(() => {
			getWidth?.(size.width);
		}, [getWidth, size]);

		return (
			<StyledRow ref={ref} gap="5px" w="100%" overX="hidden" overY="auto">
				<ValidityDateOutput value={validityDate} />
				<ValidityTimeOutput value={validityTime} />
				<WeekDaysOutput value={weekdays} />
				<TariffZonesOutput
					value={priceZoneIds}
					outsidePriceZones={outsidePriceZones}
				/>
				<TariffCalculationOutput value={calculationType} />
				<PriceZoneChangeDistanceOutput
					value={priceZoneChangeDistanceThreshold}
				/>
				<ApplyToOrderPointsOutput value={applyToOrderPoints} />
				<OrderSourceOutput value={orderSource} />
				<OrdersOutput value={orders} />
				<AutoClassesOutput
					autoClasses={autoClasses}
					active={carClasses.active}
					value={carClassIds}
				/>
				<MinOrderCostOutput value={minOrderCost} />
				<ConsiderSuburbanInTaximeterOutput
					value={considerSuburbanInTaximeter}
				/>
				<SectorsOutput
					polygonType={polygonType}
					value={sectors}
					sectors={sectorModels}
					sectorIds={sectorIds}
				/>
			</StyledRow>
		);
	},
);

declare namespace RuleOutput {
	interface Props {
		rule: Rules.Rule;
		autoClasses: CarClass.Model[];
		sectorModels?: Sector.Model[];
		getWidth?: (num: number) => void;
	}
}

export default RuleOutput;
