import React, { useMemo, Suspense, useEffect, lazy } from "react";
import { Tabs } from "uikit";
import { useTranslation } from "react-i18next";

import Tariff from "../../../../services/Tariff";
import setRates from "../../../../redux/actions/Preferences/Rates/setRates";
import { useTypedDispatch, useTypedSelector } from "../../../../redux/store";
import getClasses from "../../../../redux/services/Preferences/CarClass/getClasses";
import getServices from "../../../../redux/services/Preferences/Services/getServices";
import useActiveTab from "../../../../hooks/useActiveTab";
import useModelSubscribe from "../../../../hooks/useModelSubscribe2";
import { hasAccess } from "../../../../access";
import { TabOptions } from "../../../../types";
import { StyledRow, SuspenseLoader } from "../../../../components/common";
import BasicPageLayout from "../../../../components/BasicPageLayout";

import { TabKeys, TabAccessNames } from "./constants/access";
import {
	/* ChangeHistory, */
	/* DriverShifts, */
	MessagesHistory,
	/* Ratings, */
	CallsHistory,
	PushHistory,
	Transactions,
	BankTransactions,
} from "./tabs";

const LazyOrders = lazy<React.FC<any>>(async () => {
	const elem = await import(
		"./tabs/Orders" /* webpackChunkName: "orders-page" */
	);
	return elem;
});

const LazyGPSLog = lazy<React.FC<any>>(async () => {
	const elem = await import(
		"./tabs/GPSLog" /* webpackChunkName: "gps-log-page" */
	);
	return elem;
});

const Archives: React.FC = () => {
	const { t } = useTranslation();
	const dispatch = useTypedDispatch();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);
	const ratesSubscription = useModelSubscribe({}, Tariff);

	const models = useMemo<Tariff.Model[]>(
		() => ratesSubscription.models,
		[ratesSubscription],
	);

	useEffect(() => {
		if (dispatch) {
			dispatch(getServices());
			dispatch(getClasses());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (models?.length) dispatch(setRates(models));
	}, [dispatch, models]);

	const tabs: TabOptions.Array = useMemo(
		() =>
			[
				{
					key: TabKeys.ORDERS,
					label: t("mainPage.archives.orders.title"),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<LazyOrders />
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.ORDERS],
				},
				{
					key: TabKeys.BANK_TRANSACTIONS,
					label: t("mainPage.archives.bankTransactions.title"),
					value: {
						render: () => <BankTransactions />,
					},
					accessName: TabAccessNames[TabKeys.BANK_TRANSACTIONS],
				},
				{
					key: TabKeys.BANK_TRANSACTIONS,
					label: t("mainPage.archives.transactions.title"),
					value: {
						render: () => <Transactions />,
					},
					accessName: TabAccessNames[TabKeys.TRANSACTIONS],
				},
				// {
				// 	key: TabKeys.RATINGS,
				// 	label: t("mainPage.archives.ratings.title"),
				// 	value: {
				// 		render: () => <Ratings />,
				// 	},
				// accessName: TabAccessNames[TabKeys.RATINGS],
				// },
				{
					key: TabKeys.GPS_LOG,
					label: t("mainPage.archives.gpsLog.title"),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<LazyGPSLog />
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.GPS_LOG],
				},
				{
					key: TabKeys.MESSAGES,
					label: t("mainPage.archives.messages.title"),
					value: {
						render: () => <MessagesHistory />,
					},
					accessName: TabAccessNames[TabKeys.MESSAGES],
				},
				{
					key: TabKeys.PUSH,
					label: t("mainPage.archives.push.title"),
					value: {
						render: () => <PushHistory />,
					},
					accessName: TabAccessNames[TabKeys.PUSH],
				},
				// {
				// 	key: TabKeys.DRIVER_SHIFTS,
				// 	label: t("mainPage.archives.driverShifts.title"),
				// 	value: {
				// 		render: () => <DriverShifts />,
				// 	},
				// accessName: TabAccessNames[TabKeys.DRIVER_SHIFTS],
				// },
				// {
				// 	key: TabKeys.CHANGE_HISTORY,
				// 	label: t("mainPage.archives.changeHistory.title"),
				// 	value: {
				// 		render: () => <ChangeHistory />,
				// 	},
				// accessName: TabAccessNames[TabKeys.CHANGE_HISTORY],
				// },
				{
					key: TabKeys.CALLS_HISTORY,
					label: t("mainPage.archives.callsHistory.title"),
					value: {
						render: () => <CallsHistory />,
					},
					accessName: TabAccessNames[TabKeys.CALLS_HISTORY],
				},
			].filter((btn) => hasAccess(btn.accessName, personalRole)),
		[t, personalRole],
	);

	const { activeKey, activeTab, setActiveKey } = useActiveTab(tabs);

	return (
		<Suspense
			fallback={
				<StyledRow position="absolute" top="50%" left="50%">
					<SuspenseLoader />
				</StyledRow>
			}
		>
			<BasicPageLayout
				navigation={
					<Tabs
						value={activeKey}
						onChange={(newActive) => {
							setActiveKey(newActive);
						}}
						options={tabs}
						variant="vertical"
					/>
				}
				content={activeTab.value.render()}
			/>
		</Suspense>
	);
};

export default Archives;
