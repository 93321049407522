import { RefObject } from "react";
import { react } from "uikit";
import IgnoreExecutors from "./components/IgnoreExecutors";

interface Context {
	ignoreExecutorsRef: RefObject<IgnoreExecutors.Ref | null>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isIgnoreExecutorsValid =
			!!this.context?.ignoreExecutorsRef.current?.validate();

		return isIgnoreExecutorsValid;
	}
}
