import React, { Dispatch, useCallback } from "react";
import { Column } from "uikit";
import Header from "./components/Header";
import useObjectEditor from "../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import List from "./components/List";

const Viewer: React.FC<Viewer.Props> = ({ value, disabled, onChange }) => {
	const valueEditor = useObjectEditor(value, onChange);

	const headerOnClear = useCallback(() => valueEditor.clear(), [valueEditor]);

	return (
		<Column sizes="auto! 1fr">
			<Header
				disabled={disabled}
				count={valueEditor.value.length}
				onClear={headerOnClear}
			/>
			<List value={value} disabled={disabled} onChange={onChange} />
		</Column>
	);
};

declare namespace Viewer {
	type Value = string[];

	interface Props {
		value: Value;

		disabled: boolean;

		onChange: Dispatch<Value>;
	}
}

export default Viewer;
