import * as ModelEvent from "@node-elion/syncron";
import { uniq } from "lodash";

import ServiceSubscribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";
import { SortingOrder } from "../../types/SortingOrder";
import Base from "../Base";
import SubscriptionBase from "../../types/Subscription";
import SubscriptionPool from "../../redux/services/SubscriptionPool";
import TaxiService from "../TaxiService2";
import Executor from "../Executor";
import ExecutorRateSubscription from "../ExecutorRateSubscription";
import ExecutorRateCommissionPlan from "../ExecutorRateCommissionPlan";
import ExecutorRateDiscountPlan from "../ExecutorRateDiscountPlan";
import { createObjectLanguageNames } from "../../assets/languages/langs";
import Language from "../Language";

const conditionOperatorMaps = {
	fromResponse: {
		greater: "greater",
		less: "less",
		equal: "equal",
		greaterEqual: "greaterOrEqual",
		lessEqual: "lessOrEqual",
	},
	toRequest: {
		greater: "greater",
		less: "less",
		equal: "equal",
		greaterOrEqual: "greaterEqual",
		lessOrEqual: "lessEqual",
	},
} as const;

class ExecutorRate extends Base {
	static fromResponse(data: any, lang: Language = "ru"): ExecutorRate.Model {
		const taxiServiceIds = ({
			taxiServices = [],
		}: ExecutorRate.Model): number[] => {
			try {
				if (!taxiServices || !taxiServices.length) return [];
				return uniq<number>(taxiServices.map((item) => item?.id));
			} catch {
				return [];
			}
		};

		return {
			id: data.id,
			// active: data.active,
			active: true,
			default: data.default,
			root: data.root || false,

			executorIds: data.executorIds,

			taxiServices: data.taxiServices || [],
			taxiServiceIds: taxiServiceIds(data),

			subscriptions: data.subscriptions?.map(
				ExecutorRateSubscription.fromResponse,
			),

			commissionPlans: data.commissionPlans?.map(
				ExecutorRateCommissionPlan.fromResponse,
			),

			discountPlans: data.discountedPlans?.map(
				ExecutorRateDiscountPlan.fromResponse,
			),

			name: data.name?.[lang] || data.name.ru || "",

			ignoreOldOrders:
				data.configuration?.discounted?.ignoreOldOrders ?? false,

			amountOfMoneyToBlock: {
				active:
					data.configuration?.locking?.lockingAmount?.active ?? false,
				value: data.configuration?.locking?.lockingAmount?.value ?? 0,
				auto: data.configuration?.locking?.lockingAmount?.auto ?? false,
			},

			amountOfMoneyToUnblock: {
				active:
					data.configuration?.locking?.unlockingAmount?.active ??
					false,
				value: data.configuration?.locking?.unlockingAmount?.value ?? 0,
				auto:
					data.configuration?.locking?.unlockingAmount?.auto ?? false,
			},

			orderTypeCondition: {
				active:
					data.configuration?.locking?.conditions?.active ?? false,
				operator: data.configuration?.locking?.conditions?.direction
					? conditionOperatorMaps.fromResponse[
							data.configuration?.locking?.conditions?.direction
					  ]
					: "equal",
				value: data.configuration?.locking?.conditions?.value ?? 0,
				types: data.configuration?.locking?.conditions?.types ?? [
					"cashOrders",
				],
			},

			disallowTakeOrders: {
				active:
					data.configuration?.locking?.conditions?.orderRestrictions
						?.active ?? false,
				value:
					data.configuration?.locking?.conditions?.orderRestrictions
						?.commissionThreshold ?? 0,
			},

			updatedAt: data.updatedAt,
			createdAt: data.createdAt,
			deletedAt: data.deletedAt,
		};
	}

	static toRequest(
		model: ExecutorRate.Model.New | ExecutorRate.Model.Modified,
	): any {
		const commissionPlans = model.commissionPlans?.map(
			(commissionPlan) => ({
				...ExecutorRateCommissionPlan.toRequest(commissionPlan),

				id: commissionPlan.id,
			}),
		);

		const payload = {
			active: true,
			default: model.default,
			taxiServiceIds: model.taxiServiceIds,

			executorIds: model.executorIds,

			subscriptions: model.subscriptions?.map((subscription) => ({
				...ExecutorRateSubscription.toRequest(subscription),

				id: subscription.id,
			})),

			commissionPlans,

			discountedPlans: model.discountPlans?.map((discountPlan) => ({
				...ExecutorRateDiscountPlan.toRequest(discountPlan),

				id: discountPlan.id,
			})),
			name: createObjectLanguageNames(model.name),
			// name: {
			// 	en: model.name,
			// 	ru: model.name,
			// 	uk: model.name,
			// 	az: model.name,
			// 	tr: model.name,
			// },

			configuration: {
				discounted: {
					ignoreOldOrders: model.ignoreOldOrders,
				},

				locking: {
					lockingAmount: {
						active: model.amountOfMoneyToBlock?.active,
						value: model.amountOfMoneyToBlock?.value,
						auto: model.amountOfMoneyToBlock?.auto,
					},

					unlockingAmount: {
						active: model.amountOfMoneyToUnblock?.active,
						value: model.amountOfMoneyToUnblock?.value,
						auto: model.amountOfMoneyToUnblock?.auto,
					},

					conditions: {
						active: model.orderTypeCondition?.active,
						value: model.orderTypeCondition?.value,
						types: model.orderTypeCondition?.types,
						direction: model.orderTypeCondition
							? conditionOperatorMaps.toRequest[
									model.orderTypeCondition.operator
							  ]
							: undefined,

						orderRestrictions: {
							active: model.disallowTakeOrders?.active,
							commissionThreshold:
								model.disallowTakeOrders?.value,
						},
					},
				},
			},
		};

		return payload;
	}

	public static async getById(id: number, lang?: Language) {
		const data = await this.request((prpc) =>
			prpc.theirsModel.executorRate.getById(id),
		);

		return ExecutorRate.fromResponse(data, lang);
	}

	public static async store(object: ExecutorRate.Model.New, force = false) {
		try {
			const res = await this.request(
				(prpc) =>
					prpc.theirsModel.executorRate.create(
						ExecutorRate.toRequest(object),
						{ force },
					),
				{ silent: false, error: true },
			);
			console.log("ExecutorRate store", { object, res, force });
			return res;
		} catch (error) {
			return null;
		}
	}

	public static async update(
		object: ExecutorRate.Model.Modified,
		force = false,
	) {
		try {
			const res = await this.request(
				(prpc) =>
					prpc.theirsModel.executorRate.update(
						object.id,
						ExecutorRate.toRequest(object),
						{ force },
					),
				{ silent: false, error: true },
			);
			console.log("ExecutorRate update", { object, res, force });
			return res;
		} catch (error) {
			return null;
		}
	}

	public static async destroy(id: number[] | number) {
		if (Array.isArray(id))
			await Promise.all(id.map((id) => this.destroyOne(id)));
		else await this.destroyOne(id);
	}

	public static async subscribe(
		options: ExecutorRate.SubscribeOptions,
		onUpdate: SubscriptionBase.OnUpdate<ExecutorRate.Model>,
	): Promise<SubscriptionBase<ExecutorRate.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				console.log("[ExecutorRate] subscribe", {
					state,
				});
				onUpdate({
					...state,

					models: state.models.map((item) =>
						this.fromResponse(item, options.lang),
					),
				});
			},
		});
		const subscription = await SubscriptionPool.add(
			(prpc) =>
				prpc.theirsModel.executorRate.subscribe({
					params: this.optionsToRequest(options),
					ping: () => true,
					onEvent: (event) => {
						modelEventConstructor.onEvent(event);
					},
					onError: (error) => {
						// eslint-disable-next-line no-console
						console.log(error);
					},
				}),
			{ name: "ExecutorRate.subscribe" },
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (options: ExecutorRate.SubscribeOptions) =>
				subscription.update(this.optionsToRequest(options)),
		} as SubscriptionBase<ExecutorRate.SubscribeOptions>;
	}

	private static async destroyOne(id: number) {
		this.request((prpc) => prpc.theirsModel.executorRate.delete(id));
	}

	private static optionsToRequest(options: ExecutorRate.SubscribeOptions) {
		return {
			query: options.query,
			offset: options.offset,
			limit: options.limit,

			taxiServiceIds: options.taxiServiceIds,

			order: options.order,
		};
	}
}

declare namespace ExecutorRate {
	export interface Model {
		id: number;
		active: boolean;
		root: boolean;
		default: boolean;

		executorIds: number[];

		taxiServices?: TaxiService.Model[];
		taxiServiceIds: number[];
		executors?: Executor.Model[];

		subscriptions?: ExecutorRateSubscription.Model[];
		commissionPlans?: ExecutorRateCommissionPlan.Model[];
		discountPlans?: ExecutorRateDiscountPlan.Model[];

		name: string;

		ignoreOldOrders: boolean;

		amountOfMoneyToBlock: {
			active: boolean;
			value: number;
			auto: boolean;
		};
		amountOfMoneyToUnblock: {
			active: boolean;
			value: number;
			auto: boolean;
		};

		orderTypeCondition: {
			active: boolean;
			operator:
				| "greater"
				| "less"
				| "equal"
				| "greaterOrEqual"
				| "lessOrEqual";
			value: number;
			types: (
				| "cashOrders"
				| "nonCashOrders"
				| "suburbanOrders"
				| "onlineOrders"
				| "bonusOrders"
				| "executorOrder"
				| "cardPaymentToTaxiService"
				| "cardPaymentToExecutor"
				| "terminalToTaxiService"
				| "terminalToExecutor"
				| "localTransfer"
				| "globalTransfer"
				| "externalTransfer"
			)[];
		};
		disallowTakeOrders: {
			active: boolean;
			value: number;
		};

		updatedAt: string;
		createdAt: string;
		deletedAt: string | null;
	}

	interface SubscribeOptions
		extends Omit<ServiceSubscribeOptionsBase<ExecutorRate.Model>, "order"> {
		taxiServiceIds?: number[];
		lang?: Language;
		order?: Partial<Record<keyof Model, SortingOrder>>;
	}

	namespace Model {
		interface New {
			name: string;

			active: boolean;
			default: boolean;
			taxiServiceIds: number[];
			executorIds: number[];

			subscriptions: ((
				| ExecutorRateSubscription.Model.New
				| ExecutorRateSubscription.Model.Modified
			) & { id?: number })[];

			commissionPlans: ((
				| ExecutorRateCommissionPlan.Model.New
				| ExecutorRateCommissionPlan.Model.Modified
			) & { id?: number })[];

			discountPlans: ((
				| ExecutorRateDiscountPlan.Model.New
				| ExecutorRateDiscountPlan.Model.Modified
			) & { id?: number })[];

			ignoreOldOrders: boolean;

			amountOfMoneyToBlock: {
				active: boolean;
				value: number;
				auto: boolean;
			};
			amountOfMoneyToUnblock: {
				active: boolean;
				value: number;
				auto: boolean;
			};

			orderTypeCondition: {
				active: boolean;
				operator:
					| "greater"
					| "less"
					| "equal"
					| "greaterOrEqual"
					| "lessOrEqual";
				value: number;
				types: (
					| "cashOrders"
					| "nonCashOrders"
					| "suburbanOrders"
					| "onlineOrders"
					| "bonusOrders"
					| "executorOrder"
					| "cardPaymentToTaxiService"
					| "cardPaymentToExecutor"
					| "terminalToTaxiService"
					| "terminalToExecutor"
					| "localTransfer"
					| "globalTransfer"
					| "externalTransfer"
				)[];
			};
			disallowTakeOrders: {
				active: boolean;
				value: number;
			};
		}
		type Modified = Partial<New> & { id: number };
	}
}

export default ExecutorRate;
