/* eslint-disable no-case-declarations */
import React, { Dispatch, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckBox } from "uikit";

import AdditionalTariff from "../../../../../../../../services/AdditionalTariff";
import LightTable, {
	useTableOptions,
	UseTableOptions,
} from "../../../../../../../../components/LightTable";
import TableSettings from "../../../../../../../../components/TableSettings";
import {
	StyledGrid,
	StyledGridItem,
} from "../../../../../../../../components/common";

import tPath from "../../constants/tPath";
import { Columns, columns, defaultColumnsId } from "./constants";

const Content: React.FC<Content.Props> = ({
	value,
	selected,
	setSelected,
	onEdit,
	onChangeTable,
	editorTable,
}) => {
	const { t } = useTranslation();

	const {
		sort,
		onSort,
		onScroll,
		tableRef,
		onKeyUp,
		onKeyDown,
		ctrlPressed,
		lang,
	} = useTableOptions({
		itemLimit: 2,
		heightRow: 44,
		value: editorTable,
		setValue: onChangeTable,
	});

	const [columnsId, setColumnsId] = useState(defaultColumnsId);

	const tColumns = useMemo(
		() =>
			columns.map((c) => ({
				...c,
				label: t(`${tPath}.columns.${c.id}`),
			})),
		[t],
	);

	const updateSelected = useCallback(
		(template) => {
			if (selected.includes(template.id)) {
				setSelected(selected.filter((id) => template.id !== id));
			} else {
				setSelected([...selected, template.id]);
			}
		},
		[setSelected, selected],
	);

	const handleOnRowClick = useCallback(
		(tariff: any) => {
			if (tariff) {
				if (ctrlPressed) updateSelected(tariff);
				else {
					const exist = selected.find((item) => item === tariff.id);
					if (exist && selected.length <= 1) setSelected([]);
					else setSelected([tariff.id]);
				}
			}
		},
		[ctrlPressed, updateSelected, selected, setSelected],
	);

	const renderColumns = useMemo(
		() =>
			columnsId.map((columnId) => {
				const column = tColumns.find((col) => col.id === columnId);
				if (!column) return null;

				return (
					<LightTable.Column
						key={columnId}
						verticalAlign="middle"
						width={column.width}
						flexGrow={column.width ? 0 : 1}
						sortable
						resizable
					>
						<LightTable.HeaderCell style={{ padding: "0 20px" }}>
							{column.label}
						</LightTable.HeaderCell>
						<LightTable.Cell
							style={{ padding: "0 20px" }}
							dataKey={column.id}
							verticalAlign="middle"
							fullText
						>
							{(settings) => {
								switch (columnId) {
									case Columns.NAME:
										return settings.name[lang];

									case Columns.METHOD:
										return t(
											`${tPath}.modal.tabs.main.rounding.method.options.${settings.additionalFields.general.rounding.method}`,
										);

									case Columns.CURRENCY:
										return settings.currency.name[lang];

									case Columns.SHORT_NAME:
										return settings.currency.settings[
											settings.additionalFields.general
												.currency.displayStyle
										];

									default:
										return null;
								}
							}}
						</LightTable.Cell>
					</LightTable.Column>
				);
			}),
		[columnsId, tColumns, lang, t],
	);

	const getRowClassName = useCallback(
		(rowData) =>
			rowData && selected.includes(rowData.id) ? "selected" : "",
		[selected],
	);

	const areas = useMemo(() => `'table' 'settings' `, []);
	return (
		<StyledGrid
			areas={areas}
			rows="1fr 32px"
			w="100%"
			h="100%"
			br="5px"
			over="hidden"
		>
			<StyledGridItem
				area="table"
				w="100%"
				h="100%"
				tabIndex={-1}
				onKeyDown={onKeyDown}
				onKeyUp={onKeyUp}
			>
				<LightTable
					ref={tableRef}
					data={value}
					virtualized
					fillHeight
					headerHeight={44}
					rowHeight={44}
					shouldUpdateScroll={false}
					rowClassName={getRowClassName}
					onRowClick={handleOnRowClick}
					onRowDoubleClick={onEdit}
					sortColumn={sort?.dataKey}
					sortType={sort?.sortType}
					onScroll={onScroll}
					onSortColumn={onSort}
				>
					<LightTable.Column verticalAlign="middle" width={50}>
						<LightTable.HeaderCell style={{ padding: "0 25px" }}>
							<CheckBox
								value={
									selected.length === value.length &&
									value.length !== 0
								}
								onChange={(isAllSelected) => {
									if (isAllSelected) {
										setSelected(value.map(({ id }) => id));
									} else {
										setSelected([]);
									}
								}}
							/>
						</LightTable.HeaderCell>
						<LightTable.Cell
							verticalAlign="middle"
							style={{ padding: "0 25px" }}
						>
							{(template) => (
								<CheckBox
									value={selected.includes(template.id)}
									onChange={() => {
										updateSelected(template);
									}}
								/>
							)}
						</LightTable.Cell>
					</LightTable.Column>
					{renderColumns}
				</LightTable>
			</StyledGridItem>
			<StyledGridItem area="settings" w="100%" h="100%" zi="1">
				<TableSettings
					value={columnsId}
					defaultValue={defaultColumnsId}
					columns={tColumns}
					onChange={setColumnsId}
				/>
			</StyledGridItem>
		</StyledGrid>
	);
};

declare namespace Content {
	interface Props {
		value: AdditionalTariff.Model[];
		selected: number[];
		setSelected: Dispatch<number[]>;
		onEdit?: Dispatch<AdditionalTariff.Model>;
		editorTable: UseTableOptions["editor"];
		onChangeTable: UseTableOptions["onChange"];
	}

	type ColumnType = {
		id: string;
		label: string;
		width?: number;
		justify?: "center";
	};
}

export default Content;
