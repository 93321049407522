import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, TextSelect } from "uikit";
import Parking from "../../../../../../../services/Parking2";
import useModelSubscribe from "../../../../../../../hooks/useModelSubscribe2";
import { useTypedSelector } from "../../../../../../../redux/store";

const ParkingSearchField = ({
	query,
	error,
	onChangeError,
	onChangeAddressError,
	onChangeQuery,
	onChangeAddressQuery,
	onChangeSelectedParkingId,
	onChangeIsEditModal,
	tabIndex,
	isEditModal,
	taxiServiceData,
	selectedExecutor,
	taxiServiceId,
	onChangeTabIndex,
}) => {
	const { t } = useTranslation();
	const lang = useTypedSelector((state) => state.session.language);

	const [focusOnField, setFocusOnField] = useState<boolean>(false);
	const [firstLoad, setFirstLoad] = useState<boolean>(false);

	const parkingModelOptions = useMemo(() => {
		const result: Parking.SubscribeOptions = {
			language: lang,
			name: query,
		};

		result.taxiServiceIds = selectedExecutor?.taxiServiceId
			? [selectedExecutor.taxiServiceId]
			: [taxiServiceId];

		return result;
	}, [query, selectedExecutor?.taxiServiceId, taxiServiceId, lang]);

	const parkingsModelData = useModelSubscribe(parkingModelOptions, Parking);

	const parkings = useMemo(() => {
		if (parkingsModelData.models.length) {
			setFirstLoad(true);
		}
		return query ? parkingsModelData?.models : [];
	}, [parkingsModelData.models, query]);

	const onSelectParking = useCallback(
		(parking) => {
			onChangeSelectedParkingId(parking.value.id);
			onChangeQuery(parking.value.name);
			onChangeIsEditModal(true);
			onChangeAddressError(false);
			onChangeAddressQuery("");
			setFocusOnField(false);
		},
		[
			onChangeAddressError,
			onChangeAddressQuery,
			onChangeIsEditModal,
			onChangeQuery,
			onChangeSelectedParkingId,
		],
	);

	const parkingOptions = useMemo(() => {
		const options = parkings?.map((parking, index) => {
			const taxiService = taxiServiceData?.cache.find(
				(taxiService) => taxiService.id === parking.taxiServiceId,
			);
			const companyAndTaxiService = `${taxiService?.company?.name?.[lang]} (${taxiService?.settlement?.[lang]})`;
			return {
				key: index,
				label: `${parking.name?.[lang]} (${companyAndTaxiService})`,
				value: {
					id: parking.id,
					name: parking.name?.[lang],
				},
			};
		});
		return options;
	}, [parkings, taxiServiceData, lang]);

	useEffect(() => {
		if (!parkingsModelData.loading) {
			if (!parkingsModelData.metadataState.total && query) {
				if (firstLoad) {
					onChangeError(true);
				}
			} else {
				onChangeError(false);
				setFirstLoad(true);
			}
		}
	}, [
		onChangeError,
		query,
		parkings.length,
		parkingsModelData.loading,
		parkingsModelData.models.length,
		parkingsModelData.metadataState.total,
		firstLoad,
	]);

	const onFocus = useCallback(() => {
		setFocusOnField(true);
		onChangeTabIndex(1);
	}, [onChangeTabIndex]);

	const onBlur = useCallback(() => {
		setFocusOnField(false);
	}, []);

	useEffect(() => {
		if (tabIndex === 1) {
			onFocus();
		} else {
			onBlur();
		}
	}, [onBlur, onFocus, tabIndex]);

	return (
		<Row gaps="10px" maxedWidth align="center" justify="space-between">
			<span>
				{t(
					"orderPageWidgets.parking.parkingsModal.parkingSearchField.str200",
				) ?? ""}
				:
			</span>
			<TextSelect
				value={query}
				onChange={onChangeQuery}
				onSelect={onSelectParking}
				onBlur={onBlur}
				onFocus={onFocus}
				error={error}
				focused={focusOnField}
				placeholder={
					t(
						"orderPageWidgets.parking.parkingsModal.parkingSearchField.str0",
					) ?? ""
				}
				style={{ width: 390 }}
				options={parkingOptions}
				disabled={isEditModal}
			/>
		</Row>
	);
};

export default ParkingSearchField;
