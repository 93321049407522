import React, { useMemo } from "react";
import { isNumber } from "lodash";

import ExecutorReport from "../../../../../../../../../../services/ExecutorReport";

import { CellContentRoot } from "../../../../../../../../../../components/LightTable";

const CashCountCellContent: React.FC<CashCountCellContent.Props> = ({
	item,
}) => {
	const content = useMemo(() => {
		if (!isNumber(item?.ordersCounters?.cash?.count)) return "";
		return item.ordersCounters.cash.count;
	}, [item?.ordersCounters?.cash?.count]);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace CashCountCellContent {
	interface Props {
		item: ExecutorReport.Model;
	}
}

export default CashCountCellContent;
