import React, {
	Dispatch,
	RefAttributes,
	useCallback,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Option, Select, react } from "uikit";

import Language from "../../../../../../../../../../../../../../../../services/Language";
// import ExecutorGroup from "../../../../../../../../../../../../../../../../services/ExecutorGroup";
// import useModelSubscribe from "../../../../../../../../../../../../../../../../hooks/useModelSubscribe2";
import mapByKey from "../../../../../../../../../../../../../../../../utils/mapByKey";
import LabeledField from "../../../../../../../../../../../../../../../../components/LabeledField";

import { useExecutorContext } from "../../../../../../../../context";
import InternalController from "./Controller";

const ExecutorTeamId = react.withController<
	ExecutorTeamId.PropsBase,
	ExecutorTeamId.Controller
>(
	({
		value,

		language,
		disabled,
		// taxiServiceIds,

		onChange,
	}) => {
		const { t } = useTranslation();
		const { executorsGroup } = useExecutorContext();

		// const modelData = useModelSubscribe({ taxiServiceIds }, ExecutorGroup);
		// const items = useMemo(() => modelData.models, [modelData.models]);

		const [error, setError] = useState(false);

		const selectOptions = useMemo<Option<number>[]>(
			() =>
				executorsGroup.map((item) => ({
					key: item.id,
					label: item.name?.[language],
					value: item.id,
				})),
			[executorsGroup, language],
		);

		const selectOptionByKey = useMemo(
			() => mapByKey(selectOptions, "key"),
			[selectOptions],
		);

		const selectOnChange = useCallback(
			(key) => {
				setError(false);

				onChange(selectOptionByKey[key].value);
			},
			[onChange, selectOptionByKey],
		);

		return (
			<LabeledField
				label={
					t(
						"pages.mainPage.pages.accounts.tabs.executors.editModal.content.mainTab.workData.executorTeamId.str0",
					) ?? ""
				}
			>
				<Select
					value={value}
					disabled={disabled || executorsGroup.length === 0}
					error={error}
					placeholder={
						t(
							"pages.mainPage.pages.accounts.tabs.executors.editModal.content.mainTab.workData.executorTeamId.str1",
						) ?? ""
					}
					options={selectOptions}
					onChange={selectOnChange}
				/>
			</LabeledField>
		);
	},
	InternalController,
);

declare namespace ExecutorTeamId {
	type Ref = InternalController | null;

	type Controller = InternalController;

	type Value = number | undefined;

	interface PropsBase {
		value: Value;

		language: Language;
		disabled: boolean;
		taxiServiceIds: number[];

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default ExecutorTeamId;
