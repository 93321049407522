import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { CheckBox, Icon, theme } from "uikit";
import styled from "styled-components";

import {
	ACCESS_TO_THE_COMPONENT,
	AccessCompletedBranch,
	DisplayFields,
	AccessKey,
	ACCESS_TRANSLATE,
} from "../../../../../../../../../../../../constants/access";
import { StyledColumn } from "../../../../../../../../../../../../components/common";

import {
	Divider,
	Root,
	Button,
	Label,
	Header,
	NumericDial,
} from "./components";

const LeftPane = styled.div`
	flex: 1;
	margin: 10px;
`;

const RightPane = styled.div`
	flex: 1;
	margin: 10px;
`;

const ExpandableSection = styled.div<{ isOpen: boolean; level: number }>`
	cursor: pointer;
	display: flex;
	align-items: center;
	margin-left: ${(props) => props.level}px;
`;

const IconWrapper = styled(Icon)<{ isOpen: boolean }>`
	transform: ${(props) =>
		props.isOpen ? "rotate(-180deg)" : "rotate(0deg)"};
	transition: transform 0.3s ease;
	margin-left: 8px;
`;

const CheckboxContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;

const Column = styled(StyledColumn)<{ marginLeft: number }>`
	margin-left: ${(props) => props.marginLeft}px;
	margin-top: 20px;
	// cursor: pointer;
`;

const SelectedItem = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 5px;
`;

const TreeSelect: React.FC<TreeSelect.Props> = ({
	value,
	onChange,
	isMainRole,
	disabled,
}) => {
	const [t] = useTranslation();
	const [access, setAccess] = useState<DisplayFields>(
		value ?? ACCESS_TO_THE_COMPONENT,
	);
	const [openSections, setOpenSections] = useState<string[]>([]);
	const [selectedItems, setSelectedItems] = useState<string[]>([]);

	//* Function to initialize the selected elements in the order they are displayed in the tree
	const initializeSelectedItems = useCallback(
		(tree: DisplayFields, path: string[] = []): string[] => {
			let orderedItems: string[] = [];
			Object.keys(tree).forEach((key) => {
				const currentPath = [...path, key];
				const value = tree[key];

				if (key === AccessKey.SHOW && value) {
					orderedItems.push(path.join("."));
				} else if (typeof value === "object") {
					orderedItems = [
						...orderedItems,
						...initializeSelectedItems(
							value as DisplayFields,
							currentPath,
						),
					];
				}
			});
			return orderedItems;
		},
		[],
	);

	useEffect(() => {
		const orderedItems = initializeSelectedItems(access);
		setSelectedItems(orderedItems);
	}, [access, initializeSelectedItems]);

	useEffect(() => {
		onChange(access);
	}, [access, onChange]);

	const toggleSection = (path: string) => {
		setOpenSections((prev) =>
			prev.includes(path)
				? prev.filter((section) => section !== path)
				: [...prev, path],
		);
	};

	const updateItemAndChildren = (item: any, checked: boolean) => {
		if (typeof item === "object") {
			item[AccessKey.SHOW] = checked;
			updateAllChildren(item, checked);
		}
	};

	const updateAllChildren = (item: any, checked: boolean) => {
		if (typeof item === "object") {
			for (const key in item) {
				if (key === AccessKey.SHOW) {
					item[key] = checked;
				} else if (typeof item[key] === "object") {
					updateAllChildren(item[key], checked);
				}
			}
		}
	};

	const handleCheckboxChange = (path: string[], checked: boolean) => {
		const newAccess: DisplayFields = JSON.parse(JSON.stringify(access));
		let currentLevel = newAccess;

		for (let i = 0; i < path.length; i++) {
			const key = path[i];
			if (i === path.length - 1) {
				updateItemAndChildren(currentLevel[key], checked);
			} else {
				currentLevel = currentLevel[key];
			}
		}

		if (JSON.stringify(newAccess) !== JSON.stringify(access)) {
			setAccess(newAccess);
		}

		const orderedItems = initializeSelectedItems(newAccess);
		setSelectedItems(orderedItems);
	};

	const hasOtherProperties = (obj: DisplayFields) =>
		Object.keys(obj).some((key) => key !== AccessKey.SHOW);

	const handleRemoveItem = (item: string) => {
		const path = item.split(".");
		handleCheckboxChange(path, false);
	};

	const containsDot = (str: string): boolean => str.includes(".");

	const getDisabledStatus = (path: string[], checked: boolean): boolean => {
		// if (path.length === 0) return false;
		// if (path.length === 1) return true;

		let parent: DisplayFields | AccessCompletedBranch | undefined = access;

		for (const key of path.slice(0, -1)) {
			if (parent && typeof parent === "object" && key in parent) {
				parent = parent[key];
			} else {
				return false;
			}
		}

		if (parent && typeof parent === "object" && AccessKey.SHOW in parent) {
			return !parent[AccessKey.SHOW];
		}

		return false;
	};

	const renderAccessTree = (tree: DisplayFields, path: string[] = []) =>
		Object.keys(tree).map((key) => {
			const currentPath = [...path, key];
			const value = tree[key];

			if (key === AccessKey.SHOW) {
				return null;
			}

			const isChecked =
				typeof value === "boolean"
					? value
					: value?.[AccessKey.SHOW] || false;
			const isExpandable =
				typeof value === "object" && hasOtherProperties(value);
			const currentPathString = currentPath.join(".");
			const isDisabled = getDisabledStatus(currentPath, isChecked);

			const handleParentCheckboxChange = (checked: boolean) => {
				handleCheckboxChange(currentPath, checked);
			};

			const textTranslate =
				t(ACCESS_TRANSLATE?.[key] ?? key) ?? key ?? "";
			const isOpen = openSections.includes(currentPathString);

			return (
				<Column key={key} marginLeft={path.length ? 25 : 0}>
					<CheckboxContainer>
						<CheckBox
							disabled={isMainRole || disabled || isDisabled}
							value={!!isChecked}
							onChange={(selected) => {
								handleParentCheckboxChange(selected);
							}}
						/>
						{isExpandable ? (
							<ExpandableSection
								isOpen={isOpen}
								level={path.length}
								onClick={() => toggleSection(currentPathString)}
							>
								<Label selected={!!isChecked}>
									{textTranslate}
								</Label>
								<IconWrapper
									id="down-arrow"
									size={10}
									colors={[theme.colors.primary]}
									isOpen={isOpen}
								/>
							</ExpandableSection>
						) : (
							<Label selected={!!isChecked}>
								{textTranslate}
							</Label>
						)}
					</CheckboxContainer>
					{isExpandable &&
						openSections.includes(currentPathString) && (
							<div>
								{renderAccessTree(
									value as DisplayFields,
									currentPath,
								)}
							</div>
						)}
				</Column>
			);
		});

	const headerOnClear = () => {
		const newAccess = { ...access };
		const setAllShowFalse = (obj: AccessCompletedBranch) => {
			Object.keys(obj).forEach((key) => {
				if (key === AccessKey.SHOW) {
					obj[key] = false;
				} else if (typeof obj[key] === "object") {
					setAllShowFalse(obj[key] as AccessCompletedBranch);
				}
			});
		};

		setAllShowFalse(newAccess);

		setAccess(newAccess);
		setSelectedItems([]);
	};

	const getTextAfterLastDot = (str: string): string => {
		if (typeof str !== "string") return "";
		const lastDotIndex = str.lastIndexOf(".");

		return lastDotIndex !== -1 ? str.substring(lastDotIndex + 1) : str;
	};

	return (
		<Root>
			<LeftPane>{renderAccessTree(access)}</LeftPane>
			<Divider />
			<RightPane>
				<NumericDial
					disabled={isMainRole || disabled}
					count={selectedItems?.length ?? 0}
					onClear={headerOnClear}
				/>
				{selectedItems.map((item) =>
					containsDot(item) ? (
						<SelectedItem key={item}>
							<Label selected={true}>
								{t(
									ACCESS_TRANSLATE?.[
										getTextAfterLastDot(item)
									] ?? getTextAfterLastDot(item),
								) ??
									getTextAfterLastDot(item) ??
									""}
							</Label>
							<Button
								disabled={isMainRole || disabled}
								onClick={() => handleRemoveItem(item)}
							>
								<Icon
									id="trash"
									size={20}
									colors={[
										theme.colors.text_hovered_placeholder,
									]}
								/>
							</Button>
						</SelectedItem>
					) : (
						<Header
							key={item}
							label={
								t(ACCESS_TRANSLATE?.[item] ?? item) ??
								item ??
								""
							}
						/>
					),
				)}
			</RightPane>
		</Root>
	);
};

declare namespace TreeSelect {
	interface Props {
		value: DisplayFields;
		onChange: (newAccess: DisplayFields) => void;
		isMainRole: boolean;
		disabled: boolean;
	}
}

export default TreeSelect;
