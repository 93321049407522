import React, { Dispatch } from "react";
import { CheckBoxWithContent } from "uikit";

const Base: React.FC<Base.Props> = ({ value, disabled, label, onChange }) => (
	<CheckBoxWithContent
		value={value}
		disabled={disabled}
		gap="10px"
		onChange={onChange}
	>
		{label}
	</CheckBoxWithContent>
);

declare namespace Base {
	type Value = boolean;

	interface Props {
		value: Value;

		disabled: boolean;
		label: string;

		onChange: Dispatch<Value>;
	}
}

export default Base;
