/* eslint-disable react-hooks/rules-of-hooks */
import React, { Dispatch, PropsWithChildren, RefAttributes, memo } from "react";
import { CheckBox, react, useRefWithSetter } from "uikit";
import { useTranslation } from "react-i18next";

import { PaymentProviderAccessorType } from "../../../../../../../../../../../../services/Payment/types";
import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";
import {
	StyledColumn,
	StyledGridItem,
} from "../../../../../../../../../../../../components/common";
import tPath from "../../../../../../constants/tPath";
import Balances from "../../components/Balances";
import TabRoot from "../../components/TabRoot";

import InternalController from "./Controller";
import Other from "./components/Other";
import MainData from "./components/MainData";
import Options from "./components/Options";
import Notes from "./components/Notes";

const Main = memo(
	react.withController<Main.PropsBase, Main.Controller>(
		({
			counterpartyId,
			groups,
			visible,
			controller,
			value,
			onChange,
			children,
		}) => {
			const { t } = useTranslation();
			const [mainDataRef, setMainDataRef] =
				useRefWithSetter<MainData.Ref | null>(null);
			const [otherRef, setOtherRef] = useRefWithSetter<Other.Ref | null>(
				null,
			);
			controller.setContext({ mainDataRef, otherRef });

			const editor = useObjectEditor(value, onChange);

			const accountValue = editor.usePicker([
				"paymentAccounts",
				"paymentTransactions",
			]);

			const optionsData = editor.usePicker([
				"accessAllEmployeeToPersonalApp",
				"isAccessToCorporateAccount",
				"addCustomerAsEmployeeAfterOrder",
				"corporateLogin",
				"corporatePassword",
			]);

			const otherData = editor.usePicker([
				"latestTaxiServiceId",
				"companyId",
				"defaultTaxiServiceId",
				"counterpartyGroupId",
			]);

			const assigner = editor.useAssigner();

			const allowGeneralBonusBalance = editor.useGetter(
				"allowGeneralBonusBalance",
			);
			const setAllowGeneralBonusBalance = editor.useSetter(
				"allowGeneralBonusBalance",
			);
			const ignoreGeneralThresholdBalance = editor.useGetter(
				"ignoreGeneralThresholdBalance",
			);
			const setIgnoreGeneralThresholdBalance = editor.useSetter(
				"ignoreGeneralThresholdBalance",
			);

			const additionalFields = editor.useGetter("additionalFields");
			const setAdditionalFields = editor.useSetter("additionalFields");
			const additionalFieldsEditor = useObjectEditor(
				additionalFields,
				setAdditionalFields,
			);

			const mainData = additionalFieldsEditor.usePicker([
				"name",
				"edrpou",
				"contactName",
				"address",
				"email",
				"phones",
			]);

			const notesData = additionalFieldsEditor.usePicker([
				"notes",
				"orderNotes",
				"executorNotes",
			]);

			const additionalFieldsAssigner =
				additionalFieldsEditor.useAssigner();

			return (
				<TabRoot visible={visible} hasPaddings>
					<StyledColumn h="100%" w="100%">
						<StyledColumn gap="25px" h="100%" w="100%">
							<MainData
								ref={setMainDataRef}
								value={mainData}
								onChange={additionalFieldsAssigner}
							/>
							<Options value={optionsData} onChange={assigner}>
								<Other
									ref={setOtherRef}
									groups={groups}
									value={otherData}
									onChange={assigner}
								/>
							</Options>
							<Balances
								areas="'balances checkbox'"
								columns="60% 1fr"
								gap="10px"
								label={t([
									`${tPath}.modal.tabs.main.check.title`,
									"Balances",
								])}
								ignoreGeneralThresholdBalance={
									ignoreGeneralThresholdBalance
								}
								allowGeneralBonusBalance={
									allowGeneralBonusBalance
								}
								entityId={counterpartyId}
								ownerId={otherData.companyId}
								owner={PaymentProviderAccessorType.COMPANY}
								tPath={`${tPath}.modal.tabs.main.check.balances`}
								value={accountValue}
								onChange={assigner}
							>
								<StyledGridItem area="checkbox" m="0 0 0 auto">
									<StyledColumn gap="7px">
										<CheckBox
											label={
												t([
													`${tPath}.modal.tabs.main.check.ignore`,
													"Ignore the general disconnection threshold 1",
												]) ||
												"Ignore the general disconnection threshold"
											}
											value={
												ignoreGeneralThresholdBalance
											}
											onChange={
												setIgnoreGeneralThresholdBalance
											}
										/>
										<CheckBox
											disabled={
												ignoreGeneralThresholdBalance
											}
											label={
												t([
													`${tPath}.modal.tabs.main.check.allowed`,
													"Allow payment from the general bonus account",
												]) ||
												"Allow payment from the general bonus account"
											}
											value={allowGeneralBonusBalance}
											onChange={
												setAllowGeneralBonusBalance
											}
										/>
									</StyledColumn>
								</StyledGridItem>
							</Balances>

							<StyledColumn h="100%" w="100%">
								{children}
							</StyledColumn>
							<Notes
								value={notesData}
								onChange={additionalFieldsAssigner}
							/>
						</StyledColumn>
					</StyledColumn>
				</TabRoot>
			);
		},
		InternalController,
	),
);

declare namespace Main {
	type Ref = InternalController | null;
	type Controller = InternalController;

	interface PropsBase extends PropsWithChildren {
		counterpartyId?: number;
		groups: Other.Props["groups"];
		visible: boolean;
		value: Value;
		onChange: Dispatch<Value>;
	}
	type Props = PropsBase & RefAttributes<Ref>;

	interface Value extends Options.Value, Other.Value, Balances.Value {
		additionalFields: AdditionalFields;
		allowGeneralBonusBalance: boolean;
		ignoreGeneralThresholdBalance: boolean;
	}

	interface AdditionalFields extends MainData.Value, Notes.Value {
		deactivationThreshold: {
			value: number;
			description: string;
		};
	}
}

export default Main;
