import styled from "styled-components";
import { Column, theme } from "uikit";

const Root = styled(Column)`
	padding: 10px;

	overflow: auto;

	${theme.scrollbar}
`;

export default Root;
