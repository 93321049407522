import React, { memo, useCallback, useState } from "react";
import { Button, Icon } from "uikit";

import { PaymentProviderAccessorType } from "../../../../../../../../../../services/Payment/types";
import { PaymentAccount } from "../../../../../../../../../../types/PaymentAccount";
import { updateBalance } from "../../../../../../../../../../utils/updateBalances";
import BalanceModal from "../../../../../../../../../../components/BalanceModal";
import { useAccountingExecutors } from "../../../../../../hooks";
import ExecutorReport from "../../../../../../../../../../services/ExecutorReport";

const Balance: React.FC<Balance.Props> = memo(
	({ taxiServiceId, paymentAccount, iconId, executorId }) => {
		const [showModal, setShowModal] = useState(false);
		const { filters, updateExecutor, setIsSortMap } =
			useAccountingExecutors();

		const handleOpen = useCallback(() => {
			setShowModal(true);
		}, []);
		const handleClose = useCallback(() => {
			setShowModal(false);
		}, []);

		const handleSubmit = useCallback(
			async ({ amount, description }) => {
				if (amount === 0 || !paymentAccount || !taxiServiceId) {
					setShowModal(false);
					return;
				}

				const res = await updateBalance(
					{
						...paymentAccount,
						isEdited: true,
						amount,
						description,
					},
					taxiServiceId,
					PaymentProviderAccessorType.TAXI_SERVICE,
				);

				if (res) {
					setShowModal(false);
					if (executorId) {
						const params: ExecutorReport.SearchOptions = {
							dateRange: filters.dateRange,
							executorIds: [executorId],
						};

						const res = await ExecutorReport.getExecutorReport(
							params,
						);

						if (res && res.items?.length) {
							const exist = res.items.find(
								(executor) => executor.id === executorId,
							);
							if (exist) {
								updateExecutor(exist);

								setIsSortMap(true);
							}
						}
					}
				}
			},
			[
				paymentAccount,
				taxiServiceId,
				filters.dateRange,
				executorId,
				updateExecutor,
				setIsSortMap,
			],
		);

		return (
			<div>
				<Button.Button
					variant="secondary"
					disabled={!taxiServiceId}
					onClick={handleOpen}
					icon={<Icon id={iconId} size={20} />}
				/>
				{showModal && (
					<BalanceModal
						onClose={handleClose}
						onSubmit={handleSubmit}
					/>
				)}
			</div>
		);
	},
);

declare namespace Balance {
	interface Props {
		taxiServiceId?: number | null;
		paymentAccount?: PaymentAccount;
		iconId: "balance" | "bonus-balance";
		executorId?: number | null;
	}
}

export default Balance;
