import React, { useMemo } from "react";

import {
	StyledGrid,
	StyledGridItem,
	SuspenseLoader,
} from "../../../../../../components/common";

const LoyaltyProgram: React.FC = () => {
	const areas = useMemo(() => `'header' 'main'`, []);
	return (
		<StyledGrid areas={areas} rows="50px 1fr" w="100%" h="100%">
			<StyledGridItem area="header"></StyledGridItem>
			<StyledGridItem area="main" justifySelf="center" alignSelf="center">
				<SuspenseLoader />
			</StyledGridItem>
		</StyledGrid>
	);
};

export default LoyaltyProgram;
