import styled from "styled-components";
import { theme } from "uikit";

const Divider = styled.div`
	width: 1px;
	height: 100%;

	background: ${theme.colors.button_secondary};
`;

export default Divider;
