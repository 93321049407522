import { getPRPC } from "../../../hooks/usePRPC";
import createRPCQuery from "../../../utils/createRPCQuery.util";

export default function editOrder(id, order) {
	return () => {
		const prpcow = getPRPC();

		if (!prpcow) return null;

		return createRPCQuery(
			() => {
				console.log("[editOrder]: orders.update request!", id, order);
				return prpcow?.theirsModel.orders.update(id, order);
			},
			{ verbose: true, name: "order.update" },
		);
	};
}
