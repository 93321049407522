/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import { LatLngLiteral } from "leaflet";
import { clone, defaults, isEqual } from "lodash";
import { getPRPC } from "../../hooks/usePRPC";
import ModelService from "../../redux/services/ModelService";
import { destroyOne, joinOne, leaveOne, rearrangeOne } from "./utils";
import Language from "../Language";
import ServiceSubscribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";
import createRPCQuery from "../../utils/createRPCQuery.util";
import parkings from "../../redux/reducers/parkings";
import Executor from "../Executor";

class Parking extends ModelService<
	Parking.SubscribeOptions,
	Parking.Model,
	"parkings"
>(parkings, (state) => state.parkings) {
	static defaultSharedOptions: Parking.SharedOptions = {
		deprecate: true,
	};

	static fromResponse(data: any): Parking.Model {
		const sectorIds = data.parkingToSectors.map(
			(parkingToSector) => parkingToSector.sector.id,
		) as (number | "outside")[];

		if (data.outside) sectorIds.unshift("outside");

		return {
			id: data.id,

			taxiServiceId: data.taxiService.id,
			sectorIds,

			executorsQueue: data.executorsQueue,

			position: new Date(data.position),
			name: data.name,
			vertices: data.vertices[0].slice(0, -1),

			active: data.status,
			bySector: data.bySector,

			createdAt: "",
			updatedAt: "",
			deletedAt: null,
		};
	}

	static toRequest(model: Parking.Model.New | Parking.Model.Modified): any {
		const vertices = clone(model.vertices);

		if (vertices && !isEqual(vertices[vertices.length - 1], vertices[0]))
			vertices.push(vertices[0]);

		const data: any = {
			vertices,
			taxiServiceId: model.taxiServiceId,
			executorsQueue: model.executorsQueue,
			position: model.position,
			name: model.name,
			point: model.point,
			status: model.active,
		};
		if (!model.point) delete data.point;
		if (!model.executorsQueue) delete data.executorsQueue;

		if (Array.isArray(model.sectorIds)) {
			const outside = model.sectorIds.includes("outside");
			const sectorIds = model.sectorIds.filter(
				(sectorId) => sectorId !== "outside",
			);

			data.sectorIds = sectorIds;
			data.outside = outside;
		}

		return data;
	}

	static async getById(parkingId: number, options?: Parking.StoreOptions) {
		options = defaults(options, Parking.defaultSharedOptions);

		const prpc = getPRPC();

		if (!prpc) return null;

		const result = await createRPCQuery(() =>
			prpc.theirsModel.parking.getById(parkingId),
		);

		if (options.deprecate) Parking.deprecateAll();
		return result;
	}

	static async getAll(
		requestOptions: Parking.SubscribeOptions,
		options?: Parking.StoreOptions,
	) {
		options = defaults(options, Parking.defaultSharedOptions);

		const prpc = getPRPC();

		if (!prpc) return null;

		const result = await createRPCQuery(() =>
			prpc.theirsModel.parking.getAll(requestOptions),
		);

		if (options.deprecate) Parking.deprecateAll();
		return result;
	}

	static async store(
		object: Parking.Model.New,
		options?: Parking.StoreOptions,
	) {
		options = defaults(options, Parking.defaultSharedOptions);

		const prpc = getPRPC();

		if (!prpc) return;

		await createRPCQuery(() =>
			prpc.theirsModel.parking.create(Parking.toRequest(object)),
		);

		if (options.deprecate) Parking.deprecateAll();
	}

	static async update(
		object: Parking.Model.Modified,
		options?: Parking.UpdateOptions,
	) {
		options = defaults(options, Parking.defaultSharedOptions);

		const prpc = getPRPC();

		if (!prpc) return;

		await createRPCQuery(() =>
			prpc.theirsModel.parking.update(
				object.id,
				Parking.toRequest(object),
			),
		);

		if (options.deprecate) Parking.deprecateAll();
	}

	static async destroy(
		id: number[] | number,
		options?: Parking.DestroyOptions,
	) {
		options = defaults(options, Parking.defaultSharedOptions);

		const prpc = getPRPC();

		if (!prpc) return;

		if (Array.isArray(id))
			await Promise.all(id.map((id) => destroyOne(id)));
		else await destroyOne(id);

		if (options.deprecate) Parking.deprecateAll();
	}

	static async join(
		parkingId: number,
		executorsIds: number[],
		options?: Parking.DestroyOptions,
	) {
		options = defaults(options, Parking.defaultSharedOptions);

		const prpc = getPRPC();

		if (!prpc) return;

		if (Array.isArray(executorsIds))
			await Promise.all(
				executorsIds.map((executorId) =>
					joinOne(parkingId, executorId),
				),
			);
		if (options.deprecate) Parking.deprecateAll();
	}

	static async leave(
		executorsIds: number[],
		options?: Parking.DestroyOptions,
	) {
		options = defaults(options, Parking.defaultSharedOptions);

		const prpc = getPRPC();

		if (!prpc) return;

		if (Array.isArray(executorsIds))
			await Promise.all(
				executorsIds.map((executorId) => leaveOne(executorId)),
			);
		if (options.deprecate) Parking.deprecateAll();
	}

	static async rearrange(
		parkingId: number,
		executors: Parking.RearrangePosition[],
		options?: Parking.DestroyOptions,
	) {
		options = defaults(options, Parking.defaultSharedOptions);

		const prpc = getPRPC();

		if (!prpc) return;

		if (Array.isArray(executors))
			await Promise.all(
				executors.map((executor) => rearrangeOne(parkingId, executor)),
			);

		if (options.deprecate) Parking.deprecateAll();
	}

	static Global = {
		async index(options: Parking.SubscribeOptions) {
			const prpc = getPRPC();

			if (!prpc) return null;

			const result = await createRPCQuery(() =>
				prpc.theirsModel.parking.getAll({
					limit: options.limit,
					offset: options.offset,
					query: options.query,
					name: options.name,

					taxiServiceIds: options.taxiServiceIds,

					status: options.active,

					lang: options.language,

					order: options.order,
				}),
			);

			return {
				cache: result.items.map(Parking.fromResponse),
				offset: result.pagination.offset,
				limit: result.pagination.count,
				total: result.pagination.count,
				deprecated: false,
			};
		},
	};
}

declare namespace Parking {
	interface Model {
		id: number;

		taxiServiceId: number;
		sectorIds: (number | "outside")[];

		executorsQueue: {
			id: number;
			executor: Executor.Model;
			createdAt: string;
			position: string;
		}[];

		position: Date | number;
		name: Record<Language, string>;
		vertices: LatLngLiteral[];

		point?: {
			lat: number;
			lng: number;
		};

		active: boolean;
		bySector: boolean;

		createdAt: string;
		updatedAt: string;
		deletedAt: string | null;
	}

	interface ExecutorModel {
		id: number;
		bySector?: boolean;
		geoCentroid?: { type: string; coordinates: number[] };
		name?: {
			ru?: string;
			en?: string;
			uk?: string;
		};
		outside?: boolean;
		position?: string;
		status?: boolean;
		vertices?: {
			type: string;
			coordinates: number[][];
		};
	}
	interface CurrentParking {
		createdAt?: string;
		id: number;
		parking: ExecutorModel;
		position: string;
	}

	interface RearrangePosition {
		executorId: number;
		position: number | Date;
	}

	interface SubscribeOptions
		extends ServiceSubscribeOptionsBase<Parking.Model> {
		taxiServiceIds?: number[];
		sectorIds?: number[];
		name?: string;
		point?: {
			lat: number;
			lng: number;
		};

		language?: Language;

		active?: boolean;
	}

	interface SharedOptions {
		deprecate?: boolean;
	}

	interface StoreOptions extends SharedOptions {}
	interface UpdateOptions extends SharedOptions {}
	interface DestroyOptions extends SharedOptions {}

	namespace Model {
		type New = Omit<
			Model,
			"id" | "createdAt" | "updatedAt" | "deletedAt" | "bySector"
		>;
		interface Modified
			extends Partial<
				Omit<
					Model,
					| "createdAt"
					| "updatedAt"
					| "deletedAt"
					| "bySector"
					| "executorsQueue"
				>
			> {
			id: number;
			active: boolean;
			executorsQueue?: number[];
		}
	}
}

export default Parking;
