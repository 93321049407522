import React from "react";
import { Switch } from "react-router-dom";

import { RequireAuth, RequireNotAuth, Layout } from "../middlewares";
import { MainLayout, AuthLayout } from "../components";
import { ROUTES } from "../constants/routes";
import { generateAccessName, AccessKey } from "../access";
// * import pages
import LoginPage from "../pages/LoginPage";
import InterfaceSettings from "../pages/Settings/pages/Interface";
import OrdersSettings from "../pages/Settings/pages/Orders";
import MessageSettings from "../pages/Settings/pages/Message";
import PaymentSystemSettings from "../pages/Settings/pages/PaymentSystem";
import VoipSettings from "../pages/Settings/pages/Voip";
import Clients from "../pages/MainPage/pages/Customers";
import Blacklist from "../pages/MainPage/pages/Blacklist";
import Accounting from "../pages/MainPage/pages/Accounting";
import Accounts from "../pages/MainPage/pages/Accounts";
import Archives from "../pages/MainPage/pages/Archives";
import OrderExchange from "../pages/MainPage/pages/OrderExchange";
import Reports from "../pages/MainPage/pages/Reports";
import Charts from "../pages/MainPage/pages/Reports/pages/Charts";
import AccountingReports from "../pages/MainPage/pages/Reports/pages/AccountingReports";
import TariffsSettings from "../pages/Settings/pages/Tariffs";
import MobileApps from "../pages/Settings/pages/MobileApps";
import Finances from "../pages/Settings/pages/Finances";
import Parameters from "../pages/Settings/pages/Parameters";
import MainPage from "../pages/MainPage";
import Settings from "../pages/Settings";
import OrderSettingPage from "../pages/Settings/pages/Map";
import PreferencesPages from "../pages/PreferencesPages";
import MainPageOrder from "../pages/MainPage/pages/Orders";

import { RouterWithAuthControl } from ".";

const MainPageComponent = RequireAuth(Layout(MainPage, MainLayout));
const LoginPageComponent = RequireNotAuth(Layout(LoginPage, AuthLayout));

const MainOrderPageComponent = RequireAuth(Layout(MainPageOrder, MainLayout));
const AccountingPageComponent = RequireAuth(Layout(Accounting, MainLayout));
const AccountsPageComponent = RequireAuth(Layout(Accounts, MainLayout));
const ArchivesPageComponent = RequireAuth(Layout(Archives, MainLayout));
const ReportsPageComponent = RequireAuth(Layout(Reports, MainLayout));
const ChartsPageComponent = RequireAuth(Layout(Charts, MainLayout));
const AccountingReportsPageComponent = RequireAuth(
	Layout(AccountingReports, MainLayout),
);
const CustomersPageComponent = RequireAuth(Layout(Clients, MainLayout));
const BlacklistPageComponent = RequireAuth(Layout(Blacklist, MainLayout));
const PreferencesPagesComponent = RequireAuth(
	Layout(PreferencesPages, MainLayout),
);
const SettingsPageComponent = RequireAuth(Layout(Settings, MainLayout));
const OrdersSettingsPageComponent = RequireAuth(
	Layout(OrdersSettings, MainLayout),
);
const TariffsSettingsPageComponent = RequireAuth(
	Layout(TariffsSettings, MainLayout),
);
const FinancesPageComponent = RequireAuth(Layout(Finances, MainLayout));
const MessageSettingsPageComponent = RequireAuth(
	Layout(MessageSettings, MainLayout),
);
const MobileAppsPageComponent = RequireAuth(Layout(MobileApps, MainLayout));
const ParametersPageComponent = RequireAuth(Layout(Parameters, MainLayout));
const OrderSettingPageComponent = RequireAuth(
	Layout(OrderSettingPage, MainLayout),
);
const OrderExchangePageComponent = RequireAuth(
	Layout(OrderExchange, MainLayout),
);
const PaymentSystemSettingsPageComponent = RequireAuth(
	Layout(PaymentSystemSettings, MainLayout),
);
const InterfaceSettingsPageComponent = RequireAuth(
	Layout(InterfaceSettings, MainLayout),
);
const VoipSettingsPageComponent = RequireAuth(Layout(VoipSettings, MainLayout));

const RouterSwitch: React.FC = () => (
	<Switch>
		<RouterWithAuthControl
			exact
			path={ROUTES.MAIN}
			component={MainPageComponent}
			accessName={AccessKey.MAIN}
		/>
		<RouterWithAuthControl
			exact
			path={ROUTES.LOGIN}
			component={LoginPageComponent}
			accessName={AccessKey.LOGIN}
		/>
		<RouterWithAuthControl
			exact
			key="test"
			path={ROUTES.ORDERS}
			component={MainOrderPageComponent}
			accessName={AccessKey.ORDERS}
		/>
		<RouterWithAuthControl
			exact
			path={ROUTES.ACCOUNTING}
			component={AccountingPageComponent}
			accessName={AccessKey.ACCOUNTING}
		/>
		<RouterWithAuthControl
			exact
			path={ROUTES.ACCOUNTS}
			component={AccountsPageComponent}
			accessName={AccessKey.ACCOUNTS}
		/>
		<RouterWithAuthControl
			exact
			path={ROUTES.ARCHIVES}
			component={ArchivesPageComponent}
			accessName={AccessKey.ARCHIVES}
		/>
		<RouterWithAuthControl
			exact
			path={ROUTES.REPORTS}
			component={ReportsPageComponent}
			accessName={AccessKey.REPORTS}
		/>
		<RouterWithAuthControl
			exact
			path={ROUTES.CUSTOMERS}
			component={CustomersPageComponent}
			accessName={AccessKey.CUSTOMERS}
		/>
		<RouterWithAuthControl
			exact
			path={ROUTES.BLACKLIST}
			component={BlacklistPageComponent}
			accessName={AccessKey.BLACKLIST}
		/>
		<RouterWithAuthControl
			path={ROUTES.PREFERENCES_PAGENAME}
			component={PreferencesPagesComponent}
			accessName={AccessKey.PREFERENCES_PAGE_NAME}
		/>
		<RouterWithAuthControl
			exact
			path={ROUTES.SETTINGS}
			component={SettingsPageComponent}
			accessName={AccessKey.SETTINGS}
		/>
		{/* // ? settings part */}
		<RouterWithAuthControl
			exact
			path={ROUTES.SETTINGS_ORDERS}
			component={OrdersSettingsPageComponent}
			accessName={generateAccessName(
				AccessKey.SETTINGS,
				AccessKey.SETTINGS_ORDERS,
			)}
		/>
		<RouterWithAuthControl
			exact
			path={ROUTES.SETTINGS_TARIFFS}
			component={TariffsSettingsPageComponent}
			accessName={generateAccessName(
				AccessKey.SETTINGS,
				AccessKey.SETTINGS_TARIFFS,
			)}
		/>
		<RouterWithAuthControl
			exact
			path={ROUTES.SETTINGS_FINANCES}
			component={FinancesPageComponent}
			accessName={generateAccessName(
				AccessKey.SETTINGS,
				AccessKey.SETTINGS_FINANCES,
			)}
		/>
		<RouterWithAuthControl
			exact
			path={ROUTES.SETTINGS_MESSAGES}
			component={MessageSettingsPageComponent}
			accessName={generateAccessName(
				AccessKey.SETTINGS,
				AccessKey.SETTINGS_MESSAGES,
			)}
		/>
		<RouterWithAuthControl
			exact
			path={ROUTES.SETTINGS_MOBILE_APPS}
			component={MobileAppsPageComponent}
			accessName={generateAccessName(
				AccessKey.SETTINGS,
				AccessKey.SETTINGS_MOBILE_APPS,
			)}
		/>
		<RouterWithAuthControl
			exact
			path={ROUTES.SETTINGS_PARAMETERS}
			component={ParametersPageComponent}
			accessName={generateAccessName(
				AccessKey.SETTINGS,
				AccessKey.SETTINGS_PARAMETERS,
			)}
		/>
		<RouterWithAuthControl
			exact
			path={ROUTES.SETTINGS_MAP}
			component={OrderSettingPageComponent}
			accessName={generateAccessName(
				AccessKey.SETTINGS,
				AccessKey.SETTINGS_MAP,
			)}
		/>
		<RouterWithAuthControl
			exact
			path={ROUTES.SETTINGS_ORDER_EXCHANGE}
			component={OrderExchangePageComponent}
			accessName={generateAccessName(
				AccessKey.SETTINGS,
				AccessKey.SETTINGS_ORDER_EXCHANGE,
			)}
		/>
		<RouterWithAuthControl
			exact
			path={ROUTES.SETTINGS_PAYMENT_SYSTEMS}
			component={PaymentSystemSettingsPageComponent}
			accessName={generateAccessName(
				AccessKey.SETTINGS,
				AccessKey.SETTINGS_PAYMENT_SYSTEMS,
			)}
		/>
		<RouterWithAuthControl
			exact
			path={ROUTES.SETTINGS_INTERFACE}
			component={InterfaceSettingsPageComponent}
			accessName={generateAccessName(
				AccessKey.SETTINGS,
				AccessKey.SETTINGS_INTERFACE,
			)}
		/>
		<RouterWithAuthControl
			exact
			path={ROUTES.SETTINGS_VOIP}
			component={VoipSettingsPageComponent}
			accessName={generateAccessName(
				AccessKey.SETTINGS,
				AccessKey.SETTINGS_VOIP,
			)}
		/>
		{/* // ? reports part */}
		<RouterWithAuthControl
			exact
			path={ROUTES.REPORTS_CHARTS}
			component={ChartsPageComponent}
			accessName={generateAccessName(
				AccessKey.REPORTS,
				AccessKey.REPORTS_CHARTS,
			)}
		/>
		<RouterWithAuthControl
			exact
			path={ROUTES.REPORTS_ACCOUNTING}
			component={AccountingReportsPageComponent}
			accessName={generateAccessName(
				AccessKey.REPORTS,
				AccessKey.REPORTS_ACCOUNTING,
			)}
		/>
	</Switch>
);

export default RouterSwitch;
