import React, { memo, useCallback } from "react";
import { DateAndTimePicker } from "uikit";
import { useTranslation } from "react-i18next";

import { StyledP, StyledRow } from "../../../../../../components/common";
import useObjectEditor from "../../../../../../hooks/useObjectEditor";

const DateRange: React.FC<DateRange.Props> = memo(({ value, onChange }) => {
	const { t } = useTranslation();

	const valueEditor = useObjectEditor(value, onChange);

	const dateFrom = valueEditor.useGetter("dateFrom");
	const setDateFrom = valueEditor.useSetter("dateFrom");

	const dateTo = valueEditor.useGetter("dateTo");
	const setDateTo = valueEditor.useSetter("dateTo");

	const handleFromChange = useCallback(
		(newFrom) => {
			setDateFrom(newFrom);
		},
		[setDateFrom],
	);

	const handleToChange = useCallback(
		(newTo) => {
			setDateTo(newTo);
		},
		[setDateTo],
	);

	return (
		<StyledRow gap="7px" alignItems="center">
			<StyledP>{t(`from`)}</StyledP>
			<DateAndTimePicker
				value={dateFrom}
				onChange={handleFromChange}
				maxDate={dateTo}
			/>
			<StyledP>{t(`to`)}</StyledP>
			<DateAndTimePicker
				value={dateTo}
				onChange={handleToChange}
				minDate={dateFrom}
			/>
		</StyledRow>
	);
});

declare namespace DateRange {
	interface Value {
		dateFrom: Date;
		dateTo: Date;
	}

	interface Props {
		value: Value;
		onChange: (value: Value) => void;
	}
}

export default DateRange;
