import { getPRPC } from "../../../hooks/usePRPC";
import createRPCQuery from "../../../utils/createRPCQuery.util";

export default function calcOrderCost(order) {
	return async () => {
		const prpcow = getPRPC();

		if (!prpcow) return null;

		const res = await createRPCQuery(
			() => prpcow?.theirsModel.orders.cost.calculate(order),
			{ verbose: true, silent: true, name: "order.cost.calculate" },
		);

		return res;
	};
}
