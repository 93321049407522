/* eslint-disable no-shadow */

import React, {
	Dispatch,
	RefAttributes,
	memo,
	useCallback,
	useMemo,
} from "react";
import { Column, Option, Row, Select, react, useRefWithSetter } from "uikit";
import { useTranslation } from "react-i18next";

import Client from "../../../../../../../../../../../../../../../services/Client";
import useObjectEditor from "../../../../../../../../../../../../../../../hooks/useObjectEditor";
import LabeledField from "../../../../../../../../../../../../../../../components/LabeledField";
import useModelSubscribe from "../../../../../../../../../../../../../../../hooks/useModelSubscribe2";
import CustomerRate from "../../../../../../../../../../../../../../../services/CustomerRate";
import { useTypedSelector } from "../../../../../../../../../../../../../../../redux/store";

import InternalController from "./Controller";
import ClientGroup from "./components/ClientGroup";
import CompanyAndTaxiService from "./components/CompanyAndTaxiService";
import CarClassId from "./components/CarClassIId";

declare namespace AdditionalData {
	type Ref = InternalController;

	type Controller = InternalController;

	type Value = Pick<
		Client.Model,
		"carClassId" | "customerGroupId" | "latestTaxiServiceId"
	> &
		CompanyAndTaxiService.Value & {
			mainRateId?: number;
			additionalRateId?: number;
		};

	interface PropsBase {
		groups: ClientGroup.Props["groups"];
		value: Value;
		onChange: Dispatch<Value>;
		isCompanyEditable: boolean;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

const AdditionalDataBase = react.withController<
	AdditionalData.PropsBase,
	AdditionalData.Controller
>(({ groups, value, onChange, isCompanyEditable, controller }) => {
	const language = useTypedSelector((state) => state.session.language);

	const { t } = useTranslation();

	const mainCustomerRateData = useModelSubscribe(
		{ type: CustomerRate.Model.Type.Main },
		CustomerRate,
	);

	const additionalCustomerRateData = useModelSubscribe(
		{ type: CustomerRate.Model.Type.Additional },
		CustomerRate,
	);

	const mainCustomerRateOptions = useMemo<Option<number>[]>(
		() =>
			mainCustomerRateData.models.map((customerRate) => ({
				key: customerRate.id,
				value: customerRate.id,
				label: customerRate.name[language],
			})),
		[language, mainCustomerRateData.models],
	);

	const additionalCustomerRateOptions = useMemo<Option<number>[]>(
		() =>
			additionalCustomerRateData.models.map((customerRate) => ({
				key: customerRate.id,
				value: customerRate.id,
				label: customerRate.name[language],
			})),
		[language, additionalCustomerRateData.models],
	);

	const [companyAndTaxiServiceRef, setCompanyAndTaxiServiceRef] =
		useRefWithSetter<CompanyAndTaxiService.Ref | null>(null);

	const valueEditor = useObjectEditor(value, onChange);
	controller.setContext({ companyAndTaxiServiceRef });
	// const picker = valueEditor.usePicker([]);
	// const assigner = valueEditor.useAssigner();

	const taxiServiceId = valueEditor.useGetter("defaultTaxiServiceId");
	const carClassId = valueEditor.useGetter("carClassId");
	const setCarClassId = valueEditor.useSetter("carClassId");

	const groupId = valueEditor.useGetter("customerGroupId");
	const setGroupId = valueEditor.useSetter("customerGroupId");

	const mainRateIdValue = valueEditor.useGetter("mainRateId");
	const mainRateIdOnChange = valueEditor.useSetter("mainRateId");

	const additionalRateIdValue = valueEditor.useGetter("additionalRateId");
	const additionalRateIdOnChange = valueEditor.useSetter("additionalRateId");

	const onCarClassIdChange = useCallback<React.Dispatch<typeof carClassId>>(
		(carClassId) => {
			// required to replace undefined with null
			// or else deletion of carClass won't work

			setCarClassId(carClassId == null ? -1 : carClassId);
		},
		[setCarClassId],
	);

	return (
		<Column gaps="8px*">
			<CompanyAndTaxiService
				ref={setCompanyAndTaxiServiceRef}
				isCompanyEditable={isCompanyEditable}
				value={value}
				onChange={onChange}
			>
				<ClientGroup
					groups={groups}
					value={groupId}
					onChange={setGroupId}
				/>
			</CompanyAndTaxiService>

			<Row gaps="8px*" sizes="275.5px*">
				<LabeledField
					label={
						t(
							`mainPage.customers.clients.main.additionalData.mainLoyaltyProgram`,
						) || "Main loyalty program"
					}
				>
					<Select
						value={mainRateIdValue}
						options={mainCustomerRateOptions}
						disabled={mainCustomerRateOptions.length === 0}
						onSelect={(option) => mainRateIdOnChange(option?.value)}
					/>
				</LabeledField>

				<LabeledField
					label={
						t(
							`mainPage.customers.clients.main.additionalData.additionalLoyaltyProgram`,
						) || "Additional loyalty program"
					}
				>
					<Select
						value={additionalRateIdValue}
						options={additionalCustomerRateOptions}
						disabled={additionalCustomerRateOptions.length === 0}
						onSelect={(option) =>
							additionalRateIdOnChange(option?.value)
						}
					/>
				</LabeledField>
				<CarClassId
					taxiServiceId={taxiServiceId}
					value={carClassId}
					onChange={onCarClassIdChange}
				/>
				<LabeledField
					label={
						t(
							`mainPage.customers.clients.main.additionalData.tariff`,
						) || "Tariff"
					}
				>
					<Select disabled />
				</LabeledField>
			</Row>
		</Column>
	);
}, InternalController);

const AdditionalData = memo(AdditionalDataBase);

export default AdditionalData;
