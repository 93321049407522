/* eslint-disable no-shadow */

import React, {
	Dispatch,
	Key,
	createContext,
	useCallback,
	useContext,
	useMemo,
} from "react";
import { CheckBox, Column, Icon, theme } from "uikit";
import { noop } from "lodash";

import Item from "../..";
import usePermissionService from "../../../../../../../../../../../../../../../../../../../../../../hooks/usePermissionService";
import useObjectEditor from "../../../../../../../../../../../../../../../../../../../../../../hooks/useObjectEditor";

import List from "./components/List";
import Label from "./components/Label";
import Header from "./components/Header";

export interface ExpandedContext {
	items: string[];

	onChangeItems: Dispatch<string[]>;
}

export const ExpandedContext = createContext<ExpandedContext | null>(null);

const Expandable: React.FC<Expandable.Props> = ({
	value,

	disabled,
	permission,
	label,
	options,

	onChange,
}) => {
	const valueEditor = useObjectEditor(value, onChange);

	const permissionService = usePermissionService();

	const expandedContext = useContext(ExpandedContext);

	const expandedContextItems = useMemo(
		() => expandedContext?.items ?? [],
		[expandedContext?.items],
	);

	const expandedContextOnChangeItems = useMemo(
		() => expandedContext?.onChangeItems ?? noop,
		[expandedContext?.onChangeItems],
	);

	const expandedItemsEditor = useObjectEditor(
		expandedContextItems,
		expandedContextOnChangeItems,
	);

	const expanded = useMemo(
		() => expandedItemsEditor.includes(permission),
		[expandedItemsEditor, permission],
	);

	const setExpanded = useCallback(
		(value: boolean) =>
			value
				? expandedItemsEditor.push(permission)
				: expandedItemsEditor.removeByValue(permission),
		[expandedItemsEditor, permission],
	);

	const selectedAllValue = useMemo(
		() => permissionService.has(valueEditor.value, `${permission}.*`),
		[permission, permissionService, valueEditor.value],
	);

	const availablePermissions = useMemo(
		() => permissionService.decode([`${permission}.*`]),
		[permission, permissionService],
	);

	const selectedAllOnChange = useCallback(
		(selectedAll: boolean) => {
			if (selectedAll) valueEditor.concatUnique(availablePermissions);
			else valueEditor.removeByValues(availablePermissions);
		},
		[availablePermissions, valueEditor],
	);

	const toggleExpanded = useCallback(
		() => setExpanded(!expanded),
		[expanded, setExpanded],
	);

	return (
		<Column gaps="10px">
			<Header
				align="center"
				gaps="10px*"
				sizes="auto! 1fr auto!"
				onClick={toggleExpanded}
			>
				<CheckBox
					value={selectedAllValue}
					disabled={disabled}
					onChange={selectedAllOnChange}
				/>
				<Label selected={selectedAllValue}>{label}</Label>
				<Icon
					style={
						expanded ? { transform: "rotate(-180deg)" } : undefined
					}
					id="down-arrow"
					size={10}
					colors={[theme.colors.primary]}
				/>
			</Header>
			<List visible={expanded}>
				<Column gaps="10px*">
					{options.map((option) => {
						let props: Item.Props;

						if ("options" in option) {
							props = {
								...option,

								value: permissionService.pick(
									valueEditor.value,
									`${option.value}.*`,
								),

								permission: option.value,

								onChange: (newPermissions) => {
									const permissions = permissionService.pick(
										valueEditor.value,
										`${option.value}.*`,
									);

									const removedPermissions =
										permissions.filter(
											(permission) =>
												!newPermissions.includes(
													permission,
												),
										);

									const addedPermissions =
										newPermissions.filter(
											(permission) =>
												!permissions.includes(
													permission,
												),
										);

									removedPermissions.forEach(
										(removedPermission) => {
											valueEditor.removeByValue(
												removedPermission,
											);
										},
									);

									valueEditor.concat(addedPermissions);
								},
							} as Item.Props2;
						} else {
							props = {
								...option,

								value: permissionService.has(
									valueEditor.value,
									option.value,
								),

								onChange: (selected) => {
									if (selected)
										valueEditor.push(option.value);
									else
										valueEditor.removeByValue(option.value);
								},
							} as Item.Props1;
						}

						props.disabled = disabled;

						return (
							// eslint-disable-next-line react/jsx-key
							<Item nested {...props} />
						);
					})}
				</Column>
			</List>
		</Column>
	);
};

declare namespace Expandable {
	type Value = string[];

	type Option =
		| {
				key: Key;
				label: string;
				value: string;
		  }
		| {
				key: Key;
				label: string;
				value: string;
				options: Options;
		  };

	type Options = Option[];

	interface Props {
		value: Value;

		disabled: boolean;
		permission: string;
		label: string;
		options: Options;

		onChange: Dispatch<Value>;
	}
}

export default Expandable;
