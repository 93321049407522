/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { ReduxReducer } from "../../../../interface";
import { Orders } from "../../interface";

import { State, OrderInterfase, ExecuterFilter } from "./interface";

export const baseFilters: ExecuterFilter = {
	companyIds: [],
	taxiServiceIds: [],
	statuses: ["available", "busy", "dinner", "home", "on_order", "closed"],
	online: ["online", "offline"],
	workingStatus: ["working", "notWorking"],
	isYourOrderStatus: true,
	order: {
		column: undefined,
		type: undefined,
	},
	query: "",
};

const initialState: State = {
	criteria: baseFilters,
};

const setExecutorsSort: ReduxReducer<State, OrderInterfase> = (
	state,
	action,
) => {
	state.criteria.order = action.payload;
};

const setExecutorsFilter: ReduxReducer<State, ExecuterFilter> = (
	state,
	action,
) => {
	state.criteria = { ...action.payload };
};

const orderExecutorsFilters = createSlice({
	name: Orders.ConstantOrderSlice.EXECUTORS_FILTERS,
	initialState,
	reducers: {
		setExecutorsSort,
		setExecutorsFilter,
	},
});

export const orderExecutorsFiltersAction = orderExecutorsFilters.actions;
export const orderExecutorsFiltersReducer = orderExecutorsFilters.reducer;
export type orderExecutorsFiltersState = State;

export default orderExecutorsFilters;
