/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import * as ModelEvent from "@node-elion/syncron";

import ServiceSubscribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";
import Language from "../Language";
import Base from "../Base";
import TaxiService from "../TaxiService";
import Dispatcher from "../Dispatcher";
import Executor from "../Executor";
import Car from "../Car";
import Subscription from "../../types/Subscription";
import Card from "../Card";
import { SortingOrder } from "../../types/SortingOrder";
import SubscriptionPool from "../../redux/services/SubscriptionPool";

class CarPark extends Base {
	// Its needed due to typescript bundler conflict
	private static _Card: Card | null = null;

	public static get Card() {
		if (this._Card) return this._Card;

		this._Card = new Card((prpc) => prpc.theirsModel.fleet.card);

		return this._Card;
	}

	public static fromResponse(data: any): CarPark.Model {
		return {
			id: data.id,

			taxiServices: data.fleetToTaxiServices?.map((fleetToTaxiService) =>
				TaxiService.fromResponse(fleetToTaxiService.taxiService),
			),
			dispatchers: data.fleetToDispatchers?.map((fleetToDispatcher) =>
				Dispatcher.fromResponse(fleetToDispatcher.dispatcher),
			),
			executors: data.executors?.map(Executor.fromResponse),
			cars: data.cars?.map(Car.fromResponse),

			name: data.name,
			description: data.description,

			active: data.active,

			createdAt: data.createdAt,
			updatedAt: data.updatedAt,
			deletedAt: data.deletedAt,
		};
	}

	public static toRequest(
		model: CarPark.Model.New | CarPark.Model.Modified,
	): any {
		return {
			taxiServiceIds: model.taxiServiceIds,
			dispatcherIds: model.dispatcherIds,
			executorIds: model.executorIds,
			carIds: model.carIds,

			name: model.name,
			description: model.description,

			active: model.active,
		};
	}

	public static async store(object: CarPark.Model.New) {
		this.request((prpc) =>
			prpc.theirsModel.fleet.create(CarPark.toRequest(object)),
		);
	}

	public static async update(object: CarPark.Model.Modified) {
		this.request((prpc) =>
			prpc.theirsModel.fleet.update(object.id, CarPark.toRequest(object)),
		);
	}

	public static async destroy(id: number[] | number) {
		if (Array.isArray(id))
			await Promise.all(id.map((id) => this.destroyOne(id)));
		else await this.destroyOne(id);
	}

	public static async subscribe(
		options: CarPark.SubscribeOptions,
		onUpdate: Subscription.OnUpdate<CarPark.Model>,
	): Promise<Subscription<CarPark.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				onUpdate({
					...state,

					models: state.models.map(this.fromResponse),
				});
			},
		});
		const subscription = await SubscriptionPool.add(
			(prpc) =>
				prpc.theirsModel.fleet.subscribe({
					params: this.optionsToRequest(options),
					ping: () => true,
					onEvent: (events) => {
						modelEventConstructor.onEvent(events);
					},
					onError: (error) => {
						// eslint-disable-next-line no-console
						console.log(error);
					},
				}),
			{ name: "CarPark.subscribe" },
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (options: CarPark.SubscribeOptions) =>
				subscription.update(this.optionsToRequest(options)),
		} as Subscription<CarPark.SubscribeOptions>;
	}

	private static async destroyOne(id: number) {
		this.request((prpc) => prpc.theirsModel.fleet.delete(id));
	}

	private static optionsToRequest(options: CarPark.SubscribeOptions) {
		return {
			offset: options.offset,
			limit: options.limit,

			order: options.order,

			taxiServiceIds: options.taxiServiceIds,

			query: options.query,
			active: options.active,
			lang: options.language,
		};
	}
}

declare namespace CarPark {
	interface Model {
		id: number;

		taxiServices?: TaxiService.Model[];
		dispatchers?: Dispatcher.Model[];
		executors?: Executor.Model[];
		cars?: Car.Model[];

		name: string;
		description: string;

		active: boolean;

		createdAt: string;
		updatedAt: string;
		deletedAt: string | null;
	}

	interface SubscribeOptions
		extends Omit<ServiceSubscribeOptionsBase<CarPark.Model>, "order"> {
		active?: boolean;
		language?: Language;
		taxiServiceIds?: number[];

		order?: Partial<Record<"name" | "active" | "createdAt", SortingOrder>>;
	}

	namespace Model {
		type NonEditablePropertyName =
			| "id"
			| "createdAt"
			| "updatedAt"
			| "deletedAt";

		type ModifiedPropertyName =
			| "taxiServices"
			| "dispatchers"
			| "executors"
			| "cars";

		interface ModifiedProperties {
			taxiServiceIds: number[];
			dispatcherIds: number[];
			executorIds: number[];
			carIds: number[];
		}

		type New = Omit<Model, NonEditablePropertyName | ModifiedPropertyName> &
			ModifiedProperties;

		type Modified = Partial<
			Omit<Model, NonEditablePropertyName | ModifiedPropertyName> &
				ModifiedProperties
		> &
			Pick<Model, "id">;
	}
}

export default CarPark;
