import { RefObject } from "react";
import { react } from "uikit";
import PersonData from "./components/PersonData";
import WorkData from "./components/WorkData";

interface Context {
	personDataRef: RefObject<PersonData.Ref | null>;
	workDataRef: RefObject<WorkData.Ref | null>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isWorkDataValid = !!this.context?.workDataRef.current?.validate();
		const isPersonDataValid =
			!!this.context?.personDataRef.current?.validate();

		return isPersonDataValid && isWorkDataValid;
	}
}
