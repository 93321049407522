import React, {
	Dispatch,
	SetStateAction,
	useCallback,
	useLayoutEffect,
	useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { CheckBox } from "uikit";
import { RowDataType } from "rsuite-table";
import moment from "moment";

import Sector from "../../../../../../../../services/Sector";
import {
	useColumns,
	useVisibleColumns,
} from "../../../../../../../../hooks/useTableSettings";

import LightTable, {
	useTableOptions,
	UseTableOptions,
} from "../../../../../../../../components/LightTable";
import TableSettings from "../../../../../../../../components/TableSettings";

import Root from "./components/Root";
import { timeDateUTS } from "../../../../../../../../services/Sector/utils";

const Content: React.FC<Content.Props> = ({
	value,
	selected,
	setSelected,
	onEdit,
	onChangeTable,
	editorTable,
}) => {
	const { t } = useTranslation();
	const {
		tableRef,
		ctrlPressed,
		onKeyDown,
		onKeyUp,
		onScroll,
		sort,
		onSort,
		setDataLength,
	} = useTableOptions({
		value: editorTable,
		setValue: onChangeTable,
	});

	useLayoutEffect(() => {
		setDataLength(value.length);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value.length]);

	const columns = useColumns("averageSpeed");
	const { columnIds, setColumnIds } = useVisibleColumns(
		"sectors.averageSpeed",
		"averageSpeed",
	);

	const updateSelected = useCallback(
		(item: RowDataType<Sector.AverageSpeed>) => {
			const model = item as Sector.AverageSpeed;
			if (!model.id) return;
			if (ctrlPressed) {
				if (selected.includes(model?.id)) {
					setSelected(selected.filter((id) => model.id !== id));
				} else {
					setSelected([...selected, model.id]);
				}
			} else {
				selected.includes(model.id)
					? setSelected([])
					: setSelected([model.id]);
			}
		},
		[ctrlPressed, selected, setSelected],
	);

	const modifyDom = useCallback(
		(dow: number[]) => {
			if (dow.length === 7) return t("preferences.sectors.all");
			if ([5, 6].every((item) => dow.includes(item))) {
				return `${moment().weekday(5).format("dddd")} - ${moment()
					.weekday(6)
					.format("dddd")}`;
			}
			if ([0, 1, 2, 3, 4].every((item) => dow.includes(item))) {
				return `${moment().weekday(0).format("dddd")} - ${moment()
					.weekday(4)
					.format("dddd")}`;
			}

			const days = dow.map((item) =>
				moment().weekday(item).format("dddd"),
			);

			return `${days.join(",") || ""}`;
		},
		[t],
	);

	const data = useMemo(
		() =>
			columnIds.map((columnId) => {
				const column = columns.find((col) => col.id === columnId);
				if (!column) return null;

				return (
					<LightTable.Column
						key={columnId}
						verticalAlign="middle"
						flexGrow={1}
						resizable
						sortable
					>
						<LightTable.HeaderCell>
							{column.label}
						</LightTable.HeaderCell>
						<LightTable.Cell
							verticalAlign="middle"
							fullText
							dataKey={column.id}
						>
							{(rowData: RowDataType<Sector.AverageSpeed>) => {
								const { dow, speed, end, start } =
									rowData as Sector.AverageSpeed;

								switch (columnId) {
									case "dow":
										return modifyDom(dow);
									case "speed":
										return `${speed} ${t("units.kph")}`;

									case "time":
										return `${timeDateUTS(
											start,
										)} - ${timeDateUTS(end)}`;

									default:
										return null;
								}
							}}
						</LightTable.Cell>
					</LightTable.Column>
				);
			}),
		[columnIds, columns, t, modifyDom],
	);

	const getRowClassName = useCallback(
		(item: RowDataType<Sector.AverageSpeed[]>) =>
			item && selected.includes(item.id) ? "selected" : "",
		[selected],
	);

	const changeAllSelectedHandler = useCallback(
		(isAllSelected: boolean) => {
			if (isAllSelected) {
				setSelected(value.map(({ id }) => id));
			} else {
				setSelected([]);
			}
		},
		[setSelected, value],
	);

	return (
		<Root sizes="1fr auto!" maxedHeight maxedWidth>
			<div tabIndex={-1} onKeyDown={onKeyDown} onKeyUp={onKeyUp}>
				<LightTable
					ref={tableRef}
					data={value}
					virtualized
					fillHeight
					headerHeight={46}
					rowHeight={46}
					shouldUpdateScroll={false}
					rowClassName={getRowClassName}
					onRowClick={updateSelected}
					onRowDoubleClick={onEdit}
					onScroll={onScroll}
					sortColumn={sort?.dataKey}
					sortType={sort?.sortType}
					onSortColumn={onSort}
				>
					<LightTable.Column verticalAlign="middle" width={50}>
						<LightTable.HeaderCell style={{ padding: "0 25px" }}>
							<CheckBox
								value={
									selected.length === value.length &&
									value.length !== 0
								}
								onChange={changeAllSelectedHandler}
							/>
						</LightTable.HeaderCell>
						<LightTable.Cell
							verticalAlign="middle"
							style={{ padding: "0 25px" }}
						>
							{(item) => (
								<CheckBox
									value={selected.includes(item.id)}
									onChange={() => {
										updateSelected(item);
									}}
								/>
							)}
						</LightTable.Cell>
					</LightTable.Column>
					{data}
				</LightTable>
			</div>
			<TableSettings
				value={columnIds}
				defaultValue={columnIds}
				columns={columns}
				onChange={setColumnIds}
			/>
		</Root>
	);
};

declare namespace Content {
	type Value = Sector.AverageSpeed[];

	interface Props {
		value: Value;
		selected: number[];
		setSelected: Dispatch<SetStateAction<number[]>>;
		onEdit?: Dispatch<Sector.AverageSpeed>;
		editorTable: UseTableOptions["editor"];
		onChangeTable: UseTableOptions["onChange"];
	}
}

export default Content;
