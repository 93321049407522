import React, { Dispatch, memo, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { noop } from "lodash";

import ExecutorRateSubscription from "../../../../../../../../../../../../services/ExecutorRateSubscription";
import { useTypedSelector } from "../../../../../../../../../../../../redux/store";
import { createObjectLanguageNames } from "../../../../../../../../../../../../assets/languages/langs";
import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";
import Root from "../../../../../../../../components/Root";
import DefaultPageHeader from "../../../../../../../../../../../../components/DefaultPageHeader";
import DeleteModal from "../../../../../../../../../../../../components/DeleteModal";
import TabRoot from "../TabRoot";

import Content from "./components/Content";
import EditModal from "./components/EditModal";

const sort = {};

const SubscriptionTab: React.FC<SubscriptionTab.Props> = memo(
	({ value, visible, onChange }) => {
		const language = useTypedSelector((state) => state.session.language);

		const [selected, setSelected] = useState<Content.Item.Id[]>([]);
		const [showDeleteModal, setShowDeleteModal] = useState(false);
		const [editingItem, setEditingItem] = useState<EditModal.Value | null>(
			null,
		);

		const valueEditor = useObjectEditor(value, onChange);

		const subscriptions = valueEditor.useGetter("subscriptions");

		const subscriptionsEditor =
			valueEditor.usePropertyEditor("subscriptions");

		const editItem = useCallback(
			(id: Content.Item.Id) => {
				const subscription = subscriptions.find(
					(subscription) => subscription.id === id,
				);

				if (!subscription) return;

				setEditingItem({
					id,
					classifierId: subscription.classifierId,
					name: subscription.name?.[language],
					startDate: new Date(subscription.startDate),
					period: subscription.period ?? {
						unit: ExecutorRateSubscription.Model.PeriodUnit.Daily,
						value: 1,
					},
					fee: {
						value: subscription.fee?.value ?? 0.01,
					},
					mode: subscription.action,
					createdAt: subscription.createdAt,
					settings: {
						chargeOnOrderCompletion:
							subscription.accrual?.chargeOnOrderCompletion ||
							false,
						// enabled: false,
						// condition: EditModal.Content.Settings.Condition.Always,
						// orderTypeEnabled: false,
						// orderType:
						// 	EditModal.Content.Settings.OrderType.OrdersPerDay,
					},
				});
			},
			[language, subscriptions],
		);

		const defaultPageHeaderCanEdit = useMemo(
			() => selected.length === 1,
			[selected.length],
		);

		const defaultPageHeaderCanDelete = useMemo(
			() => selected.length !== 0,
			[selected.length],
		);

		const defaultPageHeaderCanCopy = useMemo(
			() => selected.length === 1,
			[selected.length],
		);

		const defaultPageHeaderOnAdd = useCallback(() => {
			setEditingItem({
				id: Symbol("subscription id"),

				settings: {
					chargeOnOrderCompletion: false,
					// enabled: false,
					// condition: EditModal.Content.Settings.Condition.Always,
					// orderTypeEnabled: false,
					// orderType:
					// 	EditModal.Content.Settings.OrderType.OrdersPerDay,
				},

				period: {
					unit: ExecutorRateSubscription.Model.PeriodUnit.Daily,
					value: 1,
				},

				fee: {
					value: 0.01,
				},
			});
		}, []);

		const defaultPageHeaderOnEdit = useCallback(() => {
			editItem(selected[0]);
		}, [editItem, selected]);

		const defaultPageHeaderOnDelete = useCallback(() => {
			setShowDeleteModal(true);
		}, []);

		const defaultPageHeaderOnCopy = useCallback(() => {
			const subscription = subscriptions.find(
				(subscription) => subscription.id === selected[0],
			);

			if (!subscription) {
				return;
			}

			const newSubscription = {
				...subscription,
				id: Symbol("subscription id"),
			};

			subscriptionsEditor.push(newSubscription);
		}, [selected, subscriptions, subscriptionsEditor]);

		const editModalOnCancel = useCallback(() => {
			setEditingItem(null);
		}, []);

		const editModalOnSave = useCallback(
			(value: EditModal.Value.Validated) => {
				const item = {
					id: value.id,

					classifierId: value.classifierId,
					name: createObjectLanguageNames(value.name),
					// name: {
					// 	uk: value.name,
					// 	az: value.name,
					// 	tr: value.name,
					// 	ru: value.name,
					// 	en: value.name,
					// },

					startDate: value.startDate,

					period: value.period,

					fee: {
						value: value.fee.value,
					},

					accrual: value.settings,

					action: value.mode,

					active: true,

					createdAt: value.createdAt ?? new Date().toISOString(),
					updatedAt: new Date().toISOString(),
					deletedAt: null,
				};

				const index = subscriptionsEditor.value.findIndex(
					(subscription) => subscription.id === value.id,
				);

				if (index === -1) {
					subscriptionsEditor.push(item);
				} else {
					subscriptionsEditor.set(index, item);
				}

				setEditingItem(null);
			},
			[subscriptionsEditor],
		);

		const deleteModalOnCancel = useCallback(() => {
			setShowDeleteModal(false);
		}, []);

		const deleteModalOnConfirm = useCallback(() => {
			selected.forEach((id) => {
				const index = subscriptionsEditor.value.findIndex(
					(subscription) => subscription.id === id,
				);

				if (index !== -1) {
					subscriptionsEditor.remove(index);
				}
			});

			setShowDeleteModal(false);
		}, [selected, subscriptionsEditor]);

		const { t } = useTranslation();
		return (
			<TabRoot hasPaddings={false} visible={visible}>
				<Root sizes="auto! 1fr" maxedWidth maxedHeight>
					<DefaultPageHeader
						canEdit={defaultPageHeaderCanEdit}
						canDelete={defaultPageHeaderCanDelete}
						onAdd={defaultPageHeaderOnAdd}
						onDelete={defaultPageHeaderOnDelete}
						onEdit={defaultPageHeaderOnEdit}
						afterAdditionalButtons={[
							{
								icon: { id: "copy", size: 20 },
								onClick: defaultPageHeaderOnCopy,
								disabled: !defaultPageHeaderCanCopy,
							},
						]}
					/>
					<Content
						selected={selected}
						sort={sort}
						data={subscriptions}
						loading={false}
						onChangeSelected={setSelected}
						onChangeSort={noop}
						onEdit={editItem}
						onLoadMore={noop}
					/>
					{editingItem && (
						<EditModal
							value={editingItem}
							language={language}
							onCancel={editModalOnCancel}
							onSave={editModalOnSave}
						/>
					)}
					{showDeleteModal && (
						<DeleteModal
							label={`${
								t(
									"pages.settings.pages.finances.tabs.executorTariffPlans2.editModal.content.subscriptionTab.str200",
								) ?? ""
							}?`}
							onCancel={deleteModalOnCancel}
							onConfirm={deleteModalOnConfirm}
						/>
					)}
				</Root>
			</TabRoot>
		);
	},
);

declare namespace SubscriptionTab {
	interface Value {
		subscriptions: Value.Subscription[];
	}

	namespace Value {
		interface Subscription
			extends Omit<ExecutorRateSubscription.Model, "id"> {
			id: number | symbol;
		}
	}

	interface Props {
		value: Value;

		visible: boolean;
		disabled?: boolean;

		onChange: Dispatch<Value>;
	}
}

export default SubscriptionTab;
