import * as ModelEvent from "@node-elion/syncron";
import { ModelId } from "../../types/ModelId";
import ServiceSubscribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";
import Subscription from "../../types/Subscription";
import Base from "../Base";
import Lang from "../Language";
import Card from "../Card";
import TaxiService from "../TaxiService";
import fromJsonToString from "../../pages/Settings/pages/Message/PushNotices/components/Modal/components/Content/components/TemplateBody/helpers/fromJsonToString";
import {
	MessageTemplateActions,
	MessageTemplateTypes,
} from "../../types/MessageTemplateEnums";
import ButtonSettings from "../../pages/Settings/pages/Message/PushNotices/components/Modal/components/Content/components/ButtonSettings";
import File from "../File";
import SubscriptionPool from "../../redux/services/SubscriptionPool";

class PushNotice extends Base {
	private static _Card: Card | null = null;

	public static get Card() {
		if (this._Card) return this._Card;

		this._Card = new Card(
			(prpc) => prpc.theirsModel.notification.template.card,
		);

		return this._Card;
	}

	public static fromResponse(data: any): PushNotice.Model {
		const taxiServices = data.notificationTemplateToTaxiServices.map(
			({ taxiService }) => taxiService,
		);

		return {
			id: data.id,

			name: data.name,
			title: data.title,
			templateText: data.handlebars,
			isValidityPeriod: data.isValidityPeriod,
			validityPeriod: data.validityPeriod / 1000,
			action: data.action,
			isDefault: data.isDefault,
			type: data.type,
			lang: data.lang,
			destinationApp: data.destinationApp,
			active: data.active,
			taxiServices,
			taxiServiceIds: taxiServices.map(({ id }) => id),
			additionalFields: data.additionalFields,
			bigPicture: data.bigPicture
				? File.fromResponse(data.bigPicture)
				: null,
			largeIcon: data.largeIcon
				? File.fromResponse(data.largeIcon)
				: null,
			orderTemplate: data.orderTemplate,

			createdAt: data.createdAt,
			updatedAt: data.updatedAt,
			deletedAt: data.deletedAt,
		};
	}

	public static toRequest(model: PushNotice.New | PushNotice.Modified) {
		let handlebars = "";
		if (model.templateText) {
			handlebars =
				typeof model.templateText === "string"
					? model.templateText
					: fromJsonToString(model.templateText);
		}

		return {
			name: model.name,
			title: model.title,
			handlebars,
			isValidityPeriod: model.isValidityPeriod,
			lang: model.lang,
			destinationApp: model.destinationApp,
			active: model.active,
			action: model.action,
			taxiServiceIds: model.taxiServiceIds,
			type: model.type,
			additionalFields: model.additionalFields,
			validityPeriod: model.validityPeriod && model.validityPeriod * 1000,
			bigPictureId: model.bigPicture?.id ?? -1,
			largeIconId: model.largeIcon?.id ?? -1,
			orderTemplate: model.orderTemplate,
		};
	}

	public static async store(object: PushNotice.New, force: boolean) {
		try {
			await this.request((prpc) =>
				prpc.theirsModel.notification.template.create(
					PushNotice.toRequest(object),
					force,
				),
			);
		} catch (err: any) {
			if (err.status === 403) {
				return false;
			}
		}
		return true;
	}

	public static async copy(id) {
		try {
			await this.request((prpc) =>
				prpc.theirsModel.notification.template.copy(id),
			);
		} catch (err: any) {
			throw new Error("Error in copy methor:", err);
		}
	}

	public static async update(object: PushNotice.Modified, force: boolean) {
		// console.log({ object, preparedObject: PushNotice.toRequest(object) });
		try {
			await this.request((prpc) =>
				prpc.theirsModel.notification.template.update(
					object.id,
					PushNotice.toRequest(object),
					force,
				),
			);
		} catch (err: any) {
			if (err.status === 403) {
				return false;
			}
		}
		return true;
	}

	public static async destroy(id: number[] | number) {
		this.request((prpc) =>
			prpc.theirsModel.notification.template.delete(id),
		);
	}

	public static async subscribe(
		options: PushNotice.SubscribeOptions,
		onUpdate: Subscription.OnUpdate<PushNotice.Model>,
	): Promise<Subscription<PushNotice.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				onUpdate({
					...state,

					models: state.models.map(this.fromResponse),
				});
			},
		});

		const subscription = await SubscriptionPool.add(
			(prpc) =>
				prpc.theirsModel.notification.template.subscribe({
					params: this.optionsToRequest(options),
					ping: () => true,
					onEvent: async (events) => {
						await modelEventConstructor.onEvent(events);
					},
					onError: (error) => {
						// eslint-disable-next-line no-console
						console.log(error);
					},
				}),
			{ name: "PushNotice.subscribe" },
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (subOptions: PushNotice.SubscribeOptions) =>
				subscription.update(this.optionsToRequest(subOptions)),
		} as Subscription<PushNotice.SubscribeOptions>;
	}

	public static async getParams(params?: {
		destinationApp: NoticeDestinationApp;
	}) {
		const res = await this.request((prpc) =>
			prpc.theirsModel.notification.template.getTemplateParams(params),
		);

		return res;
	}

	private static optionsToRequest(optns: PushNotice.SubscribeOptions) {
		return {
			lang: optns.language,
			active: optns.active,
			limit: optns.limit,
			offset: optns.offset,
			order: optns.order,
			actions: optns.actions,
			destinationApps: optns.destinationApps,
		};
	}
}

declare namespace PushNotice {
	interface Model extends NonEditableProperties {
		name: string;
		title: string;
		templateText: string;
		isValidityPeriod: boolean;
		validityPeriod: number;
		lang: Lang;
		destinationApp: NoticeDestinationApp;
		active: boolean;
		taxiServices: TaxiService.Model[];
		taxiServiceIds: number[];
		additionalFields: ButtonSettings.AdditionalFields;
		bigPicture: File.Model | null;
		largeIcon: File.Model | null;
		orderTemplate: boolean;

		readonly action: MessageTemplateActions;
		readonly type: MessageTemplateTypes;
		readonly isDefault: boolean;
	}

	type New = Omit<Model, NonEditablePropertyNames>;
	type Modified = Partial<New> & NonEditableProperties;

	interface NonEditableProperties {
		readonly id: ModelId;
		readonly createdAt: string;
		readonly updatedAt: string;
		readonly deletedAt: string | null;
	}
	type NonEditablePropertyNames =
		| "id"
		| "createdAt"
		| "updatedAt"
		| "deletedAt";

	interface SubscribeOptions
		extends Omit<ServiceSubscribeOptionsBase<PushNotice.Model>, "query"> {
		active?: boolean;
		language?: Lang;
		taxiServiceIds?: number[];
		actions?: MessageTemplateActions;
		destinationApps?: NoticeDestinationApp[];
	}
}
// eslint-disable-next-line no-shadow
export enum NoticeDestinationApp {
	EXECUTOR = "EXECUTOR",
	CUSTOMER = "CUSTOMER",
	EXECUTOR_AND_CUSTOMER = "EXECUTOR_AND_CUSTOMER",
}

export default PushNotice;
