import React, { useMemo } from "react";
import { isNumber } from "lodash";

import ExecutorReport from "../../../../../../../../../../services/ExecutorReport";

import { CellContentRoot } from "../../../../../../../../../../components/LightTable";

const InvoiceCountCellContent: React.FC<InvoiceCountCellContent.Props> = ({
	item,
}) => {
	const content = useMemo(() => {
		if (!isNumber(item?.ordersCounters?.invoice?.count)) return "";
		return item.ordersCounters.invoice.count;
	}, [item?.ordersCounters?.invoice?.count]);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace InvoiceCountCellContent {
	interface Props {
		item: ExecutorReport.Model;
	}
}

export default InvoiceCountCellContent;
