/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-param-reassign */
import React, {
	Dispatch,
	Key,
	ReactNode,
	RefAttributes,
	memo,
	useCallback,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Column, react } from "uikit";
import { RowDataType } from "rsuite-table";
import moment from "moment";

import Car from "../../services/Car";
import Language from "../../services/Language";
import useObjectEditor from "../../hooks/useObjectEditor";
import useTaxiServiceIdsDecoder from "../../hooks/useTaxiServiceIdsDecoder";
import useModelSubscribe from "../../hooks/useModelSubscribe2";
import LightTable from "../LightTable";
import TableSelect from "../TableSelect";

import Header from "./components/Header";
import Root from "./components/Root";
import InternalController from "./Controller";

type CellContent = (rowData: RowDataType) => ReactNode;

const defaultColumnIds = [
	"parkNumber",
	"aliases",
	"mark",
	"model",
	"class",
	"bodyType",
	"registeredAt",
	"responsibleDispatcher",
	"company",
	"taxiService",
	"color",
	"note",
];

const CarSelectTab = memo(
	react.withController<CarSelectTab.PropsBase, CarSelectTab.Controller>(
		({
			value,

			disabled,
			visible,
			language,

			allowedTaxiServiceIds,
			allowedCarParkIds,
			taxiServiceId,

			onChange,
		}) => {
			const { t } = useTranslation();
			const valueEditor = useObjectEditor(value, onChange);

			const [filters, setFilters] = useState<Header.Filters.Value>({
				companyIds: ["all"],
				taxiServiceIds: ["all"],
				language,
				search: "",
			});

			const decodeTaxiServiceIds = useTaxiServiceIdsDecoder();

			const [sort, setSort] = useState<TableSelect.Sort>({});
			const [columIds, setColumnIds] =
				useState<string[]>(defaultColumnIds);

			const modelSubscriptionOptions =
				useMemo<Car.SubscribeOptions>(() => {
					const result: Car.SubscribeOptions = {
						taxiServiceIds: decodeTaxiServiceIds(
							filters.companyIds,
							filters.taxiServiceIds,
						),
						carParkIds: allowedCarParkIds,
						language: filters.language,
						query: filters.search,
					};

					if (sort.column && sort.type)
						result.order = {
							[sort.column]: sort.type,
						} as Car.SubscribeOptions["order"];

					return result;
				}, [
					allowedCarParkIds,
					decodeTaxiServiceIds,
					filters.companyIds,
					filters.language,
					filters.search,
					filters.taxiServiceIds,
					sort.column,
					sort.type,
				]);

			const data = useModelSubscribe(modelSubscriptionOptions, Car);

			const items = useMemo(() => {
				if (taxiServiceId) {
					return (
						data.models?.filter(
							(item) => item.taxiService?.id === taxiServiceId,
						) ?? []
					);
				}

				return data.models ?? [];
			}, [data.models, taxiServiceId]);

			const tableSelectValue = valueEditor.useGetter("carIds");
			const tableSelectOnChange = valueEditor.useSetter("carIds");

			const tableSelectOptions = useMemo(
				() => items.map((item) => ({ key: item.id, ...item })),
				[items],
			);

			const tableSelectIsOptionActive = useCallback(
				(option: TableSelect.Option<Car.Model>) => option.active,
				[],
			);

			const tableSelectColumns = useMemo<TableSelect.Column[]>(
				() => [
					{
						id: "parkNumber",
						name: t("carSelectTab.str150") ?? "",
						render: () => (
							<LightTable.Column width={120} sortable>
								<LightTable.HeaderCell verticalAlign="middle">
									{t("carSelectTab.str150") ?? ""}
								</LightTable.HeaderCell>
								<LightTable.Cell
									verticalAlign="middle"
									dataKey="callSign"
								>
									{
										((item: Car.Model) =>
											item.parkNumber) as CellContent
									}
								</LightTable.Cell>
							</LightTable.Column>
						),
					},
					{
						id: "aliases",
						name: t("carSelectTab.str151") ?? "",
						render: () => (
							<LightTable.Column width={120} sortable>
								<LightTable.HeaderCell verticalAlign="middle">
									{t("carSelectTab.str151") ?? ""}
								</LightTable.HeaderCell>
								<LightTable.Cell
									verticalAlign="middle"
									dataKey="callSign"
								>
									{
										((item: Car.Model) =>
											item.executors
												?.map(
													(executor) =>
														executor.alias,
												)
												.join(", ") ??
											"") as CellContent
									}
								</LightTable.Cell>
							</LightTable.Column>
						),
					},
					{
						id: "mark",
						name: t("carSelectTab.str152") ?? "",
						// TODO: [Cars] Fix
						render: () => (
							<LightTable.Column width={80}>
								<LightTable.HeaderCell verticalAlign="middle">
									{t("carSelectTab.str152") ?? ""}
								</LightTable.HeaderCell>
								<LightTable.Cell verticalAlign="middle">
									{(() => "") as CellContent}
								</LightTable.Cell>
							</LightTable.Column>
						),
					},
					{
						id: "model",
						name: t("carSelectTab.str153") ?? "",
						render: () => (
							<LightTable.Column width={80}>
								<LightTable.HeaderCell verticalAlign="middle">
									{t("carSelectTab.str153") ?? ""}
								</LightTable.HeaderCell>
								<LightTable.Cell verticalAlign="middle">
									{
										((item: Car.Model) =>
											item.model?.name ??
											"") as CellContent
									}
								</LightTable.Cell>
							</LightTable.Column>
						),
					},
					{
						id: "class",
						name: t("carSelectTab.str154") ?? "",
						render: () => (
							<LightTable.Column width={80} sortable>
								<LightTable.HeaderCell verticalAlign="middle">
									{t("carSelectTab.str154") ?? ""}
								</LightTable.HeaderCell>
								<LightTable.Cell verticalAlign="middle">
									{
										((item: Car.Model) =>
											item.class?.name[
												filters.language
											] ?? "") as CellContent
									}
								</LightTable.Cell>
							</LightTable.Column>
						),
					},
					{
						id: "bodyType",
						name: t("carSelectTab.str155") ?? "",
						render: () => (
							<LightTable.Column width={100}>
								<LightTable.HeaderCell verticalAlign="middle">
									{t("carSelectTab.str155") ?? ""}
								</LightTable.HeaderCell>
								<LightTable.Cell verticalAlign="middle">
									{
										((item: Car.Model) =>
											item.bodyType?.name ??
											"") as CellContent
									}
								</LightTable.Cell>
							</LightTable.Column>
						),
					},
					{
						id: "registeredAt",
						name: t("carSelectTab.str156") ?? "",
						render: () => (
							<LightTable.Column width={120}>
								<LightTable.HeaderCell verticalAlign="middle">
									{t("carSelectTab.str156") ?? ""}
								</LightTable.HeaderCell>
								<LightTable.Cell verticalAlign="middle">
									{
										((item: Car.Model) =>
											moment(item.createdAt).format(
												"DD-MM-yyyy",
											)) as CellContent
									}
								</LightTable.Cell>
							</LightTable.Column>
						),
					},
					{
						id: "responsibleDispatcher",
						name: t("carSelectTab.str157") ?? "",
						render: () => (
							<LightTable.Column width={180}>
								<LightTable.HeaderCell verticalAlign="middle">
									{t("carSelectTab.str157") ?? ""}
								</LightTable.HeaderCell>
								<LightTable.Cell verticalAlign="middle">
									{
										((item: Car.Model) =>
											`${
												item.responsibleDispatcher
													?.person?.lastName ?? ""
											} ${
												item.responsibleDispatcher
													?.person?.firstName ?? ""
											} ${
												item.responsibleDispatcher
													?.person?.fatherName ?? ""
											}`.trim()) as CellContent
									}
								</LightTable.Cell>
							</LightTable.Column>
						),
					},
					{
						id: "company",
						name: t("carSelectTab.str158") ?? "",
						render: () => (
							<LightTable.Column width={100} sortable>
								<LightTable.HeaderCell verticalAlign="middle">
									{t("carSelectTab.str158") ?? ""}
								</LightTable.HeaderCell>
								<LightTable.Cell
									verticalAlign="middle"
									dataKey="company"
								>
									{
										((item: Car.Model) =>
											item.taxiService?.company?.name[
												filters.language
											] ?? "") as CellContent
									}
								</LightTable.Cell>
							</LightTable.Column>
						),
					},
					{
						id: "taxiService",
						name: t("carSelectTab.str159") ?? "",
						render: () => (
							<LightTable.Column width={100} sortable>
								<LightTable.HeaderCell verticalAlign="middle">
									{t("carSelectTab.str159") ?? ""}
								</LightTable.HeaderCell>
								<LightTable.Cell
									verticalAlign="middle"
									dataKey="taxiService"
								>
									{
										((item: Car.Model) =>
											item.taxiService?.settlement[
												filters.language
											] ?? "") as CellContent
									}
								</LightTable.Cell>
							</LightTable.Column>
						),
					},
					{
						id: "color",
						name: t("carSelectTab.str160") ?? "",
						render: () => (
							<LightTable.Column width={80}>
								<LightTable.HeaderCell verticalAlign="middle">
									{t("carSelectTab.str160") ?? ""}
								</LightTable.HeaderCell>
								<LightTable.Cell verticalAlign="middle">
									{
										((item: Car.Model) =>
											item.color?.name[
												filters.language
											] ?? "") as CellContent
									}
								</LightTable.Cell>
							</LightTable.Column>
						),
					},
					{
						id: "note",
						name: t("carSelectTab.str161") ?? "",
						render: () => (
							<LightTable.Column flexGrow={1}>
								<LightTable.HeaderCell verticalAlign="middle">
									{t("carSelectTab.str161") ?? ""}
								</LightTable.HeaderCell>
								<LightTable.Cell verticalAlign="middle">
									{
										((item: Car.Model) =>
											item.notes) as CellContent
									}
								</LightTable.Cell>
							</LightTable.Column>
						),
					},
				],
				[filters.language, t],
			);

			return (
				<Root hasPaddings={false} visible={visible}>
					<Column sizes="auto! 1fr" maxedWidth maxedHeight>
						<Header
							filters={filters}
							allowedTaxiServiceIds={allowedTaxiServiceIds}
							onChangeFilters={setFilters}
						/>
						<TableSelect
							value={tableSelectValue}
							sort={sort}
							columnIds={columIds}
							disabled={disabled}
							options={tableSelectOptions}
							defaultColumnIds={defaultColumnIds}
							isOptionActive={tableSelectIsOptionActive}
							Columns={tableSelectColumns}
							onChange={
								tableSelectOnChange as (value: Key[]) => void
							}
							onChangeSort={setSort}
							onChangeColumnIds={setColumnIds}
						/>
					</Column>
				</Root>
			);
		},
		InternalController,
	),
);

declare namespace CarSelectTab {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value {
		carIds: number[];
	}

	interface PropsBase {
		value: Value;

		disabled: boolean;
		visible: boolean;
		language: Language;
		allowedTaxiServiceIds: number[];
		allowedCarParkIds?: number[];
		taxiServiceId?: number;
		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default CarSelectTab;
