/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-shadow */

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { isEqual, isNumber } from "lodash";

import {
	Dispatcher as DispatcherService,
	Role as RoleService,
} from "../../../../../../services";
import { useTypedSelector } from "../../../../../../redux/store";
import {
	useTaxiServiceIdsDecoder,
	useGetCompanyIdsByTaxiServiceIds,
	useModelSubscribeOld,
	useModelSubscribe,
} from "../../../../../../hooks";
import { useSort } from "../../../../../../hooks/useTableSettings";
import mapByKey from "../../../../../../utils/mapByKey";
import DeleteModal from "../../../../../../components/DeleteModal";

import Content from "./components/Content";
import Header from "./components/Header";
import EditModal from "./components/EditModal";
import Root from "./components/Root";

const Users: React.FC = () => {
	const settingsLanguage = useTypedSelector(
		(state) => state.session.language,
	);

	const getCompanyIdsByTaxiServiceIds = useGetCompanyIdsByTaxiServiceIds();
	const decodeTaxiServiceIds = useTaxiServiceIdsDecoder();

	const roleData = useModelSubscribe(
		{ assignableTo: "dispatcher", language: settingsLanguage },
		RoleService,
	);

	const roles = useMemo(() => roleData.models, [roleData.models]);

	const defaultRole = useMemo(
		() => roles.find((role) => role.default),
		[roles],
	);

	const [headerFilters, setHeaderFilters] = useState<Header.Filters.Value>({
		statuses: ["active", "blocked", "dismissed"],
		search: "",
	});

	const { sortMethod: sort, setSortMethod: setSort } = useSort(
		"accounts.dispatcher",
		"dispatcher",
	);

	const modelSubscriptionOptions =
		useMemo<DispatcherService.SubscribeOptions>(() => {
			const result: DispatcherService.SubscribeOptions = {
				query: headerFilters.search,
				statuses: headerFilters.statuses,
				language: settingsLanguage,
			};

			if (sort?.column && sort?.type)
				result.order = {
					[sort.column as keyof DispatcherService.SubscribeOptions["order"]]:
						sort.type,
				};

			return result;
		}, [
			headerFilters.search,
			headerFilters.statuses,
			settingsLanguage,
			sort?.column,
			sort?.type,
		]);

	const modelData = useModelSubscribeOld(
		modelSubscriptionOptions,
		DispatcherService,
	);

	const modelItems = useMemo(
		() => modelData?.cache ?? [],
		[modelData?.cache],
	);

	const modelItemById = useMemo(
		() => mapByKey(modelItems, "id"),
		[modelItems],
	);

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [editingItem, setEditingItem] = useState<EditModal.Value | null>(
		null,
	);
	const [selected, setSelected] = useState<number[]>([]);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const edit = useCallback(
		(id: number) => {
			const item = modelItemById[id];

			setEditingItem({
				id: item.id,

				firstName: item.person?.firstName ?? "",
				lastName: item.person?.lastName,
				fatherName: item.person?.fatherName,
				phones: item.additionalFields.phones ?? [],
				email: item.person?.emails?.[0]?.value ?? "",
				login: item.login,

				alias: item.alias ?? "",
				roleIds: item.roleIds,
				carParkIds: item.carParks?.map((carPark) => carPark.id) ?? [],
				companyIds: getCompanyIdsByTaxiServiceIds(item.taxiServiceIds),
				taxiServiceIds: item.taxiServiceIds,
				defaultTaxiServiceId: item.defaultTaxiServiceId,
				softPhoneSIPIds:
					item.dispatcherToSIPs
						?.filter(
							(dispatcherToSIP) =>
								!!dispatcherToSIP.sip?.isSoftPhone,
						)
						.map((dispatcherToSIP) => dispatcherToSIP.sip?.id)
						.filter(isNumber) ?? [],
				hardPhoneSIPIds:
					item.dispatcherToSIPs
						?.filter(
							(dispatcherToSIP) =>
								!dispatcherToSIP.sip?.isSoftPhone,
						)
						.map((dispatcherToSIP) => dispatcherToSIP.sip?.id)
						.filter(isNumber) ?? [],
				status: item.status,

				notes: item.notes,

				series: item.additionalFields.passport?.series ?? "",
				number: item.additionalFields.passport?.number ?? "",
				issuedAt: item.additionalFields.passport?.issuedAt
					? new Date(item.additionalFields.passport.issuedAt)
					: undefined,
				issuedBy: item.additionalFields.passport?.issuedBy ?? "",
				taxId: item.additionalFields.taxId ?? "",
				birthAt: item.person?.birthday
					? new Date(item.person.birthday)
					: undefined,
				residenceAddress:
					item.additionalFields.passport?.residenceAddress ?? "",
				livingAddress:
					item.additionalFields.passport?.livingAddress ?? "",

				passportFiles: item.passportFiles,
				personalPhotos: item.personalFiles,
				otherFiles: item.otherFiles,

				allowedIpAddresses: item.allowedIpAddresses,

				createdAt: item.createdAt ?? undefined,
			});
		},
		[getCompanyIdsByTaxiServiceIds, modelItemById],
	);

	const headerCanEdit = useMemo(
		() => selected.length === 1,
		[selected.length],
	);

	const headerCanDelete = useMemo(
		() => selected.length !== 0,
		[selected.length],
	);

	const headerOnAdd = useCallback(() => {
		setEditingItem({
			firstName: "",
			phones: ["", ""],
			email: "",
			login: "",
			password: "",

			alias: "",
			roleIds:
				typeof defaultRole?.id === "undefined" ? [] : [defaultRole.id],
			carParkIds: [],
			companyIds: [],
			taxiServiceIds: [],
			softPhoneSIPIds: [],
			hardPhoneSIPIds: [],
			defaultTaxiServiceId: undefined,
			status: "active",

			notes: "",

			series: "",
			number: "",
			issuedBy: "",
			taxId: "",
			residenceAddress: "",
			livingAddress: "",

			passportFiles: [],
			personalPhotos: [],
			otherFiles: [],

			allowedIpAddresses: [],
		});
	}, [defaultRole?.id]);

	const headerOnEdit = useCallback(() => {
		edit(selected[0]);
	}, [edit, selected]);

	const headerOnDelete = useCallback(() => {
		setShowDeleteModal(true);
	}, []);

	const contentOnEdit = useCallback((item) => edit(item.id), [edit]);

	const contentOnLoadMore = useCallback(() => {}, []);

	const content = useMemo(
		() => (
			<>
				<Header
					filters={headerFilters}
					canEdit={headerCanEdit}
					canDelete={headerCanDelete}
					onChangeFilters={setHeaderFilters}
					onAdd={headerOnAdd}
					onEdit={headerOnEdit}
					onDelete={headerOnDelete}
				/>
				<Content
					selected={selected}
					sort={sort}
					loading={false}
					data={modelItems}
					language={settingsLanguage}
					onChangeSelected={setSelected}
					onChangeSort={setSort}
					onEdit={contentOnEdit}
					onLoadMore={contentOnLoadMore}
				/>
			</>
		),
		[
			headerFilters,
			headerCanEdit,
			headerCanDelete,
			headerOnAdd,
			headerOnEdit,
			headerOnDelete,
			selected,
			sort,
			modelItems,
			settingsLanguage,
			setSort,
			contentOnEdit,
			contentOnLoadMore,
		],
	);

	const editModalOnCancel = useCallback(() => {
		setEditingItem(null);
	}, []);

	const editModalOnSave = useCallback(
		async (newItem: EditModal.Value.Validated) => {
			if (isEqual(editingItem, newItem)) {
				setEditingItem(null);

				return;
			}

			let personalFileIds: number[] = [];
			let passportFileIds: number[] = [];
			let otherFileIds: number[] = [];

			if (typeof newItem.id === "number") {
				personalFileIds = newItem.personalPhotos
					.filter((file) => file.id)
					.map((file) => file.id!);

				personalFileIds = personalFileIds.concat(
					(
						await Promise.all(
							newItem.personalPhotos
								.filter((file) => !file.id)
								.map((file) => file.upload()),
						)
					)
						.filter(Boolean)
						.map((file) => file!.id!),
				);

				passportFileIds = newItem.passportFiles
					.filter((file) => file.id)
					.map((file) => file.id!);

				passportFileIds = passportFileIds.concat(
					(
						await Promise.all(
							newItem.passportFiles
								.filter((file) => !file.id)
								.map((file) => file.upload()),
						)
					)
						.filter(Boolean)
						.map((file) => file!.id!),
				);

				otherFileIds = newItem.otherFiles
					.filter((file) => file.id)
					.map((file) => file.id!);

				otherFileIds = otherFileIds.concat(
					(
						await Promise.all(
							newItem.otherFiles
								.filter((file) => !file.id)
								.map((file) => file.upload()),
						)
					)
						.filter(Boolean)
						.map((file) => file!.id!),
				);
			} else {
				personalFileIds = (
					await Promise.all(
						newItem.personalPhotos.map((file) => file.upload()),
					)
				)
					.filter(Boolean)
					.map((file) => file!.id!);

				passportFileIds = (
					await Promise.all(
						newItem.passportFiles.map((file) => file.upload()),
					)
				)
					.filter(Boolean)
					.map((file) => file!.id!);

				otherFileIds = (
					await Promise.all(
						newItem.otherFiles.map((file) => file.upload()),
					)
				)
					.filter(Boolean)
					.map((file) => file!.id!);
			}

			const newProperties: DispatcherService.Model.New = {
				personalFileIds,
				passportFileIds,
				otherFileIds,

				person: {
					firstName: newItem.firstName,
					lastName: newItem.lastName,
					fatherName: newItem.fatherName,
					phones: [],
					emails: newItem.email ? [newItem.email] : [],
					birthday: newItem.birthAt,
				},

				login: newItem.login,
				password: newItem.password,

				alias: newItem.alias,
				taxiServiceIds: decodeTaxiServiceIds(
					newItem.companyIds,
					newItem.taxiServiceIds,
				),

				defaultTaxiServiceId: newItem.defaultTaxiServiceId,
				roleIds: newItem.roleIds,
				status: newItem.status,
				carParkIds: newItem.carParkIds,
				sipIds: [
					...newItem.softPhoneSIPIds,
					...newItem.hardPhoneSIPIds,
				],

				notes: newItem.notes,

				additionalFields: {
					passport: {
						series: newItem.series,
						number: newItem.number,
						issuedBy: newItem.issuedBy,
						issuedAt: newItem.issuedAt,
						residenceAddress: newItem.residenceAddress,
						livingAddress: newItem.livingAddress,
					},
					taxId: newItem.taxId,
					phones: newItem.phones,
				},

				allowedIpAddresses: newItem.allowedIpAddresses,
			};

			if (typeof newItem.id === "number")
				await DispatcherService.update({
					id: newItem.id,

					...newProperties,
				});
			else await DispatcherService.store(newProperties);

			setEditingItem(null);
		},
		[decodeTaxiServiceIds, editingItem],
	);

	const deleteModalOnCancel = useCallback(() => {
		setShowDeleteModal(false);
	}, []);

	const deleteModalOnConfirm = useCallback(async () => {
		setShowDeleteModal(false);

		await DispatcherService.destroy(selected);

		setSelected([]);
	}, [selected]);

	useEffect(() => {
		DispatcherService.deprecateAll();
	}, []);

	return (
		<Root sizes="auto! 1fr" gaps="16px" maxedWidth maxedHeight>
			{content}
			{editingItem && (
				<EditModal
					value={editingItem}
					language={settingsLanguage}
					onCancel={editModalOnCancel}
					onSave={editModalOnSave}
				/>
			)}
			{showDeleteModal && (
				<DeleteModal
					onCancel={deleteModalOnCancel}
					onConfirm={deleteModalOnConfirm}
				/>
			)}
		</Root>
	);
};

export default Users;
