import { Option } from "uikit";

import typedKeys from "../../utils/typedKeys";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import devTranslation from "./dev.yml";

type TRecord<T> = Record<string, T>;
interface RecursiveDictionary extends TRecord<string | RecursiveDictionary> {}

export const ConstantLanguage = {
	UK: "uk",
	RU: "ru",
	EN: "en",
	TR: "tr",
	AZ: "az",
} as const;
export type TypeLanguage = typeof ConstantLanguage;
export type TypeLanguageKey<T> = {
	[key in ValueLanguage]: T;
};
export type ValueLanguage =
	(typeof ConstantLanguage)[keyof typeof ConstantLanguage];

export type Language = ValueLanguage;

interface LanguagePackage {
	name: string;
	translation: RecursiveDictionary;
	sortName: string;
}
type LanguagePackages = Record<Language, LanguagePackage>;

export const languagePackages: LanguagePackages = {
	uk: {
		name: "Українська",
		translation: devTranslation,
		sortName: "УК",
	},
	en: {
		name: "English",
		translation: devTranslation,
		sortName: "EN",
	},
	tr: {
		name: "Türkçe",
		translation: devTranslation,
		sortName: "TR",
	},
	az: {
		name: "Azərbaycan",
		translation: devTranslation,
		sortName: "AZ",
	},
	ru: {
		name: "Русский",
		translation: devTranslation,
		sortName: "РУ",
	},
} as const;

export const languages: Language[] = typedKeys(languagePackages);
export const defaultLanguage: Language = ConstantLanguage.UK;

export const languageOptions: Option<Language>[] = typedKeys(
	languagePackages,
).map((key) => ({
	key,
	value: key,
	label: languagePackages[key].name,
}));

export type RecordObject = Record<
	string,
	string | number | boolean | symbol | undefined | null
>;

export const createObjectFromConstantKye = <Key>(): Record<Language, Key> =>
	Object.values(ConstantLanguage).reduce((acc, key) => {
		acc[key.toLowerCase()] = "";
		return acc;
	}, {}) as Record<Language, Key>;

export const createObjectFromConstant = (): Record<Language, string> =>
	Object.values(ConstantLanguage).reduce((acc, key) => {
		acc[key.toLowerCase()] = "";
		return acc;
	}, {}) as Record<Language, string>;

export const createObjectLanguageNames = (
	name = "",
): Record<Language, string> =>
	languages.reduce((acc, key) => {
		acc[key] = name;
		return acc;
	}, createObjectFromConstant());

export const createObjectLanguageNamesFromObject = (
	value?: Partial<Record<Language, string>>,
): Record<Language, string> =>
	languages.reduce((acc, key) => {
		acc[key] = value?.[key] || "";
		return acc;
	}, createObjectFromConstant());

export const createObjectLanguage = <
	Key extends string | number | boolean | RecordObject = RecordObject,
>(
	value: Key,
): Record<Language, Key> =>
	languages.reduce((acc, key) => {
		acc[key] = value;
		return acc;
	}, createObjectFromConstantKye<Key>());

export const createObjectFromConstantByType = <T>(): Record<Language, T> =>
	languages.reduce((acc, key) => {
		acc[key as Language] = {} as T;
		return acc;
	}, {} as Record<Language, T>);

export const addToObjectLanguage = <
	Value extends Record<Language, RecordObject>,
	Add extends Record<Language, RecordObject>,
>(
	value: Value,
	add: Add,
): Record<Language, Add & Value> =>
	languages.reduce((acc, key) => {
		acc[key] = {
			...(value?.[key] ?? {}),
			...(add?.[key] ?? {}),
		};
		return acc;
	}, createObjectFromConstantByType<any>());

export const addToObjectLanguageByAdditionKey = <
	Key extends string,
	Add extends Record<Language, RecordObject>,
	Value extends Record<Language, RecordObject>,
>(
	key: string,
	add: Add,
	value: Value,
): Record<Language, Add & Value & { [key in keyof Key]: string }> =>
	languages.reduce((acc, language) => {
		acc[language] = {
			...(value?.[language] || {}),
			[key]: add?.[language],
		};
		return acc;
	}, createObjectFromConstantByType<any>());

export const addToObjectByAdditionKey = <
	Key extends string,
	Add extends Record<Language, RecordObject>,
	Value extends RecordObject,
>(
	key: string,
	add: Add,
	value: Value,
): Record<Language, Add & Value & { [key in keyof Key]: string }> =>
	languages.reduce((acc, language) => {
		acc[language] = {
			...(value || {}),
			[key]: add?.[language],
		};
		return acc;
	}, createObjectFromConstantByType<any>());

export const addToObjectByAdditionKeyAndObject = <
	Key extends string,
	Add extends Record<Language, RecordObject>,
	Value extends RecordObject,
	Obj extends Record<Language, RecordObject>,
>(
	key: Key,
	add: Add,
	value: Value,
	obj: Obj,
): Record<Language, Add & Value & Obj & { [key in keyof Key]: string }> =>
	languages.reduce((acc, language) => {
		acc[language] = {
			...(value || {}),
			...(obj?.[language] || {}),
			[key]: add?.[language],
		};
		return acc;
	}, createObjectFromConstantByType<any>());

export const pickFromObjectByAdditionKey = <
	Value extends Record<Language, RecordObject>,
>(
	key: string,
	value?: Value,
): Record<Language, string> =>
	languages.reduce((acc, language) => {
		acc[language] = value?.[language]?.[key] || "";
		return acc;
	}, createObjectFromConstantByType<any>());

// const lang = useTypedSelector((state) => state.session.language);
export const findValueByLanguageKey = (
	obj?: Partial<Record<Language, string>>,
): string | undefined => {
	if (!obj) return undefined;

	let foundValue: string | undefined;

	languages.forEach((lang) => {
		if (obj?.[lang] !== undefined) {
			foundValue = obj[lang];
		}
	});

	if (!foundValue) return undefined;
	if (typeof foundValue === "string") return foundValue?.trim();
	return foundValue;
};

export const findValuesByLanguageKey = (
	obj?: Partial<Record<Language, string>>,
): Partial<Record<Language, string | undefined>>[] => {
	const foundValues: Partial<Record<Language, string | undefined>>[] = [];

	languages.forEach((lang) => {
		const value = {
			[lang]: obj?.[lang],
		};
		foundValues.push(value);
	});

	return foundValues;
};

export const getValueByLanguageKey = <Value = any>(data: {
	value: Partial<Record<Language, any>>;
	language?: Language;
}): Value | undefined => {
	const { value, language } = data;

	if (!value) return undefined;

	let foundValue = language ? value?.[language] : undefined;

	languages.forEach((lang) => {
		if (value?.[lang] !== undefined && !foundValue) {
			foundValue = value[lang];
		}
	});
	return foundValue;
};
