import { react } from "uikit";
import { RefObject } from "react";
import MainData from "./components/MainData";
import Other from "./components/Other";

interface Context {
	mainDataRef: RefObject<MainData.Ref | null>;
	otherRef: RefObject<Other.Ref | null>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isMainDataRefValid =
			!!this.context?.mainDataRef.current?.validate();
		const isOtherRefValid = !!this.context?.otherRef.current?.validate();

		return isMainDataRefValid && isOtherRefValid;
	}
}
