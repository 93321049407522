import React, { useMemo } from "react";
import Order from "../../../../../../../services/Order";
import CellContentRoot from "../CellContentRoot";
import { getOrderPassengerPoints } from "../../../../../../../redux/constants/OrdersPage/order";

const DestinationSettlementCellContent: React.FC<
	DestinationSettlementCellContent.Props
> = ({ item }) => {
	const points = useMemo(() => getOrderPassengerPoints(item), [item]);

	const content = useMemo(() => points.at(-1)?.settlement, [points]);

	return (
		<CellContentRoot align="center" maxedHeight maxedWidth>
			{content}
		</CellContentRoot>
	);
};

declare namespace DestinationSettlementCellContent {
	interface Props {
		item: Order.Model;
	}
}

export default DestinationSettlementCellContent;
