import React, { Dispatch, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import PhoneBlacklist from "../../../../../../services/PhoneBlacklist";
import LightInputModal from "../../../../../../components/LightInputModal";

import Content from "./components/Content";

const ClientCancelOrderModal: React.FC<ClientCancelOrderModal.Props> = ({
	addClientToBlackList,
	onSubmit,
	...props
}) => {
	const { t } = useTranslation();
	const [value, setValue] = useState<Content.Value>({
		comment: "",
		blockLevel: PhoneBlacklist.Level.WARNING,
	});

	const modalOnSubmit = useCallback(() => {
		onSubmit?.(value);
	}, [onSubmit, value]);

	return (
		<LightInputModal
			{...props}
			draggable={false}
			title={
				t("pages.mainPage.pages.orders.clientCancelOrderModal.str0") ??
				""
			}
			onSubmit={modalOnSubmit}
		>
			<Content
				value={value}
				addClientToBlackList={addClientToBlackList}
				onChange={setValue}
			/>
		</LightInputModal>
	);
};

declare namespace ClientCancelOrderModal {
	type Value = Content.Value;

	interface Props
		extends Omit<
			LightInputModal.Props,
			"draggable" | "title" | "onSubmit"
		> {
		addClientToBlackList: boolean;

		onSubmit?: Dispatch<Value>;
	}
}

export default ClientCancelOrderModal;
