import React, {
	Dispatch,
	RefAttributes,
	useCallback,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Option, Select, react } from "uikit";

import Language from "../../../../../../../../../../../../../../../../services/Language";
import { useTypedSelector } from "../../../../../../../../../../../../../../../../redux/store";
import LabeledField from "../../../../../../../../../../../../../../../../components/LabeledField";

import InternalController from "./Controller";

const DefaultTaxiServiceId = react.withController<
	DefaultTaxiServiceId.Props,
	DefaultTaxiServiceId.Controller
>(
	({
		controller,

		value,

		disabled,
		language,
		options,

		onChange,
	}) => {
		const { t } = useTranslation();
		const { models: taxiServices } = useTypedSelector(
			(state) => state.taxiServices,
		);

		const filteredTaxiServices = useMemo(
			() =>
				taxiServices.filter((taxiService) =>
					options.includes(taxiService.id),
				),
			[options, taxiServices],
		);

		const [error, setError] = useState(false);

		const selectOptions = useMemo<Option<number>[]>(
			() =>
				filteredTaxiServices.map((taxiService) => ({
					key: taxiService.id,
					label: taxiService.settlement[language],
					value: taxiService.id,
				})),
			[filteredTaxiServices, language],
		);

		const selectOnSelect = useCallback(
			(option: Option<DefaultTaxiServiceId.NewValue>) => {
				setError(false);

				onChange(option.value);
			},
			[onChange],
		);

		controller.setContext({ value, setError });

		return (
			<LabeledField
				label={
					t(
						"pages.mainPage.pages.accounts.tabs.users.editModal.content.mainTab.workData.defaultTaxiServiceId.str0",
					) ?? ""
				}
			>
				<Select
					value={value}
					disabled={selectOptions.length < 2 || disabled}
					error={error}
					placeholder={
						t(
							"pages.mainPage.pages.accounts.tabs.users.editModal.content.mainTab.workData.defaultTaxiServiceId.str0",
						) ?? ""
					}
					options={selectOptions}
					onSelect={selectOnSelect}
				/>
			</LabeledField>
		);
	},
	InternalController,
);

declare namespace DefaultTaxiServiceId {
	type Ref = InternalController | null;

	type Controller = InternalController;

	type Value = number | undefined;
	type NewValue = number;

	interface PropsBase {
		value: Value;

		disabled: boolean;
		language: Language;
		options: number[];

		onChange: Dispatch<NewValue>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default DefaultTaxiServiceId;
