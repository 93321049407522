import { NonEditableProperties } from "./NonEditableProperties";

/**
 * Type of the payment account.
 */
export enum PaymentAccountEnumType {
	MAIN = "main",
	BONUS = "bonus",
	COMMISSION = "commission",
	COMPENSATION = "compensation",
	CUSTOMER_TERMINAL = "customer_terminal",
	COUNTERPARTY_TERMINAL = "counterparty_terminal",
	AGENT_TERMINAL = "agent_terminal",
	EXECUTOR_TERMINAL = "executor_terminal",
	CARD = "card",
}

export enum PaymentProviderAccessorType {
	COMPANY = "company",
	PROVIDER = "provider",
	TAXI_SERVICE = "taxiService",
}
export const PAYMENT_PROVIDER_ACCESSOR_TYPES = [
	PaymentProviderAccessorType.COMPANY,
	PaymentProviderAccessorType.PROVIDER,
	PaymentProviderAccessorType.TAXI_SERVICE,
];

/**
 * Type of the payment account.
 */
export const PaymentAccountType = {
	MAIN: "main",
	BONUS: "bonus",
	COMMISSION: "commission",
	COMPENSATION: "compensation",
	CUSTOMER_TERMINAL: "customer_terminal",
	COUNTERPARTY_TERMINAL: "counterparty_terminal",
	AGENT_TERMINAL: "agent_terminal",
	EXECUTOR_TERMINAL: "executor_terminal",
	CARD: "card",
} as const;
export type TypePaymentAccount = typeof PaymentAccountType;
export type ValuePaymentAccount =
	(typeof PaymentAccountType)[keyof typeof PaymentAccountType];

export type PaymentAccountOrder = "main" | "bonus" | "threshold";

export interface PaymentAccount extends NonEditableProperties {
	configuration: {
		name: string;
		paymentSystem: string;
		configurationType: string;
		assetPaymentsToken?: string;
	} | null;
	isEdited?: boolean;
	isProvider: boolean;
	internal?: boolean;
	description?: string;
	type: ValuePaymentAccount | "threshold";
	amount: number;
	active: boolean;
}
