import React, { Dispatch, memo, useCallback, useMemo, useState } from "react";
import { CheckBox } from "uikit";
import { useTranslation } from "react-i18next";
import { RowDataType } from "rsuite-table";

import CustomersBlacklist from "../../../../../../../../services/CustomersBlacklist";
import { ModelId } from "../../../../../../../../types/ModelId";
import LightTable, {
	useTableOptions,
	UseTableOptions,
} from "../../../../../../../../components/LightTable";
import TableSettings from "../../../../../../../../components/TableSettings";

import tPath from "../../constants/tPath";
import Root from "./components/Root";
import { Columns, columns, defaultColumnsId } from "./constants";

const Content: React.FC<Content.Props> = memo(
	({ value, selected, setSelected, onEdit, onChangeTable, editorTable }) => {
		const { t } = useTranslation();
		const {
			sort,
			onSort,
			onScroll,
			tableRef,
			onKeyUp,
			onKeyDown,
			ctrlPressed,
			lang,
		} = useTableOptions({
			itemLimit: 2,
			value: editorTable,
			setValue: onChangeTable,
		});

		const tColumns = useMemo(
			() =>
				columns.map((c) => ({
					...c,
					label: t(`${tPath}.columns.${c.id}`),
				})),
			[t],
		);

		const [columnsId, setColumnsId] = useState<Columns[] | string[]>(
			defaultColumnsId,
		);

		const updateSelected = useCallback(
			(item: RowDataType<CustomersBlacklist.Model>) => {
				const model = item as CustomersBlacklist.Model;

				if (ctrlPressed) {
					if (selected.includes(model.id)) {
						setSelected(selected.filter((id) => model.id !== id));
					} else {
						setSelected([...selected, model.id]);
					}
				} else {
					selected.includes(model.id)
						? setSelected([])
						: setSelected([model.id]);
				}
			},
			[ctrlPressed, selected, setSelected],
		);

		const getCompany = useCallback(
			(phone: CustomersBlacklist.Model) => {
				const companyNames = phone.companies?.map(
					(item) => item.name?.[lang],
				);

				return <>{companyNames?.join(", ")}</>;
			},
			[lang],
		);

		const renderColumns = useMemo(
			() =>
				columnsId.map((columnId) => {
					const column = tColumns.find((col) => col.id === columnId);
					if (!column) return null;

					return (
						<LightTable.Column
							key={columnId}
							verticalAlign="middle"
							width={column.width}
							flexGrow={column.width ? 0 : 1}
							sortable
							resizable
						>
							<LightTable.HeaderCell
								style={{ padding: "0 20px" }}
							>
								{column.label}
							</LightTable.HeaderCell>
							<LightTable.Cell
								style={{ padding: "0 20px" }}
								dataKey={column.id}
								verticalAlign="middle"
								fullText
							>
								{(itemData) => {
									const item =
										itemData as CustomersBlacklist.Model;

									switch (columnId) {
										case Columns.PHONE:
											return item.phone;

										case Columns.SURNAME:
											return item.surname;

										case Columns.NAME:
											return item.name;

										case Columns.FATHER_NAME:
											return item.fatherName;

										case Columns.DESCRIPTION:
											return item.description;

										case Columns.LEVEL:
											return t(
												`${tPath}.modal.tabs.main.level.options.${item.level}`,
											);

										case Columns.COMPANIES:
											return getCompany(item);

										default:
											return null;
									}
								}}
							</LightTable.Cell>
						</LightTable.Column>
					);
				}),
			[columnsId, getCompany, t, tColumns],
		);

		const getRowClassName = useCallback(
			(item) => (item && selected.includes(item.id) ? "selected" : ""),
			[selected],
		);

		const handleAllSelectedChange = useCallback(
			(isAllSelected) => {
				if (isAllSelected) {
					setSelected(value.map(({ id }) => id));
				} else {
					setSelected([]);
				}
			},
			[setSelected, value],
		);

		return (
			<Root sizes="1fr auto!">
				<div tabIndex={-1} onKeyDown={onKeyDown} onKeyUp={onKeyUp}>
					<LightTable
						ref={tableRef}
						data={value}
						virtualized
						fillHeight
						shouldUpdateScroll={false}
						rowHeight={46}
						rowClassName={getRowClassName}
						onRowClick={updateSelected}
						onRowDoubleClick={onEdit}
						sortColumn={sort?.dataKey}
						sortType={sort?.sortType}
						onScroll={onScroll}
						onSortColumn={onSort}
					>
						<LightTable.Column verticalAlign="middle" width={50}>
							<LightTable.HeaderCell
								style={{
									padding: "0 25px",
								}}
							>
								<CheckBox
									value={
										selected.length === value.length &&
										value.length !== 0
									}
									onChange={handleAllSelectedChange}
								/>
							</LightTable.HeaderCell>
							<LightTable.Cell
								verticalAlign="middle"
								style={{ padding: "0 25px" }}
							>
								{(item) => (
									<CheckBox
										value={selected.includes(item.id)}
										onChange={() => {
											updateSelected(item);
										}}
									/>
								)}
							</LightTable.Cell>
						</LightTable.Column>
						{renderColumns}
					</LightTable>
				</div>

				<TableSettings
					value={columnsId}
					defaultValue={defaultColumnsId}
					columns={tColumns}
					onChange={setColumnsId}
				/>
			</Root>
		);
	},
);

declare namespace Content {
	interface Props {
		value: CustomersBlacklist.Model[];
		selected: ModelId[];
		setSelected: (x: ModelId[]) => void;
		onEdit?: Dispatch<CustomersBlacklist.Modified>;
		editorTable: UseTableOptions["editor"];
		onChangeTable: UseTableOptions["onChange"];
	}
}

export default Content;
