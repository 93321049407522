import React, { Dispatch, useCallback, useMemo, useRef } from "react";
import { UseTranslationResponse, useTranslation } from "react-i18next";
import styled from "styled-components";
import { SortType } from "rsuite-table";
import { clone, isString } from "lodash";

import KeyBinder from "../../../../../../../../../../services/KeyBinder";
import TaxiService from "../../../../../../../../../../services/TaxiService";
import Language from "../../../../../../../../../../services/Language";
import { Role } from "../../../../../../../../../../services";
import {
	useColumns,
	useVisibleColumns,
	useWidths,
} from "../../../../../../../../../../hooks/useTableSettings";
import { TableSettings } from "../../../../../../../../../../components";
import LightTable, {
	useTableOptions,
} from "../../../../../../../../../../components/LightTable";
import { StyledColumn } from "../../../../../../../../../../components/common";

import columnBase, {
	ColumnId as LocalColumnId,
	defaultColumnIds,
} from "./columns";

const Root = styled(StyledColumn)`
	display: grid;
	grid-template-rows: 1fr auto;
	width: 100%;
	height: 100%;
`;

const ModelTable: React.FC<ModelTable.Props> = ({
	selected,
	sort,
	loading,
	data,
	language,
	onChangeSelected,
	onChangeSort,
	onEdit,
	onLoadMore,
}) => {
	const { t } = useTranslation();

	const {
		// sort,
		onSort,
		onScroll,
		tableRef,
		onKeyUp,
		onKeyDown,
		ctrlPressed,
		lang,
		widthScroll,
	} = useTableOptions({
		heightRow: 32,
		itemLimit: 2,
	});

	const columns = useColumns("executorParameters");
	const { columnIds, setColumnIds } = useVisibleColumns(
		"setting.executorParameters",
		"executorParameters",
	);
	const { widths, setWidths } = useWidths(
		"setting.executorParameters",
		"executorParameters",
	);

	const modelTableColumns = useMemo<ModelTable.Column[]>(
		() =>
			columns
				.map((column) => ({
					id: column.id as LocalColumnId,
					width: widths[column.id],
					visible: columnIds.includes(column.id as LocalColumnId),
				}))
				.sort((leftColumn, rightColumn) => {
					const leftIndex = columnIds.indexOf(leftColumn.id);
					const rightIndex = columnIds.indexOf(rightColumn.id);
					return leftIndex - rightIndex;
				}),
		[columns, widths, columnIds],
	);

	const canLoadMoreRef = useRef(true);

	const tableRowClassName = useCallback(
		(rowData) => {
			if (selected.includes(rowData?.id ?? "")) return "selected";

			if (!rowData?.active) return "not-active";

			return "";
		},
		[selected],
	);

	const tableOnScroll = useMemo(() => {
		canLoadMoreRef.current = true;

		return (x: number, y: number) => {
			if (!canLoadMoreRef.current) return;

			const contextHeight = data.length * 44;
			const top = Math.abs(y);
			const tableContainerHeight =
				tableRef.current?.root.getBoundingClientRect().height ?? 0;

			if (contextHeight - top - tableContainerHeight < 300) {
				onLoadMore();

				canLoadMoreRef.current = false;
			}
		};
	}, [data.length, onLoadMore, tableRef]);

	const setItemSelection = useCallback(
		(id: number, value: boolean) => {
			if (value) {
				const newSelected = clone(selected);

				newSelected.push(id);

				onChangeSelected(newSelected);
			} else
				onChangeSelected(
					selected.filter((selectedId) => selectedId !== id),
				);
		},
		[onChangeSelected, selected],
	);

	const tableOnRowClick = useCallback(
		(rowData) =>
			KeyBinder.isControlPressed
				? setItemSelection(rowData.id, !selected.includes(rowData.id))
				: onChangeSelected(
						selected.includes(rowData.id) ? [] : [rowData.id],
				  ),
		[onChangeSelected, selected, setItemSelection],
	);

	const rowHeight = useCallback((rowData) => {
		const taxiServiceDescription = rowData?.description;
		const defaultHeight = 44;

		if (taxiServiceDescription && isString(taxiServiceDescription)) {
			const fontSize = 13;
			const fontBetween = 3;
			const lineHeight = fontSize + fontBetween;
			const padding = 28;

			const lines = taxiServiceDescription.split("\n").length;

			const calculatedHeight = lines * lineHeight;

			return Math.max(defaultHeight, calculatedHeight + padding);
		}

		return defaultHeight;
	}, []);

	const modelTableOnChangeColumns = useCallback(
		(columns: ModelTable.Column[]) => {
			setColumnIds(
				columns
					.filter((column) => column.visible)
					.map((column) => column.id),
			);
			setWidths(
				columns.reduce((accumulator, column) => {
					accumulator[column.id] = column.width;
					return accumulator;
				}, {} as Record<LocalColumnId, number>),
			);
		},
		[setColumnIds, setWidths],
	);

	const renderedColumns = useMemo(
		() =>
			modelTableColumns
				.filter((column) => column.visible)
				.map((column) => {
					const node = columnBase[column.id]?.render({
						width: column.width,
						language: lang,

						onResize: (width, columnId) => {
							const newColumns = modelTableColumns.map(
								(columns) =>
									columns.id === columnId
										? { ...columns, width: width ?? 0 }
										: columns,
							);

							modelTableOnChangeColumns(newColumns);
						},
					});

					return node;
				}),
		[modelTableColumns, lang, modelTableOnChangeColumns],
	);

	return (
		<Root>
			<LightTable
				ref={tableRef}
				fillHeight
				virtualized
				shouldUpdateScroll={false}
				headerHeight={44}
				rowHeight={rowHeight}
				sortColumn={sort.column}
				sortType={sort.type}
				loading={loading}
				data={data}
				rowClassName={tableRowClassName}
				onScroll={tableOnScroll}
				onRowClick={tableOnRowClick}
				onRowDoubleClick={onEdit}
				onSortColumn={(column, type) => onChangeSort({ column, type })}
			>
				{renderedColumns}
			</LightTable>
			<TableSettings
				value={columnIds}
				defaultValue={defaultColumnIds}
				columns={columns}
				onChange={setColumnIds}
			/>
		</Root>
	);
};

declare namespace ModelTable {
	interface Column {
		id: LocalColumnId;
		width: number;
		visible: boolean;
	}

	interface Sort {
		column?: string;
		type?: SortType;
	}

	interface Props {
		selected: number[];
		sort: Sort;

		loading: boolean;
		data: any[];
		language: Language;

		onChangeSelected: Dispatch<number[]>;
		onChangeSort: Dispatch<Sort>;

		onEdit: Dispatch<number>;
		onLoadMore: () => void;
	}

	interface ColumnContext {
		language: Language;
		t: UseTranslationResponse<"translation", undefined>[0];
		taxiServiceById: Record<number, TaxiService.Model>;
		width: number;
		onResize: (width?: number, columnId?: string) => void;
	}
}

export default ModelTable;
