import React, {
	Dispatch,
	memo,
	RefAttributes,
	useCallback,
	useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { CheckBox, Column, Row, theme, react, useRefWithSetter } from "uikit";

import ExecutorGroup from "../../../../../../../../../../../../services/ExecutorGroup";
import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";
import { Language } from "../../../../../../../../../../../../assets/languages/langs";
import {
	CheckBoxWithText,
	CompaniesAndTaxiServices,
	CompaniesAndTaxiServicesBase,
	Name,
	NameBase,
	StyledColumn,
	StyledRow,
	TextWithStepper,
} from "../../../../../../../../../../../../components/common";
import translationPath from "../../../../../../constants/translationPath";

import Selects from "./components/Selects";
import First from "./components/Blocks/First";
import Second from "./components/Blocks/Second";
import Third from "./components/Blocks/Third";
import Fourth from "./components/Blocks/Fourth";
import Fifth from "./components/Blocks/Fifth";
import Sixth from "./components/Blocks/Sixth";
import Seventh from "./components/Blocks/Seventh";
import Eighth from "./components/Blocks/Eighth";
import InternalController from "./Controller";

const MainBase = react.withController<Main.PropsBase, Main.Controller>(
	({ value, onChange, language, controller }) => {
		const { t } = useTranslation();
		const [companiesAndTaxiServicesRef, setCompaniesAndTaxiServicesRefRef] =
			useRefWithSetter<CompaniesAndTaxiServicesBase.Ref | null>(null);

		const [nameRef, setNameRef] = useRefWithSetter<NameBase.Ref | null>(
			null,
		);

		controller.setContext({ companiesAndTaxiServicesRef, nameRef });

		const valueEditor = useObjectEditor(value, onChange);

		const configuration = valueEditor.useGetter("configuration");
		const setConfiguration = valueEditor.useSetter("configuration");

		const priority = valueEditor.useGetter("priority");
		const setPriority = valueEditor.useSetter("priority");

		const name = valueEditor.useGetter("name");
		const setName = valueEditor.useSetter("name");

		const isAllowToSelectCarClassesInApp = valueEditor.useGetter(
			"isAllowToSelectCarClassesInApp",
		);
		const isAllowToSelectCarClassesInAppOnChange = valueEditor.useSetter(
			"isAllowToSelectCarClassesInApp",
		);

		const valueName = useMemo(() => {
			if (name?.[language]) return name?.[language];
			return "";
		}, [language, name]);

		const handleName = useCallback(
			(newName: string) => {
				const groupName: Record<Language, string> = { ...name };
				groupName[language] = newName;
				setName(groupName);
			},
			[language, setName, name],
		);

		const taxiServicesData = valueEditor.usePicker(
			["taxiServiceIds", "companyIds"],
			"json",
		);

		const selectsValue = valueEditor.usePicker(["executorAppId"]);

		const assigner = valueEditor.useAssigner();

		const borderStyles = {
			border: `1px solid ${theme.colors.color_border_basic}`,
			borderRadius: theme.borderRadius.m,
			padding: 10,
		};
		const columnGaps = "10px*";

		const translateTexts = useMemo(() => {
			const tPath =
				"pages.mainPage.pages.accounts.tabs.executors.editModal.content.optionTab";

			return {
				checkBoxTitle: t(`${tPath}.str0`) ?? "",
				checkBox3: `${tPath}.str202`,
			};
		}, [t]);

		return (
			<StyledColumn w="100%" h="100%" gap="20px" p="20px">
				<Row sizes="39%! 29%! 29%!" justify="space-between">
					<Name
						ref={setNameRef}
						name={valueName}
						setName={handleName}
						disabled={false}
						label={`${translationPath}.modal.tabs.main.name`}
						placeholder={`${translationPath}.modal.tabs.main.name`}
					/>
					<CompaniesAndTaxiServices
						ref={setCompaniesAndTaxiServicesRefRef}
						value={taxiServicesData}
						onChange={assigner}
					/>
				</Row>
				<StyledRow
					gap="0 1rem"
					alignItems="center"
					justify="space-between"
				>
					<CheckBoxWithText
						disabled
						title={
							"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.str150"
						}
					/>

					<CheckBoxWithText
						title={translateTexts.checkBox3}
						value={isAllowToSelectCarClassesInApp}
						onChange={isAllowToSelectCarClassesInAppOnChange}
					/>

					<TextWithStepper
						value={priority}
						onChange={setPriority}
						title={
							t(
								"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.str200",
							) ?? ""
						}
						stepperSetting={{
							min: 0,
							max: 100,
						}}
						columns="repeat(2,1fr)"
					/>
				</StyledRow>
				<Selects value={selectsValue} onChange={assigner} />
				<Column gaps="20px*" style={{ padding: "0 0 20px 0" }}>
					<First value={configuration} onChange={setConfiguration} />
					<Second />
					<Third />
					<Fourth />
					<Fifth />
					<Sixth />
					<Seventh />
					<Column style={borderStyles}>
						<CheckBox
							disabled
							label={
								t(
									"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.str0",
								) ?? ""
							}
						/>
					</Column>
					<Eighth />
					<Row sizes="1fr 1fr" style={borderStyles}>
						<Column gaps={columnGaps}>
							<CheckBox
								disabled
								label={
									t(
										"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.str201",
									) ?? ""
								}
							/>
							<CheckBox
								disabled
								label={
									t(
										"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.str202",
									) ?? ""
								}
							/>
							<CheckBox
								disabled
								label={
									t(
										"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.str203",
									) ?? ""
								}
							/>
						</Column>
						<Column gaps={columnGaps}>
							<CheckBox
								disabled
								label={
									t(
										"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.str204",
									) ?? ""
								}
							/>
							<CheckBox
								disabled
								label={
									t(
										"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.str205",
									) ?? ""
								}
							/>
							<CheckBox
								disabled
								label={
									t(
										"pages.mainPage.pages.accounts.tabs.executorTeams.modal.content.tabs.main.str206",
									) ?? ""
								}
							/>
						</Column>
					</Row>
				</Column>
			</StyledColumn>
		);
	},
	InternalController,
);

const Main = memo(MainBase);
declare namespace Main {
	type Ref = InternalController | null;
	type Controller = InternalController;
	interface Value
		extends Pick<
			ExecutorGroup.Model,
			| "companyIds"
			| "taxiServiceIds"
			| "taxiServices"
			| "name"
			| "executorAppId"
			| "priority"
			| "isAllowToSelectCarClassesInApp"
			| "configuration"
		> {}
	interface PropsBase {
		value: Value;
		language: Language;
		onChange: Dispatch<Value>;
	}
	type Props = PropsBase & RefAttributes<Ref>;
}

export default Main;
