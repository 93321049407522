import React, {
	Dispatch,
	memo,
	PropsWithChildren,
	useCallback,
	useMemo,
} from "react";
import { useTranslation } from "react-i18next";

import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";
import {
	PaymentProviderAccessorType,
	PaymentAccount,
} from "../../../../../../../../../../../../types/PaymentAccount";
import {
	StyledGrid,
	StyledGridItem,
	StyledRow,
	Style,
} from "../../../../../../../../../../../../components/common";
import FieldsContainer from "../../../../../../../../../../../../components/FieldsContainer";
import LabeledField from "../../../../../../../../../../../../components/LabeledField";

import Balance from "./components/Balance";
import Transactions from "./components/Transactions";

const Balances: React.FC<Balances.Props> = memo((props) => {
	const {
		label,
		tPath,
		entityId,
		ownerId,
		owner,
		value,
		onChange,
		children,
		areas = "'balances'",
		ignoreGeneralThresholdBalance,
		allowGeneralBonusBalance,
		...gridStyle
	} = props;

	const { t } = useTranslation();

	const editor = useObjectEditor(value, onChange);

	const transactions = editor.useGetter("paymentTransactions");
	const setTransactions = editor.useSetter("paymentTransactions");

	const accounts = useMemo(
		() => value.paymentAccounts ?? [],
		[value?.paymentAccounts],
	);

	const isDisabled = useCallback(
		(account: PaymentAccount): boolean => {
			if (account.type === "threshold") {
				return !!entityId && ignoreGeneralThresholdBalance;
			}

			if (account.type === "bonus") {
				if (ignoreGeneralThresholdBalance) return true;
				return !!entityId && !allowGeneralBonusBalance;
			}

			return !entityId;
		},
		[ignoreGeneralThresholdBalance, allowGeneralBonusBalance, entityId],
	);

	return (
		<FieldsContainer label={label ?? (t(`account`) || "")}>
			<StyledGrid areas={areas} {...gridStyle}>
				<StyledGridItem area="balances">
					<StyledRow gap="10px" justify="start">
						{transactions ? (
							<Transactions
								path={tPath}
								value={transactions}
								onChange={setTransactions}
								ignoreGeneralThresholdBalance={
									ignoreGeneralThresholdBalance
								}
								allowGeneralBonusBalance={
									allowGeneralBonusBalance
								}
							/>
						) : (
							accounts.map((account, i) => (
								<LabeledField
									key={i}
									label={
										t([
											`${tPath}.${account.type}`,
											`${"balances"}.${account?.type}`,
										]) || ""
									}
								>
									<Balance
										disabled={isDisabled(account)}
										ownerId={ownerId}
										owner={owner}
										label={
											t(
												"pages.mainPage.pages.customers.tabs.counterparties.modal.content.balances.str0",
											) ?? ""
										}
										value={account}
									/>
								</LabeledField>
							))
						)}
					</StyledRow>
				</StyledGridItem>
				{children}
			</StyledGrid>
		</FieldsContainer>
	);
});

declare namespace Balances {
	interface Props extends Style.Grid, PropsWithChildren {
		label?: string | null;
		sizes?: string;
		tPath?: string;
		entityId?: number;
		owner: PaymentProviderAccessorType;
		ownerId: number | undefined;
		allowGeneralBonusBalance: boolean;
		ignoreGeneralThresholdBalance: boolean;
		value: Value;
		onChange: Dispatch<Value>;
	}

	type Value = {
		paymentAccounts: PaymentAccount[];
		paymentTransactions?: Transactions.Value;
	};
}

export default Balances;
