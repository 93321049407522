import React, { useMemo } from "react";
import moment from "moment";

import Order from "../../../../../../../services/Order";
import CellContentRoot from "../CellContentRoot";

const CreateOrderTimeCellContent: React.FC<
	CreateOrderTimeCellContent.Props
> = ({ item }) => {
	const content = useMemo(
		() =>
			moment(item.createdAt)
				.set({ second: 0, millisecond: 0 })
				.format("HH:mm:ss"),
		[item.createdAt],
	);

	return (
		<CellContentRoot align="center" maxedHeight maxedWidth>
			{content}
		</CellContentRoot>
	);
};

declare namespace CreateOrderTimeCellContent {
	interface Props {
		item: Order.Model;
	}
}

export default CreateOrderTimeCellContent;
