import React, { useMemo, useState, useCallback } from "react";

import ExecutorReport from "../../../../../../../../../../services/ExecutorReport";
import { PaymentProviderAccessorType } from "../../../../../../../../../../services/Payment/types";
import { PaymentAccountType } from "../../../../../../../../../../types/PaymentAccount";
import formatNumber from "../../../../../../../../../../utils/formatNumber";
import { updateBalance } from "../../../../../../../../../../utils/updateBalances";
import BalanceModal from "../../../../../../../../../../components/BalanceModal";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";

import { useAccountingExecutors } from "../../../../../../hooks";

const BalanceCellContent: React.FC<BalanceCellContent.Props> = ({ item }) => {
	const [showModal, setShowModal] = useState(false);
	const { filters, updateExecutor, setIsSortMap } = useAccountingExecutors();

	const taxiServiceId = useMemo(
		() => item?.taxiService?.id,
		[item?.taxiService?.id],
	);
	const paymentAccount = useMemo(() => {
		if (!item?.paymentAccounts) return null;
		return item.paymentAccounts.find(
			(a) => a.type === PaymentAccountType.MAIN,
		);
	}, [item.paymentAccounts]);

	const content = useMemo(() => {
		if (!paymentAccount) return null;
		return paymentAccount?.amount ?? 0;
	}, [paymentAccount]);

	const handleOpen = useCallback(() => {
		setShowModal(true);
	}, []);

	const handleClose = useCallback(() => {
		setShowModal(false);
	}, []);

	const handleSubmit = useCallback(
		async ({ amount, description }) => {
			if (amount === 0 || !paymentAccount || !taxiServiceId) {
				setShowModal(false);
				return;
			}

			const res = await updateBalance(
				{
					...paymentAccount,
					isEdited: true,
					amount,
					description,
				},
				taxiServiceId,
				PaymentProviderAccessorType.TAXI_SERVICE,
			);

			if (res) {
				setShowModal(false);
				const params: ExecutorReport.SearchOptions = {
					dateRange: filters.dateRange,
					executorIds: [item.id],
				};

				const res = await ExecutorReport.getExecutorReport(params);

				if (res && res.items?.length) {
					const exist = res.items.find(
						(executor) => executor.id === item.id,
					);
					if (exist) {
						updateExecutor(exist);
						setIsSortMap(true);
					}
				}
			}
		},
		[
			paymentAccount,
			taxiServiceId,
			filters.dateRange,
			item.id,
			updateExecutor,
			setIsSortMap,
		],
	);

	const colorMainBalance = useMemo(() => {
		if (content === null) return "";
		if (
			!item?.rate ||
			!item?.rate?.active ||
			!item?.rate?.amountOfMoneyToBlock?.active
		) {
			return "";
		}
		const { amountOfMoneyToBlock } = item.rate;

		if (amountOfMoneyToBlock.value >= content) return "red";

		return "";
	}, [item.rate, content]);

	return (
		<CellContentRoot
			cursor="pointer"
			alignItems="center"
			w="100%"
			h="100%"
			textColor={colorMainBalance}
			onDoubleClick={handleOpen}
		>
			{content !== null ? formatNumber(content) : ""}
			{showModal && (
				<BalanceModal onClose={handleClose} onSubmit={handleSubmit} />
			)}
		</CellContentRoot>
	);
};

declare namespace BalanceCellContent {
	interface Props {
		item: ExecutorReport.Model;
	}
}

export default BalanceCellContent;
