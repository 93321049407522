/* eslint-disable no-shadow */
import React, { Dispatch, memo, useMemo } from "react";
import { Row } from "uikit";
import { useTranslation } from "react-i18next";

import Client from "../../../../../../../../services/Client";
import { useTypedSelector } from "../../../../../../../../redux/store";
import {
	UseTableOptions,
	useTableOptions,
} from "../../../../../../../../components/LightTable";
import DefaultPageHeader from "../../../../../../../../components/DefaultPageHeader";
import {
	generateAccessName,
	AccessKey,
	hasAccess,
} from "../../../../../../../../access";
import { TabKeys, ARR_ACCESS_PART_KEY } from "../../../../constants/access";

import InternalFilters from "./components/Filters";
import Root from "./components/Root";
import Call from "./components/Call";
import SendingSMS from "./components/SendingSMS";
import Balance from "./components/Balance";
import SendingPush from "./components/SendingPush";

const Header = memo<Header.Props>(
	({
		clients,
		filters,
		canEdit,
		canDelete,
		onChangeFilters,
		onAdd,
		onEdit,
		onDelete,
		onChangeTable,
		editorTable,
	}) => {
		const { t } = useTranslation();

		const personalRole = useTypedSelector(
			(state) => state.account.personalRole,
		);

		const AccessBtnGroup = useMemo(() => {
			const ACCESS_TAB = ARR_ACCESS_PART_KEY[TabKeys.CLIENTS];

			const retval = {
				call: hasAccess(
					generateAccessName(...ACCESS_TAB, AccessKey.CALL),
					personalRole,
				),
				sendingSMS: hasAccess(
					generateAccessName(...ACCESS_TAB, AccessKey.SENDING_SMS),
					personalRole,
				),
				sendingPush: hasAccess(
					generateAccessName(...ACCESS_TAB, AccessKey.SENDEING_PUSH),
					personalRole,
				),
				balance: hasAccess(
					generateAccessName(...ACCESS_TAB, AccessKey.BALANSE),
					personalRole,
				),
				bonusBalance: hasAccess(
					generateAccessName(...ACCESS_TAB, AccessKey.BONUS_BALANSE),
					personalRole,
				),
			};

			return retval;
		}, [personalRole]);

		const { onActive, isActive, isNotActive } = useTableOptions({
			value: editorTable,
			setValue: onChangeTable,
		});

		const mainAccount = clients[0]?.paymentAccounts.find(
			(a) => a.type === "main",
		);
		const bonusAccount = clients[0]?.paymentAccounts.find(
			(a) => a.type === "bonus",
		);
		const client = clients.length === 1 && clients[0];

		return (
			<Root
				gaps="10px*"
				align="center"
				justify="space-between"
				maxedWidth
			>
				<Row align="center" gaps="60px*">
					<DefaultPageHeader
						accessName={ARR_ACCESS_PART_KEY[TabKeys.CLIENTS]}
						canEdit={canEdit}
						canDelete={canDelete}
						onAdd={onAdd}
						onEdit={onEdit}
						onDelete={onDelete}
						titleAdd={
							t(
								"pages.mainPage.pages.customers.tabs.clients.header.str200",
							) ?? ""
						}
						titleEdit={
							t(
								"pages.mainPage.pages.customers.tabs.clients.header.str201",
							) ?? ""
						}
						titleDelete={
							t(
								"pages.mainPage.pages.customers.tabs.clients.header.str202",
							) ?? ""
						}
						afterAdditionalButtons={[
							{
								disabled: false,
								icon: { id: "counterpart", size: 20 },
								onClick: () => {
									onActive("yes");
								},
								variation: isActive ? "primary" : "secondary",
								title:
									t(
										"pages.mainPage.pages.customers.tabs.clients.header.str203",
									) ?? "",
							},
							{
								disabled: false,
								icon: { id: "counterpart", size: 20 },
								onClick: () => {
									onActive("no");
								},
								variation: isNotActive
									? "primary"
									: "secondary",
								title:
									t(
										"pages.mainPage.pages.customers.tabs.clients.header.str204",
									) ?? "",
							},
						]}
					/>

					<Row align="center" gaps="10px*">
						{AccessBtnGroup.call && <Call clients={clients} />}
						{AccessBtnGroup.sendingSMS && (
							<SendingSMS clients={clients} />
						)}
						{AccessBtnGroup.sendingPush && (
							<SendingPush clients={clients} />
						)}
					</Row>
					<Row align="center" gaps="10px*">
						{AccessBtnGroup.balance && (
							<Balance
								client={client}
								paymentAccount={mainAccount}
								iconId="balance"
								title={
									t(
										"pages.mainPage.pages.customers.tabs.clients.header.str0",
									) ?? ""
								}
							/>
						)}
						{AccessBtnGroup.bonusBalance && (
							<Balance
								client={client}
								paymentAccount={bonusAccount}
								iconId="bonus-balance"
								title={
									t(
										"pages.mainPage.pages.customers.tabs.clients.header.str1",
									) ?? ""
								}
							/>
						)}
					</Row>
				</Row>
				<InternalFilters value={filters} onChange={onChangeFilters} />
			</Root>
		);
	},
);

declare namespace Header {
	interface Props {
		clients: Client.Model[];
		filters: InternalFilters.Value;
		canEdit: boolean;
		canDelete: boolean;
		onChangeFilters: Dispatch<InternalFilters.Value>;
		onAdd: () => void;
		onEdit: () => void;
		onDelete: () => void;
		editorTable: UseTableOptions["editor"];
		onChangeTable: UseTableOptions["onChange"];
	}

	namespace Filters {
		type Props = InternalFilters.Props;
		type Value = InternalFilters.Value;
	}
}

export default Header;
