import React, { useEffect, useMemo, useState, useCallback, memo } from "react";
import { Column } from "uikit";

import { isString } from "lodash";
import {
	useSort,
	useVisibleColumns,
	useWidths,
} from "../../../../../../hooks/useTableSettings";
import {
	useArchiveOrders,
	useGetCompanies,
	useOrdersSubscribe,
	useArchiveFilters,
} from "../../hooks";
import useDebouncedValue from "../../../../../../hooks/useDebouncedValue";
import useOldModelSubscribe from "../../../../../../hooks/useModelSubscribe";

import TaxiService from "../../../../../../services/TaxiService";
import Order from "../../../../../../services/Order";

import { ColumnId } from "../../../../../../constants/tables/types";
import { DateRange } from "../../../../../../types/DataRange";
import IPagination from "../../../../../../types/IPagination";

import { getTaxiServiceIds } from "../../utils";
import ArchiveTable from "./components/ArchiveTable";
import { ArchiveHeaderOrdersMemo } from "./components/Header";
import ArchiveInfoModal from "./components/ArchiveInfoModal";
import ArchiveFiltersModal from "./components/ArchiveFiltersModal";
import { StyledRow } from "../../../../../../components/common";
import TableWidths from "../../../../../../types/TableWidths";

const ArchiveOrders: React.FC<ArchiveOrders.Props> = ({
	styles,
	table,
	subscribe,
	children,
}): JSX.Element => {
	const TABLE_ROW_HEIGHT = 32;
	const TABLE_HEADER_HEIGHT = 26;
	useGetCompanies();
	const {
		orders: { orders },
	} = useArchiveOrders();

	const taxiServices = useOldModelSubscribe({}, TaxiService)?.cache;

	const {
		orderFilters: {
			filter: {
				dateFrom,
				dateTo,
				searchQuery,
				companyFilter,
				taxiServiceFilter,
			},
		},
	} = useArchiveFilters();

	const tablaOptions = useMemo<ArchiveOrders.Table>(() => {
		const saveSlot = table?.mainWidth || "archives.orderArchive";
		const tableId = "orderArchive";
		return { saveSlot, tableId };
	}, [table]);

	const { columnIds, setColumnIds } = useVisibleColumns(
		tablaOptions.saveSlot,
		tablaOptions.tableId,
	);
	const { sortMethod, setSortMethod } = useSort(
		tablaOptions.saveSlot,
		tablaOptions.tableId,
	);

	const { widths, setWidths } = useWidths(
		tablaOptions.saveSlot,
		tablaOptions.tableId,
	);

	const onColumnWidthsChange = useCallback(
		(widths: TableWidths<ColumnId<"orderArchive">>) => {
			if (widths) {
				setWidths(widths);
			}
		},
		[setWidths],
	);

	const taxiServiceIds = useMemo(
		() => getTaxiServiceIds(taxiServiceFilter, companyFilter, taxiServices),
		[companyFilter, taxiServiceFilter, taxiServices],
	);

	const filterDateRange = useMemo<DateRange>(
		() => ({ from: dateFrom, to: dateTo }),
		[dateFrom, dateTo],
	);

	const [offset, setOffset] = useState(0);
	const [limit, setLimit] = useState(50);
	const [virtualCount, setVirtualCount] = useState(0);
	const debouncedSearchQuery = useDebouncedValue(searchQuery, 500);

	useEffect(() => {
		if (subscribe) {
			if (typeof subscribe?.offset === "number") {
				setOffset(subscribe?.offset);
			}
			if (typeof subscribe?.limit === "number") {
				setLimit(subscribe?.limit);
			}
		}
	}, [subscribe]);

	const subscribeOptions = useMemo<Order.SubscribeOptions>(() => {
		const options = subscribe || {};

		return {
			query: debouncedSearchQuery,
			dateRange: filterDateRange,
			taxiServiceIds,
			tab: "overall",
			...options,
			// offset: offset === 0 ? undefined : offset,
			// limit: Math.max(limit, 1),
			order:
				isString(sortMethod.column) && isString(sortMethod.type)
					? { [sortMethod.column]: sortMethod.type }
					: undefined,
		};
	}, [
		subscribe,
		sortMethod.column,
		sortMethod.type,
		debouncedSearchQuery,
		filterDateRange,
		taxiServiceIds,
	]);

	const { metadataState } = useOrdersSubscribe(subscribeOptions);

	const pagination: IPagination = (metadataState.pagination as unknown as
		| IPagination
		| undefined) || {
		offset: 0,
		limit: 0,
		count: 0,
	};

	useEffect(() => {
		if (
			virtualCount < offset + orders.length ||
			virtualCount > pagination.count
		)
			setVirtualCount(Math.min(offset + orders.length, pagination.count));
	}, [
		virtualCount,
		offset,
		orders.length,
		pagination.offset,
		pagination.count,
	]);

	return (
		<StyledRow w={"100%"} h={"100%"}>
			<Column
				maxedWidth
				maxedHeight
				justify="stretch"
				align="center"
				{...styles}
			>
				<ArchiveHeaderOrdersMemo />
				<ArchiveInfoModal
					orderModal={{ saveSlot: table?.orderModalWidth }}
				/>
				<ArchiveFiltersModal />
				{children}
				<ArchiveTable
					loading={false}
					offset={offset}
					count={virtualCount}
					totalCount={pagination.count}
					sortMethod={sortMethod}
					columnWidths={widths}
					visibleColumns={columnIds}
					onSortMethodChange={setSortMethod}
					onOffsetChange={setOffset}
					onLimitChange={setLimit}
					onColumnWidthsChange={onColumnWidthsChange}
					onVisibleColumnsChange={setColumnIds}
					tableHeaderHeight={TABLE_HEADER_HEIGHT}
					tableRowHeight={TABLE_ROW_HEIGHT}
				/>
			</Column>
		</StyledRow>
	);
};

declare namespace ArchiveOrders {
	type Table = { saveSlot: string; tableId: "orderArchive" };
	interface Props {
		children?: React.ReactNode;
		table?: {
			/**
			 * ```ts
			 * const name = `${name}.orderArchive`
			 * ``` */
			mainWidth: string;
			/**
			 * ```ts
			 * const name = "${name}.orderArchiveModal"
			 * ``` */
			orderModalWidth: string;
		};
		styles?: Column.Props;
		subscribe?: Omit<Order.SubscribeOptions, "order">;
	}
}
export const ArchiveOrdersMemo = memo(ArchiveOrders);
export default ArchiveOrders;
