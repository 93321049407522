import React, { Dispatch, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { RowDataType, SortType } from "rsuite-table";
import { CheckBox, theme, ToggleButton } from "uikit";
import { uniq } from "lodash";
import styled from "styled-components";

import CarColor from "../../../../../../../../services/CarColor";
import getCompanies from "../../../../../../../../redux/services/Company/getCompanies";
import returnColors from "../../../../../../../../redux/services/Preferences/Colors/returnColors";
import { useTypedDispatch } from "../../../../../../../../redux/store";
import setColors from "../../../../../../../../redux/actions/Preferences/Colors/setColors";
import {
	ColumnId,
	ColumnIds,
} from "../../../../../../../../constants/tables/types";
import { createObjectLanguageNamesFromObject } from "../../../../../../../../assets/languages/langs";
import LightTable, {
	useTableOptions,
	UseTableOptions,
} from "../../../../../../../../components/LightTable";
import {
	CompaniesByIds,
	StyledGridItem,
	TaxiServicesByIds,
} from "../../../../../../../../components/common";
import { Language } from "../../../../../../../../services";

const StyledCell = styled.div`
	color: ${(props: { active: boolean }) =>
		props.active ? theme.colors.primary : "lightgray"} !important;
	${theme.longWord}
`;

const StyledColor = styled.div`
	margin: 0 auto;
	width: 14px;
	height: 14px;
	border-radius: 5px;
	border: 2px solid rgba(100, 112, 121, 0.4);
	background-color: ${(props: { color: string }) => props.color};
`;

const Content: React.FC<Content.Props> = ({
	columns,
	data,
	selected,
	onChangeSelected,
	languageFilter,
	onEdit,
	onChangeTable,
	editorTable,
}) => {
	const { t } = useTranslation();
	const dispatch = useTypedDispatch();
	const [loading, setLoading] = React.useState(false);

	const { sort, onSort, tableRef, onKeyUp, onKeyDown, ctrlPressed } =
		useTableOptions({
			value: editorTable,
			setValue: onChangeTable,
		});

	useEffect(() => {
		dispatch(getCompanies());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const tableHeight = 700;

	const loadMore = useCallback(() => {
		setLoading(true);
		const promise: any = dispatch(
			returnColors({ offset: data.length, limit: 50 }),
		);

		promise.then((result) => {
			dispatch(setColors([...data, ...result]));
			setLoading(false);
		});
	}, [data, dispatch]);

	const handleScroll = useCallback(
		(x, y) => {
			const contextHeight = data.length * 44;
			const top = Math.abs(y);
			if (contextHeight - top - tableHeight < 300) {
				loadMore();
			}
		},
		[data.length, loadMore],
	);

	const updateSelected = useCallback(
		(item: RowDataType<CarColor.Model>, force = false) => {
			const model = item as CarColor.Model;

			if (ctrlPressed || force) {
				if (selected.includes(model.id)) {
					onChangeSelected(selected.filter((id) => model.id !== id));
				} else {
					onChangeSelected([...selected, model.id]);
				}
			} else {
				selected.includes(model.id)
					? onChangeSelected([])
					: onChangeSelected([model.id]);
			}
		},
		[ctrlPressed, onChangeSelected, selected],
	);

	const renderColumn = useCallback(
		(column: ColumnId<"color">) => {
			switch (column) {
				case "checkbox":
					return (
						<LightTable.Column width={64}>
							<LightTable.HeaderCell verticalAlign="middle">
								<CheckBox
									value={
										data.length
											? selected.length === data.length
											: false
									}
									onChange={(value) =>
										onChangeSelected(
											value
												? data.map((item) => item.id)
												: [],
										)
									}
								/>
							</LightTable.HeaderCell>
							<LightTable.Cell>
								{(rowData) => (
									<CheckBox
										value={selected.includes(rowData.id)}
										onChange={() => {
											updateSelected(rowData, true);
										}}
									/>
								)}
							</LightTable.Cell>
						</LightTable.Column>
					);

				case "active":
					return (
						<LightTable.Column
							key={column}
							verticalAlign="middle"
							flexGrow={0.4}
							sortable
						>
							<LightTable.HeaderCell
								verticalAlign="middle"
								align="center"
							>
								{t(
									"pages.preferencesPages.screenDirectory.colors.content.str200",
								) ?? ""}
								.
							</LightTable.HeaderCell>
							<LightTable.Cell
								dataKey="active"
								style={{
									display: "flex",
									justifyContent: "center",
								}}
							>
								{(rowData) => (
									<ToggleButton.ToggleButton
										value={rowData?.active}
										onChange={(value) =>
											CarColor.update({
												id: rowData.id,
												active: value,
												taxiServiceIds:
													rowData.taxiServices.map(
														(item) => item.id,
													) || [],
												name: createObjectLanguageNamesFromObject(
													rowData.name,
												),
											})
										}
									/>
								)}
							</LightTable.Cell>
						</LightTable.Column>
					);

				case "colorName":
					return (
						<LightTable.Column
							resizable
							key={column}
							flexGrow={1}
							sortable
						>
							<LightTable.HeaderCell verticalAlign="middle">
								{t(
									"pages.preferencesPages.screenDirectory.colors.content.str100",
								) ?? ""}
							</LightTable.HeaderCell>
							<LightTable.Cell dataKey="name">
								{(rowData) => (
									<StyledCell active={rowData.active}>
										{rowData?.name[languageFilter]}
									</StyledCell>
								)}
							</LightTable.Cell>
						</LightTable.Column>
					);

				case "assignedColor":
					return (
						<LightTable.Column resizable key={column} flexGrow={1}>
							<LightTable.HeaderCell
								verticalAlign="middle"
								align="center"
							>
								{t(
									"pages.preferencesPages.screenDirectory.colors.content.str150",
								) ?? ""}
							</LightTable.HeaderCell>
							<LightTable.Cell>
								{(rowData) => (
									<StyledCell active={rowData.active}>
										<StyledColor
											color={
												rowData?.value
													? `#${rowData?.value.toString(
															16,
													  )}`
													: "0"
											}
										/>
									</StyledCell>
								)}
							</LightTable.Cell>
						</LightTable.Column>
					);

				case "company":
					return (
						<LightTable.Column
							resizable
							key={column}
							flexGrow={1}
							sortable
						>
							<LightTable.HeaderCell verticalAlign="middle">
								{t(
									"pages.preferencesPages.screenDirectory.colors.content.str151",
								) ?? ""}
							</LightTable.HeaderCell>
							<LightTable.Cell dataKey="company" fullText>
								{(rowData) => (
									<StyledCell active={rowData.active}>
										<CompaniesByIds
											taxiServiceIds={uniq(
												rowData?.taxiServices?.map(
													(item) => item.id,
												) || [],
											)}
											lang={languageFilter}
										/>
									</StyledCell>
								)}
							</LightTable.Cell>
						</LightTable.Column>
					);

				case "branch":
					return (
						<LightTable.Column
							resizable
							sortable
							key={column}
							flexGrow={1}
						>
							<LightTable.HeaderCell verticalAlign="middle">
								{t(
									"pages.preferencesPages.screenDirectory.colors.content.str152",
								) ?? ""}
							</LightTable.HeaderCell>
							<LightTable.Cell dataKey="taxiService" fullText>
								{(rowData) => (
									<StyledCell active={rowData.active}>
										<TaxiServicesByIds
											taxiServiceIds={uniq(
												rowData?.taxiServices?.map(
													(item) => item.id,
												) || [],
											)}
											lang={languageFilter}
										/>
									</StyledCell>
								)}
							</LightTable.Cell>
						</LightTable.Column>
					);

				default:
					return <></>;
			}
		},
		[data, selected, t, onChangeSelected, updateSelected, languageFilter],
	);

	return (
		<StyledGridItem
			area="table"
			w="100%"
			h="100%"
			tabIndex={-1}
			onKeyDown={onKeyDown}
			onKeyUp={onKeyUp}
		>
			<LightTable
				ref={tableRef}
				data={data}
				virtualized
				fillHeight
				headerHeight={44}
				rowHeight={44}
				// hea
				onRowClick={updateSelected}
				onRowDoubleClick={onEdit}
				shouldUpdateScroll={false}
				rowClassName={(rowData) =>
					rowData && selected.includes(rowData.id) ? "selected" : ""
				}
				sortColumn={sort?.dataKey}
				sortType={sort?.sortType}
				onSortColumn={onSort}
				loading={loading}
				onScroll={handleScroll}
			>
				{columns.map((column) => renderColumn(column))}

				{/* <Column flexGrow={0.5} align="right">
					<HeaderCell verticalAlign="middle"> </HeaderCell>
					<Cell handleEdit={(rowData) => onEdit(rowData)}>
						{(rowData) =>
							!rowData.native ? (
								<Row
									gaps="21px"
									style={{ padding: "0 25px" }}
									justify="right"
								>
									<StyledButton
										onClick={() => {
											onDelete(rowData?.id);
										}}
									>
										<SVGgeneral id="delete" />
									</StyledButton>
									<StyledButton
										onClick={() => onEdit(rowData)}
									>
										<SVGgeneral id="pencil" />
									</StyledButton>
								</Row>
							) : (
								<Row
									gaps="21px"
									style={{ padding: "0 25px" }}
									justify="right"
								></Row>
							)
						}
					</Cell>
				</Column> */}
			</LightTable>
		</StyledGridItem>
	);
};

declare namespace Content {
	interface Props {
		columns: ColumnIds<"color">;
		data: CarColor.Model[];
		selected: number[];

		editorTable: UseTableOptions["editor"];
		onChangeTable: UseTableOptions["onChange"];

		onDelete: (id) => void;
		onEdit: (value) => void;
		languageFilter: Language;
		onChangeSelected: Dispatch<number[]>;
	}

	interface Sort {
		column?: string;
		type?: SortType;
	}
}

export default Content;
