import { RefObject } from "react";
import { react } from "uikit";
import OrdersCounter from "./components/OrdersCounter";
import ExecutorsCounter from "./components/ExecutorsCounter";

interface Context {
	ordersCounterRef: RefObject<OrdersCounter.Ref | null>;
	executorsCounterRef: RefObject<ExecutorsCounter.Ref | null>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isOrdersCounterValid =
			this.context?.ordersCounterRef.current?.validate();

		const isExecutorsCounterValid =
			this.context?.executorsCounterRef.current?.validate();

		return isOrdersCounterValid && isExecutorsCounterValid;
	}
}
