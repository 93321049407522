import React, { useMemo } from "react";
import { isNumber } from "lodash";

import ExecutorReport from "../../../../../../../../../../services/ExecutorReport";
import formatNumber from "../../../../../../../../../../utils/formatNumber";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";

const PaymentFromExecutorToAccountCellContent: React.FC<
	PaymentFromExecutorToAccountCellContent.Props
> = ({ item }) => {
	const content = useMemo(() => {
		if (!isNumber(item?.cardReplenishmentAmount)) return formatNumber(0);
		return formatNumber(item?.cardReplenishmentAmount);
	}, [item?.cardReplenishmentAmount]);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace PaymentFromExecutorToAccountCellContent {
	interface Props {
		item: ExecutorReport.Model;
	}
}

export default PaymentFromExecutorToAccountCellContent;
