import React, {
	CSSProperties,
	PropsWithChildren,
	ReactNode,
	useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { isUndefined } from "lodash";
import { Icon } from "uikit";

import { useTypedSelector } from "../../../../redux/store";
import { generateAccessName, AccessKey, hasAccess } from "../../../../access";
import DefaultPageHeaderButton from "../../../DefaultPageHeaderButton";
import { Popup, StyledP, StyledRow } from "../../../common";

const Row = styled(StyledRow)`
	align-items: center;
`;

const ControlButtons: React.FC<ControlButtons.Props> = ({
	onAdd,
	onEdit,
	onDelete,
	canAdd = true,
	canEdit = false,
	canDelete = false,

	titleAdd = "table.buttons.add",
	idAdd = "table_add_button_id",
	titleEdit = "table.buttons.edit",
	idEdit = "table_edit_button_id",
	titleDelete = "table.buttons.delete",
	idDelete = "table_delete_button_id",
	popupAdd = {},
	popupEdit = {},
	popupDelete = {},

	beforeAdditionalButtons,
	buttons,
	afterAdditionalButtons,
	variant,
	accessName,
}) => {
	const { t } = useTranslation();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);

	const AccessBtnGroup = useMemo(() => {
		const ACCESS_IS_ALLOWED_BY_DEFAULT = true;

		const retval = {
			add: accessName
				? hasAccess(
						generateAccessName(
							...accessName,
							AccessKey.CONTROL_BUTTON_ADD,
						),
						personalRole,
				  )
				: ACCESS_IS_ALLOWED_BY_DEFAULT,
			edit: accessName
				? hasAccess(
						generateAccessName(
							...accessName,
							AccessKey.CONTROL_BUTTON_EDIT,
						),
						personalRole,
				  )
				: ACCESS_IS_ALLOWED_BY_DEFAULT,
			delete: accessName
				? hasAccess(
						generateAccessName(
							...accessName,
							AccessKey.CONTROL_BUTTON_DELETE,
						),
						personalRole,
				  )
				: ACCESS_IS_ALLOWED_BY_DEFAULT,
			afterAdditionalButtons:
				afterAdditionalButtons && accessName
					? hasAccess(
							generateAccessName(
								...accessName,
								AccessKey.AFTER_ADDITIONAL_BUTTONS,
							),
							personalRole,
					  )
					: ACCESS_IS_ALLOWED_BY_DEFAULT,
		};

		return retval;
	}, [accessName, afterAdditionalButtons, personalRole]);

	const defaultButtons = useMemo(
		() => (
			<>
				{AccessBtnGroup.add && (
					<Popup
						useHoverControl
						trackerId={idAdd}
						offset={{ x: 32, y: 33 }}
						{...popupAdd}
						tracker={
							<DefaultPageHeaderButton
								variant={variant || "primary"}
								disabled={!canAdd}
								onClick={onAdd}
								icon={<Icon id="plus" size={16} />}
								id={idAdd}
							/>
						}
					>
						<StyledP
							br="4px"
							bgC="#000000"
							colors="#ffffff"
							p="5px 10px"
						>
							{t(titleAdd)}
						</StyledP>
					</Popup>
				)}
				{AccessBtnGroup.edit && (
					<Popup
						useHoverControl
						trackerId={idEdit}
						offset={{ x: 32, y: 33 }}
						{...popupEdit}
						tracker={
							<DefaultPageHeaderButton
								disabled={!canEdit}
								variant={variant || "secondary"}
								icon={<Icon id="pencil" size={20} />}
								onClick={onEdit}
								id={idEdit}
							/>
						}
					>
						{titleEdit && (
							<StyledP
								br="4px"
								bgC="#000000"
								colors="#ffffff"
								p="5px 10px"
							>
								{t(titleEdit)}
							</StyledP>
						)}
					</Popup>
				)}
				{AccessBtnGroup.delete && (
					<Popup
						useHoverControl
						trackerId={idDelete}
						offset={{ x: 32, y: 33 }}
						{...popupDelete}
						tracker={
							<DefaultPageHeaderButton
								disabled={!canDelete}
								variant={variant || "secondary"}
								icon={<Icon id="trash" size={20} />}
								onClick={onDelete}
								id={idDelete}
							/>
						}
					>
						{titleDelete && (
							<StyledP
								br="4px"
								bgC="#000000"
								colors="#ffffff"
								p="5px 10px"
							>
								{t(titleDelete)}
							</StyledP>
						)}
					</Popup>
				)}
			</>
		),
		[
			AccessBtnGroup.add,
			AccessBtnGroup.edit,
			AccessBtnGroup.delete,
			idAdd,
			popupAdd,
			variant,
			canAdd,
			onAdd,
			t,
			titleAdd,
			idEdit,
			popupEdit,
			canEdit,
			onEdit,
			titleEdit,
			idDelete,
			popupDelete,
			canDelete,
			onDelete,
			titleDelete,
		],
	);

	const currentButtons = useMemo(
		() =>
			// eslint-disable-next-line no-nested-ternary
			isUndefined(buttons)
				? defaultButtons
				: Array.isArray(buttons)
				? buttons.map(
						(
							{
								title,
								id,
								disabled,
								icon,
								onClick,
								variation,
								margin,
								popup,
								children,
							},
							index,
						) => (
							<Popup
								key={index}
								useHoverControl
								trackerId={id}
								offset={{ x: 32, y: 33 }}
								{...popup}
								tracker={
									<DefaultPageHeaderButton
										style={{ margin }}
										key={index}
										disabled={disabled}
										variant={variation || "secondary"}
										icon={
											<Icon
												{...icon}
												size={icon.size || 20}
											/>
										}
										onClick={onClick}
										id={id}
									/>
								}
							>
								{title && (
									<StyledP
										br="4px"
										bgC="#000000"
										colors="#ffffff"
										p="5px 10px"
									>
										{title}
									</StyledP>
								)}
								{children}
							</Popup>
						),
				  )
				: buttons,
		[buttons, defaultButtons],
	);

	return (
		<Row gap="10px">
			{Array.isArray(beforeAdditionalButtons)
				? beforeAdditionalButtons.map(
						(
							{
								title,
								id,
								disabled,
								icon,
								onClick,
								variation,
								margin,
								popup,
								children,
							},
							index,
						) => (
							<Popup
								key={index}
								useHoverControl
								trackerId={id}
								offset={{ x: 32, y: 33 }}
								{...popup}
								tracker={
									<DefaultPageHeaderButton
										style={{ margin }}
										key={index}
										disabled={disabled}
										variant={variation || "secondary"}
										icon={
											<Icon
												{...icon}
												size={icon.size || 20}
											/>
										}
										onClick={onClick}
										id={id}
									/>
								}
							>
								{title && (
									<StyledP
										br="4px"
										bgC="#000000"
										colors="#ffffff"
										p="5px 10px"
									>
										{title}
									</StyledP>
								)}
								{children}
							</Popup>
						),
				  )
				: beforeAdditionalButtons}
			{currentButtons}

			{AccessBtnGroup.afterAdditionalButtons &&
				(Array.isArray(afterAdditionalButtons)
					? afterAdditionalButtons.map(
							(
								{
									disabled,
									icon,
									onClick,
									variation,
									margin,
									title,
									id,
									popup,
									children,
								},
								index,
							) => (
								<Popup
									key={index}
									useHoverControl
									trackerId={id}
									offset={{ x: 32, y: 33 }}
									{...popup}
									tracker={
										<DefaultPageHeaderButton
											style={{ margin }}
											key={index}
											disabled={disabled}
											variant={variation || "secondary"}
											icon={
												<Icon
													{...icon}
													size={icon.size || 20}
												/>
											}
											onClick={onClick}
											id={id}
										/>
									}
								>
									{title && (
										<StyledP
											br="4px"
											bgC="#000000"
											colors="#ffffff"
											p="5px 10px"
										>
											{title}
										</StyledP>
									)}
									{children}
								</Popup>
							),
					  )
					: afterAdditionalButtons)}
		</Row>
	);
};

declare namespace ControlButtons {
	interface Props {
		onAdd?: VoidFunction;
		onEdit?: VoidFunction;
		onDelete?: VoidFunction;
		canAdd?: boolean;
		canEdit?: boolean;
		canDelete?: boolean;
		titleAdd?: string;
		idAdd?: string;
		titleEdit?: string;
		idEdit?: string;
		titleDelete?: string;
		idDelete?: string;
		popupAdd?: Popup.Props;
		popupEdit?: Popup.Props;
		popupDelete?: Popup.Props;

		beforeAdditionalButtons?: Button[] | ReactNode;
		buttons?: Button[] | ReactNode;
		afterAdditionalButtons?: Button[] | ReactNode;
		variant?: Variant;
		accessName?: AccessKey[];
	}

	interface Button extends PropsWithChildren {
		popup?: Popup.Props;
		icon: Icon.Props;
		onClick: VoidFunction;
		disabled?: boolean;
		variation?: Variant;
		margin?: CSSProperties["margin"];
		title?: string;
		id?: string;
	}

	type Variant = "negative" | "primary" | "secondary" | "white";
}

export default ControlButtons;
