import { Dispatch } from "react";
import { react } from "uikit";

import {
	numberTimeToMilliseconds,
	stringTimeToMilliseconds,
} from "../../../../../../../../../../services/Sector/utils";
import Content from ".";

export interface Context {
	errors: Content.Errors;
	setErrors: Dispatch<Content.Errors>;
	value: Content.Value;
	id: number;
	allAverageTime: { start: number; end: number; dow: number[]; id: number }[];
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const value = this.context?.value;
		const id = this.context?.id;
		const errors = this.context?.errors;
		const setErrors = this.context?.setErrors;
		const allAverageTime = this.context?.allAverageTime || [];

		let isError = false;
		if (value && errors) {
			const { start, end, dow, speed } = value;

			/** Enter time `start` */
			const startTime = numberTimeToMilliseconds(
				stringTimeToMilliseconds(start, true),
				false,
			);
			/** Enter time  `end` */
			const endTime = numberTimeToMilliseconds(
				stringTimeToMilliseconds(end, true),
				false,
			);

			if (startTime >= endTime) {
				setErrors?.({ ...errors, end: true });
				isError = true;
			}
			if (endTime < 0) {
				setErrors?.({ ...errors, end: true });
				isError = true;
			}
			if (startTime < 0) {
				setErrors?.({ ...errors, start: true });
				isError = true;
			}

			if (speed < 0) {
				setErrors?.({ ...errors, speed: true });
				isError = true;
			}

			if (dow.length === 0) {
				setErrors?.({ ...errors, dow: true });
				isError = true;
			}

			if (dow.length > 0) {
				if (allAverageTime.length > 0) {
					const averagesDow = [...allAverageTime].filter((item) =>
						item.dow.some((day) => dow.includes(day)),
					);

					if (averagesDow.length > 0) {
						let startExist = false;
						let endExist = false;

						const averagesStartTime = averagesDow
							.sort((a, b) => {
								if (a?.end > b?.start && a?.start > b?.start)
									return 1;
								if (a.end === b.start) return 0;
								return -1;
							})
							.find((item) => {
								/** Item  time  `start` inside arr */
								const itemStartTime = numberTimeToMilliseconds(
									item.start,
									false,
								);
								/** Item time  `end` inside arr */
								const itemEndTime = numberTimeToMilliseconds(
									item.end,
									false,
								);

								if (id === item.id) {
									const onlyDow =
										dow.join(",") === item.dow.join(",");

									if (
										!onlyDow &&
										startTime === itemStartTime &&
										itemEndTime === endTime
									) {
										return false;
									}
								}

								if (id !== item.id) {
									if (
										startTime >= itemStartTime &&
										startTime <= itemEndTime
									) {
										startExist = true;
									}
									if (
										endTime >= itemStartTime &&
										endTime <= itemEndTime
									) {
										endExist = true;
									}
								}

								return false;
							});

						if (startExist || endExist) {
							setErrors?.({
								...errors,
								start: true,
								end: true,
							});
							isError = true;
						}

						if (endExist) {
							setErrors?.({
								...errors,
								start: startExist,
								end: endExist,
							});
							isError = true;
						}

						if (startExist) {
							setErrors?.({
								...errors,
								start: startExist,
								end: endExist,
							});
							isError = true;
						}

						if (averagesStartTime) {
							setErrors?.({
								...errors,
								start: true,
								end: true,
							});
							isError = true;
						}
					}
				}
			}
		}

		const isErrors = Object.values(errors ?? {}).every((item) => !item);

		return isErrors && !isError;
	}
}
