import React from "react";
import { Column, ColumnId } from ".";
import ExecutorCallSignCellContent from "../components/ExecutorCallSignCellContent";
import base from "./base";

function executorCallSign({ width, onResize }: Column.Options) {
	return base(
		ColumnId.ExecutorCallSign,
		(item) => <ExecutorCallSignCellContent item={item} />,
		{
			width,

			onResize: onResize as (
				columnWidth?: number | undefined,
				dataKey?: string | undefined,
			) => void,
		},
	);
}

export default executorCallSign;
