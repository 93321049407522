import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "uikit";

import SIP from "../../../../../../../../../../services/SIP";
import { useTypedSelector } from "../../../../../../../../../../redux/store";
import {
	generateAccessName,
	AccessKey,
	hasAccess,
} from "../../../../../../../../../../access";
import Button from "../Button";

const CallExecutorButton: React.FC<CallExecutorButton.Props> = () => {
	const ACCESS_SECTION = AccessKey.ORDERS;
	const ACCESS_NAME = AccessKey.COLOR_ORDER;

	const { t } = useTranslation();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);
	const sipToDispatcherId = useTypedSelector(
		(state) => state.sipToDispatcher.id,
	);

	const activeOrder = useTypedSelector(
		(state) => state.ordersPageReducer.activeOrder,
	);

	const disabled = useMemo(() => {
		if (!activeOrder?.id) return true;
		if (!sipToDispatcherId) return true;
		if (!activeOrder.passengers.length) return true;

		const hasExecutor = activeOrder.executorToOrder.length;

		return !hasExecutor;
	}, [sipToDispatcherId, activeOrder]);

	const onClick = useCallback(() => {
		if (sipToDispatcherId) {
			SIP.callToCustomer({
				sipToDispatcherId,
				orderId: activeOrder.id,
			});
		}
	}, [activeOrder, sipToDispatcherId]);

	const isNotAccess = useMemo(
		() =>
			!hasAccess(
				generateAccessName(ACCESS_SECTION, ACCESS_NAME),
				personalRole,
			),
		[ACCESS_SECTION, ACCESS_NAME, personalRole],
	);

	if (isNotAccess) return null;

	return (
		<Button
			variant="secondary"
			disabled={disabled}
			settingsKeyId="callOrderExecutor"
			title={
				t(
					"pages.mainPage.pages.orders.orderHeader.secondRow.callExexcutorButton.str0",
				) ?? ""
			}
			tabIndex={-1}
			onClick={onClick}
		>
			<Icon id="orders-call-executor" size={20} />
		</Button>
	);
};

declare namespace CallExecutorButton {
	interface Props {}
}

export default CallExecutorButton;
