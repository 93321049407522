import React, { useMemo } from "react";
import { isNumber } from "lodash";

import ExecutorReport from "../../../../../../../../../../services/ExecutorReport";
import formatNumber from "../../../../../../../../../../utils/formatNumber";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";

const OrdersAmountToCardServiceCellContent: React.FC<
	OrdersAmountToCardServiceCellContent.Props
> = ({ item }) => {
	const content = useMemo(() => {
		if (!isNumber(item?.ordersCounters?.cardToTaxiService?.amount))
			return "";
		return formatNumber(item?.ordersCounters?.cardToTaxiService?.amount);
	}, [item?.ordersCounters?.cardToTaxiService?.amount]);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace OrdersAmountToCardServiceCellContent {
	interface Props {
		item: ExecutorReport.Model;
	}
}

export default OrdersAmountToCardServiceCellContent;
