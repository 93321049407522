import React, {
	Dispatch,
	KeyboardEvent,
	SetStateAction,
	useCallback,
	useMemo,
	useState,
} from "react";
import { CheckBox } from "uikit";
import { RowDataType } from "rsuite-table";
import { useTranslation } from "react-i18next";

import LightTable, {
	UseTableOptions,
} from "../../../../../../../../components/LightTable";
import TableSettings from "../../../../../../../../components/TableSettings";
import { useTypedSelector } from "../../../../../../../../redux/store";
import Agent from "../../../../../../../../services/Agent";
import {
	useColumns,
	useVisibleColumns,
} from "../../../../../../../../hooks/useTableSettings";
import getAgentCellData from "../../../../../../../../constants/tables/agent/getAgentCellData";

import Root from "./components/Root";

const Content: React.FC<Content.Props> = ({
	value,
	selected,
	setSelected,
	onEdit,
}) => {
	const { t } = useTranslation();
	const lang = useTypedSelector((state) => state.session.language);

	const columns = useColumns("agent");
	const { columnIds, setColumnIds } = useVisibleColumns(
		"customers.agent",
		"agent",
	);

	const [ctrlPressed, setCtrlPressed] = useState(false);

	const updateSelected = useCallback(
		(item: RowDataType<Agent.Model>, force = false) => {
			const model = item as Agent.Model;
			if (ctrlPressed || force) {
				if (selected.includes(model.id)) {
					setSelected(selected.filter((id) => model.id !== id));
				} else {
					setSelected([...selected, model.id]);
				}
			} else {
				selected.includes(model.id)
					? setSelected([])
					: setSelected([model.id]);
			}
		},
		[ctrlPressed, selected, setSelected],
	);

	const data = useMemo(
		() =>
			columnIds.map((columnId) => {
				const column = columns.find((col) => col.id === columnId);
				if (!column) return null;

				return (
					<LightTable.Column
						key={columnId}
						verticalAlign="middle"
						flexGrow={1}
					>
						<LightTable.HeaderCell>
							{column.label}
						</LightTable.HeaderCell>
						<LightTable.Cell verticalAlign="middle">
							{(rowData: RowDataType<Agent.Model>) =>
								getAgentCellData(rowData, columnId, lang, t)
							}
						</LightTable.Cell>
					</LightTable.Column>
				);
			}),
		[columnIds, columns, lang, t],
	);

	const getRowClassName = useCallback(
		(item: RowDataType<Agent.Model>) =>
			item && selected.includes(item.id) ? "selected" : "",
		[selected],
	);

	const changeAllSelectedHandler = useCallback(
		(isAllSelected: boolean) => {
			if (isAllSelected) {
				setSelected(value.map(({ id }) => id));
			} else {
				setSelected([]);
			}
		},
		[setSelected, value],
	);

	const handleKeyDown = useCallback((e: KeyboardEvent<HTMLDivElement>) => {
		if (e.ctrlKey) {
			setCtrlPressed(true);
		}
	}, []);

	const handleKeyUp = useCallback((e: KeyboardEvent<HTMLDivElement>) => {
		if (!e.ctrlKey) {
			setCtrlPressed(false);
		}
	}, []);

	return (
		<Root sizes="1fr auto!">
			<div tabIndex={-1} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp}>
				<LightTable
					data={value}
					virtualized
					fillHeight
					shouldUpdateScroll={false}
					rowClassName={getRowClassName}
					onRowClick={updateSelected}
					onRowDoubleClick={onEdit}
				>
					<LightTable.Column verticalAlign="middle" width={50}>
						<LightTable.HeaderCell style={{ padding: "0 25px" }}>
							<CheckBox
								value={
									selected.length === value.length &&
									value.length !== 0
								}
								onChange={changeAllSelectedHandler}
							/>
						</LightTable.HeaderCell>
						<LightTable.Cell
							verticalAlign="middle"
							style={{ padding: "0 25px" }}
						>
							{(item) => (
								<CheckBox
									value={selected.includes(item.id)}
									onChange={() => {
										updateSelected(item, true);
									}}
								/>
							)}
						</LightTable.Cell>
					</LightTable.Column>
					{data}
				</LightTable>
			</div>
			<TableSettings
				value={columnIds}
				defaultValue={columnIds}
				columns={columns}
				onChange={setColumnIds}
			/>
		</Root>
	);
};

declare namespace Content {
	interface Props {
		value: Agent.Model[];
		selected: number[];
		setSelected: Dispatch<SetStateAction<number[]>>;
		onEdit?: (agent: Agent.Model) => void;
		editorTable: UseTableOptions["editor"];
		onChangeTable: UseTableOptions["onChange"];
	}
}

export default Content;
