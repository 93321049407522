import React, { memo } from "react";
import Root from "./components/Root";

const CellContentRoot: React.FC<CellContentRoot.Props> = memo(
	({ ...props }) => <Root {...props} />,
);

declare namespace CellContentRoot {
	interface Props extends Root.Props {}
}

export default CellContentRoot;
