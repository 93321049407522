import React, { memo, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon, Row, theme } from "uikit";

import { PaymentProviderAccessorType } from "../../../../../../../../../../../../../../services/Payment/types";
import { useCurrencyGlobalSettings } from "../../../../../../../../../../../../../../hooks";
import { PaymentAccount } from "../../../../../../../../../../../../../../types/PaymentAccount";
import formatNumber from "../../../../../../../../../../../../../../utils/formatNumber";
import { updateBalance } from "../../../../../../../../../../../../../../utils/updateBalances";
import BalanceModal from "../../../../../../../../../../../../../../components/BalanceModal";
import Label from "../Label";

const Balance: React.FC<Balance.Props> = memo((props) => {
	const { ownerId, owner, value, disabled, label } = props;
	const { t } = useTranslation();
	const currencyGlobalSettings = useCurrencyGlobalSettings();

	const [showModal, setShowModal] = useState(false);
	const [error, setError] = useState(false);
	const [isSave, setIsSave] = useState<boolean>(false);

	const formattedNumber = useMemo(
		() => formatNumber(value.amount ?? 0),
		[value.amount],
	);

	const handleOpenModal = useCallback(() => {
		setShowModal(true);
	}, []);

	const handleCloseModal = useCallback(() => {
		setShowModal(false);
	}, []);

	const handleSubmit = useCallback(
		async (data: { amount: number; description: string }) => {
			try {
				const { amount, description } = data;
				if (!description) {
					setError(true);
					return;
				}

				if (value.type === "card" || value.type === "threshold") {
					value.amount = amount;
					value.description = description;
					setShowModal(false);
				}

				if (
					!amount ||
					!ownerId ||
					value.type === "card" ||
					value.type === "threshold"
				) {
					return;
				}

				const updatedAccount: PaymentAccount = {
					...value,
					isEdited: !!amount,
					amount,
					description,
				};
				if (isSave) return;
				setIsSave(true);

				const res = await updateBalance(updatedAccount, ownerId, owner);

				if (res) {
					setIsSave(false);
					setShowModal(false);
				}
			} catch (error) {
				console.error(error);
				setShowModal(false);
			}
		},
		[isSave, owner, ownerId, value],
	);

	return (
		<>
			<Row align="center" gaps="4px 11.5px">
				<Icon id="wallet" size={16} colors={[theme.colors.success]} />
				<Label>
					{formattedNumber} {currencyGlobalSettings}
				</Label>
				<Button.Button
					disabled={disabled}
					text={
						label ??
						t(
							"pages.mainPage.pages.customers.tabs.counterparties.modal.content.balances.balance.str201",
						) ??
						""
					}
					style={{
						minHeight: "32px",
					}}
					onClick={handleOpenModal}
				/>
			</Row>
			{showModal && (
				<BalanceModal
					error={error}
					isSave={isSave}
					onClose={handleCloseModal}
					onSubmit={handleSubmit}
					defaultAmount={
						value.type === "card" ? value.amount : undefined
					}
					defaultDescription={
						value.type === "card" ? value?.description : undefined
					}
				/>
			)}
		</>
	);
});

declare namespace Balance {
	interface Props {
		value: Value;
		ownerId: number | undefined;
		owner: PaymentProviderAccessorType;
		label?: string;
		disabled?: boolean;
	}

	type Value = PaymentAccount;
}

export default Balance;
