import React, { useMemo } from "react";
import { isNumber } from "lodash";

import ExecutorReport from "../../../../../../../../../../services/ExecutorReport";

import { CellContentRoot } from "../../../../../../../../../../components/LightTable";

/** cardToTaxiService */
const CardCountCellContent: React.FC<CardCountCellContent.Props> = ({
	item,
}) => {
	const content = useMemo(() => {
		if (!isNumber(item?.ordersCounters?.card?.count)) return "";
		return item.ordersCounters.card.count;
	}, [item?.ordersCounters?.card?.count]);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace CardCountCellContent {
	interface Props {
		item: ExecutorReport.Model;
	}
}

export default CardCountCellContent;
