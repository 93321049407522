import React from "react";
import { Column, ColumnId } from ".";
import base from "./base";
import DestinationSectorCellContent from "../components/DestinationSectorCellContent";

function destinationSector({ width, language, onResize }: Column.Options) {
	return base(
		ColumnId.DestinationSector,
		(item) => (
			<DestinationSectorCellContent item={item} language={language} />
		),
		{
			width,

			onResize: onResize as (
				columnWidth?: number | undefined,
				dataKey?: string | undefined,
			) => void,
		},
	);
}

export default destinationSector;
