import React, { useMemo } from "react";
import Order from "../../../../../../../services/Order";
import CellContentRoot from "../CellContentRoot";

const ExecutorCallStatusCellContent: React.FC<
	ExecutorCallStatusCellContent.Props
> = ({ item }) => {
	const callStatus = useMemo(() => (item as any).executorCallStatus, [item]);

	const content = useMemo(
		() =>
			callStatus?.status !== "need_call"
				? callStatus?.metadata?.callerCallSign ??
				  callStatus?.user?.data?.callSign ??
				  ""
				: "",
		[
			callStatus?.metadata?.callerCallSign,
			callStatus?.status,
			callStatus?.user?.data?.callSign,
		],
	);

	const backgroundColor = useMemo(() => {
		switch (callStatus?.status) {
			case "need_call":
				return "#ADD7E7";
			case "called":
				return "#007F01";
			case "calling":
				return "#FFA500";
			default:
				return undefined;
		}
	}, [callStatus]);

	return (
		<CellContentRoot
			align="center"
			backgroundColor={backgroundColor}
			maxedHeight
			maxedWidth
		>
			{content}
		</CellContentRoot>
	);
};

declare namespace ExecutorCallStatusCellContent {
	interface Props {
		item: Order.Model;
	}
}

export default ExecutorCallStatusCellContent;
