import React from "react";
import { Column, ColumnId } from ".";
import base from "./base";
import ExecutorNoteCellContent from "../components/CustomerNoteCellContent";

function customerNote({ width, onResize }: Column.Options) {
	return base(
		ColumnId.CustomerNote,
		(item) => <ExecutorNoteCellContent item={item} />,
		{
			width,
			sortable: false,

			onResize: onResize as (
				columnWidth?: number | undefined,
				dataKey?: string | undefined,
			) => void,
		},
	);
}

export default customerNote;
