import React, {
	ReactElement,
	useCallback,
	useEffect,
	useLayoutEffect,
	useState,
	useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { CheckBox } from "uikit";
import { SortType } from "rsuite-table";

import Executor from "../../../../../services/Executor";
import Language from "../../../../../services/Language";
import { useTypedSelector } from "../../../../../redux/store";
import CompactTable from "../../../../CompactTable";
import Circle from "../../../../Circle";
import { getExecutorStatuses } from "../../constants";

import Row from "./components/Row";
import AliasRow from "./components/AliasRow";

interface Props {
	data: Executor.Model[];
	sort: Sort | undefined;
	onSort: (column, type) => void;
	tableRowClassName: (rowData) => string;
	onRowDoubleClick: (rowData) => void;
	onRowSelect: (rowData) => void;
	// columnWidths: {
	// 	company: number;
	// 	taxiService: number;
	// 	status: number;
	// 	isWorking: number;
	// };
	columnIds: string[];
	// eslint-disable-next-line react/require-default-props
	handleColumnResize?: (newWidth: number, status: string) => number;
	language: Language;
}

interface ColumnContext {
	language: Language;
}

interface Sort {
	column?: string;
	type?: SortType;
}

const ExecutorAlias = ({ data, onDoubleClick }) => {
	const actualExecutorJournal = data?.executorShiftJournals?.find(
		(journal) => journal.isLatest,
	)?.car?.class;

	const textColor = actualExecutorJournal?.textColor;
	const backgroundColor = actualExecutorJournal?.backgroundColor;

	const textColorHex = `${textColor?.model}(${textColor?.color?.join(",")})`;
	const backgroundColorHex = `${
		backgroundColor?.model
	}(${backgroundColor?.color?.join(",")})`;

	const carClassData = {
		shortName: actualExecutorJournal?.shortName || "",
		backgroundColor: backgroundColorHex,
		textColor: textColorHex,
		useBackgroundColor: actualExecutorJournal?.useBackgroundColor,
		useTextColor: actualExecutorJournal?.useTextColor,
	};
	return (
		<AliasRow
			bgColor={carClassData.backgroundColor}
			textColor={carClassData.textColor}
			useBgColor={carClassData.useBackgroundColor}
			useTextColor={carClassData.useTextColor}
			onDoubleClick={onDoubleClick}
			style={{ justifyContent: "center" }}
		>
			{`${data?.alias} ${carClassData.shortName || ""}`}
		</AliasRow>
	);
};

const ModelTable: React.FC<Props> = ({
	data,
	sort,
	onSort,
	tableRowClassName,
	onRowDoubleClick,
	onRowSelect,
	// columnWidths,
	columnIds,
	language,
}) => {
	const { t } = useTranslation();
	const TABLE_HEADER_HEIGHT = 26;
	const TABLE_ROW_HEIGHT = 32;

	const EXECUTOR_STATUSES = useMemo(() => getExecutorStatuses(t), [t]);

	const getStatusData = useCallback(
		(rowData) => {
			const isWorking = rowData?.isWorking ?? false;

			const onOwnOrder =
				rowData?.executorToOrders.find(
					(order) => order.source === "executor" && !order.closedAt,
				) ?? false;

			const hasDebt = rowData?.debt ?? false;

			let status = rowData?.status;

			if (isWorking) {
				if (hasDebt) {
					if (onOwnOrder) {
						status = "ownOrderDebt";
					} else {
						status = "availableDebt";
					}
				} else if (onOwnOrder) {
					status = "ownOrder";
				}
			} else {
				status = "notWorking";
			}

			const statusData = EXECUTOR_STATUSES[status];

			return statusData;
		},
		[EXECUTOR_STATUSES],
	);

	const getStatusBackgroundColor = useCallback(
		(rowData) => {
			const online = rowData?.online ?? false;

			const statusData = getStatusData(rowData);

			return online ? statusData?.color : statusData?.offlineColor;
		},
		[getStatusData],
	);

	const getStatusFontColor = useCallback(
		(rowData) => {
			const statusData = getStatusData(rowData);

			return statusData?.fontColor;
		},
		[getStatusData],
	);

	const getStatusName = useCallback(
		(rowData) => {
			const isYourOrderStatus = rowData.executorToOrders.find(
				(order) => order.source === "executor" && !order.closedAt,
			);
			if (rowData.debt) {
				if (rowData.status === "available") {
					return EXECUTOR_STATUSES.availableDebt.label;
				}
				if (rowData.closedBy) return EXECUTOR_STATUSES.closed.label;

				if (isYourOrderStatus) {
					return EXECUTOR_STATUSES.ownOrderDebt.label;
				}

				return `${EXECUTOR_STATUSES[rowData.status].label} ${
					t("orderPageWidgets.executors.modelTable.str200") ?? ""
				}`;
			}

			if (isYourOrderStatus) {
				return EXECUTOR_STATUSES.ownOrder.label;
			}
			return EXECUTOR_STATUSES[rowData.status].label;
		},
		[t, EXECUTOR_STATUSES],
	);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [rerender, setRerender] = useState(false);
	const { layout }: any = useTypedSelector((state) => state.widgets);

	useEffect(() => {
		if (layout?.dockbox?.children[1]?.activeId === "executors") {
			setRerender((prev) => !prev);
		}
	}, [layout?.dockbox?.children]);

	const Columns: Record<string, (context: ColumnContext) => ReactElement> = {
		online: () => (
			<CompactTable.Column
				// resizable
				sortable
				width={30}
				minWidth={30}
				key="online"
				// onResize={(newWidth) => handleColumnResize(newWidth, "online")}
			>
				<CompactTable.HeaderCell verticalAlign="middle">
					{t("orderPageWidgets.executors.modelTable.str150") ?? ""}
				</CompactTable.HeaderCell>
				<CompactTable.Cell fullText align="center" dataKey="online">
					{(rowData) => (
						<Row
							active={rowData.active}
							onDoubleClick={() => onRowDoubleClick(rowData)}
						>
							<CheckBox hovered={false} value={rowData.online} />
						</Row>
					)}
				</CompactTable.Cell>
			</CompactTable.Column>
		),
		alias: () => (
			<CompactTable.Column
				// resizable
				sortable
				width={55}
				minWidth={55}
				key="alias"
				// onResize={(newWidth) =>
				// 	handleColumnResize(newWidth, "alias")
				// }
			>
				<CompactTable.HeaderCell verticalAlign="middle">
					{t("orderPageWidgets.executors.modelTable.str151") ?? ""}
				</CompactTable.HeaderCell>
				<CompactTable.Cell align="center" dataKey="alias" fullText>
					{(rowData) => (
						<ExecutorAlias
							data={rowData}
							onDoubleClick={() => onRowDoubleClick(rowData)}
						/>
					)}
				</CompactTable.Cell>
			</CompactTable.Column>
		),

		carCallSigns: () => (
			<CompactTable.Column
				// resizable
				sortable
				width={55}
				minWidth={55}
				key="carCallSigns"
				// onResize={(newWidth) =>
				// 	handleColumnResize(newWidth, "alias")
				// }
			>
				<CompactTable.HeaderCell verticalAlign="middle">
					{t("orderPageWidgets.executors.modelTable.str152") ?? ""}
				</CompactTable.HeaderCell>
				<CompactTable.Cell
					align="center"
					dataKey="carCallSigns"
					fullText
				>
					{(rowData) => (
						<Row
							active={rowData.active}
							onDoubleClick={() => onRowDoubleClick(rowData)}
							style={{ justifyContent: "center" }}
						>
							{rowData?.executorToCrews[0]?.crew?.car?.callSign}
						</Row>
					)}
				</CompactTable.Cell>
			</CompactTable.Column>
		),
		executorGroup: () => (
			<CompactTable.Column
				// resizable
				sortable
				width={100}
				minWidth={100}
				key="executorGroup"
				// onResize={(newWidth) =>
				// 	handleColumnResize(newWidth, "alias")
				// }
			>
				<CompactTable.HeaderCell verticalAlign="middle">
					{t("orderPageWidgets.executors.modelTable.str153") ?? ""}
				</CompactTable.HeaderCell>
				<CompactTable.Cell
					align="center"
					dataKey="executorGroup"
					fullText
				>
					{(rowData) => (
						<Row
							active={rowData.active}
							onDoubleClick={() => onRowDoubleClick(rowData)}
							style={{ justifyContent: "center" }}
						>
							{rowData?.group?.name?.[language]}
						</Row>
					)}
				</CompactTable.Cell>
			</CompactTable.Column>
		),
		company: () => (
			<CompactTable.Column
				// resizable
				sortable
				// width={columnWidths.company}
				minWidth={50}
				flexGrow={1}
				key="company"
				// onResize={(newWidth) => handleColumnResize(newWidth, "company")}
			>
				<CompactTable.HeaderCell verticalAlign="middle">
					{t("orderPageWidgets.executors.modelTable.str154") ?? ""}
				</CompactTable.HeaderCell>
				<CompactTable.Cell align="center" dataKey="company" fullText>
					{(rowData) => (
						<Row
							active={rowData.active}
							onDoubleClick={() => onRowDoubleClick(rowData)}
						>
							{rowData.taxiService?.company?.name[language]}
						</Row>
					)}
				</CompactTable.Cell>
			</CompactTable.Column>
		),
		taxiService: () => (
			<CompactTable.Column
				// resizable
				sortable
				// width={columnWidths.taxiService}
				minWidth={50}
				flexGrow={0.7}
				key="taxiService"
				// onResize={(newWidth) =>
				// 	handleColumnResize(newWidth, "taxiService")
				// }
			>
				<CompactTable.HeaderCell verticalAlign="middle">
					{t("orderPageWidgets.executors.modelTable.str155") ?? ""}
				</CompactTable.HeaderCell>
				<CompactTable.Cell
					align="center"
					dataKey="taxiService"
					fullText
				>
					{(rowData) => (
						<Row
							active={rowData.active}
							onDoubleClick={() => onRowDoubleClick(rowData)}
						>
							{rowData.taxiService?.settlement[language]}
						</Row>
					)}
				</CompactTable.Cell>
			</CompactTable.Column>
		),
		status: () => (
			<CompactTable.Column
				// resizable
				sortable
				// width={columnWidths.status}
				minWidth={50}
				flexGrow={1}
				key="status"
				// onResize={(newWidth) => handleColumnResize(newWidth, "status")}
			>
				<CompactTable.HeaderCell verticalAlign="middle">
					{t("orderPageWidgets.executors.modelTable.str156") ?? ""}
				</CompactTable.HeaderCell>
				<CompactTable.Cell fullText dataKey="status" align="center">
					{(rowData) => (
						<Row
							background={getStatusBackgroundColor(rowData)}
							color={getStatusFontColor(rowData)}
							active={rowData.active}
							onDoubleClick={() => onRowDoubleClick(rowData)}
						>
							{<span>{getStatusName(rowData)}</span>}
						</Row>
					)}
				</CompactTable.Cell>
			</CompactTable.Column>
		),
		isWorking: () => (
			<CompactTable.Column
				// resizable
				sortable
				// width={columnWidths.isWorking}
				minWidth={50}
				flexGrow={1}
				key="isWorking"
				// onResize={(newWidth) =>
				// 	handleColumnResize(newWidth, "workingStatus")
				// }
			>
				<CompactTable.HeaderCell verticalAlign="middle">
					{t("orderPageWidgets.executors.modelTable.str157") ?? ""}
				</CompactTable.HeaderCell>
				<CompactTable.Cell fullText dataKey="isWorking" align="left">
					{(rowData) => (
						<Row
							active={rowData.active}
							onDoubleClick={() => onRowDoubleClick(rowData)}
						>
							{
								<div
									style={{
										display: "flex",
										alignItems: "center",
										padding: "0px",
									}}
								>
									{rowData?.isWorking ? (
										<Circle color="rgb(76, 175, 80)" />
									) : (
										<Circle />
									)}
									<span style={{ marginLeft: 8 }}>
										{rowData?.schedule?.name[language]}
									</span>
								</div>
							}
						</Row>
					)}
				</CompactTable.Cell>
			</CompactTable.Column>
		),
	};

	return (
		<div style={{ overflow: "hidden" }}>
			<CompactTable
				data={data}
				virtualized
				headerHeight={TABLE_HEADER_HEIGHT}
				rowHeight={TABLE_ROW_HEIGHT}
				sortColumn={sort?.column}
				sortType={sort?.type}
				fillHeight
				loading={false}
				onScroll={() => {}}
				shouldUpdateScroll={false}
				onSortColumn={(column, type) => onSort(column, type)}
				rowClassName={tableRowClassName}
				onRowClick={onRowSelect}
			>
				{columnIds.map((columnId) => Columns[columnId]({ language }))}
			</CompactTable>
		</div>
	);
};

export default ModelTable;
