import React, { Dispatch, useMemo, useState } from "react";
import Root from "./components/Root";
import Item from "./components/Item";
import usePermissionService from "../../../../../../../../../../../../../../../../../../hooks/usePermissionService";
import useObjectEditor from "../../../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import { ExpandedContext } from "./components/Item/components/Expandable";

const List: React.FC<List.Props> = ({ value, disabled, options, onChange }) => {
	const valueEditor = useObjectEditor(value, onChange);

	const permissionService = usePermissionService();

	const [expanded, setExpanded] = useState<string[]>([]);

	const expandedContextValue = useMemo<ExpandedContext>(
		() => ({
			items: expanded,
			onChangeItems: setExpanded,
		}),
		[expanded],
	);

	return (
		<ExpandedContext.Provider value={expandedContextValue}>
			<Root>
				{options.map((option) => {
					let props: Item.Props;

					if ("options" in option) {
						props = {
							...option,

							value: permissionService.pick(
								valueEditor.value,
								`${option.value}.*`,
							),

							permission: option.value,

							onChange: (newPermissions) => {
								const permissions = permissionService.pick(
									valueEditor.value,
									`${option.value}.*`,
								);

								const removedPermissions = permissions.filter(
									(permission) =>
										!newPermissions.includes(permission),
								);

								const addedPermissions = newPermissions.filter(
									(permission) =>
										!permissions.includes(permission),
								);

								removedPermissions.forEach(
									(removedPermission) => {
										valueEditor.removeByValue(
											removedPermission,
										);
									},
								);

								valueEditor.concat(addedPermissions);
							},
						} as Item.Props2;
					} else {
						props = {
							...option,

							value: permissionService.has(
								valueEditor.value,
								option.value,
							),

							onChange: (selected) => {
								if (selected) valueEditor.push(option.value);
								else valueEditor.removeByValue(option.value);
							},
						} as Item.Props1;
					}

					props.disabled = disabled;

					return (
						// eslint-disable-next-line react/jsx-key
						<Item {...props} />
					);
				})}
			</Root>
		</ExpandedContext.Provider>
	);
};

declare namespace List {
	type Value = string[];

	type Option = Item.Option;
	type Options = Item.Options;

	interface Props {
		value: Value;

		disabled: boolean;
		options: Options;

		onChange: Dispatch<Value>;
	}
}

export default List;
