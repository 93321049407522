import React, { useCallback, useEffect, useMemo, useState } from "react";
import { isEqual } from "lodash";

import { Role as RoleService } from "../../../../../../services";
import {
	useTypedSelector,
	useTypedDispatch,
} from "../../../../../../redux/store";
import Account from "../../../../../../redux/services/Account";
import mapByKey from "../../../../../../utils/mapByKey";
import {
	useTaxiServiceIdsDecoder,
	useGetCompanyIdsByTaxiServiceIds,
	useModelSubscribe,
} from "../../../../../../hooks";
import { useSort } from "../../../../../../hooks/useTableSettings";
import DeleteModal from "../../../../../../components/DeleteModal";
import {
	ACCESS_TO_THE_COMPONENT,
	DisplayFields,
} from "../../../../../../constants/access";

import { Content, Header, EditModal, Root } from "./components";
import getCompanies from "../../../../../../redux/services/Company/getCompanies";

const Roles: React.FC = () => {
	const dispatch = useTypedDispatch();

	const settingsLanguage = useTypedSelector(
		(state) => state.session.language,
	);
	const user = useTypedSelector((state) => state.account.user);

	const { models: taxiServices } = useTypedSelector(
		(state) => state.taxiServices,
	);

	const getCompanyIdsByTaxiServiceIds = useGetCompanyIdsByTaxiServiceIds();
	const decodeTaxiServiceIds = useTaxiServiceIdsDecoder();

	const [headerFilters, setHeaderFilters] = useState<Header.Filters.Value>({
		search: "",
	});
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [editingItem, setEditingItem] = useState<EditModal.Value | null>(
		null,
	);
	const [selected, setSelected] = useState<number[]>([]);
	const [selectOnChangeAccess, setSelectOnChangeAccess] =
		useState<DisplayFields>(ACCESS_TO_THE_COMPONENT);

	// const modelSubscriptionOptions = useMemo<RoleService.SubscribeOptions>(
	// 	() => ({
	// 		query: headerFilters.search,
	// 	}),
	// 	[headerFilters.search],
	// );
	const { companies } = useTypedSelector((state) => state.ordersPageReducer);

	useEffect(() => {
		dispatch(getCompanies());
	}, [dispatch]);

	const modelData = useModelSubscribe({}, RoleService);

	const modelItems = useMemo(() => modelData.models, [modelData.models]);

	const modelItemById = useMemo(
		() => mapByKey(modelItems, "id"),
		[modelItems],
	);

	const { sortMethod: sort, setSortMethod: setSort } = useSort(
		"accounts.roles",
		"role",
	);

	const taxiServiceIds = useMemo(
		() => taxiServices?.map((item) => item.id) || [],
		[taxiServices],
	);
	const companyIds = useMemo(
		() => companies?.items?.map((item) => item.id) || [],
		[companies],
	);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const edit = useCallback(
		(id: number) => {
			const item = modelItemById[id];

			setEditingItem({
				id: item.id,

				executorIds: item.executorIds ?? [],
				dispatcherIds: item.dispatcherIds ?? [],
				serviceIds: item.serviceIds ?? [],

				name: item.name,

				assignableTo: item.assignableTo,

				companyIds,
				taxiServiceIds,
				displayFields: item.displayFields ?? selectOnChangeAccess,
				description: item.description,

				active: item.active,
				default: item.default,

				createdAt: item.createdAt ?? undefined,
			});
			setSelectOnChangeAccess(
				item.displayFields ?? ACCESS_TO_THE_COMPONENT,
			);
		},
		[
			getCompanyIdsByTaxiServiceIds,
			modelItemById,
			selectOnChangeAccess,
			taxiServiceIds,
			companyIds,
		],
	);

	const headerCanEdit = useMemo(
		() => selected.length === 1,
		[selected.length],
	);

	const headerCanDelete = useMemo(
		() =>
			selected.length !== 0 &&
			!selected.some((id) => modelItemById[id].default),
		[modelItemById, selected],
	);

	const headerOnAdd = useCallback(() => {
		setEditingItem({
			executorIds: [],
			dispatcherIds: [],
			serviceIds: [],

			name: "",

			assignableTo: undefined,

			companyIds,
			taxiServiceIds,
			displayFields: selectOnChangeAccess,

			description: "",

			active: true,
			default: false,
		});
		setSelectOnChangeAccess(ACCESS_TO_THE_COMPONENT);
	}, [selectOnChangeAccess, taxiServiceIds, companyIds]);

	const headerOnEdit = useCallback(() => {
		edit(selected[0]);
	}, [edit, selected]);

	const headerOnDelete = useCallback(() => {
		setShowDeleteModal(true);
	}, []);

	const contentOnEdit = useCallback((item) => edit(item.id), [edit]);

	const contentOnLoadMore = useCallback(() => {}, []);

	const content = useMemo(
		() => (
			<>
				<Header
					filters={headerFilters}
					canEdit={headerCanEdit}
					canDelete={headerCanDelete}
					onChangeFilters={setHeaderFilters}
					onAdd={headerOnAdd}
					onEdit={headerOnEdit}
					onDelete={headerOnDelete}
				/>
				<Content
					selected={selected}
					sort={sort}
					loading={false}
					data={modelItems}
					language={settingsLanguage}
					onChangeSelected={setSelected}
					onChangeSort={setSort}
					onEdit={contentOnEdit}
					onLoadMore={contentOnLoadMore}
				/>
			</>
		),
		[
			contentOnEdit,
			contentOnLoadMore,
			headerCanDelete,
			headerCanEdit,
			headerFilters,
			headerOnAdd,
			headerOnDelete,
			headerOnEdit,
			modelItems,
			selected,
			setSort,
			settingsLanguage,
			sort,
		],
	);

	const editModalOnCancel = useCallback(() => {
		setEditingItem(null);
	}, []);

	const editModalOnSave = useCallback(
		async (newItem: EditModal.Value.Validated) => {
			if (
				isEqual(editingItem, newItem) &&
				isEqual(editingItem?.displayFields, selectOnChangeAccess)
			) {
				setEditingItem(null);

				return;
			}

			const newProperties: RoleService.Model.New = {
				dispatcherIds: newItem.dispatcherIds,
				executorIds: newItem.executorIds,
				serviceIds: newItem.serviceIds,
				taxiServiceIds: decodeTaxiServiceIds(
					newItem.companyIds,
					newItem.taxiServiceIds,
				),
				displayFields: selectOnChangeAccess,

				name: newItem.name,
				description: newItem.description,
				permissions: [] /* newItem.permissions, */,

				active: newItem.active,
				assignableTo: newItem.assignableTo,
				default: newItem.default,
			};

			if (typeof newItem.id === "number")
				await RoleService.update({
					id: newItem.id,

					...newProperties,
				});
			else await RoleService.store(newProperties);

			if (user?.id) {
				dispatch(Account.me(user?.id.toString()));
			}

			setSelectOnChangeAccess(ACCESS_TO_THE_COMPONENT);
			setEditingItem(null);
		},
		[
			decodeTaxiServiceIds,
			dispatch,
			editingItem,
			selectOnChangeAccess,
			user?.id,
		],
	);

	const deleteModalOnCancel = useCallback(() => {
		setShowDeleteModal(false);
	}, []);

	const deleteModalOnConfirm = useCallback(async () => {
		setShowDeleteModal(false);

		await RoleService.destroy(selected);

		setSelected([]);
	}, [selected]);

	return (
		<Root sizes="auto! 1fr" gaps="16px" maxedWidth maxedHeight>
			{content}
			{editingItem && (
				<EditModal
					value={editingItem}
					language={settingsLanguage}
					onCancel={editModalOnCancel}
					onSave={editModalOnSave}
					selectOnChangeAccess={setSelectOnChangeAccess}
				/>
			)}
			{showDeleteModal && (
				<DeleteModal
					onCancel={deleteModalOnCancel}
					onConfirm={deleteModalOnConfirm}
				/>
			)}
		</Root>
	);
};

export default Roles;
