/* eslint-disable no-shadow */
import React, { Dispatch, memo, useMemo } from "react";
import { Icon, Row } from "uikit";

import Executor from "../../../../../../../../services/Executor";
import { useTypedSelector } from "../../../../../../../../redux/store";
import ControlButtons from "../../../../../../../PreferencesPages/components/ScreenDirectory/components/components/ControlButtons";
import Button from "../../../../../../../PreferencesPages/components/ScreenDirectory/components/components/Button";
import {
	generateAccessName,
	AccessKey,
	hasAccess,
} from "../../../../../../../../access";
import { TabKeys, ARR_ACCESS_PART_KEY } from "../../../../constants/access";

import InternalFilters from "./components/Filters";
import Root from "./components/Root";
import Balance from "./components/Balance";
import SendingSMS from "./components/SendingSMS";
import Call from "./components/Call";
import SendingPush from "./components/SendingPush";

const Header = memo<Header.Props>(
	({
		checkedExecutors,
		filters,
		canEdit,
		canDelete,
		onChangeFilters,
		onAdd,
		onEdit,
		onDelete,
	}) => {
		const personalRole = useTypedSelector(
			(state) => state.account.personalRole,
		);

		const AccessBtnGroup = useMemo(() => {
			const ACCESS_TAB = ARR_ACCESS_PART_KEY[TabKeys.EXECUTORS];

			const retval = {
				call: hasAccess(
					generateAccessName(...ACCESS_TAB, AccessKey.CALL),
					personalRole,
				),
				sendingSMS: hasAccess(
					generateAccessName(...ACCESS_TAB, AccessKey.SENDING_SMS),
					personalRole,
				),
				sendingPush: hasAccess(
					generateAccessName(...ACCESS_TAB, AccessKey.SENDEING_PUSH),
					personalRole,
				),
			};

			return retval;
		}, [personalRole]);

		const mainAccount = checkedExecutors[0]?.paymentAccounts.find(
			(a) => a.type === "main",
		);
		const bonusAccount = checkedExecutors[0]?.paymentAccounts.find(
			(a) => a.type === "bonus",
		);
		const executor = checkedExecutors.length === 1 && checkedExecutors[0];

		return (
			<Root
				gaps="10px*"
				align="center"
				justify="space-between"
				maxedWidth
			>
				<Row gaps="60px*" align="center">
					<ControlButtons
						canEdit={canEdit}
						canDelete={canDelete}
						onAdd={onAdd}
						onEdit={onEdit}
						onDelete={onDelete}
						accessName={ARR_ACCESS_PART_KEY[TabKeys.EXECUTORS]}
					/>
					<Row align="center" gaps="10px*">
						{AccessBtnGroup.call && (
							<Call executors={checkedExecutors} />
						)}
						{AccessBtnGroup.sendingSMS && (
							<SendingSMS executors={checkedExecutors} />
						)}
						{AccessBtnGroup.sendingPush && (
							<SendingPush executors={checkedExecutors} />
						)}
					</Row>
					<Row align="center" gaps="10px*">
						<Balance
							executor={executor}
							paymentAccount={mainAccount}
							iconId="balance"
						/>
						<Balance
							executor={executor}
							paymentAccount={bonusAccount}
							iconId="bonus-balance"
						/>
					</Row>
				</Row>
				<Row gaps="10px*">
					<Button
						variant="secondary"
						icon={<Icon id="save" size={20} />}
						disabled
					/>
					<Button
						variant="secondary"
						icon={<Icon id="print" size={20} />}
						disabled
					/>
					<InternalFilters
						value={filters}
						onChange={onChangeFilters}
					/>
				</Row>
			</Root>
		);
	},
);

declare namespace Header {
	interface Props {
		checkedExecutors: Executor.Model[];
		filters: InternalFilters.Value;
		canEdit: boolean;
		canDelete: boolean;
		onChangeFilters: Dispatch<InternalFilters.Value>;
		onAdd: () => void;
		onEdit: () => void;
		onDelete: () => void;
	}

	namespace Filters {
		type Value = InternalFilters.Value;
		type Props = InternalFilters.Props;
	}
}

export default Header;
