import React, { ReactElement, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Nullable, Row, theme } from "uikit";
import { Popover, Whisper } from "rsuite";
import moment from "moment";
import { SortType } from "rsuite-table";

import Parking from "../../../../../services/Parking";
import Language from "../../../../../services/Language";
import { useTypedSelector } from "../../../../../redux/store";
import CompactTable from "../../../../CompactTable";
import { ModelTable } from "../../../Executors";

interface Props {
	// executorRef: React.MutableRefObject<any>;
	data: { type?: SortType; column?: SortType; data: Parking.Model[] };
	selectedId: Nullable<number>;
	onChangeSelectedId: React.Dispatch<React.SetStateAction<number>>;
	onChangeIsEditModal: React.Dispatch<React.SetStateAction<boolean>>;
	columnIds: string[];
	globalLang: Language;
	sort: ModelTable.Sort;
	onChangeSort: (
		{
			type,
			column,
		}: {
			type: SortType;
			column: SortType;
		},
		taxiServiceId: number,
	) => void;
	onShowParkingModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const StyledCell = styled(Row)<{ active: boolean; background?: string }>`
	padding: 9px 6px;
	color: ${(props) => (props.active ? props.color : "lightgray")} !important;
	background: ${(props) => props?.background || "transparent"};
	width: 100%;
	display: flex;
	gap: 5px;
	font-size: 12px;
	white-space: wrap;
	align-items: center;
	z-index: 1000;

	${theme.longWord}
`;

const StyledCellExecutor = styled(Row)<{
	active: boolean;
	background?: string;
}>`
	padding: 3px 6px;
	color: ${(props) => (props.active ? props.color : "lightgray")} !important;
	background: ${(props) => props?.background || "transparent"};
	width: 100%;
	display: flex;
	gap: 3px;
	font-size: 12px;
	white-space: wrap;
	align-items: flex-start;
	z-index: 1000;

	${theme.longWord}
`;

const StyledExecutorCell = styled("div")<{ bgColor: string; fnColor: string }>`
	padding: 6px 10px;
	height: 26px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${(props) => props.fnColor || "#fff"};
	font-weight: 700;
	font-size: 11px;
	border-radius: 3px;
	background-color: ${(props) => props.bgColor || "#9BA3A8"};
	border: ${(props) =>
		props.bgColor === "transparent"
			? `1px solid ${theme.colors.color_border_basic};`
			: "none"};
`;

const StyledExecutorShortName = styled("div")<{
	useBgColor: boolean;
	useTextColor: boolean;
	bgColor: string;
	textColor: string;
}>`
	padding: 2px;
	height: 20px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	font-size: 11px;
	color: ${(props) =>
		props.useTextColor ? props.textColor : theme.colors.primary};
	background-color: ${(props) =>
		props.useBgColor ? props.bgColor : "transparent"};
	border-radius: 2px;
`;

const getPersonFullName = (data) =>
	`${data?.person?.surname ? data.person.surname : ""} ${
		data?.person?.name ? data.person.name : ""
	} ${data?.person?.fatherName ? data.person.fatherName : ""}`;

const getPersonPhones = (data) => {
	const phones =
		data?.person?.phones.map((phone) => phone.number).join(", ") || "";
	return phones;
};

const getCar = (data, lang) => {
	const car = data?.executorToCrews?.length
		? data?.executorToCrews[0]?.crew?.car
		: {};

	const registrationNumber = car?.additionalFields?.registrationNumber || "";

	return {
		carData: `${car?.model?.brand?.name || ""} ${car?.model?.name || ""} ${
			registrationNumber ? `(${registrationNumber})` : ""
		}`,
		carType: `${car?.carClass?.name?.[lang] || ""}`,
	};
};

const getSpeed = (data) => data?.location?.speed || "";

const getLastOrder = (data) => {
	const max = data?.executorToOrders?.length
		? Math.max(
				...data.executorToOrders.map((a) =>
					new Date(a.order.closedAt).getTime(),
				),
		  )
		: "";
	return max ? moment(max).format("HH:mm DD/MM/yyyy") : "";
};

const ExecutorComponent = ({ data }) => {
	const { t } = useTranslation();

	const lang = useTypedSelector((state) => state.session.language);

	const executorStatuses = useMemo(
		() => ({
			busy: {
				label: t("orderPageWidgets.parking.parkingTable.str202") ?? "",
				color: "rgb(252, 151, 57)",
				offlineColor: "rgba(252, 151, 57, 0.3)",
				fontColor: theme.colors.primary,
			},
			dinner: {
				label: t("orderPageWidgets.parking.parkingTable.str203") ?? "",
				color: "rgb(33, 150, 243)",
				offlineColor: "rgba(33, 150, 243, 0.3)",
				fontColor: theme.colors.primary,
			},
			home: {
				label: t("orderPageWidgets.parking.parkingTable.str204") ?? "",
				color: "rgb(16, 228, 235)",
				offlineColor: "rgba(16, 228, 235, 0.3)",
				fontColor: theme.colors.primary,
			},
			on_order: {
				label: t("orderPageWidgets.parking.parkingTable.str205") ?? "",
				color: "rgb(255, 128, 130)",
				offlineColor: "rgba(255, 128, 130, 0.3)",
				fontColor: theme.colors.primary,
			},
			available: {
				label: t("orderPageWidgets.parking.parkingTable.str206") ?? "",
				color: "rgb(76, 175, 80)",
				offlineColor: "rgba(76, 175, 80, 0.3)",
				fontColor: theme.colors.primary,
			},
			closed: {
				label: t("orderPageWidgets.parking.parkingTable.str207") ?? "",
				color: "rgb(134, 75, 75)",
				offlineColor: "rgba(134, 75, 75, 0.3)",
				fontColor: theme.colors.white,
			},
			your_order: {
				label: t("orderPageWidgets.parking.parkingTable.str208") ?? "",
				color: "rgb(102, 84, 177)",
				offlineColor: "rgba(102, 84, 177, 0.3)",
				fontColor: theme.colors.white,
			},
		}),
		[t],
	);

	const getOnlineStatus = (data) =>
		data.online
			? t("orderPageWidgets.parking.parkingTable.str200") ?? ""
			: t("orderPageWidgets.parking.parkingTable.str201") ?? "";

	const backgroundColor = useMemo(() => {
		if (!data?.workingStatus) {
			return data?.online
				? "rgb(190, 190, 190)"
				: "rgba(190, 190, 190, 0.3)";
		}

		const isYourOrderStatus = data.executorToOrders.find(
			(order) => order.source === "executor" && !order.closedAt,
		);

		if (isYourOrderStatus) {
			return data?.online
				? executorStatuses.your_order.color
				: executorStatuses.your_order.offlineColor;
		}

		return data?.online
			? executorStatuses[data.status]?.color
			: executorStatuses[data.status]?.offlineColor;
	}, [data.executorToOrders, data?.online, data.status, data?.workingStatus]);

	const fontColor = useMemo(() => {
		if (!data?.workingStatus) {
			return theme.colors.primary;
		}

		const isYourOrderStatus = data.executorToOrders.find(
			(order) => order.source === "executor" && !order.closedAt,
		);

		if (isYourOrderStatus) {
			return executorStatuses.your_order.fontColor;
		}

		return executorStatuses[data.status]?.fontColor;
	}, [data.executorToOrders, data.status, data?.workingStatus]);

	const car = useMemo(() => getCar(data, lang), [data, lang]);
	return (
		<Whisper
			speaker={
				<Popover>
					<div>
						<div>
							{t(
								"orderPageWidgets.parking.parkingTable.str209",
							) ?? ""}
							: {data?.callSign}
						</div>
						<div>
							{t(
								"orderPageWidgets.parking.parkingTable.str210",
							) ?? ""}
							: {getPersonFullName(data)}
						</div>
						<div>
							{t(
								"orderPageWidgets.parking.parkingTable.str211",
							) ?? ""}
							: {executorStatuses[data.status]?.label}
						</div>
						<div>
							{t(
								"orderPageWidgets.parking.parkingTable.str212",
							) ?? ""}
							: {getPersonPhones(data)}
						</div>
						<div>
							{t(
								"orderPageWidgets.parking.parkingTable.str214",
							) ?? ""}
							: {getOnlineStatus(data)}
						</div>
						<div>
							{t(
								"orderPageWidgets.parking.parkingTable.str215",
							) ?? ""}
							: {car.carData}
						</div>
						<div>
							{t(
								"orderPageWidgets.parking.parkingTable.str216",
							) ?? ""}
							: {car.carType}
						</div>
						<div>
							{t(
								"orderPageWidgets.parking.parkingTable.str217",
							) ?? ""}
							:{" "}
						</div>
						<div>
							{t(
								"orderPageWidgets.parking.parkingTable.str218",
							) ?? ""}
							:{" "}
						</div>
						<div>
							{t(
								"orderPageWidgets.parking.parkingTable.str219",
							) ?? ""}
							: {getLastOrder(data)}
						</div>
						<div>
							{t(
								"orderPageWidgets.parking.parkingTable.str220",
							) ?? ""}
							: {getSpeed(data)}
						</div>
					</div>
				</Popover>
			}
			placement="bottom"
		>
			<StyledExecutorCell bgColor={backgroundColor} fnColor={fontColor}>
				{data.callSign}
			</StyledExecutorCell>
		</Whisper>
	);
};

const ShortName = ({ data }) => {
	const actualExecutorJournal = data.executorShiftJournals?.find(
		(journal) => journal.isLatest,
	)?.car?.class;

	const textColor = actualExecutorJournal?.textColor;
	const backgroundColor = actualExecutorJournal?.backgroundColor;

	const textColorHex = `${textColor?.model}(${textColor?.color?.join(",")})`;
	const backgroundColorHex = `${
		backgroundColor?.model
	}(${backgroundColor?.color?.join(",")})`;

	const carClassData = {
		shortName: actualExecutorJournal?.shortName || "",
		backgroundColor: backgroundColorHex,
		textColor: textColorHex,
		useBackgroundColor: actualExecutorJournal?.useBackgroundColor,
		useTextColor: actualExecutorJournal?.useTextColor,
	};

	return carClassData.shortName.length ? (
		<StyledExecutorShortName
			bgColor={carClassData.backgroundColor}
			textColor={carClassData.textColor}
			useBgColor={carClassData.useBackgroundColor}
			useTextColor={carClassData.useTextColor}
		>
			{carClassData.shortName}
		</StyledExecutorShortName>
	) : (
		<div style={{ height: 0, width: 0, position: "absolute" }}></div>
	);
};

const ParkingTable: React.FC<Props> = ({
	// executorRef,
	data,
	selectedId,
	onChangeSelectedId,
	onChangeIsEditModal,
	columnIds,
	globalLang,
	sort,
	// onChangeSort,
	onShowParkingModal,
}) => {
	const { t } = useTranslation();
	// const sortColumn = useCallback(
	// 	(column, type, taxiServiceId) => {
	// 		onChangeSort({ column, type }, taxiServiceId);
	// 	},
	// 	[onChangeSort],
	// );

	const tableRowClassName = useCallback(
		(rowData) => {
			if (rowData?.id === selectedId) return "founded";

			if (!rowData?.active) return "not-active";

			return "";
		},
		[selectedId],
	);

	const onRowSelect = useCallback(
		(rowData) => {
			if (rowData.id === selectedId) {
				onChangeSelectedId(0);
			} else {
				onChangeSelectedId(rowData.id);
			}
		},
		[onChangeSelectedId, selectedId],
	);

	const onRowDoubleClick = useCallback(
		(rowData) => {
			onChangeSelectedId(rowData.id);
			onChangeIsEditModal(true);
			onShowParkingModal(true);
		},
		[onChangeIsEditModal, onChangeSelectedId, onShowParkingModal],
	);

	const Columns: Record<string, () => ReactElement> = {
		name: () => (
			<CompactTable.Column flexGrow={0.2} sortable>
				<CompactTable.HeaderCell verticalAlign="middle">
					{t("orderPageWidgets.parking.parkingTable.str150") ?? ""}
				</CompactTable.HeaderCell>
				<CompactTable.Cell
					align="center"
					dataKey="name"
					wordWrap
					fullText
					style={{ cursor: "pointer" }}
				>
					{(rowData) => (
						<StyledCell
							active={rowData.active}
							justify="flex-start"
							style={{ fontSize: 14 }}
						>
							{rowData.name[globalLang]}
						</StyledCell>
					)}
				</CompactTable.Cell>
			</CompactTable.Column>
		),
		driversAmount: () => (
			<CompactTable.Column width={44} sortable>
				<CompactTable.HeaderCell verticalAlign="top">
					{t("orderPageWidgets.parking.parkingTable.str151") ?? ""}
				</CompactTable.HeaderCell>
				<CompactTable.Cell
					align="center"
					dataKey="executorsCount"
					fullText
					style={{ fontSize: 14, cursor: "pointer" }}
				>
					{(rowData) => (
						<StyledCell active={rowData.active} justify="center">
							{rowData.executorsQueue.length}
						</StyledCell>
					)}
				</CompactTable.Cell>
			</CompactTable.Column>
		),
		aliases: () => (
			<CompactTable.Column flexGrow={0.4} sortable>
				<CompactTable.HeaderCell
					verticalAlign="middle"
					itemID="executorsColumn"
				>
					{t("orderPageWidgets.parking.parkingTable.str152") ?? ""}
				</CompactTable.HeaderCell>
				<CompactTable.Cell
					dataKey="executors"
					wordWrap
					fullText
					autoHeight
					style={{ cursor: "pointer" }}
				>
					{(rowData) => (
						<StyledCellExecutor active={rowData.active} wrap="wrap">
							{rowData.executorsQueue?.length ? (
								rowData.executorsQueue.map((queue) => (
									<Row
										key={queue.executor.id}
										align="center"
										gaps="2px"
									>
										<ExecutorComponent
											data={queue.executor}
										/>
										<ShortName data={queue.executor} />
									</Row>
								))
							) : (
								<div style={{ height: 26 }}></div>
							)}
						</StyledCellExecutor>
					)}
				</CompactTable.Cell>
			</CompactTable.Column>
		),
	};

	return (
		<CompactTable
			// ref={executorRef}
			virtualized
			data={data.data}
			headerHeight={26}
			sortColumn={sort.column}
			sortType={sort.type}
			// onSortColumn={(column, type) =>
			// 	sortColumn(column, type, data.data[0].taxiServiceId)
			// }
			autoHeight
			loading={false}
			shouldUpdateScroll={false}
			rowClassName={tableRowClassName}
			onRowClick={onRowSelect}
			onRowDoubleClick={onRowDoubleClick}
		>
			{columnIds.map((columnId) => Columns[columnId]())}
		</CompactTable>
	);
};

export default ParkingTable;
