import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Column, theme } from "uikit";
import { cloneDeep, compact, isNumber, uniq } from "lodash";

import Client from "../../../../../../../../../services/Client";
import Employee from "../../../../../../../../../services/Employee";
import useModelSubscribe from "../../../../../../../../../hooks/useModelSubscribe2";
import useCurrentTaxiServices from "../../../../../../../../../hooks/useCurrentTaxiService";
import { ModelId } from "../../../../../../../../../types/ModelId";
import DefaultPageHeader from "../../../../../../../../../components/DefaultPageHeader";
import ControlButtons from "../../../../../../../../../components/DefaultPageHeader/components/ControlButtons";
import DeleteModal from "../../../../../../../../../components/DeleteModal";
import { useTableOptions } from "../../../../../../../../../components/LightTable";
import Cards from "../../Modal/components/Content/tabs/Accounts/Cards";
import Accounts from "../../Modal/components/Content/tabs/Accounts";
import Root from "../components/Root";

import tPath from "./constants/tPath";
import Content from "./components/Content";
import Modal from "./components/Modal";
import ClientSelectModal from "./components/ClientSelectModal";
import prepareEmployee from "./constants/prepareEmployee";
import defaultValue from "./constants/defaultValue";

const GeneralEmployees: React.FC<GeneralEmployees.Props> = ({
	tableFrom,
	employees,
	setEmployees,
	checks,
	setChecks,
	cards,
	setCards,
	canAdd,
	visible,
	isCounterparty,
	isCheck,
	columnsName,
	companyId,
	taxiServiceId,
	counterpartyId,
	setEmployeeDeleteIds,
	cardId,
}) => {
	const { active, editor, onChange, onActive, isActive, isNotActive } =
		useTableOptions();

	const {
		editor: editorClients,
		onChange: editorOnChange,
		sort: sortClient,
		limit: limitClient,
		lang,
		query,
	} = useTableOptions();

	const optionsSubscribeClient = useMemo(() => {
		const order = {};
		const payload: Client.SubscribeOptions = {
			language: lang,
			limit: limitClient,
			order,
			query,
			statuses: [Client.Status.ACTIVE],
		};
		if (sortClient.dataKey) {
			order[sortClient.dataKey] = sortClient.sortType;
		}

		return payload;
	}, [limitClient, sortClient, lang, query]);

	const { models: clients } = useModelSubscribe(
		optionsSubscribeClient,
		Client,
	);
	const { t } = useTranslation();

	const [actualDefaultValue, setActualDefaultValue] = useState(defaultValue);

	const settings = useCurrentTaxiServices()?.settings;
	const employee = settings?.counterparty.check.checkCard.employee;

	useEffect(() => {
		if (employee) {
			setActualDefaultValue({
				...defaultValue,
				active: employee.active,
				phones: [{ ...employee.phones, value: "" }],
				isAccessToCorporateAccount: employee.isAccessToCorporateAccount,
				isAccessToCorporateApp: employee.isAccessToCorporateApp,
				isOneTimePassword: employee?.isOneTimePassword,
				oneTimePassword: employee?.oneTimePassword,
			});
		}
	}, [employee]);

	const [showModal, setShowModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showClientSelectModal, setShowClientSelectModal] = useState(false);

	const [selectedClientIds, setSelectedClientIds] = useState<number[]>([]);

	const selectClientHandler = useCallback(() => {
		setShowClientSelectModal(true);
	}, []);

	const closeSelectClientModalHandler = useCallback(() => {
		setShowClientSelectModal(false);
	}, []);

	const onSubmitClientsModal = useCallback(
		(ids: number[]) => {
			const newEmployees = clients
				.filter(({ id }) => ids.includes(id))
				.map(prepareEmployee);

			setEmployees?.([...employees, ...newEmployees]);
			setSelectedClientIds(ids);
			setShowClientSelectModal(false);
		},
		[clients, employees, setEmployees],
	);

	const [editingItem, setEditingItem] =
		useState<Modal.Value>(actualDefaultValue);

	const [selected, setSelected] = useState<(string | ModelId)[]>([]);

	const modelItemById = useCallback(
		(id: ModelId | string) =>
			employees.find((employee) => employee.id === id) as
				| Employee.Model
				| Employee.New,
		[employees],
	);

	const edit = useCallback(
		(id: ModelId | string) => {
			const item = modelItemById(id);

			setEditingItem(cloneDeep(item));
			setShowModal(true);
			setSelected([]);
		},
		[modelItemById],
	);

	const editHeaderHandler = useCallback(() => {
		edit(selected[0]);
	}, [edit, selected]);

	const editContentHandler = useCallback(
		(employee: Employee.Modified) => edit(employee.id),
		[edit],
	);

	const addHandler = useCallback(() => {
		setEditingItem(actualDefaultValue);
		setShowModal(true);
	}, [actualDefaultValue]);

	const preDeleteHandler = useCallback(() => {
		setShowDeleteModal(true);
	}, []);

	const cancelDelete = useCallback(() => {
		setShowDeleteModal(false);
		setSelected([]);
	}, []);

	const deleteHandler = useCallback(() => {
		if (canAdd) {
			// remove employee inside card
			const employeeDeleteIds: number[] = [];

			const newEmployees = [...employees].filter(({ id }) =>
				id ? !selected.includes(id) : false,
			);

			[...employees]
				.filter(({ id }) => selected.includes(id))
				.map(({ id }) => id)
				.forEach((id) => {
					if (isNumber(id)) employeeDeleteIds.push(id);
				});

			setEmployeeDeleteIds?.(employeeDeleteIds);
			setEmployees?.(newEmployees);
		} else {
			// remove employee inside counterpart
			if (checks) {
				const updatedChecks = checks?.map((check) => ({
					...check,
					checkCards: check.checkCards.map((card) => {
						const ids: number[] = [];

						card.employees
							.filter(({ id }) => selected.includes(id))
							.map(({ id }) => id)
							.forEach((id) => {
								if (isNumber(id)) ids.push(id);
							});

						const employeeDeleteIds = compact<number>(
							uniq([...(card?.employeeDeleteIds || []), ...ids]),
						);

						return {
							...card,
							employeeDeleteIds,
							employees: card.employees.filter(
								({ id }) => !selected.includes(id),
							),
						};
					}),
				}));
				if (updatedChecks) setChecks?.(updatedChecks);
			}
			if (cards) {
				// remove employee inside checks
				const updatedCards = cards?.map((card) => {
					const ids: number[] = [];

					card.employees
						.filter(({ id }) => selected.includes(id))
						.map(({ id }) => id)
						.forEach((id) => {
							if (isNumber(id)) ids.push(id);
						});

					const employeeDeleteIds = compact<number>(
						uniq([...(card?.employeeDeleteIds || []), ...ids]),
					);

					return {
						...card,
						employeeDeleteIds,
						employees: card.employees.filter(
							({ id }) => !selected.includes(id),
						),
					};
				});
				if (updatedCards) setCards?.(updatedCards);
			}
		}

		setSelected([]);
		setShowDeleteModal(false);
	}, [
		canAdd,
		cards,
		checks,
		employees,
		selected,
		setCards,
		setChecks,
		setEmployeeDeleteIds,
		setEmployees,
	]);

	const cancelHandler = useCallback(() => {
		setShowModal(false);
		setEditingItem(actualDefaultValue);
	}, [actualDefaultValue]);

	const saveHandler = useCallback(
		(employee: Modal.Value) => {
			const addCarIdToPhone = ({
				empl,
				existCardId,
			}: {
				empl: any;
				existCardId?: number | string;
			}): Employee.Model => {
				const phones = empl.phones?.length
					? empl.phones?.map((phone) => {
							const isNull = !phone.isCounterparty;

							const autoDiscoverCheckCardId =
								phone.isCounterparty && isNumber(existCardId)
									? existCardId
									: true;

							return {
								id: phone.id,
								value: phone.value,
								group: phone.group,
								isCounterparty: phone.isCounterparty,
								autoDiscoverCheckCardId: isNull
									? null
									: autoDiscoverCheckCardId,
							};
					  })
					: [];

				return { ...empl, phones };
			};

			if (canAdd) {
				if (employee.id) {
					const newEmployees = [...employees].map((empl) => {
						if (empl.id === employee.id) {
							return addCarIdToPhone({
								empl: employee,
								existCardId: cardId,
							});
						}
						return empl;
					});
					setEmployees?.(newEmployees);
				} else {
					setEmployees?.([
						...employees,
						addCarIdToPhone({
							empl: { ...employee, id: crypto.randomUUID() },
							existCardId: cardId,
						}),
					]);
				}
			} else {
				if (checks && employee.id) {
					const updatedChecks = checks.map((check) => ({
						...check,
						checkCards: check.checkCards.map((card) => ({
							...card,
							employees: card.employees.map((empl) =>
								empl.id === employee.id
									? addCarIdToPhone({
											empl: employee,
											existCardId: card.id,
									  })
									: addCarIdToPhone({
											empl,
											existCardId: card.id,
									  }),
							),
						})),
					}));
					setChecks?.(updatedChecks as Accounts.Check[]);
				}
				if (cards && employee.id) {
					const updatedCards = cards.map((card) => ({
						...card,
						employees: card.employees.map((empl) =>
							empl.id === employee.id
								? addCarIdToPhone({
										empl: employee,
										existCardId: card.id,
								  })
								: addCarIdToPhone({
										empl,
										existCardId: card.id,
								  }),
						),
					}));
					setCards?.(updatedCards as Cards.Value);
				}
			}

			setShowModal(false);
			setEditingItem(actualDefaultValue);
		},
		[
			actualDefaultValue,
			canAdd,
			cards,
			checks,
			employees,
			setCards,
			setChecks,
			setEmployees,
			cardId,
		],
	);

	const selectClientButton: ControlButtons.Button[] = useMemo(
		() => [
			{
				variation: "primary",
				icon: { id: "notepad-edit", size: 20 },
				onClick: selectClientHandler,
			},
		],
		[selectClientHandler],
	);

	const columnsFilter = {
		counterparty: !!isCounterparty,
		check: !!isCheck,
		card: !isCounterparty && !isCheck,
	};

	const valueData = useMemo(() => {
		try {
			if (active === null) return [];
			const payload = [...employees];
			if (active === false) {
				return payload.filter((item) => item.active === active);
			}

			if (active === true) {
				return payload.filter((item) => item.active === active);
			}

			return payload;
		} catch (error) {
			console.error(error);
			return [];
		}
	}, [active, employees]);

	return (
		<Root visible={visible} hasPaddings={false}>
			<Column
				style={{
					paddingTop: 10,
					height: "100%",
					width: "100%",
					borderTopLeftRadius: theme.borderRadius.m,
				}}
				sizes="auto 1fr"
				gaps="8px*"
			>
				<DefaultPageHeader
					canEdit={selected.length === 1}
					canDelete={!!selected.length}
					onAdd={addHandler}
					onDelete={preDeleteHandler}
					onEdit={editHeaderHandler}
					canAdd={!!canAdd}
					beforeAdditionalButtons={canAdd && selectClientButton}
					afterAdditionalButtons={[
						{
							disabled: false,
							icon: { id: "counterpart", size: 20 },
							onClick: () => {
								onActive("yes");
							},
							variation: isActive ? "primary" : "secondary",
							title:
								t([
									`${tPath}.table.showActive`,
									"Show active employees",
								]) || "",
						},
						{
							disabled: false,
							icon: { id: "counterpart", size: 20 },
							onClick: () => {
								onActive("no");
							},
							variation: isNotActive ? "primary" : "secondary",
							title:
								t([
									`${tPath}.table.showNoActive`,
									"Show inactive employees",
								]) || "",
						},
					]}
				/>
				<Content
					columnsName={columnsName}
					tableFrom={tableFrom}
					columnsFilter={columnsFilter}
					checks={checks}
					cards={cards}
					value={valueData}
					selected={selected}
					setSelected={setSelected}
					onEdit={editContentHandler}
					editorTable={editor}
					onChangeTable={onChange}
				/>
				{showClientSelectModal && (
					<ClientSelectModal
						editorTable={editorClients}
						onChangeTable={editorOnChange}
						clients={clients}
						selected={selectedClientIds}
						onClose={closeSelectClientModalHandler}
						onSubmit={onSubmitClientsModal}
					/>
				)}
				{showModal && (
					<Modal
						transactionTableFrom={tableFrom}
						value={editingItem}
						onCancel={cancelHandler}
						onSave={saveHandler}
						companyId={companyId}
						counterpartyId={counterpartyId}
						taxiServiceId={taxiServiceId}
						checks={checks}
					/>
				)}
				{showDeleteModal && (
					<DeleteModal
						label={
							selected.length === 1
								? t(`${tPath}.deleteModal.title`) || ""
								: t(`${tPath}.deleteModal.title2`) || ""
						}
						onCancel={cancelDelete}
						onConfirm={deleteHandler}
					/>
				)}
			</Column>
		</Root>
	);
};

declare namespace GeneralEmployees {
	interface Props {
		tableFrom: string;
		isCounterparty?: boolean;
		isCheck?: boolean;
		cardId?: number | string;
		employees: Value;
		setEmployees?: (employees: Value) => void;
		checks?: Accounts.Check[];
		setChecks?: (checks: Accounts.Check[]) => void;
		cards?: Cards.Value;
		setCards?: (cards: Cards.Value) => void;
		canAdd?: boolean;
		visible: boolean;
		columnsName?: Content.ColumnNames;
		companyId?: number;
		taxiServiceId?: number;
		counterpartyId?: number;
		/** add only the card, if need remove employees */
		setEmployeeDeleteIds?: (
			ids: Accounts.Check["employeeDeleteIds"],
		) => void;
	}

	type Value = (Employee.Model | Employee.New)[];
}

export default GeneralEmployees;
