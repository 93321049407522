/* eslint-disable import/no-unresolved */
/* eslint-disable no-shadow */
import React, { Dispatch, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import translationPath from "../../constants/translationPath";
import useObjectEditor from "../../../../../../../../hooks/useObjectEditor";
import EditModal from "../../../../../../../../components/EditModal";
import ClarificationModal from "../../../../../../../../components/DeleteModal";

import { SystemTabs } from "./constants/tabs";
import Content from "./components/Content";
import Root from "./components/Root";
import Header from "./components/Header";

const Modal: React.FC<Modal.Props> = ({
	value,
	onCancel,
	onSave,
	createdAt,
	headerTitle,

	showClarificationModal,
	setShowClarificationModal,
}) => {
	const { t } = useTranslation();

	const [tab, setTab] = useState<Header.TabType>(SystemTabs.MAIN);

	const [internalValue, setInternalValue] = useState(value);

	const handleSubmit = useCallback(() => {
		onSave(internalValue);
	}, [internalValue, onSave]);

	const valueEditor = useObjectEditor(internalValue, setInternalValue);

	// const active = valueEditor.useGetter("active");
	// const setActive = valueEditor.useSetter("active");

	const contentValues = valueEditor.usePicker([]);
	const contentOnChange = valueEditor.useAssigner();

	return (
		<EditModal onCancel={onCancel} onSave={handleSubmit}>
			<Root sizes="auto! 1fr">
				<Header
					title={headerTitle}
					createdAt={createdAt}
					tab={tab}
					onChangeTab={setTab}
				/>
				<Content
					tab={tab}
					value={contentValues}
					onChange={contentOnChange}
				/>
			</Root>
			{showClarificationModal && (
				<ClarificationModal
					label={t(`${translationPath}.clarificationModal`) || ""}
					onCancel={() => {
						setShowClarificationModal(false);
					}}
					onConfirm={() => {}}
				/>
			)}
		</EditModal>
	);
};

declare namespace Modal {
	interface Props {
		value: Value;
		headerTitle: string;
		createdAt: string;
		onCancel: () => void;
		onSave: (template: any, force?: boolean) => Promise<void>;

		showClarificationModal: boolean;
		setShowClarificationModal: Dispatch<boolean>;
	}

	type Value = any;
}

export default Modal;
