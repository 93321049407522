import { theme } from "uikit";
import styled from "styled-components";

import { Button } from "../../../../../../../../components/common";

export const ButtonFooter = styled(Button)`
	width: clamp(29px, 3.9vh, 32px);
	height: clamp(29px, 3.9vh, 32px);
`;

export const LeftButton = styled(Button)`
	width: clamp(29px, 3.9vh, 32px);
	height: clamp(29px, 3.9vh, 32px);
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
`;

export const RightButton = styled(Button)`
	width: 17px;
	height: clamp(29px, 3.9vh, 32px);
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
`;

export const CallPhonesSelect = styled.div`
	position: absolute;
	z-index: 10;
	top: 100%;
	width: max-content;

	background: white;

	border: 1px solid rgba(33, 51, 63, 0.1);
	border-radius: ${theme.borderRadius.m};
	margin-top: 3px;

	padding: 10px;
	box-shadow: 0px 3px 6px rgba(33, 51, 63, 0.15);

	.CallArrayModal__items {
		display: flex;
		flex-direction: column;

		.CallArrayModal__item {
			padding: 5px 10px;
			border-radius: ${theme.borderRadius.m};
			cursor: pointer;
			color: #647079;

			:hover {
				background: #ebebeb;
			}
		}
	}

	@media screen and (min-height: 572px) {
		top: 100%;
	}
`;

export const CallWrapper = styled.div`
	position: relative;
	display: flex;
	gap: 3px;

	button.primary,
	button.disabled {
		outline: none;
		border-radius: 5px 0 0 5px;
	}

	.call__icon_button {
		display: flex;
		justify-content: center;
		align-items: center;

		width: 14px;
		cursor: pointer;
		padding: 0;

		background: #ebebeb;

		border-radius: 0;
		border-radius: 0 5px 5px 0;
		border: 1px solid rgba(33, 51, 63, 0.1);
		outline: none;

		color: #647079;

		&[disabled] {
			color: #9ba3a866;
			background-color: white;
		}

		* {
			outline: none;
		}
	}
`;
