import React, { Dispatch, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column, RadioList2, Row, Stepper, theme } from "uikit";
import { isUndefined } from "lodash";

import useObjectEditor from "../../../../../../../../hooks/useObjectEditor";
import Divider from "../../../../../../../../components/Divider";

import Root from "./components/Root";

const Content: React.FC<Content.Props> = ({ value, onChange }) => {
	const { t } = useTranslation();
	const valueEditor = useObjectEditor(value, onChange);

	const amountValue = valueEditor.useGetter("amount");
	const setAmountValue = valueEditor.useSetter("amount");

	const amountOnChange = useCallback(
		(value: Stepper.Value) => {
			if (!isUndefined(value)) setAmountValue(value);
		},
		[setAmountValue],
	);

	const typeValue = valueEditor.useGetter("type");
	const typeOnChange = valueEditor.useSetter("type");

	const pointsAmountValue = valueEditor.useGetter("pointsAmount");
	const setPointsAmountValue = valueEditor.useSetter("pointsAmount");

	const pointsAmountOnChange = useCallback(
		(value: Stepper.Value) => {
			if (!isUndefined(value)) setPointsAmountValue(value);
		},
		[setPointsAmountValue],
	);

	const typeOptions = useMemo<RadioList2.Option<Content.Value["type"]>[]>(
		() => [
			{
				key: "all-points",
				value: "all-points",
				content:
					t(
						"pages.mainPage.pages.orders.copyOrderModal.content.str200",
					) ?? "",
			},
			{
				key: "without-points",
				value: "without-points",
				content:
					t(
						"pages.mainPage.pages.orders.copyOrderModal.content.str202",
					) ?? "",
			},
			{
				key: "first-points",
				value: "first-points",
				content: (
					<Row style={{ height: 18.56 }} align="center" gaps="12px*">
						<div>
							{t(
								"pages.mainPage.pages.orders.copyOrderModal.content.str100",
							) ?? ""}
						</div>
						<Stepper
							value={pointsAmountValue}
							min={1}
							disabled={typeValue !== "first-points"}
							onChange={pointsAmountOnChange}
						/>
						<div>
							{t(
								"pages.mainPage.pages.orders.copyOrderModal.content.str101",
							) ?? ""}
						</div>
					</Row>
				),
			},
		],
		[pointsAmountValue, typeValue, pointsAmountOnChange, t],
	);

	return (
		<Root gaps="16px*">
			<Row align="center" justify="space-between" gaps="12px*">
				<div>
					{t(
						"pages.mainPage.pages.orders.copyOrderModal.content.str102",
					) ?? ""}
				</div>
				<Row align="center" gaps="12px*">
					<Stepper
						value={amountValue}
						autoFocus
						min={1}
						onChange={amountOnChange}
					/>
					<div>
						{t(
							"pages.mainPage.pages.orders.copyOrderModal.content.str103",
						) ?? ""}
					</div>
				</Row>
			</Row>
			<Divider
				orientation="horizontal"
				thickness={1}
				size="max"
				color={theme.colors.color_border_basic}
			/>
			<Column gaps="16px*">
				<RadioList2
					value={typeValue}
					options={typeOptions}
					onChange={typeOnChange}
				>
					{(radios) => <Column gaps="16px*">{radios}</Column>}
				</RadioList2>
			</Column>
		</Root>
	);
};

declare namespace Content {
	interface Value {
		amount: number;
		type?: "all-points" | "without-points" | "first-points";
		pointsAmount: number;
	}

	interface Props {
		value: Value;

		onChange: Dispatch<Value>;
	}
}

export default Content;
