import React, { PropsWithChildren } from "react";
import Root from "./components/Root";
import Label from "./components/Label";

const Field: React.FC<Field.Props> = ({ area, label, children }) => (
	<Root align="end" gaps="6px*" area={area}>
		<Label>{label}: </Label>
		{children}
	</Root>
);

declare namespace Field {
	interface Props extends PropsWithChildren {
		area: string;

		label: string;
	}
}

export default Field;
