import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Order from "../../../../../../../services/Order";
import CellContentRoot from "../CellContentRoot";

const ParametersCellContent: React.FC<ParametersCellContent.Props> = ({
	item,
}) => {
	const { t } = useTranslation();

	const rateSettings = useMemo(
		() => item.additionalFields.rateSettings ?? {},
		[item.additionalFields.rateSettings],
	);

	const rateSettingTypes = useMemo(
		() => Object.keys(rateSettings),
		[rateSettings],
	);

	const content = useMemo(
		() =>
			rateSettingTypes
				.filter((rateSettingType) => rateSettings[rateSettingType])
				.map(
					(rateSettingType) =>
						t(`order_rate_setting.${rateSettingType}`) ?? "",
				)
				.join(", "),
		[rateSettingTypes, rateSettings, t],
	);

	return (
		<CellContentRoot align="center" maxedHeight maxedWidth>
			{content}
		</CellContentRoot>
	);
};

declare namespace ParametersCellContent {
	interface Props {
		item: Order.Model;
	}
}

export default ParametersCellContent;
