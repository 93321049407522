import React, { Dispatch, memo, useMemo } from "react";
import { CheckBox } from "uikit";
import { useTranslation } from "react-i18next";

import useObjectEditor from "../../../../../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import {
	PaymentAccount,
	PaymentAccountOrder,
} from "../../../../../../../../../../../../../../../../../../../../types/PaymentAccount";
import tPath from "../../../../../../../../constants/tPath";

import {
	StyledRow,
	StyledColumn,
} from "../../../../../../../../../../../../../../../../../../../../components/common";
import FieldsContainer from "../../../../../../../../../../../../../../../../../../../../components/FieldsContainer";
import LabeledField from "../../../../../../../../../../../../../../../../../../../../components/LabeledField";
import Transactions from "./components/Transactions";
import Balance from "./components/Balance";

const path = `${tPath}.modal.tabs.main.check`;

const defaultBalances = [
	{ type: "main", amount: 0 },
	{ type: "threshold", amount: 0 },
	{ type: "bonus", amount: 0 },
] as PaymentAccount[];

const balancesOrder: Record<PaymentAccountOrder, number> = {
	main: 1,
	bonus: 2,
	threshold: 3,
};

const Account: React.FC<Account.Props> = memo((props) => {
	const { t } = useTranslation();
	const {
		entityId,
		companyId,
		generalPaymentAccounts,
		generalPaymentTransactions,
		ignoreGeneralThresholdBalance,
		allowGeneralBonusBalance,
		value,
		onChange,
	} = props;

	const editor = useObjectEditor(value, onChange);

	const transactions = editor.useGetter("paymentTransactions");
	const setTransactions = editor.useSetter("paymentTransactions");

	const isGeneralMainBalance = editor.useGetter("useGeneralMainBalance");
	const setIsGeneralMainBalance = editor.useSetter("useGeneralMainBalance");

	const allowBonusBalance = editor.useGetter("allowBonusBalance");
	const setAllowBonusBalance = editor.useSetter("allowBonusBalance");

	const isGeneralBonusBalance = editor.useGetter("useGeneralBonusBalance");
	const setIsGeneralBonusBalance = editor.useSetter("useGeneralBonusBalance");

	const useGeneralThresholdBalance = editor.useGetter(
		"useGeneralThresholdBalance",
	);
	const setUseGeneralThresholdBalance = editor.useSetter(
		"useGeneralThresholdBalance",
	);

	const ignoreThresholdBalance = editor.useGetter("ignoreThresholdBalance");
	const setIgnoreThresholdBalance = editor.useSetter(
		"ignoreThresholdBalance",
	);

	const paymentAccounts = editor.useGetter("paymentAccounts");
	const setPaymentAccounts = editor.useSetter("paymentAccounts");

	const accounts = paymentAccounts.length
		? paymentAccounts.sort(
				(a, b) => balancesOrder[a?.type] - balancesOrder[b?.type],
		  )
		: defaultBalances;

	const generalMainBalance = useMemo(
		() => generalPaymentAccounts.find((account) => account.type === "main"),
		[generalPaymentAccounts],
	);

	const generalBonusBalance = useMemo(
		() =>
			generalPaymentAccounts.find((account) => account.type === "bonus"),
		[generalPaymentAccounts],
	);
	const generalThresholdBalance = useMemo(
		() =>
			generalPaymentAccounts.find(
				(account) => account.type === "threshold",
			),
		[generalPaymentAccounts],
	);

	const generalTransactionsMainBalance = useMemo(() => {
		if (!generalPaymentTransactions) return generalMainBalance?.amount || 0;
		return generalPaymentTransactions?.main?.reduce(
			(acc, item) => (acc += item.amount),
			0,
		);
	}, [generalMainBalance?.amount, generalPaymentTransactions]);

	const generalTransactionsBonusBalance = useMemo(() => {
		if (!generalPaymentTransactions)
			return generalBonusBalance?.amount || 0;
		return generalPaymentTransactions?.bonus?.reduce(
			(acc, item) => (acc += item.amount),
			0,
		);
	}, [generalBonusBalance?.amount, generalPaymentTransactions]);

	const generalTransactionsThresholdBalance = useMemo(() => {
		if (!generalPaymentTransactions)
			return generalThresholdBalance?.amount || 0;

		return generalPaymentTransactions?.threshold?.at(-1)?.amount || 0;
	}, [generalPaymentTransactions, generalThresholdBalance?.amount]);

	const disabledThresholdCheckBox = useMemo(() => {
		if (ignoreGeneralThresholdBalance) return true;
		if (ignoreThresholdBalance) return true;
		return false;
	}, [ignoreGeneralThresholdBalance, ignoreThresholdBalance]);

	const disabledBonusCheckBox = useMemo(() => {
		if (disabledThresholdCheckBox) return true;
		if (allowBonusBalance) return true;
		return !allowGeneralBonusBalance;
	}, [
		allowGeneralBonusBalance,
		disabledThresholdCheckBox,
		allowBonusBalance,
	]);

	return (
		<FieldsContainer label={t(`${path}.title`) || ""}>
			<StyledRow
				gap="10px"
				justify="space-between"
				flex={{ wrap: "wrap" }}
			>
				{transactions ? (
					<Transactions
						path={path}
						value={transactions}
						onChange={setTransactions}
						generalMainAmount={generalTransactionsMainBalance}
						generalBonusAmount={generalTransactionsBonusBalance}
						generalThresholdBalance={
							generalTransactionsThresholdBalance
						}
						isGeneralMainBalance={isGeneralMainBalance}
						isGeneralBonusBalance={isGeneralBonusBalance}
						ignoreThresholdBalance={ignoreThresholdBalance}
						useGeneralThresholdBalance={useGeneralThresholdBalance}
						ignoreGeneralThresholdBalance={
							ignoreGeneralThresholdBalance
						}
						allowGeneralBonusBalance={allowGeneralBonusBalance}
						allowBonusBalance={allowBonusBalance}
						setIsGeneralMainBalance={setIsGeneralMainBalance}
						setIsGeneralBonusBalance={setIsGeneralBonusBalance}
						setUseGeneralThresholdBalance={
							setUseGeneralThresholdBalance
						}
					/>
				) : (
					<StyledRow gap="10px" justify="space-between" w="70%">
						{accounts.map((account, i) => {
							if (i > 2) return <></>;
							return (
								<StyledRow key={i}>
									<StyledColumn gap="10px">
										<LabeledField
											label={
												t(
													`${path}.balances.${account?.type}`,
												) || ""
											}
										>
											<Balance
												disabled={!entityId}
												companyId={companyId}
												label={
													t(
														"pages.mainPage.pages.customers.tabs.counterparties.modal.content.accounts.modal.content.tabs.main.account.str0",
													) ?? ""
												}
												value={account}
												generalMainAmount={
													generalMainBalance?.amount
												}
												generalBonusAmount={
													generalBonusBalance?.amount
												}
												generalThresholdBalance={
													generalThresholdBalance?.amount
												}
												isGeneralMainBalance={
													isGeneralMainBalance
												}
												isGeneralBonusBalance={
													isGeneralBonusBalance
												}
												useGeneralThresholdBalance={
													useGeneralThresholdBalance
												}
												ignoreThresholdBalance={
													ignoreThresholdBalance
												}
												allowBonusBalance={
													allowBonusBalance
												}
												onSubmit={setPaymentAccounts}
												paymentAccount={account}
												paymentAccounts={
													paymentAccounts
												}
											/>
										</LabeledField>

										{account?.type === "main" && (
											<CheckBox
												label={
													t(
														"pages.mainPage.pages.customers.tabs.counterparties.modal.content.accounts.modal.content.tabs.main.account.str1",
													) ?? ""
												}
												value={isGeneralMainBalance}
												onChange={
													setIsGeneralMainBalance
												}
											/>
										)}
										{account?.type === "bonus" && (
											<CheckBox
												disabled={disabledBonusCheckBox}
												label={
													t(
														"pages.mainPage.pages.customers.tabs.counterparties.modal.content.accounts.modal.content.tabs.main.account.str2",
													) ?? ""
												}
												value={isGeneralBonusBalance}
												onChange={
													setIsGeneralBonusBalance
												}
											/>
										)}
										{account?.type === "threshold" && (
											<CheckBox
												disabled={
													disabledThresholdCheckBox
												}
												label={
													t(
														"pages.mainPage.pages.customers.tabs.counterparties.modal.content.accounts.modal.content.tabs.main.account.str3",
													) ?? ""
												}
												value={
													useGeneralThresholdBalance
												}
												onChange={
													setUseGeneralThresholdBalance
												}
											/>
										)}
									</StyledColumn>
								</StyledRow>
							);
						})}
					</StyledRow>
				)}

				<StyledColumn gap="5px 0" p="7px 0" w="300px">
					<CheckBox
						disabled={
							useGeneralThresholdBalance &&
							!ignoreGeneralThresholdBalance
						}
						value={ignoreThresholdBalance}
						label={
							t(
								"pages.mainPage.pages.customers.tabs.counterparties.modal.content.accounts.modal.content.tabs.main.account.str4",
							) ?? ""
						}
						onChange={setIgnoreThresholdBalance}
					/>
					<CheckBox
						disabled={
							ignoreThresholdBalance || isGeneralBonusBalance
						}
						label={
							t(
								"pages.mainPage.pages.customers.tabs.counterparties.modal.content.accounts.modal.content.tabs.main.account.str5",
							) ?? ""
						}
						value={allowBonusBalance}
						onChange={setAllowBonusBalance}
					/>
				</StyledColumn>
			</StyledRow>
		</FieldsContainer>
	);
});

declare namespace Account {
	interface Props {
		entityId: number | undefined;
		companyId: number | undefined;
		generalPaymentAccounts: PaymentAccount[];
		generalPaymentTransactions?: Transactions.Value;
		allowGeneralBonusBalance: boolean;
		ignoreGeneralThresholdBalance: boolean;
		value: Value;
		onChange: Dispatch<Value>;
	}

	interface Value {
		paymentAccounts: PaymentAccount[];
		useGeneralMainBalance: boolean;
		useGeneralBonusBalance: boolean;
		useGeneralThresholdBalance: boolean;
		ignoreThresholdBalance: boolean;
		allowBonusBalance: boolean;
		paymentTransactions?: Transactions.Value;
	}
}

export default Account;
