import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";

import Executor from "../../../../../../../../../../services/Executor";
import { ordersAction as orders } from "../../../../../../../../../../redux/reducers/Orders";
import { useTypedSelector } from "../../../../../../../../../../redux/store";
import useModelSubscribe from "../../../../../../../../../../hooks/useModelSubscribe2";

const ExecutorsSubscribe: React.FC = () => {
	const dispatch = useDispatch();

	// const { executors } = useTypedSelector((state) => state.orderPageFilters);
	const { criteria } = useTypedSelector(
		(state) => state.orders.executors.filter,
	);

	const onlineStatus = useMemo(() => {
		const online = criteria.online.includes("online");
		const offline = criteria.online.includes("offline");

		if (online && offline) return undefined;
		if (online) return true;
		if (offline) return false;
		return undefined;
	}, [criteria.online]);

	const workingStatus = useMemo(() => {
		const working = criteria.workingStatus.includes("working");
		const notWorking = criteria.workingStatus.includes("notWorking");

		if (working && notWorking) return undefined;
		if (working) return true;
		if (notWorking) return false;
		return undefined;
	}, [criteria.workingStatus]);

	const executorModelSubscribeOptions = useMemo(() => {
		const result: Executor.SubscribeOptions = {
			query: criteria.query,
			isOnline: onlineStatus,
			workingStatus,
			statuses: criteria.statuses,
			taxiServiceIds: criteria.taxiServiceIds,
		};

		if (criteria.order?.column && criteria.order?.type) {
			result.order = {
				[criteria.order?.column]: criteria.order?.type.toUpperCase(),
			};
		}

		return result;
	}, [
		criteria.order?.column,
		criteria.order?.type,
		criteria.query,
		criteria.statuses,
		criteria.taxiServiceIds,
		onlineStatus,
		workingStatus,
	]);

	const executorsData = useModelSubscribe(
		executorModelSubscribeOptions,
		Executor,
	);

	const filtredExecutorModelData = useMemo(() => {
		if (criteria.isYourOrderStatus) {
			return executorsData.models?.filter(
				(executor) =>
					!executor.executorToOrders.find(
						(order) =>
							order.source === "executor" && !order.closedAt,
					),
			);
		}
		return executorsData.models;
	}, [criteria.isYourOrderStatus, executorsData.models]);

	useEffect(() => {
		dispatch(
			orders.executors.data.setExecutorsData(filtredExecutorModelData),
		);
	}, [dispatch, executorsData.models, filtredExecutorModelData]);

	return null;
};

export default ExecutorsSubscribe;
