import React, { useMemo } from "react";
import { isNumber } from "lodash";

import ExecutorReport from "../../../../../../../../../../services/ExecutorReport";
import formatNumber from "../../../../../../../../../../utils/formatNumber";
import { PaymentAccountType } from "../../../../../../../../../../types/PaymentAccount";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";

const PaymentFromExecutorToAccountPeriodCellContent: React.FC<
	PaymentFromExecutorToAccountPeriodCellContent.Props
> = ({ item }) => {
	const paymentAccount = useMemo(() => {
		if (!item?.paymentAccounts) return null;
		return item.paymentAccounts.find(
			(a) => a.type === PaymentAccountType.MAIN,
		);
	}, [item.paymentAccounts]);

	const content = useMemo(() => {
		if (!isNumber(paymentAccount?.amountAtEndOfPeriod)) {
			return formatNumber(0);
		}
		return formatNumber(paymentAccount?.amountAtEndOfPeriod || 0);
	}, [paymentAccount]);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace PaymentFromExecutorToAccountPeriodCellContent {
	interface Props {
		item: ExecutorReport.Model;
	}
}

export default PaymentFromExecutorToAccountPeriodCellContent;
