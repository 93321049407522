/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { State } from "./interface";
import { Archive, ReduxReducer } from "../../interface";

const initialState: State = {
	ordersGetAll: null,
	orderGetById: null,
	orderUpdate: null,
	ordersGetHistory: null,
};

const setErrorOrdersGetHistory: ReduxReducer<
	State,
	State["ordersGetHistory"]
> = (state, { payload }) => {
	state.ordersGetHistory = payload;
};

const setErrorOrdersGetAll: ReduxReducer<State, State["ordersGetAll"]> = (
	state,
	{ payload },
) => {
	state.ordersGetAll = payload;
};

const setErrorOrderGetById: ReduxReducer<State, State["orderGetById"]> = (
	state,
	{ payload },
) => {
	state.orderGetById = payload;
};
const setErrorOrderUpdate: ReduxReducer<State, State["orderUpdate"]> = (
	state,
	{ payload },
) => {
	state.orderUpdate = payload;
};

const resetError: ReduxReducer<State, any> = (state) => {
	Object.keys(initialState).forEach((key) => {
		state[key] = initialState[key];
	});
};

const archiveOrdersErrors = createSlice({
	name: Archive.ConstantOrderSlice.ORDERS_ERRORS,
	initialState,
	reducers: {
		resetError,
		setErrorOrdersGetAll,
		setErrorOrderGetById,
		setErrorOrderUpdate,
		setErrorOrdersGetHistory,
	},
});

export const archiveOrdersErrorsAction = archiveOrdersErrors.actions;
export const archiveOrdersErrorsReducer = archiveOrdersErrors.reducer;
export type archiveOrdersErrorsState = State;

export default archiveOrdersErrors;
