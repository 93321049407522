/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import { set } from "lodash";

import { getPRPC } from "../../hooks/usePRPC";
import ModelService from "../../redux/services/ModelService";
import createRPCQuery from "../../utils/createRPCQuery.util";
import permissions from "../../redux/reducers/permissions";

class Permission extends ModelService<
	Permission.SubscribeOptions,
	Permission.Model,
	"permissions"
>(permissions, (state) => state.permissions) {
	static fromResponse(data: any): Permission.Model {
		return {
			name: data.name,
			value: data.value,
		};
	}

	static toTree(permissions: string[]): Permission.Tree {
		return permissions.reduce((accumulator, availablePermission) => {
			set(accumulator, availablePermission, true);

			return accumulator;
		}, {} as Permission.Tree);
	}

	static Global = {
		async index() {
			const prpc = getPRPC();

			if (!prpc) return null;

			const result = await createRPCQuery(() =>
				prpc.theirsModel.role.getAllPermissions(),
			);

			return {
				cache: result.map(Permission.fromResponse),
				offset: 0,
				limit: result.length,
				total: result.length,
				deprecated: false,
			};
		},
	};
}

declare namespace Permission {
	type Tree = { [Key in string]: Tree | true };

	interface Model {
		name: string;
		value: string;
	}

	interface SubscribeOptions {}
}

export default Permission;
