/* eslint-disable prettier/prettier */
/* eslint-disable no-case-declarations */
import React, { Dispatch, memo, useCallback, useMemo, useState } from "react";
import { CheckBox, ToggleButton } from "uikit";
import { useTranslation } from "react-i18next";
import { RowDataType } from "rsuite-table";

import AutoTariff from "../../../../../../../../services/AutoTariff";
import Sector from "../../../../../../../../services/Sector";
import CarClass from "../../../../../../../../services/CarClass";
import LightTable, {
	useTableOptions,
	UseTableOptions,
} from "../../../../../../../../components/LightTable";
import TableSettings from "../../../../../../../../components/TableSettings";
import DisabledCheckbox from "../../../../../../../../components/DisabledCheckbox";
import {
	StyledGrid,
	StyledGridItem,
	StyledRow,
	CompaniesByIds,
	TaxiServicesByIds,
	TranslationText,
} from "../../../../../../../../components/common";
import RuleOutput from "../Modal/components/Content/tabs/Main/components/Rules/components/Content/components/RuleOutput";
import tPath from "../../constants/tPath";

import { Columns, columns, defaultColumnsId } from "./constants";

const Content: React.FC<Content.Props> = memo(
	({
		value,
		carClasses,
		sectors,
		selected,
		setSelected,
		onEdit,
		onChangeTable,
		editorTable,
	}) => {
		const { t } = useTranslation();

		const {
			sort,
			onSort,
			onScroll,
			tableRef,
			onKeyUp,
			onKeyDown,
			ctrlPressed,
			lang,
		} = useTableOptions({
			itemLimit: 2,
			heightRow: 44,
			value: editorTable,
			setValue: onChangeTable,
		});

		const tColumns = useMemo(
			() =>
				columns.map((c) => ({
					...c,
					label: t(`${tPath}.columns.${c.id}`),
				})),
			[t],
		);
		const [columnsId, setColumnsId] = useState(defaultColumnsId);

		const updateSelected = useCallback(
			(item: RowDataType<AutoTariff.Model>, force = false) => {
				const model = item as AutoTariff.Model;
				if (ctrlPressed || force) {
					if (selected.includes(model.id)) {
						setSelected(selected.filter((id) => model.id !== id));
					} else {
						setSelected([...selected, model.id]);
					}
				} else {
					selected.includes(model.id)
						? setSelected([])
						: setSelected([model.id]);
				}
			},
			[ctrlPressed, setSelected, selected],
		);

		const handleOnRowClick = useCallback(
			(item: RowDataType<AutoTariff.Model>) => {
				const model = item as AutoTariff.Model;
				if (model) {
					if (ctrlPressed) updateSelected(model);
					else {
						const exist = selected.find(
							(item) => item === model.id,
						);
						if (exist && selected.length <= 1) setSelected([]);
						else setSelected([model.id]);
					}
				}
			},
			[ctrlPressed, updateSelected, selected, setSelected],
		);

		const renderColumns = useMemo(
			() =>
				columnsId.map((columnId) => {
					const column = tColumns.find((col) => col.id === columnId);
					if (!column) return null;

					return (
						<LightTable.Column
							key={columnId}
							verticalAlign="middle"
							width={column.width}
							resizable
							sortable
						>
							<LightTable.HeaderCell>
								<TranslationText
									text={[column.id, column.label]}
								/>
							</LightTable.HeaderCell>

							<LightTable.Cell
								style={{ padding: "0 20px" }}
								verticalAlign="middle"
								dataKey={column.id}
								fullText
							>
								{(tariff) => {
									const model = tariff as AutoTariff.Model;
									switch (columnId) {
										case Columns.ACTIVE:
											return (
												<div
													onClick={(event) =>
														event.stopPropagation()
													}
													style={{
														maxWidth: "max-content",
													}}
												>
													<ToggleButton.ToggleButton
														value={tariff?.active}
														onChange={(active) => {
															AutoTariff.update({
																id: tariff.id,
																active,
															});
														}}
													/>
												</div>
											);

										case Columns.NAME:
											return tariff.name;
										case Columns.RULES:
											const rules = tariff.rules?.map(
												(rule, i) => (
													<RuleOutput
														key={i}
														autoClasses={carClasses}
														sectorModels={sectors}
														rule={rule}
													/>
												),
											);
											return (
												<StyledRow
													gap="5px"
													w="100%"
													h="100%"
													alignItems="center"
												>
													{rules}
												</StyledRow>
											);

										case Columns.DISABLE_OTHERS:
											return (
												<DisabledCheckbox
													value={tariff.disableOthers}
												/>
											);

										case Columns.COMPANIES:
											return (
												<CompaniesByIds
													taxiServiceIds={
														model.taxiServiceIds
													}
													lang={lang}
												/>
											);

										case Columns.BRANCHES:
											return (
												<TaxiServicesByIds
													taxiServiceIds={
														model.taxiServiceIds
													}
													lang={lang}
												/>
											);

										default:
											return null;
									}
								}}
							</LightTable.Cell>
						</LightTable.Column>
					);
				}),
			[carClasses, columnsId, lang, sectors, tColumns],
		);

		const getRowClassName = useCallback(
			(rowData) =>
				rowData && selected.includes(rowData.id) ? "selected" : "",
			[selected],
		);

		const areas = useMemo(() => `'table' 'settings' `, []);

		return (
			<StyledGrid
				areas={areas}
				rows="1fr 32px"
				w="100%"
				h="100%"
				br="5px"
				over="hidden"
			>
				<StyledGridItem
					area="table"
					w="100%"
					h="100%"
					tabIndex={-1}
					onKeyDown={onKeyDown}
					onKeyUp={onKeyUp}
				>
					<LightTable
						ref={tableRef}
						data={value}
						virtualized
						fillHeight
						rowHeight={44}
						headerHeight={44}
						shouldUpdateScroll={false}
						rowClassName={getRowClassName}
						onRowClick={handleOnRowClick}
						onRowDoubleClick={onEdit}
						sortColumn={sort?.dataKey}
						sortType={sort?.sortType}
						onScroll={onScroll}
						onSortColumn={onSort}
					>
						<LightTable.Column verticalAlign="middle" width={50}>
							<LightTable.HeaderCell
								style={{ padding: "0 25px" }}
							>
								<CheckBox
									value={
										selected.length === value.length &&
										value.length !== 0
									}
									onChange={(isAllSelected) => {
										if (isAllSelected) {
											setSelected(
												value.map(({ id }) => id),
											);
										} else {
											setSelected([]);
										}
									}}
								/>
							</LightTable.HeaderCell>
							<LightTable.Cell
								verticalAlign="middle"
								style={{ padding: "0 25px" }}
							>
								{(template) => (
									<CheckBox
										value={selected.includes(template.id)}
										onChange={() => {
											updateSelected(template, true);
										}}
									/>
								)}
							</LightTable.Cell>
						</LightTable.Column>
						{renderColumns}
					</LightTable>
				</StyledGridItem>
				<StyledGridItem area="settings" w="100%" h="100%" zi="1">
					<TableSettings
						value={columnsId}
						defaultValue={defaultColumnsId}
						columns={tColumns}
						onChange={setColumnsId}
					/>
				</StyledGridItem>
			</StyledGrid>
		);
	},
);

declare namespace Content {
	interface Props {
		value: AutoTariff.Model[] | [];
		carClasses: CarClass.Model[];
		sectors?: Sector.Model[];
		selected: number[];
		setSelected: Dispatch<number[]>;
		onEdit?: Dispatch<AutoTariff.Modified>;
		editorTable: UseTableOptions["editor"];
		onChangeTable: UseTableOptions["onChange"];
	}

	type ColumnType = {
		id: string;
		label: string;
		width?: number;
	};
}

export default Content;
