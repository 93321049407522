/* eslint-disable no-shadow */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Column, DateAndTimePicker, Icon, Row, TextBox } from "uikit";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { isString, noop } from "lodash";

import {
	useTypedDispatch,
	useTypedSelector,
} from "../../../../../../../../../../../../redux/store";
import getCompanies from "../../../../../../../../../../../../redux/services/Company/getCompanies";
import useDebouncedValue from "../../../../../../../../../../../../hooks/useDebouncedValue";
import { revertOrder } from "../../../../../../../../../../../../redux/services/Order/revertOrder";
import { DateRange } from "../../../../../../../../../../../../types/DataRange";
import useDatePickerLocale from "../../../../../../../../../../../../hooks/useDatePickerLocale";
import Order from "../../../../../../../../../../../../services/Order";
import ArchiveFilter from "../../../../../../../../../../../../types/ArchiveFilter";
import TabRoot from "../TabRoot";
import MountableSubscription from "../../../../../../../../../../../../components/MountableSubscription";
import {
	useSort,
	useVisibleColumns,
	useWidths,
} from "../../../../../../../../../../../../hooks/useTableSettings";
import DefaultPageHeader from "../../../../../../../../../../../../components/DefaultPageHeader";
import ControlButtons from "../../../../../../../../../../../../components/DefaultPageHeader/components/ControlButtons";
import DefaultPageHeaderButton from "../../../../../../../../../../../../components/DefaultPageHeaderButton";
import OrderTable from "../../../../../../../../../../../../components/OrderPageWidgets/OrderTable/components/ModelTable";
import TableWidths from "../../../../../../../../../../../../types/TableWidths";
import { ColumnId } from "../../../../../../../../../../../../constants/tables/types";
import tables from "../../../../../../../../../../../../constants/tables";

declare namespace OrderTab {
	interface Value {
		id?: number;
	}

	interface Props {
		visible: boolean;

		value: Value;
	}
}

const OrderTab: React.FC<OrderTab.Props> = ({
	visible,

	value: { id },
}) => {
	const { t } = useTranslation();
	const dispatch = useTypedDispatch();
	const locale = useDatePickerLocale();
	const login = useTypedSelector((state) => state.account.user?.login);
	const lang = useTypedSelector((state) => state.session.language);
	const [activeOrderId, setActiveOrderId] = useState<number | null>(null);

	const [filter, setFilter] = useState<ArchiveFilter>({
		searchQuery: "",

		dateFrom: moment().startOf("day").toDate(),
		dateTo: moment().endOf("day").toDate(),

		companyFilter: ["all"],
		taxiServiceFilter: ["all"],
	});

	const { columnIds, setColumnIds } = useVisibleColumns(
		"accounts.executors.editModal.orders",
		"order",
	);
	const { sortMethod, setSortMethod } = useSort(
		"accounts.executors.editModal.orders",
		"order",
	);
	const { widths, setWidths } = useWidths(
		"accounts.executors.editModal.orders",
		"order",
	);

	useEffect(() => {
		dispatch(getCompanies());
	}, [dispatch]);

	const [internalDateRange, setInternalDateRange] = useState<DateRange>({
		from: moment().startOf("day").toDate(),
		to: moment().endOf("day").toDate(),
	});

	const filterDateRange = useMemo<DateRange>(
		() => ({
			from: filter.dateFrom,
			to: filter.dateTo,
		}),
		[filter.dateFrom, filter.dateTo],
	);

	const dateRangeHasChanges = useMemo(() => {
		if (filterDateRange.from.getTime() !== internalDateRange.from.getTime())
			return true;

		if (filterDateRange.to.getTime() !== internalDateRange.to.getTime())
			return true;

		return false;
	}, [
		filterDateRange.from,
		filterDateRange.to,
		internalDateRange.from,
		internalDateRange.to,
	]);

	const applyDateRange = useCallback(() => {
		setFilter((prev) => ({
			...prev,
			dateFrom: internalDateRange.from,
			dateTo: internalDateRange.to,
		}));
	}, [internalDateRange]);

	const debouncedSearchQuery = useDebouncedValue(filter.searchQuery, 500);

	const order = useMemo(
		() =>
			isString(sortMethod.column) && isString(sortMethod.type)
				? {
						[sortMethod.column]:
							sortMethod.type === "asc" ? "ASC" : "DESC",
				  }
				: undefined,
		[sortMethod],
	);

	const subscribeOptions = useMemo<Order.SubscribeOptions>(
		() => ({
			// offset: offset === 0 ? undefined : offset,
			// limit,

			order,
			query: debouncedSearchQuery,
			dateRange: filterDateRange,

			tab: "overall",
			executorIds: id == null ? undefined : [id],
		}),
		[order, debouncedSearchQuery, filterDateRange, id],
	);

	const [orders, setOrders] = useState<Order.Model[]>([]);

	const unarchiveActiveOrder = useCallback(() => {
		if (activeOrderId == null || login == null) return;

		dispatch(
			revertOrder({
				id: activeOrderId,
				executorLogin: login,
			}),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeOrderId, login]);

	const pageHeaderButtons = useMemo<ControlButtons.Button[]>(
		() => [
			{
				variation: "secondary",
				icon: { id: "pencil", size: 20 },
				disabled: true,
				onClick: noop,
			},
			{
				variation: "secondary",
				icon: { id: "return-back", size: 20 },
				disabled: true,
				onClick: unarchiveActiveOrder,
			},
			{
				variation: "secondary",
				icon: { id: "export-page", size: 20 },
				disabled: true,
				onClick: noop,
			},
		],
		[unarchiveActiveOrder],
	);

	const filters = useMemo(
		() => (
			<Row
				gaps="25px*"
				justify="end"
				align="center"
				style={{ flexGrow: 1 }}
			>
				<Row maxedWidth gaps="5px*" justify="end" align="center">
					{t(`from`, { context: "capitalized" })}
					<DateAndTimePicker
						value={internalDateRange.from}
						onChange={(newValue) => {
							if (newValue)
								setInternalDateRange((prev) => ({
									...prev,
									from: newValue,
								}));
						}}
						locale={locale}
					/>

					{t(`to`)}
					<DateAndTimePicker
						value={internalDateRange.to}
						onChange={(newValue) => {
							if (newValue)
								setInternalDateRange((prev) => ({
									...prev,
									to: newValue,
								}));
						}}
						locale={locale}
					/>

					<DefaultPageHeaderButton
						variant="primary"
						disabled={!dateRangeHasChanges}
						icon={<Icon id="search" size={20} />}
						onClick={applyDateRange}
					/>
				</Row>

				<Row gaps="5px*" align="center" sizes="240px! auto*">
					<TextBox.TextBox
						placeholder={`${t("search") ?? "Search"}...`}
						value={filter.searchQuery}
						onChange={(searchQuery) => {
							setFilter((prev) => ({
								...prev,
								searchQuery,
							}));
						}}
					/>

					<DefaultPageHeaderButton
						variant="secondary"
						icon={<Icon id="filters" size={20} />}
					/>
				</Row>
			</Row>
		),
		[
			applyDateRange,
			dateRangeHasChanges,
			filter.searchQuery,
			internalDateRange.from,
			internalDateRange.to,
			locale,
			t,
		],
	);

	const mountableSubscription = useMemo(() => {
		if (id == null) return null;
		// if (limit === 0) return null;

		return (
			<MountableSubscription
				subscribeOptions={subscribeOptions}
				Service={Order}
				onModelsChange={setOrders}
			/>
		);
	}, [id, subscribeOptions]);

	const modelTableSort = useMemo(() => {
		if (sortMethod) {
			return {
				column: sortMethod.column,
				type: sortMethod.type,
			};
		}

		return undefined;
	}, [sortMethod]);

	const columns = useMemo<OrderTable.Column[]>(
		() =>
			tables.orderArchive?.columns?.map((column) => ({
				id: column.id as OrderTable.ColumnId,
				width: widths[column?.id],
				visible: columnIds.includes(column.id as OrderTable.ColumnId),
			})),
		[columnIds, widths],
	);

	const orderTableOnChangeSelected = useCallback(
		(id: number) => setActiveOrderId(id),
		[setActiveOrderId],
	);

	const orderTableOnChangeSort = useCallback(
		(sort: OrderTable.Sort) => {
			setSortMethod(sort);
		},
		[setSortMethod],
	);

	const orderTableOnChangeColumns = useCallback(
		(columns: OrderTable.Column[]) => {
			setColumnIds(
				columns
					.filter((column) => column.visible)
					.map((column) => column.id),
			);

			setWidths(
				columns.reduce((accumulator, column) => {
					accumulator[column.id] = column.width;

					return accumulator;
				}, {} as TableWidths<ColumnId<"orderArchive">>),
			);
		},
		[setColumnIds, setWidths],
	);

	return (
		<TabRoot hasPaddings paddings="16px 0 0 0" visible={visible}>
			<Column gaps="16px" maxedHeight justify="stretch" align="center">
				{mountableSubscription}

				<DefaultPageHeader
					buttons={pageHeaderButtons}
					filters={filters}
				/>

				<OrderTable
					selected={activeOrderId ?? undefined}
					sort={modelTableSort}
					columns={columns}
					data={orders}
					language={lang}
					onChangeSelected={orderTableOnChangeSelected}
					onChangeSort={orderTableOnChangeSort}
					onChangeColumns={orderTableOnChangeColumns}
					onEdit={noop}
					onLoadMore={noop}
				/>
			</Column>
		</TabRoot>
	);
};

export default OrderTab;
