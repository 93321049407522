import styled from "styled-components";

const List = styled.div<List.Props>`
	${({ visible }) => (visible ? "" : "display: none;")}
`;

declare namespace List {
	interface Props {
		visible: boolean;
	}
}

export default List;
