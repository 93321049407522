import React, { Dispatch, memo } from "react";
import { useTranslation } from "react-i18next";

import SearchBar from "../../../../../../../../../../../../../../../../../../components/SearchBar";
import useObjectEditor from "../../../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import Root from "./components/Root";

const Header = memo<Header.Props>(({ value, onChange }) => {
	const valueEditor = useObjectEditor(value, onChange);

	const searchBarValue = valueEditor.useGetter("search");
	const searchBarOnChange = valueEditor.useSetter("search");

	const { t } = useTranslation();
	return (
		<Root gaps="16px">
			<SearchBar
				value={searchBarValue}
				placeholder={`${
					t(
						"pages.mainPage.pages.accounts.tabs.roles.editModal.content.permissionsTab.select.selector.header.str200",
					) ?? ""
				}...`}
				onChange={searchBarOnChange}
			/>
		</Root>
	);
});

declare namespace Header {
	interface Value {
		search: string;
	}

	interface Props {
		value: Value;

		onChange: Dispatch<Value>;
	}
}

export default Header;
