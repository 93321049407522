import React, { Dispatch, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Icon, Row, Tabs } from "uikit";
import { useDispatch } from "react-redux";

import { useKeyBindSettings } from "../../../../../../../../hooks/useKeyBindSettings";
import { useTypedSelector } from "../../../../../../../../redux/store";
import {
	ordersAction as orders,
	Orders,
} from "../../../../../../../../redux/reducers/Orders";
import orderPage from "../../../../../../../../redux/reducers/OrdersPage";
import { OrderType } from "../../../../../../../../redux/constants/OrdersPage";
import Divider from "../../../../../../../../components/Divider";

import useTabOptions from "./hooks/useTabOptions";
import FilterOrdersModal from "./components/FilterOrdersModal";
import FilterOrdersByExecutorModal from "./components/FilterOrdersByExecutorModal";
import FilterOrdersByCarModal from "./components/FilterOrdersByCarModal";
import Root from "./components/Root";
import Button from "./components/Button";

const mainOrderTypes = [
	"live",
	"preliminary",
	"executable",
	"all",
] as OrderType[];

const FirstRow: React.FC<FirstRow.Props> = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const ordersType = useTypedSelector(
		(state) => state.ordersPageReducer.ordersType,
	);

	const filters = useTypedSelector(
		(state) =>
			state.ordersPageReducer.filters[state.ordersPageReducer.ordersType],
	);

	const tabOptions = useTabOptions();

	const tabsOnChangeSelectedOption = useCallback(
		(option: Tabs.Option<any>) => {
			dispatch(orderPage.actions.setOrdersType(option.value));
			dispatch(orders.tabs.filter.setOrdersType(option.value));
		},
		[dispatch],
	);

	const switchToTab = useCallback(
		(tabIndex: number) => (event: KeyboardEvent) => {
			tabsOnChangeSelectedOption(tabOptions[tabIndex]);

			event.stopPropagation();
			event.preventDefault();
		},
		[tabOptions, tabsOnChangeSelectedOption],
	);

	useKeyBindSettings("liveTab", switchToTab(0));
	useKeyBindSettings("preliminaryTab", switchToTab(1));
	useKeyBindSettings("executingTab", switchToTab(2));
	useKeyBindSettings("allTab", switchToTab(3));
	useKeyBindSettings("closedTab", switchToTab(4));

	const showOnlyMyOrdersOnClick = useCallback(() => {
		dispatch(
			orderPage.actions.setFilters({
				type: ordersType,
				data: {
					...filters,
					showOnlyMyOrders: !filters.showOnlyMyOrders,
				},
			}),
		);
	}, [dispatch, filters, ordersType]);

	const showOrdersWithoutExecutorsOnClick = useCallback(() => {
		dispatch(
			orderPage.actions.setFilters({
				type: ordersType,
				data: {
					...filters,
					showOrdersWithoutExecutors:
						!filters.showOrdersWithoutExecutors,
				},
			}),
		);
	}, [dispatch, filters, ordersType]);

	const [isFilterOrdersByCarModalOpen, setIsFilterOrdersByCarModalOpen] =
		useState(false);

	const openFilterOrdersByCarModal = useCallback(() => {
		setIsFilterOrdersByCarModalOpen(true);
	}, []);

	const closeFilterOrdersByCarModal = useCallback(() => {
		setIsFilterOrdersByCarModalOpen(false);
	}, []);

	const filterOrdersByCarModalOnReset = useCallback(() => {
		closeFilterOrdersByCarModal();

		const type = ordersType !== "closed" ? mainOrderTypes : "closed";

		dispatch(
			orderPage.actions.setFilters({
				type,
				data: {
					...filters,
					byCar: undefined,
				},
			}),
		);
	}, [closeFilterOrdersByCarModal, dispatch, filters, ordersType]);

	const filterOrdersByCarModalOnSubmit = useCallback(
		(modalValue: FilterOrdersByCarModal.Value) => {
			closeFilterOrdersByCarModal();

			const type = ordersType !== "closed" ? mainOrderTypes : "closed";

			dispatch(
				orderPage.actions.setFilters({
					type,
					data: {
						...filters,
						byCar: modalValue.number ? modalValue : undefined,
					},
				}),
			);
		},
		[closeFilterOrdersByCarModal, dispatch, filters, ordersType],
	);

	const [
		isFilterOrdersByExecutorModalOpen,
		setIsFilterOrdersByExecutorModalOpen,
	] = useState(false);

	const openFilterOrdersByExecutorModal = useCallback(() => {
		setIsFilterOrdersByExecutorModalOpen(true);
	}, []);

	const closeFilterOrdersByExecutorModal = useCallback(() => {
		setIsFilterOrdersByExecutorModalOpen(false);
	}, []);

	const filterOrdersByExecutorModalOnReset = useCallback(() => {
		closeFilterOrdersByExecutorModal();

		const type = ordersType !== "closed" ? mainOrderTypes : "closed";

		dispatch(
			orderPage.actions.setFilters({
				type,
				data: {
					...filters,
					byExecutor: undefined,
				},
			}),
		);
	}, [closeFilterOrdersByExecutorModal, dispatch, filters, ordersType]);

	const filterOrdersByExecutorModalOnSubmit = useCallback(
		(modalValue: FilterOrdersByExecutorModal.Value) => {
			closeFilterOrdersByExecutorModal();

			const type = ordersType !== "closed" ? mainOrderTypes : "closed";

			dispatch(
				orderPage.actions.setFilters({
					type,
					data: {
						...filters,
						byExecutor: modalValue.number ? modalValue : undefined,
					},
				}),
			);
		},
		[closeFilterOrdersByExecutorModal, dispatch, filters, ordersType],
	);

	const [isFilterOrdersModalOpen, setIsFilterOrdersModalOpen] =
		useState(false);

	const openFilterOrdersModal = useCallback(() => {
		setIsFilterOrdersModalOpen(true);
	}, []);

	const closeFilterOrdersModal = useCallback(() => {
		setIsFilterOrdersModalOpen(false);
	}, []);

	const filterOrdersModalOnReset = useCallback(() => {
		closeFilterOrdersModal();

		const type = ordersType !== "closed" ? mainOrderTypes : "closed";

		dispatch(
			orderPage.actions.setFilters({
				type,
				data: {
					...filters,
					byOrder: undefined,
				},
			}),
		);
	}, [closeFilterOrdersModal, dispatch, filters, ordersType]);

	const filterOrdersModalOnSubmit = useCallback(
		(modalValue: FilterOrdersModal.Value) => {
			closeFilterOrdersModal();

			const type = ordersType !== "closed" ? mainOrderTypes : "closed";

			const isValid =
				modalValue.from || modalValue.phone || modalValue.to;

			dispatch(
				orderPage.actions.setFilters({
					type,
					data: {
						...filters,
						byOrder: isValid ? modalValue : undefined,
					},
				}),
			);
		},
		[closeFilterOrdersModal, dispatch, filters, ordersType],
	);

	return (
		<>
			<Root align="center" sizes="1fr auto!" gaps="10px*">
				<Tabs
					variant="bookmarks"
					value={ordersType}
					options={tabOptions}
					onChangeSelectedOption={tabsOnChangeSelectedOption}
				/>
				<Row align="center" gaps="8px*">
					<Button
						title={
							t(
								"pages.mainPage.pages.orders.orderHeader.firstRow.str0",
							) ?? ""
						}
						active={filters.showOnlyMyOrders}
						tabIndex={-1}
						onClick={showOnlyMyOrdersOnClick}
					>
						<Icon id="orders-show-my-orders" size={20} />
					</Button>
					<Button
						title={
							t(
								"pages.mainPage.pages.orders.orderHeader.firstRow.str1",
							) ?? ""
						}
						active={filters.showOrdersWithoutExecutors}
						tabIndex={-1}
						onClick={showOrdersWithoutExecutorsOnClick}
					>
						<Icon
							id="orders-show-not-distributed-orders"
							size={20}
						/>
					</Button>
					<Button
						title={
							t(
								"pages.mainPage.pages.orders.orderHeader.firstRow.str2",
							) ?? ""
						}
						settingsKeyId="searchCar"
						active={filters.byCar !== undefined}
						tabIndex={-1}
						onClick={openFilterOrdersByCarModal}
					>
						<Icon id="orders-find-car" size={20} />
					</Button>
					<Button
						title={
							t(
								"pages.mainPage.pages.orders.orderHeader.firstRow.str3",
							) ?? ""
						}
						settingsKeyId="searchExecutor"
						active={filters.byExecutor !== undefined}
						tabIndex={-1}
						onClick={openFilterOrdersByExecutorModal}
					>
						<Icon id="orders-find-executor" size={20} />
					</Button>
					<Button
						title={
							t(
								"pages.mainPage.pages.orders.orderHeader.firstRow.str4",
							) ?? ""
						}
						settingsKeyId="searchOrder"
						active={filters.byOrder !== undefined}
						tabIndex={-1}
						onClick={openFilterOrdersModal}
					>
						<Icon id="orders-find-order" size={20} />
					</Button>
					<Divider
						orientation="vertical"
						size={16}
						color="rgba(0, 0, 0, 0.12);"
					/>
					<Button
						title={
							t(
								"pages.mainPage.pages.orders.orderHeader.firstRow.str5",
							) ?? ""
						}
						tabIndex={-1}
					>
						<Icon id="filter-remove" size={20} />
					</Button>
					<Button
						title={
							t(
								"pages.mainPage.pages.orders.orderHeader.firstRow.str6",
							) ?? ""
						}
						tabIndex={-1}
					>
						<Icon id="car-remove" size={20} />
					</Button>
				</Row>
			</Root>
			{isFilterOrdersByCarModalOpen && (
				<FilterOrdersByCarModal
					value={filters.byCar}
					onClose={closeFilterOrdersByCarModal}
					onReset={filterOrdersByCarModalOnReset}
					onSubmit={filterOrdersByCarModalOnSubmit}
				/>
			)}
			{isFilterOrdersByExecutorModalOpen && (
				<FilterOrdersByExecutorModal
					value={filters.byExecutor}
					onClose={closeFilterOrdersByExecutorModal}
					onReset={filterOrdersByExecutorModalOnReset}
					onSubmit={filterOrdersByExecutorModalOnSubmit}
				/>
			)}
			{isFilterOrdersModalOpen && (
				<FilterOrdersModal
					value={filters.byOrder}
					onClose={closeFilterOrdersModal}
					onReset={filterOrdersModalOnReset}
					onSubmit={filterOrdersModalOnSubmit}
				/>
			)}
		</>
	);
};

declare namespace FirstRow {
	interface Props {}
}

export default FirstRow;
