import React, { useCallback, useMemo, useState } from "react";
import { Button, Column, DateAndTimePicker, Row } from "uikit";
import { useTranslation } from "react-i18next";
import { isNumber } from "lodash";

import TaxiService from "../../../../../../../../services/TaxiService";
import {
	useTypedDispatch,
	useTypedSelector,
} from "../../../../../../../../redux/store";
import getArchiveOrdersForChart from "../../../../../../../../redux/services/Order/getArchiveOrdersForChart";
import archiveReportsByOrders, {
	generateTimePeriods,
} from "../../../../../../../../redux/reducers/Archives/Reports/charts/byOrders";
import useModelSubscribe from "../../../../../../../../hooks/useModelSubscribe";
import useDatePickerLocale from "../../../../../../../../hooks/useDatePickerLocale";
import binarySearch from "../../../../../../../../utils/binarySearch";
import OrderStatus from "../../../../../../../../types/OrderStatus";
import Header from "../../../../../../../../components/BasicPageLayout/Header";
import CompaniesAndTaxiServicesFilter from "../../../../../../../../components/CompaniesAndTaxiServicesFilter";

import ChartTypeSelect from "./components/ChartTypeSelect";
import Chart, { ChartType, OrderChartData } from "./components/Chart";
import IntervalSelect from "./components/IntervalSelect";

const Charts: React.FC = () => {
	const { t } = useTranslation();
	const taxiServices = useModelSubscribe({}, TaxiService)?.cache;
	const dispatch = useTypedDispatch();
	const globalLang = useTypedSelector((state) => state.session.language);
	const mapLang = useTypedSelector((state) => state.mapReducer.mapLang);
	const locale = useDatePickerLocale();
	const { dateFrom, dateTo, filter, orders } = useTypedSelector(
		(state) => state.archiveReports.charts.byOrders,
	);

	const taxiServiceIds = useMemo(
		() =>
			// eslint-disable-next-line no-nested-ternary
			filter.taxiServices[0] !== "all"
				? (filter.taxiServices as number[])
				: filter.companies[0] !== "all"
				? taxiServices
						?.filter(
							(taxiService) =>
								isNumber(taxiService.company?.id) &&
								(filter.companies as Array<number>).includes(
									// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
									taxiService.company!.id,
								),
						)
						.map((taxiService) => taxiService.id)
				: undefined,
		[filter.companies, filter.taxiServices, taxiServices],
	);

	const fetchOrders = useCallback(() => {
		dispatch(
			getArchiveOrdersForChart({
				dateRange: {
					from: dateFrom,
					to: dateTo,
				},
				taxiServiceIds,
			}),
		);
	}, [dateFrom, dateTo, dispatch, taxiServiceIds]);

	const [chartType, setChartType] = useState<ChartType>("columnar");
	const chartData = useMemo(() => {
		// if (orders.length === 0) return null;

		const timePeriods = generateTimePeriods(
			filter.dateRangeLiteral,
			filter.interval,
		);

		const total = {
			label: `mainPage.reports.charts.byOrders.total`,
			data: Array<number>(timePeriods.length).fill(0),
		};
		const opened = {
			label: `mainPage.reports.charts.byOrders.opened`,
			data: Array<number>(timePeriods.length).fill(0),
		};
		const closed = {
			label: `mainPage.reports.charts.byOrders.closed`,
			data: Array<number>(timePeriods.length).fill(0),
		};

		orders.forEach((order) => {
			const timePeriodKey = binarySearch(timePeriods, order.createdAt);

			++total.data[timePeriodKey];

			if (order.status === OrderStatus.CREATED) {
				++opened.data[timePeriodKey];
			} else if (order.status === OrderStatus.CLOSED) {
				++closed.data[timePeriodKey];
			}
		});

		const data: OrderChartData = {
			timePeriods,
			sets: [total, opened, closed],
		};

		return data;
	}, [filter.dateRangeLiteral, filter.interval, orders]);

	const localizedChartData = useMemo(
		() =>
			chartData && {
				timePeriods: chartData.timePeriods.map((tp) =>
					new Date(tp).toLocaleString(
						globalLang,
						filter.interval.format,
					),
				),
				sets: chartData.sets.map((set) => ({
					...set,
					label: t(set.label),
				})),
			},
		[chartData, globalLang, filter.interval.format, t],
	);

	return (
		<Column maxedHeight justify="stretch" align="center">
			<Header>
				<Row maxedWidth gaps="5px*" justify="start" align="center">
					{t(`from`, { context: "capitalized" })}
					<DateAndTimePicker
						value={useMemo(() => new Date(dateFrom), [dateFrom])}
						onChange={(newDate) => {
							if (newDate)
								dispatch(
									archiveReportsByOrders.actions.setDateFrom(
										newDate.getTime(),
									),
								);
						}}
						locale={locale}
					/>

					{t(`to`)}
					<DateAndTimePicker
						value={useMemo(() => new Date(dateTo), [dateTo])}
						onChange={(newDate) => {
							if (newDate)
								dispatch(
									archiveReportsByOrders.actions.setDateTo(
										newDate.getTime(),
									),
								);
						}}
						locale={locale}
					/>

					<Row
						style={{
							flexGrow: 1,
							maxWidth: "800px",
						}}
						gaps="5px*"
						align="center"
						sizes="1fr!*"
					>
						<CompaniesAndTaxiServicesFilter
							companies={filter.companies}
							taxiServices={filter.taxiServices}
							language={mapLang}
							onChangeCompanies={(companyFilter) => {
								dispatch(
									archiveReportsByOrders.actions.setCompanyFilter(
										companyFilter,
									),
								);
							}}
							onChangeTaxiServices={(taxiServiceFilter) => {
								dispatch(
									archiveReportsByOrders.actions.setTaxiServiceFilter(
										taxiServiceFilter,
									),
								);
							}}
						/>

						<Button.Button
							variant="primary"
							text={t(`generate`) || "Generate"}
							onClick={fetchOrders}
						/>

						<ChartTypeSelect
							value={chartType}
							onChange={setChartType}
						/>

						<IntervalSelect
							value={filter.interval.label}
							onChange={(intervalLabel) => {
								dispatch(
									archiveReportsByOrders.actions.setInterval(
										intervalLabel,
									),
								);
							}}
						/>
					</Row>
				</Row>
			</Header>

			<Column
				maxedWidth
				maxedHeight
				justify="center"
				align="center"
				style={{
					paddingBlock: "8px",
					paddingInline: "10px",
					overflow: "hidden",
					position: "relative",
				}}
			>
				{localizedChartData ? (
					<Chart data={localizedChartData} type={chartType} />
				) : (
					<div>{t(`mainPage.reports.charts.noChart`)}</div>
				)}
			</Column>
		</Column>
	);
};

export default Charts;
