import styled from "styled-components";
import { Row, theme } from "uikit";

const Root = styled(Row)<Root.Props>`
	grid-area: ${({ area }) => area};

	font-family: Lato;
	font-size: 13px;
	font-weight: 400;
	line-height: 14px;
	letter-spacing: 0em;

	color: rgb(100, 112, 121);

	${theme.longWord}
`;

declare namespace Root {
	interface Props {
		area: string;
	}
}

export default Root;
