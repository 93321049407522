import React, { useMemo } from "react";

import Order from "../../../../../../../services/Order";
import CellContentRoot from "../CellContentRoot";

const TotalRideCountCellContent: React.FC<TotalRideCountCellContent.Props> = ({
	item,
}) => {
	const content = useMemo(
		() => item.passengers?.[0]?.customer.ordersCount,
		[item.passengers],
	);

	return (
		<CellContentRoot align="center" maxedHeight maxedWidth>
			{content}
		</CellContentRoot>
	);
};

declare namespace TotalRideCountCellContent {
	interface Props {
		item: Order.Model;
	}
}

export default TotalRideCountCellContent;
