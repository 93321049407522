import { useEffect } from "react";

import OrderSettings from "../services/OrderSettings";
import { useTypedDispatch, useTypedSelector } from "../redux/store";
import loading from "../redux/reducers/loading";
import order from "../redux/reducers/settings/order";

import usePRPC from "./usePRPC";

function useOrderSettings() {
	const dispatch = useTypedDispatch();
	const { connected, authorized } = useTypedSelector((state) => state.prpc);
	const prpc = usePRPC();

	useEffect(() => {
		(async () => {
			if (!connected || !authorized || !prpc?.theirsModel?.settings)
				return;

			const orderSettings = (await OrderSettings.getAll())[0];

			if (orderSettings)
				dispatch(order.actions.set(orderSettings.settings));

			dispatch(loading.actions.markLoaded("orderSettings"));
		})();
	}, [authorized, connected, dispatch, prpc?.theirsModel?.settings]);
}

export default useOrderSettings;
