/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-shadow */

import React, { useCallback, useMemo, useState } from "react";
import { isEqual } from "lodash";

import DeleteModal from "../../../../../../components/DeleteModal";
import { useTypedSelector } from "../../../../../../redux/store";
import useModelSubscribe from "../../../../../../hooks/useModelSubscribe2";
import Service from "../../../../../../services/Role";
import mapByKey from "../../../../../../utils/mapByKey";
import useTaxiServiceIdsDecoder from "../../../../../../hooks/useTaxiServiceIdsDecoder";
import useGetCompanyIdsByTaxiServiceIds from "../../../../../../hooks/useGetCompanyIdsByTaxiServiceIds";
import { useSort } from "../../../../../../hooks/useTableSettings";

import { Content, Header, EditModal, Root } from "./components";

const Roles: React.FC = () => {
	const settingsLanguage = useTypedSelector(
		(state) => state.session.language,
	);

	const getCompanyIdsByTaxiServiceIds = useGetCompanyIdsByTaxiServiceIds();
	const decodeTaxiServiceIds = useTaxiServiceIdsDecoder();

	const [headerFilters, setHeaderFilters] = useState<Header.Filters.Value>({
		search: "",
	});
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [editingItem, setEditingItem] = useState<EditModal.Value | null>(
		null,
	);
	const [selected, setSelected] = useState<number[]>([]);

	const modelSubscriptionOptions = useMemo<Service.SubscribeOptions>(
		() => ({
			query: headerFilters.search,
		}),
		[headerFilters.search],
	);

	const modelData = useModelSubscribe(modelSubscriptionOptions, Service);

	const modelItems = useMemo(() => modelData.models, [modelData.models]);

	const modelItemById = useMemo(
		() => mapByKey(modelItems, "id"),
		[modelItems],
	);

	const { sortMethod: sort, setSortMethod: setSort } = useSort(
		"accounts.roles",
		"role",
	);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const edit = useCallback(
		(id: number) => {
			const item = modelItemById[id];

			setEditingItem({
				id: item.id,

				executorIds: item.executorIds ?? [],
				dispatcherIds: item.dispatcherIds ?? [],
				serviceIds: item.serviceIds ?? [],

				name: item.name,

				assignableTo: item.assignableTo,

				companyIds: item.taxiServiceIds
					? getCompanyIdsByTaxiServiceIds(item.taxiServiceIds)
					: [],
				taxiServiceIds: item.taxiServiceIds ?? [],

				description: item.description,

				active: item.active,
				default: item.default,

				permissions: item.permissions,

				createdAt: item.createdAt ?? undefined,
			});
		},
		[getCompanyIdsByTaxiServiceIds, modelItemById],
	);

	const headerCanEdit = useMemo(
		() => selected.length === 1,
		[selected.length],
	);

	const headerCanDelete = useMemo(
		() =>
			selected.length !== 0 &&
			!selected.some((id) => modelItemById[id].default),
		[modelItemById, selected],
	);

	const headerOnAdd = useCallback(() => {
		setEditingItem({
			executorIds: [],
			dispatcherIds: [],
			serviceIds: [],

			name: "",

			assignableTo: undefined,

			companyIds: [],
			taxiServiceIds: [],

			description: "",

			active: true,
			default: false,

			permissions: [],
		});
	}, []);

	const headerOnEdit = useCallback(() => {
		edit(selected[0]);
	}, [edit, selected]);

	const headerOnDelete = useCallback(() => {
		setShowDeleteModal(true);
	}, []);

	const contentOnEdit = useCallback((item) => edit(item.id), [edit]);

	const contentOnLoadMore = useCallback(() => {}, []);

	const content = useMemo(
		() => (
			<>
				<Header
					filters={headerFilters}
					canEdit={headerCanEdit}
					canDelete={headerCanDelete}
					onChangeFilters={setHeaderFilters}
					onAdd={headerOnAdd}
					onEdit={headerOnEdit}
					onDelete={headerOnDelete}
				/>
				<Content
					selected={selected}
					sort={sort}
					loading={false}
					data={modelItems}
					language={settingsLanguage}
					onChangeSelected={setSelected}
					onChangeSort={setSort}
					onEdit={contentOnEdit}
					onLoadMore={contentOnLoadMore}
				/>
			</>
		),
		[
			contentOnEdit,
			contentOnLoadMore,
			headerCanDelete,
			headerCanEdit,
			headerFilters,
			headerOnAdd,
			headerOnDelete,
			headerOnEdit,
			modelItems,
			selected,
			setSort,
			settingsLanguage,
			sort,
		],
	);

	const editModalOnCancel = useCallback(() => {
		setEditingItem(null);
	}, []);

	const editModalOnSave = useCallback(
		async (newItem: EditModal.Value.Validated) => {
			if (isEqual(editingItem, newItem)) {
				setEditingItem(null);

				return;
			}

			const newProperties: Service.Model.New = {
				dispatcherIds: newItem.dispatcherIds,
				executorIds: newItem.executorIds,
				serviceIds: newItem.serviceIds,
				taxiServiceIds: decodeTaxiServiceIds(
					newItem.companyIds,
					newItem.taxiServiceIds,
				),

				name: newItem.name,
				description: newItem.description,
				permissions: newItem.permissions,

				active: newItem.active,
				assignableTo: newItem.assignableTo,
				default: newItem.default,
			};

			if (typeof newItem.id === "number")
				await Service.update({
					id: newItem.id,

					...newProperties,
				});
			else await Service.store(newProperties);

			setEditingItem(null);
		},
		[decodeTaxiServiceIds, editingItem],
	);

	const deleteModalOnCancel = useCallback(() => {
		setShowDeleteModal(false);
	}, []);

	const deleteModalOnConfirm = useCallback(async () => {
		setShowDeleteModal(false);

		await Service.destroy(selected);

		setSelected([]);
	}, [selected]);

	return (
		<Root sizes="auto! 1fr" gaps="16px" maxedWidth maxedHeight>
			{content}
			{editingItem && (
				<EditModal
					value={editingItem}
					language={settingsLanguage}
					onCancel={editModalOnCancel}
					onSave={editModalOnSave}
				/>
			)}
			{showDeleteModal && (
				<DeleteModal
					onCancel={deleteModalOnCancel}
					onConfirm={deleteModalOnConfirm}
				/>
			)}
		</Root>
	);
};

export default Roles;
