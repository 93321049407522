import React, { Dispatch, memo, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import SearchTextBox from "../../../../../../components/SearchTextBox";
import { StyledRow } from "../../../../../../components/common";

const StyledSearchTextBox = styled(SearchTextBox)`
	width: 250px;
`;

const Search: React.FC<Search.Props> = memo(({ onChange, value, text }) => {
	const { t } = useTranslation();

	const searchTextBoxOnChange = useCallback(
		(search: string) => {
			onChange?.(search);
		},
		[onChange],
	);

	const placeholder = useMemo(() => {
		if (text) `${t([text])}`;

		return `${t(["search"])}`;
	}, [t, text]);

	return (
		<StyledRow w="250px" h="32px">
			<StyledSearchTextBox
				placeholder={placeholder}
				value={value}
				onChange={searchTextBoxOnChange}
			/>
		</StyledRow>
	);
});

declare namespace Search {
	interface Props {
		value: Value;
		onChange: Dispatch<Value>;
		text?: string;
	}

	type Value = string;
}

export default Search;
