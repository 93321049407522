/* eslint-disable import/no-unresolved */

import { keys } from "uikit";
import { Keybind } from "uikit/dist/utils/keys";
import { isNil } from "lodash";

import KeyBinder from "../services/KeyBinder";

import { BaseKeybindEntries } from "../redux/reducers/settings/keybinds";
import { useTypedSelector } from "../redux/store";

import useKeyBind from "./useKeyBind";

type Handler = (event: KeyboardEvent) => void;

export function useKeyBindSettings(
	key?: keyof BaseKeybindEntries<"meta"> | undefined | null,
	callback?: Handler,
	options?: KeyBinder.State.Listener.Options,
) {
	const keybindEntry = useTypedSelector((state) =>
		isNil(key) ? [] : state.settings.keybinds[key],
	);

	useKeyBind(keybindEntry as KeyBinder.KeyBind, callback, options);
}

export function useKeyBindName(key: keyof BaseKeybindEntries<"meta">) {
	const keybindEntry = useTypedSelector(
		(state) => state.settings.keybinds[key],
	);

	return (keybindEntry as Keybind)
		.map((k) => keys.keyNames[k] || k)
		.join(" + ");
}

export function useKeyBindParams(key: keyof BaseKeybindEntries<"meta">) {
	const keybindEntry = useTypedSelector(
		(state) => state.settings.keybinds[key],
	) as Keybind;

	const altKey = keybindEntry.includes("Alt");
	const shiftKey = keybindEntry.includes("Shift");
	const ctrlKey = keybindEntry.includes("Control");

	const isEvent = (event: KeyboardEvent): boolean => {
		if (!event) return false;
		const keyCode = event.code;
		const hotkey = [
			event.shiftKey ? event.shiftKey === shiftKey : false,
			event.altKey ? event.altKey === altKey : false,
			event.ctrlKey ? event.ctrlKey === ctrlKey : false,
		];
		if (hotkey.some((item) => item) && keybindEntry.includes(keyCode)) {
			return true;
		}
		return false;
	};

	return {
		isEvent,
		keybindEntry,
		shiftKey,
		altKey,
		ctrlKey,
	};
}
