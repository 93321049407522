/* eslint-disable no-shadow */

import React, {
	Dispatch,
	RefAttributes,
	memo,
	useCallback,
	useMemo,
	useRef,
} from "react";
import { useTranslation } from "react-i18next";
import { Column, Row, TextBox, react, useRefWithSetter } from "uikit";
import Role from "../../../../../../../../../../../../../../services/Role";
import Language from "../../../../../../../../../../../../../../services/Language";
import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";
import useModelSubscribe from "../../../../../../../../../../../../../../hooks/useModelSubscribe2";

import useTaxiServiceIdsDecoder from "../../../../../../../../../../../../../../hooks/useTaxiServiceIdsDecoder";
import LabeledField from "../../../../../../../../../../../../../../components/LabeledField";
import DefaultTaxiServiceId from "./components/DefaultTaxiServiceId";

import mapByKey from "../../../../../../../../../../../../../../utils/mapByKey";
import CarParkIds from "./components/CarParkIds";
import SIPIds from "./components/SIPIds";
import RoleIds from "./components/RoleIds";
import Status from "./components/Status";
import Access from "./components/Access";
import InternalController from "./Controller";

const WorkDataBase = react.withController<
	WorkData.PropsBase,
	WorkData.Controller
>(({ controller, value, disabled, language, onChange }) => {
	const { t } = useTranslation();
	const [accessRef, setAccessRef] = useRefWithSetter<Access.Ref | null>(null);
	const [defaultTaxiServiceIdRef, setDefaultTaxiServiceIdRef] =
		useRefWithSetter<DefaultTaxiServiceId.Ref | null>(null);
	const roleIdsRef = useRef<RoleIds.Ref | null>(null);

	controller.setContext({
		accessRef,
		defaultTaxiServiceIdRef,
		roleIdsRef,
	});

	const decodeTaxiServiceIds = useTaxiServiceIdsDecoder();

	const valueEditor = useObjectEditor(value, onChange);

	const companyIds = valueEditor.get("companyIds");
	const taxiServiceIds = valueEditor.get("taxiServiceIds");

	const decodedTaxiServiceIds = useMemo(
		() => decodeTaxiServiceIds(companyIds, taxiServiceIds),
		[companyIds, decodeTaxiServiceIds, taxiServiceIds],
	);

	const roleData = useModelSubscribe(
		{ taxiServiceIds: decodedTaxiServiceIds, assignableTo: "dispatcher" },
		Role,
	);

	const roles = useMemo(() => roleData.models, [roleData.models]);

	const roleById = useMemo(() => mapByKey(roles, "id"), [roles]);

	const roleIdsValue = valueEditor.useGetter("roleIds");

	const roleIdsOnChange = valueEditor.useSetter("roleIds");

	const carParkIdsValue = valueEditor.useGetter("carParkIds");

	const carParkIdsOnChange = valueEditor.useSetter("carParkIds");

	const softPhoneSIPIdsValue = valueEditor.useGetter("softPhoneSIPIds");

	const softPhoneSIPIdsOnChange = valueEditor.useSetter("softPhoneSIPIds");

	const hardPhoneSIPIdsValue = valueEditor.useGetter("hardPhoneSIPIds");

	const hardPhoneSIPIdsOnChange = valueEditor.useSetter("hardPhoneSIPIds");

	const aliasOnChange = valueEditor.useSetter("alias");

	const accessValue = valueEditor.usePicker(["companyIds", "taxiServiceIds"]);

	const accessOnChange = useCallback(
		(accessValue: Access.Value) => {
			const decodedTaxiServiceIds = decodeTaxiServiceIds(
				accessValue.companyIds,
				accessValue.taxiServiceIds,
			);

			const assignValue: Partial<WorkData.Value> = {
				...accessValue,
			};

			if (decodedTaxiServiceIds.length <= 1)
				// eslint-disable-next-line prefer-destructuring
				assignValue.defaultTaxiServiceId = decodedTaxiServiceIds[0];

			assignValue.roleIds = roleIdsValue.filter((roleId) =>
				roleById[roleId]?.taxiServiceIds?.find(
					(taxiServiceId) => decodedTaxiServiceIds[taxiServiceId],
				),
			);

			valueEditor.assign(assignValue);
		},
		[decodeTaxiServiceIds, roleById, roleIdsValue, valueEditor],
	);

	const defaultTaxiServiceIdOnChange = valueEditor.useSetter(
		"defaultTaxiServiceId",
	);

	const statusOnChange = valueEditor.useSetter("status");

	return (
		<Column gaps="8px*">
			<Row gaps="8px*" sizes="1fr*">
				<LabeledField
					label={
						t(
							"pages.mainPage.pages.accounts.tabs.users.editModal.content.mainTab.workData.str0",
						) ?? ""
					}
				>
					<TextBox.TextBox
						value={valueEditor.get("alias")}
						disabled={disabled}
						style={{
							minHeight: "32px",
							flex: "1 0 0",
						}}
						placeholder={
							t(
								"pages.mainPage.pages.accounts.tabs.users.editModal.content.mainTab.workData.str0",
							) ?? ""
						}
						onChange={aliasOnChange}
					/>
				</LabeledField>
				<Access
					ref={setAccessRef}
					value={accessValue}
					disabled={disabled}
					language={language}
					onChange={accessOnChange}
				/>
				<DefaultTaxiServiceId
					ref={setDefaultTaxiServiceIdRef}
					value={valueEditor.get("defaultTaxiServiceId")}
					disabled={disabled}
					language={language}
					options={decodedTaxiServiceIds}
					onChange={defaultTaxiServiceIdOnChange}
				/>
			</Row>
			<Row gaps="8px*" sizes="1fr*">
				<RoleIds
					ref={roleIdsRef}
					value={roleIdsValue}
					disabled={disabled}
					taxiServiceIds={decodedTaxiServiceIds}
					onChange={roleIdsOnChange}
				/>
				<Status
					value={valueEditor.get("status")}
					disabled={disabled}
					onChange={statusOnChange}
				/>
				<CarParkIds
					value={carParkIdsValue}
					disabled={disabled}
					taxiServiceIds={decodedTaxiServiceIds}
					onChange={carParkIdsOnChange}
				/>
				<div />
			</Row>
			<Row gaps="8px*" sizes="1fr*">
				<SIPIds
					value={softPhoneSIPIdsValue}
					disabled={disabled}
					type="soft-phone"
					onChange={softPhoneSIPIdsOnChange}
				/>
				<SIPIds
					value={hardPhoneSIPIdsValue}
					disabled={disabled}
					type="hard-phone"
					onChange={hardPhoneSIPIdsOnChange}
				/>
				<div />
				<div />
			</Row>
		</Column>
	);
}, InternalController);

const WorkData = memo(WorkDataBase);

declare namespace WorkData {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value extends Access.Value {
		alias: string;
		defaultTaxiServiceId: DefaultTaxiServiceId.Value;
		roleIds: RoleIds.Value;
		status: Status.Value;
		carParkIds: CarParkIds.Value;
		softPhoneSIPIds: SIPIds.Value;
		hardPhoneSIPIds: SIPIds.Value;
	}

	interface PropsBase {
		value: Value;

		disabled: boolean;
		language: Language;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default WorkData;
