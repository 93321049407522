import { getPRPC } from "../../../hooks/usePRPC";
import createRPCQuery from "../../../utils/createRPCQuery.util";

export default function getPopularAddress(id, params) {
	const prpcow = getPRPC();

	if (!prpcow) return null;

	return createRPCQuery(() =>
		prpcow?.theirsModel.customer.getFavoriteAddresses(id, params),
	).then((result) => result);
}
