import React, { lazy, Suspense, useLayoutEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Tabs } from "uikit";

import { useTypedDispatch, useTypedSelector } from "../../../../redux/store";
import getCompanies from "../../../../redux/services/Company/getCompanies";
import getClasses from "../../../../redux/services/Preferences/CarClass/getClasses";
import { hasAccess } from "../../../../access";
import { TabOptions } from "../../../../types";
import useActiveTab from "../../../../hooks/useActiveTab";
import BasicPageLayout from "../../../../components/BasicPageLayout";
import { StyledRow, SuspenseLoader } from "../../../../components/common";

import { TabKeys, TabAccessNames } from "./constants/access";

const LazyOrders = lazy<React.FC<any>>(async () => {
	const elem = await import(
		"./tabs/Orders" /* webpackChunkName: "setting-orders-orders-page" */
	);
	return elem;
});

const LazyOrdersDistribution = lazy<React.FC<any>>(async () => {
	const elem = await import(
		"./tabs/OrdersDistribution" /* webpackChunkName: "setting-orders-orders-distribution-page" */
	);
	return elem;
});

const OrdersSettings: React.FC = () => {
	const { t } = useTranslation();
	const dispatch = useTypedDispatch();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);

	useLayoutEffect(() => {
		if (dispatch) {
			dispatch(getClasses());
			dispatch(getCompanies());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const tabs: TabOptions.Array = useMemo(
		() =>
			[
				{
					key: TabKeys.ORDERS,
					label: t("settings.orders.title"),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<LazyOrders />
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.ORDERS],
				},
				{
					key: TabKeys.ORDERS_DISTRIBUTION,
					label: t("settings.ordersDistribution.title"),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<LazyOrdersDistribution />
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.ORDERS_DISTRIBUTION],
				},
			].filter((btn) => hasAccess(btn.accessName, personalRole)),
		[t, personalRole],
	);

	const { activeKey, activeTab, setActiveKey } = useActiveTab(tabs);

	return (
		<Suspense
			fallback={
				<StyledRow position="absolute" top="50%" left="50%">
					<SuspenseLoader />
				</StyledRow>
			}
		>
			<BasicPageLayout
				navigation={
					<Tabs
						value={activeKey}
						onChange={setActiveKey}
						options={tabs}
						variant="vertical"
					/>
				}
				content={activeTab.value.render()}
			/>
		</Suspense>
	);
};

export default OrdersSettings;
