import React, { useMemo } from "react";
import { isNumber } from "lodash";

import ExecutorReport from "../../../../../../../../../../services/ExecutorReport";
import formatNumber from "../../../../../../../../../../utils/formatNumber";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";

const BonusAmountCellContent: React.FC<BonusAmountCellContent.Props> = ({
	item,
}) => {
	const content = useMemo(() => {
		if (!isNumber(item?.ordersCounters?.bonus?.amount)) {
			return formatNumber(0);
		}
		return formatNumber(item?.ordersCounters?.bonus?.amount);
	}, [item?.ordersCounters?.bonus?.amount]);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace BonusAmountCellContent {
	interface Props {
		item: ExecutorReport.Model;
	}
}

export default BonusAmountCellContent;
