import { extend } from "lodash";
import React, { Dispatch, useMemo } from "react";
import Base from "./components/Base";
import Expandable from "./components/Expandable";
import Root from "./components/Root";

function areProps1(props: Item.Props): props is Item.Props1 {
	return !("options" in props);
}

function areProps2(props: Item.Props): props is Item.Props2 {
	return "options" in props;
}

const Item = extend(
	((props) => {
		const content = useMemo(() => {
			if (areProps1(props))
				return (
					<Base
						value={props.value}
						disabled={props.disabled ?? false}
						label={props.label}
						onChange={props.onChange}
					/>
				);

			return (
				<Expandable
					value={props.value}
					disabled={props.disabled ?? false}
					permission={props.permission}
					label={props.label}
					options={props.options}
					onChange={props.onChange}
				/>
			);
		}, [props]);

		return <Root nested={props.nested}>{content}</Root>;
	}) as React.FC<Item.Props>,
	{
		areProps1,
		areProps2,
	},
);

declare namespace Item {
	type Option = Expandable.Option;
	type Options = Expandable.Options;

	interface PropsBase {
		disabled?: boolean;
		nested?: boolean;
		label: string;
	}

	interface Props1 extends PropsBase {
		value: boolean;

		onChange: Dispatch<boolean>;
	}

	interface Props2 extends PropsBase {
		permission: string;

		value: string[];

		options: Options;

		onChange: Dispatch<string[]>;
	}

	type Props = Props1 | Props2;
}

export default Item;
