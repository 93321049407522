import React, { Dispatch, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneBlacklist from "../../../../../../services/PhoneBlacklist";
import LightInputModal from "../../../../../../components/LightInputModal";
import Content from "./components/Content";

const AddClientToBlacklistModal: React.FC<AddClientToBlacklistModal.Props> = ({
	onSubmit,
	...props
}) => {
	const { t } = useTranslation();
	const [value, setValue] = useState<Content.Value>({
		comment: "",
		level: PhoneBlacklist.Level.WARNING,
	});

	const modalOnSubmit = useCallback(() => {
		onSubmit?.(value);
	}, [onSubmit, value]);

	return (
		<LightInputModal
			{...props}
			draggable={false}
			title={
				t(
					"pages.mainPage.pages.orders.addClientToBlacklistModal.str0",
				) ?? ""
			}
			onSubmit={modalOnSubmit}
		>
			<Content value={value} onChange={setValue} />
		</LightInputModal>
	);
};

declare namespace AddClientToBlacklistModal {
	type Value = Content.Value;

	interface Props
		extends Omit<
			LightInputModal.Props,
			"draggable" | "title" | "onSubmit"
		> {
		onSubmit?: Dispatch<Value>;
	}
}

export default AddClientToBlacklistModal;
