import React, { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Row, CheckBox, TimeInput } from "uikit";
import useObjectEditor from "../../../../../../../../../../../../../../../../../../../../hooks/useObjectEditor";

const ValidityTime: React.FC<ValidityTime.Props> = memo(
	({ value, onChange }) => {
		const { t } = useTranslation();
		const valueEditor = useObjectEditor(value, onChange);

		const active = valueEditor.useGetter("active");
		const setActive = valueEditor.useSetter("active");

		const from = valueEditor.useGetter("from");
		const setFrom = valueEditor.useSetter("from");
		const handleFromChange = useCallback(
			(date) => {
				setFrom(date);
			},
			[setFrom],
		);

		const to = valueEditor.useGetter("to");
		const setTo = valueEditor.useSetter("to");
		const handleToChange = useCallback(
			(date) => {
				setTo(date);
			},
			[setTo],
		);

		return (
			<Row gaps="10px*" align="center">
				<CheckBox
					label={
						t(
							"pages.settings.pages.tariffs.tabs.auto.modal.content.tabs.main.rules.modal.content.validityTime.str0",
						) ?? ""
					}
					value={active}
					onChange={setActive}
				/>
				<TimeInput value={from} onChange={handleFromChange} />
				<span>
					{t(
						"pages.settings.pages.tariffs.tabs.auto.modal.content.tabs.main.rules.modal.content.validityTime.str100",
					) ?? ""}
				</span>
				<TimeInput value={to} onChange={handleToChange} />
			</Row>
		);
	},
);

declare namespace ValidityTime {
	interface Props {
		value: Value;
		onChange: (value: Value) => void;
	}

	interface Value {
		active: boolean;
		from: Date;
		to: Date;
	}
}

export default ValidityTime;
