import React from "react";
import { Icon, theme } from "uikit";
import { noop } from "lodash";
import Root from "./components/Root";
import Label from "./components/Label";
import Button from "./components/Button";

const Item: React.FC<Item.Props> = ({ label, disabled, onDelete }) => (
	<Root align="center" gaps="10px" sizes="1fr auto!">
		<Label>{label}</Label>
		<Button disabled={disabled} onClick={disabled ? noop : onDelete}>
			<Icon
				id="trash"
				size={20}
				colors={[theme.colors.text_hovered_placeholder]}
			/>
		</Button>
	</Root>
);

declare namespace Item {
	interface Props {
		label: string;

		disabled: boolean;

		onDelete: () => void;
	}
}

export default Item;
