/* eslint-disable no-sequences */
/* eslint-disable no-shadow */
import React, {
	Dispatch,
	ReactElement,
	useCallback,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { UseTranslationResponse, useTranslation } from "react-i18next";
import { CheckBox, Column, Table, useRender } from "uikit";
import { SortType } from "rsuite-table";

import Schedule from "../../../../../../../../../../../../../../services/Schedule";

import Language from "../../../../../../../../../../../../../../services/Language";

import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";
import LightTable from "../../../../../../../../../../../../../../components/LightTable";
import TableSettings from "../../../../../../../../../../../../../../components/TableSettings";

import { columns, days, defaultColumnIds } from "./constants";
import Header from "./components/Header";

interface ColumnContext {
	t: UseTranslationResponse<"translation", undefined>[0];
	language: Language;
}

const Columns: Record<string, (context: ColumnContext) => ReactElement> = {
	days: ({ t }) => (
		<LightTable.Column flexGrow={1}>
			<LightTable.HeaderCell verticalAlign="middle">
				{t(
					"pages.mainPage.pages.accounts.tabs.workShifts.editModal.content.schedulesTab.modelTable.str150",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell verticalAlign="middle">
				{(item) =>
					days
						.filter((day) => item[day])
						.map((day) => t(`days-of-week.${day}`))
						.join(", ")
				}
			</LightTable.Cell>
		</LightTable.Column>
	),

	from: ({ t }) => (
		<LightTable.Column width={150}>
			<LightTable.HeaderCell verticalAlign="middle">
				{t(
					"pages.mainPage.pages.accounts.tabs.workShifts.editModal.content.schedulesTab.modelTable.str151",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell verticalAlign="middle">
				{(item) =>
					new Date(item.startTime).toLocaleTimeString("en-us", {
						hour: "2-digit",
						minute: "2-digit",
						second: "2-digit",
					})
				}
			</LightTable.Cell>
		</LightTable.Column>
	),

	to: ({ t }) => (
		<LightTable.Column width={150}>
			<LightTable.HeaderCell verticalAlign="middle">
				{t(
					"pages.mainPage.pages.accounts.tabs.workShifts.editModal.content.schedulesTab.modelTable.str152",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell verticalAlign="middle">
				{(item) =>
					new Date(item.endTime).toLocaleTimeString("en-us", {
						hour: "2-digit",
						minute: "2-digit",
						second: "2-digit",
					})
				}
			</LightTable.Cell>
		</LightTable.Column>
	),
};

const ModelTable: React.FC<ModelTable.Props> = ({
	selected,
	sort,
	enabled,
	loading,
	data,
	language,
	canAdd,
	canEdit,
	canDelete,
	onChangeSelected,
	onChangeSort,
	onLoadMore,
	onAdd,
	onEdit,
	onDelete,
}) => {
	const render = useRender();

	const [t] = useTranslation();

	const canLoadMoreRef = useRef(true);
	const tableRef = useRef<Table.Ref | null>(null);

	const headerOnEdit = useCallback(() => {
		onEdit();
	}, [onEdit]);

	const selectedEditor = useObjectEditor(selected, onChangeSelected);

	const tableRowClassName = useCallback(
		(item) => {
			if (!enabled) return "not-active";

			if (selected.includes(data.indexOf(item))) return "selected";

			return "";
		},
		[data, enabled, selected],
	);

	const tableOnRowClick = useCallback(
		(item) => {
			if (!enabled) return;

			if (selectedEditor.includes(item.id))
				selectedEditor.removeByValue(item.id);
			else selectedEditor.push(item.id);
		},
		[enabled, selectedEditor],
	);

	const tableOnScroll = useMemo(() => {
		canLoadMoreRef.current = true;

		return (x: number, y: number) => {
			if (!canLoadMoreRef.current) return;

			const contextHeight = data.length * 44;
			const top = Math.abs(y);
			const tableContainerHeight =
				tableRef.current?.root.getBoundingClientRect().height ?? 0;

			if (contextHeight - top - tableContainerHeight < 300) {
				onLoadMore();

				canLoadMoreRef.current = false;
			}
		};
	}, [data.length, onLoadMore]);

	const [columnIds, setColumnIds] = useState(defaultColumnIds);

	const columnContext = useMemo<ColumnContext>(
		() => ({ t, language }),
		[language, t],
	);

	useLayoutEffect(() => {
		render(true);
	}, [render]);

	return (
		<Column sizes="auto! 1fr auto!" maxedWidth maxedHeight>
			<Header
				canAdd={canAdd}
				canEdit={canEdit}
				canDelete={canDelete}
				onAdd={onAdd}
				onEdit={headerOnEdit}
				onDelete={onDelete}
			/>
			<div>
				<LightTable
					ref={tableRef}
					fillHeight
					virtualized
					shouldUpdateScroll={false}
					headerHeight={44}
					rowHeight={44}
					sortColumn={sort.column}
					sortType={sort.type}
					loading={loading}
					data={data}
					rowClassName={tableRowClassName}
					onRowClick={tableOnRowClick}
					onRowDoubleClick={onEdit}
					onScroll={tableOnScroll}
					onSortColumn={(column, type) =>
						onChangeSort({ column, type })
					}
				>
					<LightTable.Column width={36}>
						<LightTable.HeaderCell verticalAlign="middle">
							<CheckBox
								value={
									!!data.length &&
									data.every((item) =>
										selected.includes(item.id),
									)
								}
								disabled={!enabled}
								onChange={(value) => {
									if (value)
										selectedEditor.concatUnique(
											data.map((item) => item.id),
										);
									else selectedEditor.clear();
								}}
							/>
						</LightTable.HeaderCell>
						<LightTable.Cell verticalAlign="middle">
							{(item) => (
								<CheckBox
									value={selected.includes(item.id)}
									disabled={!enabled}
									onChange={(selected) => {
										if (selected)
											selectedEditor.push(item.id);
										else
											selectedEditor.removeByValue(
												item.id,
											);
									}}
								/>
							)}
						</LightTable.Cell>
					</LightTable.Column>

					{columnIds.map((columnId) =>
						Columns[columnId](columnContext),
					)}
				</LightTable>
			</div>
			<TableSettings
				value={columnIds}
				defaultValue={defaultColumnIds}
				columns={columns}
				onChange={setColumnIds}
			/>
		</Column>
	);
};

declare namespace ModelTable {
	interface Sort {
		column?: string;
		type?: SortType;
	}

	type Day = Schedule.Model.Day;

	interface Item extends Record<Day, boolean> {
		id: number;

		startTime: number;
		endTime: number;
	}

	type Data = Item[];

	interface Props {
		selected: number[];
		sort: Sort;

		enabled: boolean;
		loading: boolean;
		data: Data;
		language: Language;

		canAdd: boolean;
		canEdit: boolean;
		canDelete: boolean;

		onChangeSelected: Dispatch<number[]>;
		onChangeSort: Dispatch<Sort>;

		onLoadMore: () => void;

		onAdd: () => void;
		onEdit: (item?: Item) => void;
		onDelete: () => void;
	}
}

export default ModelTable;
