import { TFunction } from "i18next";
import tPath from "./tPath";

export default function getDeleteModalTitle(
	selectedLength: number,
	t: TFunction<"translation", undefined, "translation">,
) {
	const title =
		selectedLength === 1
			? t(`${tPath}.deleteModal.title`)
			: t(`${tPath}.deleteModal.title2`);
	if (title.includes("settings.tariffs")) {
		return "Delete selected tariff(-s)?";
	}
	return title;
}
