import React, { useMemo } from "react";

import ExecutorReport from "../../../../../../../../../../services/ExecutorReport";
import Language from "../../../../../../../../../../services/Language";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";

const GroupCellContent: React.FC<GroupCellContent.Props> = ({
	item,
	language,
}) => {
	const content = useMemo(() => {
		if (!item || !item.group) return "";

		return item.group?.name?.[language] || "";
	}, [item, language]);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace GroupCellContent {
	interface Props {
		item: ExecutorReport.Model;
		language: Language;
	}
}

export default GroupCellContent;
