import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { useTranslation } from "react-i18next";

import Executor from "../../../../../../../services/Executor";
import CarService from "../../../../../../../services/Car";
import BaseModal from "../../../../../../LightInputModal";
import { ValueLanguage } from "../../../../../../../assets/languages/langs";
import KeyBind from "../../../../../../KeyBind";
import Root from "./components/Root";
import Car from "./components/Car";

interface Props {
	onClose: () => void;
	executor: Executor.Model;
	language: ValueLanguage;
	selectedCar: CarService.Model | null;
	onSave: (car: CarService.Model) => void;
}

const CarModal: React.FC<Props> = ({
	onClose,
	executor,
	language,
	selectedCar,
	onSave,
}) => {
	const { t } = useTranslation();
	const selectedRef = useRef<HTMLDivElement | null>(null);

	const [selectedCarId, setSetSelectedCarId] = useState(
		selectedCar?.id ?? executor.cars?.[0]?.id,
	);

	const selectedCarIndex = useMemo(
		() => executor.cars?.findIndex((car) => car.id === selectedCarId) ?? 0,
		[executor.cars, selectedCarId],
	);

	const carOnSelect = useCallback((car: CarService.Model) => {
		setSetSelectedCarId(car.id);
	}, []);

	const onSaveCar = useCallback(() => {
		const car = executor.cars?.find((car) => car.id === selectedCarId);
		if (car) {
			onSave(car);

			onClose();
		}
	}, [executor.cars, onClose, onSave, selectedCarId]);

	useEffect(() => {
		selectedRef.current?.scrollIntoView();
	}, [selectedCarIndex]);

	return (
		<BaseModal
			title={
				t("orderPageWidgets.executors.executorModal.carModal.str0") ??
				""
			}
			onClose={onClose}
			onSubmit={onSaveCar}
		>
			<KeyBind
				keys={["ArrowUp"]}
				onUse={() => {
					const nextSelectedIndex = Math.max(selectedCarIndex - 1, 0);

					setSetSelectedCarId(executor.cars?.[nextSelectedIndex]?.id);
				}}
			/>
			<KeyBind
				keys={["ArrowDown"]}
				onUse={() => {
					const nextSelectedIndex = Math.min(
						selectedCarIndex + 1,
						executor.cars?.length ?? 0,
					);

					setSetSelectedCarId(executor.cars?.[nextSelectedIndex]?.id);
				}}
			/>
			<Root tabIndex={0}>
				{executor.cars?.map((car, index) => (
					<Car
						ref={
							selectedCarIndex === index ? selectedRef : undefined
						}
						key={car.id}
						car={car}
						language={language}
						active={selectedCarId === car.id}
						onSelect={carOnSelect}
					/>
				))}
			</Root>
		</BaseModal>
	);
};

export default CarModal;
