/* eslint-disable no-shadow */

import React, { Dispatch, useCallback, useMemo } from "react";
import { get, isObject, isUndefined } from "lodash";
import { useTranslation } from "react-i18next";

import Permission from "../../../../../../../../../../../../../../services/Permission";
import usePermissionService from "../../../../../../../../../../../../../../hooks/usePermissionService";

import { Root, Viewer, Selector, Divider } from "./components";

function treeToOptions(
	name: string,
	tree: Permission.Tree,
	label: (name: string) => string,
): Select.Options {
	return Object.entries(tree).map(([nodeName, nodeTree]) => {
		const value = name ? `${name}.${nodeName}` : nodeName;

		if (nodeTree === true)
			return {
				key: value,
				label: label(value),
				value,
			};

		return {
			key: value,
			label: label(value),
			value,
			options: treeToOptions(value, nodeTree, label),
		};
	});
}

function findNormalTreePath(tree: Permission.Tree): string | undefined {
	const keys = Object.keys(tree);

	if (keys.length === 1) {
		const nodeName = keys[0];

		if (!isObject(tree[nodeName])) return undefined;

		const otherPath = findNormalTreePath(tree[nodeName] as Permission.Tree);

		if (isUndefined(otherPath)) return undefined;

		if (!otherPath) return nodeName;

		return `${nodeName}.${otherPath}`;
	}

	return "";
}

const Select: React.FC<Select.Props> = ({
	value,
	disabled,
	allowed,
	onChange,
}) => {
	const [t] = useTranslation();

	const permissionService = usePermissionService();

	const availablePermissions = useMemo(
		() => permissionService.decode(allowed || ["*"]),
		[allowed, permissionService],
	);

	const internalValue = useMemo(
		() => permissionService.decode(value, availablePermissions),
		[availablePermissions, permissionService, value],
	);

	const options = useMemo(() => {
		const tree = Permission.toTree(availablePermissions);

		const normalTreePath = findNormalTreePath(tree);

		if (isUndefined(normalTreePath)) return [];

		const normalTree = get(tree, normalTreePath) as Permission.Tree;

		return isObject(normalTree)
			? treeToOptions(
					normalTreePath,
					normalTree as Permission.Tree,
					(value: string) => t(`permissions.${value}.label`),
			  )
			: [];
	}, [availablePermissions, t]);

	const selectorOnChange = useCallback(
		(value: Selector.Value) => {
			onChange(permissionService.encode(value));
		},
		[onChange, permissionService],
	);

	console.log("role internalValue", internalValue);

	return (
		<Root sizes="1fr 1px! 1fr" maxedWidth maxedHeight>
			<Selector
				value={internalValue}
				disabled={disabled}
				options={options}
				onChange={selectorOnChange}
			/>
			<Divider />
			<Viewer
				value={internalValue}
				disabled={disabled}
				onChange={onChange}
			/>
		</Root>
	);
};

declare namespace Select {
	type Value = string[];

	type Option = Selector.Option;
	type Options = Selector.Options;

	interface Props {
		value: Value;

		disabled: boolean;
		allowed?: string[];

		onChange: Dispatch<Value>;
	}
}

export default Select;
