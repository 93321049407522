import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CheckBox } from "uikit";

import { UseCreateFormReturn } from "../../../../../../../../hooks/useCreateForm";
import { Schema } from "../../../../../../../../redux/constants/OrdersPage/order";

interface RateIncludePickupCostProps {
	form: UseCreateFormReturn<Schema>;
	disabled: boolean;
}

const Taximeter: React.FC<RateIncludePickupCostProps> = ({
	form,
	disabled,
}) => {
	const { t } = useTranslation();

	return (
		<Controller
			name="taximeter"
			control={form.control}
			render={({ field }) => (
				<CheckBox
					disabled={disabled}
					value={Boolean(field.value)}
					onChange={(value) => field.onChange(value)}
					label={
						t(
							"pages.mainPage.pages.orders.orderModal.orderRideInformation.str207",
						) ?? ""
					}
				/>
			)}
		/>
	);
};

export default Taximeter;
