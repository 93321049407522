import React, { memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Column, MultilineTextBox, Row, Stepper } from "uikit";

import InputModal from "../InputModal";
import LabeledField from "../LabeledField";

const BalanceModal: React.FC<BalanceModal.Props> = memo(
	({
		onClose,
		onSubmit,
		title,
		defaultAmount,
		defaultDescription,
		error = "",

		...props
	}) => {
		const { t } = useTranslation();
		const [amount, setAmount] = useState(defaultAmount || 0);
		const [description, setDescription] = useState(
			defaultDescription || "",
		);

		const internalOnSubmit = useCallback(() => {
			onSubmit({ amount, description });
		}, [onSubmit, amount, description]);

		// TODO: It's a crutch solution, needs to be redone
		const setStepperFocus = useCallback((ref: HTMLInputElement | null) => {
			setTimeout(() => {
				ref?.focus();
			}, 200);
		}, []);

		return (
			<InputModal
				onClose={onClose}
				onSubmit={internalOnSubmit}
				title={title ?? t("balanceModal.str200") ?? ""}
				{...props}
			>
				<Column
					style={{ width: 300, minHeight: 300, padding: 24 }}
					sizes="auto 1fr"
					gaps="8px*"
				>
					<LabeledField label={t("balanceModal.str0") ?? ""}>
						<Row sizes="1fr" maxedWidth>
							<Stepper
								autoFocus
								getRef={setStepperFocus}
								decimalCount={2}
								value={amount ?? 0}
								onChange={setAmount as any}
							/>
						</Row>
					</LabeledField>
					<LabeledField label={t("balanceModal.str1") ?? ""}>
						<MultilineTextBox
							error={error}
							value={description}
							placeholder={t("balanceModal.str1") ?? ""}
							style={{ flex: "1 0 0" }}
							onChange={setDescription}
						/>
					</LabeledField>
				</Column>
			</InputModal>
		);
	},
);

declare namespace BalanceModal {
	interface Props extends Omit<InputModal.Props, "onSubmit" | "onClose"> {
		onClose: () => void;
		onSubmit: (value: { amount: number; description: string }) => void;
		defaultAmount?: number;
		defaultDescription?: string;
		error?: string | boolean | undefined;
		isSave?: boolean;
	}
}

export default BalanceModal;
