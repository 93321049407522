/* eslint-disable no-shadow */

import React, { useMemo } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { useTypedSelector } from "../../../../redux/store";
import linksConstants from "../../../../redux/constants/links.constants";
import { SVGgeneral } from "../../../../utils/generalSprite";
import { hasAccess } from "../../../../access";
import { TabKeys, TabAccessNames } from "../../constants/access";

import s from "./NavInfo.module.css";

export default function NavInfo(props) {
	const { t } = useTranslation();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);

	const tabs = useMemo(
		() =>
			[
				{
					id: TabKeys.SETTINGS_CAR_MODELS_AND_BRANDS,
					name: t("pages.preferencesPages.navInfo.str200") ?? "",
					background: "settingsCarModelsAndBrands",
					linkPath: linksConstants.preferences.carSettings,
					isActive: false,
					accessName:
						TabAccessNames[TabKeys.SETTINGS_CAR_MODELS_AND_BRANDS],
				},
				{
					id: TabKeys.CAR_CLASSES,
					name: t("pages.preferencesPages.navInfo.str201") ?? "",
					background: "carClass",
					linkPath: linksConstants.preferences.carClass,
					isActive: false,
					accessName: TabAccessNames[TabKeys.CAR_CLASSES],
				},
				// {
				// 	id: TabKeys.CAR_TYPES,
				// 	name: {t("pages.preferencesPages.navInfo.str202") ?? ""},
				// 	background: "carType",
				// 	linkPath: linksConstants.preferences.carTypes,
				// 	isActive: false,
				//  accessName: TabAccessNames[TabKeys.CAR_TYPES],
				// },
				{
					id: TabKeys.COLORS,
					name: t("pages.preferencesPages.navInfo.str203") ?? "",
					background: "colors",
					linkPath: linksConstants.preferences.colors,
					isActive: false,
					accessName: TabAccessNames[TabKeys.COLORS],
				},
				{
					id: TabKeys.OBJECTS,
					name: t("pages.preferencesPages.navInfo.str204") ?? "",
					background: "object",
					linkPath: linksConstants.preferences.objects,
					isActive: false,
					accessName: TabAccessNames[TabKeys.OBJECTS],
				},
				{
					id: TabKeys.SECTORS,
					name: t("pages.preferencesPages.navInfo.str205") ?? "",
					background: "map",
					linkPath: linksConstants.preferences.sectors,
					isActive: false,
					accessName: TabAccessNames[TabKeys.SECTORS],
				},
				{
					id: TabKeys.PARKINGS,
					name: t("pages.preferencesPages.navInfo.str206") ?? "",
					background: "parking",
					linkPath: linksConstants.preferences.parkings,
					isActive: false,
					accessName: TabAccessNames[TabKeys.PARKINGS],
				},
				{
					id: TabKeys.PRICE_ZONES,
					name: t("pages.preferencesPages.navInfo.str207") ?? "",
					background: "priceZone",
					linkPath: linksConstants.preferences.pricesZone,
					isActive: false,
					accessName: TabAccessNames[TabKeys.PRICE_ZONES],
				},
				{
					id: TabKeys.SERVICES,
					name: t("pages.preferencesPages.navInfo.str208") ?? "",
					background: "services",
					linkPath: linksConstants.preferences.carServices,
					isActive: false,
					accessName: TabAccessNames[TabKeys.SERVICES],
				},
			].filter((btn) => hasAccess(btn.accessName, personalRole)),
		[t, personalRole],
	);

	const linkPathArr = useMemo(
		() => tabs.map((item) => item.linkPath),
		[tabs],
	);

	return (
		<div className={s.nav}>
			<div className={s.wrapperUl}>
				<div className={s.listInfo}>
					{tabs.map((item, index) => (
						<NavLink
							to={item.linkPath}
							key={item.id}
							className={classNames(
								s.liItem,
								props.currentUrl === item.linkPath && s.active,
								linkPathArr.findIndex(
									(item) => item === props.currentUrl,
								) === -1 &&
									index === 0 &&
									s.active,
							)}
						>
							{/* <SVGgeneral className={s.icons} id={item.background} /> */}
							<SVGgeneral
								className={classNames(
									s.icons,
									props.currentUrl === item.linkPath &&
										s.iconActive,
									linkPathArr.findIndex(
										(item) => item === props.currentUrl,
									) === -1 &&
										index === 0 &&
										s.iconActive,
								)}
								id={item.background}
							/>
							<div className={s.name}>{item.name}</div>
						</NavLink>
					))}
				</div>
			</div>
		</div>
	);
}
