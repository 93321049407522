import React, { useMemo } from "react";
import { isNumber } from "lodash";

import ExecutorReport from "../../../../../../../../../../services/ExecutorReport";
import formatNumber from "../../../../../../../../../../utils/formatNumber";
import { CellContentRoot } from "../../../../../../../../../../components/LightTable";

const CompensationByBonusOrdersCellContent: React.FC<
	CompensationByBonusOrdersCellContent.Props
> = ({ item }) => {
	const content = useMemo(() => {
		if (!item) return "";
		if (!isNumber(item.discountCompensation)) return "";
		return formatNumber(item.discountCompensation);
	}, [item]);

	return (
		<CellContentRoot alignItems="center" w="100%" h="100%">
			{content}
		</CellContentRoot>
	);
};

declare namespace CompensationByBonusOrdersCellContent {
	interface Props {
		item: ExecutorReport.Model;
	}
}

export default CompensationByBonusOrdersCellContent;
